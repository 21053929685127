import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./addItemModal.scss";
import { FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { deleteProductDeliveryAction } from "../../../UpdatedStore/actions/ManagementActions";
import { DELETE_DELIVERY_METHODS_RESET } from "../../../UpdatedStore/constants/ManagementProductConstants";

const DeleteDeliveryItem = ({ type, site }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const dispatch = useDispatch();

  const [message, setMessage] = React.useState("");

  const { loading, error, success } = useSelector(
    (state) => state.DeleteDeliveryMethodReducer
  );

  const deleteItemHandler = () => {
    setMessage("");
    dispatch(
      deleteProductDeliveryAction({
        site: site.name,
        id: type.id,
      })
    );
  };

  useEffect(() => {
    if (success || error) {
      setMessageTimeOut();
    }

    setMessage("");
  }, [success, error, modalShow, type, site]);

  const setMessageTimeOut = () => {
    setTimeout(() => {
      dispatch({ type: DELETE_DELIVERY_METHODS_RESET });
      setModalShow(false);
    }, 2000);
  };

  return (
    <>
      <div>
        <FaTrash
          className="text-danger cursor"
          onClick={() => setModalShow(true)}
        />
      </div>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="_delete-modal"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1>Delete Delivery Method</h1>

          <button onClick={() => setModalShow(false)} className="_close-modal">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="28" height="28" rx="8" fill="#E8ECF0" />
              <path
                d="M20.698 8.23344L19.7383 7.28517C19.4786 7.02549 19.0496 7.02549 18.7786 7.28517L13.9915 12.0721L9.1142 7.19484C8.85441 6.93505 8.42537 6.93505 8.15452 7.19484L7.19484 8.15452C6.93505 8.4142 6.93505 8.84323 7.19484 9.1142L12.061 13.9803L7.28517 18.7787C7.02549 19.0384 7.02549 19.4674 7.28517 19.7384L8.24484 20.6981C8.50452 20.9577 8.93355 20.9577 9.20452 20.6981L13.9915 15.911L18.7786 20.6981C19.0383 20.9577 19.4673 20.9577 19.7383 20.6981L20.698 19.7384C20.9576 19.4787 20.9576 19.0497 20.698 18.7787L15.8996 13.9916L20.6867 9.20463C20.9576 8.93344 20.9576 8.50441 20.698 8.23344Z"
                fill="#777C85"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="_hold-content">
            <h2 className="mb-4">
              Are you sure you want to delete this Delivery Method?
            </h2>
            {error && <h6 className="text-danger my-1">{error}</h6>}
            {message && <h6 className="text-danger my-1">{message}</h6>}
            {success && <h6 className="text-success my-1">Successful...</h6>}

            <button
              className="_yes transcale"
              onClick={deleteItemHandler}
              disabled={loading}
            >
              {" "}
              {loading ? "Processing..." : "Delete"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteDeliveryItem;
