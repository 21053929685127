import React, { useEffect } from "react";
import "./yourPlatforms.scss";
import { useSelector } from "react-redux";
import Loader from "../../../UpdatedStore/components/Loader";
import Message from "../../../UpdatedStore/components/Message";
import "../muditaStore/muditaStore.scss";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { MdManageAccounts } from "react-icons/md";
import { useHistory } from "react-router-dom";
import DeleteSiteModal from "./DeleteSiteModal";
const YourPlatforms: React.FC = () => {
  const history = useHistory();
  const { loading, sites, error } = useSelector((state) => state.SitesReducer);

  const auth = useSelector((state) => state.AuthenticationReducer);

  useEffect(() => {}, []);

  const siteHandler = (site) => {
    const url = "http://" + site.domain.domain;
    window.open(url, "_blank");
  };
  // + ":8000/
  const managementHandler = (site) => {
    var randomstring = require("randomstring");
    var config = randomstring.generate(100);
    var hash = randomstring.generate(35);
    const url = `/inventory/all-items?site=${site.name}&wIM=${auth.user?.access_token}&hash=${hash}`;
    history.push(url);
  };

  return (
    <div className="_your-platforms">
      <h2>Your platforms</h2>
      {loading && <Loader />}
      {!loading &&
        sites &&
        sites.map((site, index) => (
          <div className="_on-building-hold" key={index}>
            <div className="_on-building">
              <div className="_hold-btn">
                {site.active && (
                  <DropdownButton
                    title="Actions"
                    id="dropdown-menu-align-right"
                    className="font-weight-bold shadow-none"
                    variant="success"
                  >
                    <Dropdown.Item
                      eventKey="1"
                      onClick={() => siteHandler(site)}
                    >
                      Preview <FaEye className="ml-auto" />
                    </Dropdown.Item>
                    <Dropdown.Divider />

                    <Dropdown.Item
                      eventKey="2"
                      onClick={() => managementHandler(site)}
                    >
                      Manage <MdManageAccounts className="ml-auto" />
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <DeleteSiteModal site={site.name} />
                    {/* <Dropdown.Item eventKey="4">
                      Delete Site <FaTrash className="ml-auto text-danger" />
                    </Dropdown.Item> */}
                  </DropdownButton>
                )}
              </div>
              <div className="_text">
                <span>Website</span>
                <h3>
                  {site.name.substring(0, 13)}
                  {site.name.length > 13 && "..."}
                </h3>
              </div>
            </div>
          </div>
        ))}
      {!loading && (
        <>
          {sites && sites.length === 0 && (
            <Message variant="primary">
              You haven't created any platform yet!
            </Message>
          )}
        </>
      )}
      {error && <Message variant="danger">{error}</Message>}
    </div>
  );
};

export default YourPlatforms;
