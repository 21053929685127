import {
  InputGroup,
  FormControl,
  Row,
  Col,
  Modal,
  Button,
} from "react-bootstrap";
import { HexColorPicker } from "react-colorful";
import { useState } from "react";
import { UpdateNodeAction } from "../../actions/ApiAction";
import { SHOW_MODAL_STATE } from "../../constants/ApiConstants";
import { useDispatch } from "react-redux";

const EditNavbar = ({ nodee, props }) => {
  const dispatch = useDispatch();
  const [node, setNode] = useState(nodee);

  const [TitleColor, setTitleColor] = useState(node.styles.titleColor);
  const [BGColor, setBGColor] = useState(node.styles.bgColor);

  const handleNameChange = (ObjName, ObjIndex) => {
    let updatedNav = node.data.navlinks.map((item, index) => {
      if (index === ObjIndex) {
        return { ...item, name: ObjName };
      } else {
        return item;
      }
    });
    setNode((oldNode) => {
      let newNode = { ...oldNode };
      newNode.data.navlinks = updatedNav;
      return newNode;
    });
  };

  const handleUrlChange = (ObjURL, ObjIndex) => {
    let updatedNav = node.data.navlinks.map((item, index) => {
      if (index === ObjIndex) {
        return { ...item, url: ObjURL };
      } else {
        return item;
      }
    });
    setNode((oldNode) => {
      let newNode = { ...oldNode };
      newNode.data.navlinks = updatedNav;
      return newNode;
    });
  };

  const handleTitleChange = (Obj) => {
    let updatedNav = node.data.title;
    updatedNav = Obj;
    setNode((oldNode) => {
      let newNode = { ...oldNode };
      newNode.data.title = updatedNav;
      return newNode;
    });
  };

  const saveChanges = () => {
    node.styles.bgColor = BGColor;
    node.styles.titleColor = TitleColor;
    dispatch(UpdateNodeAction(node));
    dispatch({ type: SHOW_MODAL_STATE });
  };

  return (
    <>
      <Modal.Body>
        <h4 className="tint opacity font-weight-bold mb-4">PROPERTIES</h4>

        <h6 className="tint font-weight-bold mb-1">*TITLE</h6>
        <InputGroup className="mb-4">
          <InputGroup.Prepend>
            <InputGroup.Text
              id="inputGroup-sizing-default"
              className="tint font-weight-bold"
            >
              NAME
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            aria-label="Default"
            value={node.data.title}
            onChange={(e) => handleTitleChange(e.target.value)}
            aria-describedby="inputGroup-sizing-default"
          />
        </InputGroup>

        <h6 className="tint font-weight-bold mb-1">*STYLES</h6>
        <hr />
        <Row>
          <Col>
            <b className="tint">*TITLE COLOR</b>
            <HexColorPicker color={TitleColor} onChange={setTitleColor} />
          </Col>
          <Col>
            <b className="tint">*BACKGROUND COLOR</b>
            <HexColorPicker color={BGColor} onChange={setBGColor} />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="modalRow">
          <Button
            variant="dark"
            className="btn-dark font-weight-bold rounded"
            onClick={props.onHide}
          >
            Close
          </Button>
          <Button
            className="bg-tint text-white font-weight-bold"
            onClick={saveChanges}
          >
            Save
          </Button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default EditNavbar;
