import { useState } from "react";
import "../styles.scss";
import { Dropdown } from "react-bootstrap";
import { CustomMenu, CustomToggle } from "../../CustomDropDown";
import addplat from "../../../assets/icons/addplat.png";
import search_icon from "../../../assets/icons/search_icon.png";
import { BsChevronUp, BsFilter } from "react-icons/bs";
import { useHistory } from "react-router-dom";

const PlatformHeader = ({
  handleChange,
  applyFormFilter,
  clearSearch,
  formFilterChange,
  searchValue,
  sortBy,
  show,
  handleShowDropdown,
}: any) => {
  const [showFilter, setShowFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);

  const history = useHistory();

  const naviagteHandler = () => {
    history.push("/store/builder");
  };

  return (
    <>
      <div className="platform-header d-flex flex-column flex-md-row justify-content-between align-items-center mt-5">
        <div className="search-section d-flex justify-content-between align-items-center">
          <div className="search-container spacing-top">
            <input
              type="text"
              className="search-input form-control"
              value={searchValue}
              onChange={handleChange}
              name="search"
              placeholder="Search platforms"
            />
            {/* <MdSearch className="search-icon"/> */}
            <img src={search_icon} alt="search icon" className="search-icon" />

            {/* <MdClose className="clear-icon" onClick={clearSearch}/> */}
          </div>

          {/* <div
            className="sort-container"
            onClick={() => handleShowDropdown(false)}
          >
            <Dropdown show={show}>
              <Dropdown.Toggle
                as={CustomToggle}
                aria-expanded={true}
                id="dropdown-button-dark-example1"
              >
                <BsFilter className="sort-icon" onClick={handleShowDropdown} />
              </Dropdown.Toggle>

              <Dropdown.Menu as={CustomMenu} className="sort-menu-container">
                <div id="sort-menu">
                  <div className="d-flex px-4 justify-content-between">
                    <h5 className="primary-color fw-bold">Sort</h5>
                    <div className="close-sort-container">
                      <BsChevronUp
                        className="secondary-color"
                        onClick={() => handleShowDropdown(false)}
                      />
                    </div>
                  </div>
                  <div
                    className="sort-item primary-color fw-bold"
                    onClick={() => sortBy("recent")}
                  >
                    Recently added
                  </div>
                  <div
                    className="sort-item gray-color"
                    onClick={() => sortBy("earlier")}
                  >
                    Earlier added
                  </div>
                  <div
                    className="sort-item gray-color"
                    onClick={() => sortBy("azplatefrom")}
                  >
                    A-Z Platform
                  </div>
                  <div
                    className="sort-item gray-color"
                    onClick={() => sortBy("zaplatefrom")}
                  >
                    Z-A Platform
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div> */}
        </div>

        <div className="button-container" onClick={naviagteHandler}>
          <img src={addplat} alt="button-icon" />
          <div className="create-new-button">Create new</div>
        </div>
      </div>
    </>
  );
};
export default PlatformHeader;
