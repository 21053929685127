import { Alert } from "react-bootstrap";
import { RiAlarmWarningFill } from "react-icons/ri";

const Message = ({ variant, children }) => {
  return (
    <h6 className={`font-weight-bold text-${variant}`}>
      {variant === "danger" && <RiAlarmWarningFill className="mr-1" />}
      {children}
    </h6>
  );
};

export default Message;
