import React, { useEffect, useState } from "react";
import "./Notification.scss";
import { useSelector, useDispatch } from "react-redux";
import SpinnerLoader from "../../UpdatedStore/components/SpinnerLoader";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import moment from 'moment';
import { RemoveNotificationAction } from "../../UpdatedStore/actions/ManagementActions";

const Notification: React.FC = () => {
  
  const history = useHistory();
  const dispatch = useDispatch();
  const { loadingnotf, errornotf, successnotf } = useSelector(
    (state) => state.RemoveNotificationReducer
  );

  const [show, setShow] = useState(false);
  const [loadingN, setLoadingN] = useState(false);

  const [notifications, setNotifications] = useState([]);
  const [notificationslist, setNotificationslist] = useState([]);

  const { loading, sites, error } = useSelector((state) => state.SitesReducer);
  var notListIds = [];
  const loadNotifications = () => {
    if (sites) {
      setLoadingN(true);
      var notiList = [];
     
      sites.map((site_) => {
        site_.notifications.map((noty) => {
          notiList.push({ ...noty, site: site_.name });
          notListIds.push(noty['id'])
        });
      });
      setNotificationslist(notListIds)
      setNotifications([...notiList.reverse()]);
      setTimeout(() => {
        setLoadingN(false);
      }, 2000);
    }
  };

  const removeNotification = () => {
    setNotifications([])
    dispatch(RemoveNotificationAction({ ids: notificationslist }));
    console.log("removeNotification")
    setNotifications([]);
    
    // notListIds = [];
  }
  useEffect(() => {
    loadNotifications();
  }, [sites, show]);

  const redirectOrderHandler = (site, order) => {
    var r = /\d+/;
    var order_id = order.match(r);
    history.push(`/dashboard/sites/orders/${site}/${order_id}`);
  };

  return (
    <div className="notification_warpper">
      <button onClick={() => setShow(true)}>
        <svg
          width="53"
          height="53"
          viewBox="0 0 53 53"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="53" height="53" rx="26.5" fill="#16284D" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.4956 16.2892C27.4706 16.2892 28.4068 16.4612 29.2756 16.7762V16.6692C29.2756 15.1974 28.0782 14 26.6064 14H26.3852C24.9135 14 23.7161 15.1974 23.7161 16.6692V16.7748C24.5844 16.4608 25.5203 16.2892 26.4956 16.2892ZM23.183 35.7989C23.7454 37.0705 25.0183 37.9604 26.4958 37.9604C27.9733 37.9604 29.2462 37.0705 29.8087 35.7989H23.183ZM17.7223 34.3927H35.2694C35.6014 34.3927 35.9054 34.1688 35.9745 33.8441C36.0429 33.5225 35.8963 33.2052 35.599 33.0679C35.5284 33.0222 34.9974 32.6508 34.4658 31.5275C33.4895 29.4645 33.2845 26.5585 33.2845 24.4839C33.2845 24.4798 33.2845 24.4758 33.2844 24.4718C33.2843 24.4668 33.2842 24.4619 33.284 24.4569C33.2696 20.726 30.2299 17.6954 26.4956 17.6954C22.7524 17.6954 19.7071 20.7407 19.7071 24.4839C19.7071 26.5585 19.5022 29.4645 18.5258 31.5275C17.9942 32.6508 17.4632 33.0221 17.3926 33.0679C17.0954 33.2052 16.9488 33.5225 17.0172 33.8441C17.0862 34.1688 17.3903 34.3927 17.7223 34.3927Z"
            fill="white"
          />
        </svg>
      </button>
      {show ? (
        <div className="_box">
          <div className="_wrapper-box">
            <div className="_head">
              <h2 style={{marginTop:'7px'}}>Notification</h2>

              
                {notifications.length == 0 ? '' : <button className="notification-clearAll-button"
                  onClick={() => removeNotification()}
                >Clear All</button>}

              <button onClick={() => setShow(false)}>
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="28" height="28" rx="8" fill="#E8ECF0" />
                  <path
                    d="M20.698 8.23344L19.7383 7.28517C19.4786 7.02549 19.0496 7.02549 18.7786 7.28517L13.9915 12.0721L9.1142 7.19484C8.85441 6.93505 8.42537 6.93505 8.15452 7.19484L7.19484 8.15452C6.93505 8.4142 6.93505 8.84323 7.19484 9.1142L12.061 13.9803L7.28517 18.7787C7.02549 19.0384 7.02549 19.4674 7.28517 19.7384L8.24484 20.6981C8.50452 20.9577 8.93355 20.9577 9.20452 20.6981L13.9915 15.911L18.7786 20.6981C19.0383 20.9577 19.4673 20.9577 19.7383 20.6981L20.698 19.7384C20.9576 19.4787 20.9576 19.0497 20.698 18.7787L15.8996 13.9916L20.6867 9.20463C20.9576 8.93344 20.9576 8.50441 20.698 8.23344Z"
                    fill="#777C85"
                  />
                </svg>
              </button>
            </div>

            <div className="_content" >
              {loadingN && <SpinnerLoader />}
              {!loadingN &&
                notifications.map((notie, key) => (
                  <div
                    className="_coming_notification mb-4 cursor"
                    onClick={() =>
                      redirectOrderHandler(notie.site, notie.message)
                    }
                    key={key}
                  >
                    <div className="_icon">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="40" height="40" rx="20" fill="#E2F8F1" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M19.9959 11.9108C20.8097 11.9108 21.5911 12.0544 22.3163 12.3173V12.228C22.3163 10.9995 21.3169 10 20.0884 10H19.9038C18.6752 10 17.6758 10.9995 17.6758 12.228V12.3162C18.4006 12.0541 19.1818 11.9108 19.9959 11.9108ZM17.2308 28.1958C17.7003 29.2572 18.7628 30 19.9961 30C21.2293 30 22.2918 29.2572 22.7613 28.1958H17.2308ZM12.6727 27.022H27.3194C27.5966 27.022 27.8503 26.8351 27.908 26.564C27.965 26.2956 27.8427 26.0308 27.5946 25.9161C27.5356 25.878 27.0924 25.568 26.6487 24.6303C25.8337 22.9083 25.6627 20.4827 25.6627 18.751C25.6627 18.7435 25.6625 18.736 25.6622 18.7285C25.6502 15.6143 23.1129 13.0846 19.9959 13.0846C16.8714 13.0846 14.3294 15.6265 14.3294 18.751C14.3294 20.4827 14.1584 22.9083 13.3434 24.6303C12.8997 25.568 12.4564 25.8779 12.3976 25.9161C12.1494 26.0308 12.0271 26.2957 12.0841 26.564C12.1418 26.8351 12.3956 27.022 12.6727 27.022Z"
                          fill="#3ED0A3"
                        />
                      </svg>
                    </div>

                    <div className="_title">
                      <h3>
                        {notie.title}({notie.site})
                      </h3>
                      <p>{notie.message}</p>
                      <span>{moment(notie.created).format('DD MMM, YYYY  h:mm A')}</span>
                    </div>
                  </div>
                ))}
              {!loadingN && notifications.length == 0 && (
                <h6 className="tint font-weight-bold text-center">
                  You're all done!
                </h6>
              )}
            </div>

          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Notification;
// function dispatch(arg0: any) {
//   throw new Error("Function not implemented.");
// }

