import {
  SYNC_STORE_FAIL,
  SYNC_STORE_REQUEST,
  SYNC_STORE_SUCCESS,
} from "../constants/constants";
import {
  CREATE_PRODUCT_TYPE_REQUEST,
  CREATE_PRODUCT_TYPE_SUCCESS,
  CREATE_PRODUCT_TYPE_FAIL,
  CREATE_PRODUCT_TYPE_RESET,
  EDIT_PRODUCT_TYPE_REQUEST,
  EDIT_PRODUCT_TYPE_SUCCESS,
  EDIT_PRODUCT_TYPE_FAIL,
  EDIT_PRODUCT_TYPE_RESET,
  DELETE_PRODUCT_TYPE_REQUEST,
  DELETE_PRODUCT_TYPE_SUCCESS,
  DELETE_PRODUCT_TYPE_FAIL,
  DELETE_PRODUCT_TYPE_RESET,
  // CATEGORIES
  CREATE_PRODUCT_CATEGORY_REQUEST,
  CREATE_PRODUCT_CATEGORY_SUCCESS,
  CREATE_PRODUCT_CATEGORY_FAIL,
  CREATE_PRODUCT_CATEGORY_RESET,
  EDIT_PRODUCT_CATEGORY_REQUEST,
  EDIT_PRODUCT_CATEGORY_SUCCESS,
  EDIT_PRODUCT_CATEGORY_FAIL,
  EDIT_PRODUCT_CATEGORY_RESET,
  DELETE_PRODUCT_CATEGORY_REQUEST,
  DELETE_PRODUCT_CATEGORY_SUCCESS,
  DELETE_PRODUCT_CATEGORY_FAIL,
  DELETE_PRODUCT_CATEGORY_RESET,
  GENERATE_APK_REQUEST,
  GENERATE_APK_SUCCESS,
  GENERATE_APK_FAIL,
  GENERATE_APK_RESET,
} from "../constants/ManagementsConstants";

export const syncStoreReducer = (state = {}, action) => {
  switch (action.type) {
    case SYNC_STORE_REQUEST:
      return { loading: true };

    case SYNC_STORE_SUCCESS:
      return { loading: false, success: true };

    case SYNC_STORE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const createProductTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PRODUCT_TYPE_REQUEST:
      return { loading: true };

    case CREATE_PRODUCT_TYPE_SUCCESS:
      return { loading: false, success: true };

    case CREATE_PRODUCT_TYPE_FAIL:
      return { loading: false, error: action.payload };

    case CREATE_PRODUCT_TYPE_RESET:
      return {};

    default:
      return state;
  }
};

export const createApkReducer = (state = {}, action) => {
  switch (action.type) {
    case GENERATE_APK_REQUEST:
      return { loading: true };

    case GENERATE_APK_SUCCESS:
      return { loading: false, success: true };

    case GENERATE_APK_FAIL:
      return { loading: false, error: action.payload };

    case GENERATE_APK_RESET:
      return {};

    default:
      return state;
  }
};

export const editProductTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_PRODUCT_TYPE_REQUEST:
      return { loading: true };

    case EDIT_PRODUCT_TYPE_SUCCESS:
      return { loading: false, success: true };

    case EDIT_PRODUCT_TYPE_FAIL:
      return { loading: false, error: action.payload };

    case EDIT_PRODUCT_TYPE_RESET:
      return {};

    default:
      return state;
  }
};

export const deleteProductTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PRODUCT_TYPE_REQUEST:
      return { loading: true };

    case DELETE_PRODUCT_TYPE_SUCCESS:
      return { loading: false, success: true };

    case DELETE_PRODUCT_TYPE_FAIL:
      return { loading: false, error: action.payload };

    case DELETE_PRODUCT_TYPE_RESET:
      return {};

    default:
      return state;
  }
};

// CATEGORIES
export const createProductCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PRODUCT_CATEGORY_REQUEST:
      return { loading: true };

    case CREATE_PRODUCT_CATEGORY_SUCCESS:
      return { loading: false, success: true };

    case CREATE_PRODUCT_CATEGORY_FAIL:
      return { loading: false, error: action.payload };

    case CREATE_PRODUCT_CATEGORY_RESET:
      return {};

    default:
      return state;
  }
};

export const editProductCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_PRODUCT_CATEGORY_REQUEST:
      return { loading: true };

    case EDIT_PRODUCT_CATEGORY_SUCCESS:
      return { loading: false, success: true };

    case EDIT_PRODUCT_CATEGORY_FAIL:
      return { loading: false, error: action.payload };

    case EDIT_PRODUCT_CATEGORY_RESET:
      return {};

    default:
      return state;
  }
};

export const deleteProductCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PRODUCT_CATEGORY_REQUEST:
      return { loading: true };

    case DELETE_PRODUCT_CATEGORY_SUCCESS:
      return { loading: false, success: true };

    case DELETE_PRODUCT_CATEGORY_FAIL:
      return { loading: false, error: action.payload };

    case DELETE_PRODUCT_CATEGORY_RESET:
      return {};

    default:
      return state;
  }
};
