import React from "react";

import "./numberOfVisitor.scss";
const NumberOfVisitor: React.FC = ({ title, data }) => {
  return (
    <div className="_number-of-visitor">
      <h6 className="_no">{title}</h6>
      <p className="_number">{data}</p>
    </div>
  );
};

export default NumberOfVisitor;
