import { UsersProps } from "../../types";
import UserItem from "./UserItem"
import Notfound from '../../assets/images/notfounduser.svg'


const UsersList = ({ users }: UsersProps) => {
    return (
        <div className="mb-5">
            {
            users.length > 0 ? users.map((user, index) => {
                return <UserItem {...user} key={index} />
            })
            : 
            <div className="mt-5 text-center">
                <img src={Notfound} alt="" />
                <h3 className="primary-color fw-bold mt-5">Your search can’t be found</h3>
                <p className="mt-3 primary-color-light">Please make sure your keyword is correct or <br /> try another keywords</p>
            </div>
        }
        </div>
    )
}
export default UsersList;