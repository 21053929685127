import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./allItems.scss";
import HoldProduct from "../../holdProducts/HoldProduct";
import SpinnerLoader from "../../../../UpdatedStore/components/SpinnerLoader";
import { useHistory } from "react-router-dom";
import {
  GetProductsAction,
  GetPromotionsAction,
} from "../../../../UpdatedStore/actions/ProductManagementActions";
import { Tab, Nav } from "react-bootstrap";

const AllItems: React.FC = ({ siteData }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { sites } = useSelector((state) => state.SitesReducer);

  const [siteProducts, setSiteProducts] = useState(null);
  const [sitePromotions, setSitePromotions] = useState(null);

  const { loading, products } = useSelector(
    (state) => state.ProductsReducer
  );

  const { options } = useSelector((state) => state.FilterWithOptionsReducer);

  const { text: searchText } = useSelector((state) => state.FilterItemReducer);

  const {
    loading: loadingPromotions,
    error: errorPromotions,
    products: productPromotions,
  } = useSelector((state) => state.PromotionsReducer);

  const { descending } = useSelector(
    (state) => state.InventoryArrangementReducer
  );

  useEffect(() => {
    const currentUrl = history.location.search;
    const urlParams = new URLSearchParams(currentUrl);
    const node = urlParams.get("site");

    if (!node) {
      history.push("/dashboard/management");
    }

    if (products && productPromotions) {
      const filtext = searchText.toLowerCase();

      var FilteredProducts = products.filter((product_Item) => {
        const filterConstant = product_Item.name.toLowerCase();
        if (filterConstant.indexOf(filtext) != -1) {
          return product_Item;
        }
      });

      var FilteredPromotions = productPromotions.filter((product_Item2) => {
        const filterConstant2 = product_Item2.name.toLowerCase();
        if (filterConstant2.indexOf(filtext) != -1) {
          return product_Item2;
        }
      });

      if (descending) {
        var shallowList = [...FilteredProducts].reverse();
        var shallowList2 = [...FilteredPromotions].reverse();

        const items_to_set = options
          ? shallowList.filter((iTEM) => {
              var prev_type = siteData?.product_types.find(
                (obj) => obj.id === Number(options.productType)
              );

              if (iTEM.type.toLowerCase() === prev_type?.name.toLowerCase()) {
                if (
                  iTEM.quantity >= options.minStock &&
                  iTEM.quantity <= options.maxStock
                ) {
                  if (
                    iTEM.price >= options.minPrice &&
                    iTEM.price <= options.maxPrice
                  ) {
                    return iTEM;
                  }
                }
              }
            })
          : null;

        const items_to_set2 = options
          ? shallowList2.filter((iTEM) => {
              var prev_type2 = siteData?.product_types.find(
                (obj) => obj.id === Number(options.productType)
              );

              if (iTEM.type.toLowerCase() === prev_type2?.name.toLowerCase()) {
                if (
                  iTEM.quantity >= options.minStock &&
                  iTEM.quantity <= options.maxStock
                ) {
                  if (
                    Number(iTEM.RealPrice) >= options.minPrice &&
                    Number(iTEM.RealPrice) <= options.maxPrice
                  ) {
                    return iTEM;
                  }
                }
              }
            })
          : null;
        setSiteProducts(items_to_set ? items_to_set : shallowList);
        setSitePromotions(items_to_set2 ? items_to_set2 : shallowList2);
      } else {
        var shallowList3 = [...FilteredProducts];
        var shallowList4 = [...FilteredPromotions];

        const items_to_set3 = options
          ? shallowList3.filter((iTEM) => {
              var prev_type3 = siteData?.product_types.find(
                (obj) => obj.id === Number(options.productType)
              );

              if (iTEM.type.toLowerCase() === prev_type3?.name.toLowerCase()) {
                if (
                  iTEM.quantity >= options.minStock &&
                  iTEM.quantity <= options.maxStock
                ) {
                  if (
                    iTEM.price >= options.minPrice &&
                    iTEM.price <= options.maxPrice
                  ) {
                    return iTEM;
                  }
                }
              }
            })
          : null;

        const items_to_set4 = options
          ? shallowList4.filter((iTEM) => {
              var prev_type4 = siteData?.product_types.find(
                (obj) => obj.id === Number(options.productType)
              );

              if (iTEM.type.toLowerCase() === prev_type4?.name.toLowerCase()) {
                if (
                  iTEM.quantity >= options.minStock &&
                  iTEM.quantity <= options.maxStock
                ) {
                  if (
                    Number(iTEM.RealPrice) >= options.minPrice &&
                    Number(iTEM.RealPrice) <= options.maxPrice
                  ) {
                    return iTEM;
                  }
                }
              }
            })
          : null;

        setSiteProducts(items_to_set3 ? items_to_set3 : shallowList3);
        setSitePromotions(items_to_set4 ? items_to_set4 : shallowList4);
      }
    }
  }, [
    history,
    searchText,
    productPromotions,
    products,
    descending,
    options,
    sites,
  ]);

  return (
    <div className="storeBox__">
      <div className="_hold-management-tabs">
        <Tab.Container defaultActiveKey="products">
          <div className="wrap-tabs">
            <Nav variant="pills" className="_tabs">
              <Nav.Item>
                <Nav.Link eventKey="products"> Products </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="promotions">Promotions</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          <div>
            <Tab.Content>
              <Tab.Pane eventKey="products">
                {loading && <SpinnerLoader />}
                {!loading && siteProducts && siteProducts?.length <= 0 && (
                  <h2 className="text-info font-weight-bold text-center p-4">
                    Nothing to see here!
                  </h2>
                )}

                {siteProducts && siteProducts?.length > 0 && (
                  <div className="_hold-stores">
                    {siteProducts?.map((productItem, index) => (
                      <HoldProduct
                        site={siteData}
                        productItem={productItem}
                        product={true}
                        key={index}
                      />
                    ))}
                  </div>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="promotions">
                {loadingPromotions && <SpinnerLoader />}
                {!loadingPromotions &&
                  sitePromotions &&
                  sitePromotions?.length <= 0 && (
                    <h2 className="text-info font-weight-bold text-center p-4">
                      Nothing to see here!
                    </h2>
                  )}

                {sitePromotions && sitePromotions?.length > 0 && (
                  <div className="_hold-stores">
                    {sitePromotions?.map((productItem, index) => (
                      <HoldProduct
                        site={siteData}
                        productItem={productItem}
                        promotion={true}
                        key={index}
                      />
                    ))}
                  </div>
                )}
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
    </div>
  );
};

export default AllItems;
