import React, { useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import "./OrderStatus.scss";
import ActiveOrders from "./activeOrders/ActiveOrders";
import PreviousOrders from "./previousOrders/PreviousOrders";

const OrderStatus: React.FC = () => {
  const [exportShow, setExportShow] = useState(false);
  return (
    <div className="order-status">
      <Tab.Container defaultActiveKey="ActiveOrderes">
        <div className="wrap-tabs">
          <Nav variant="pills" className="_tabs">
            <Nav.Item>
              <Nav.Link eventKey="ActiveOrderes"> Active orders </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="PreviousOrders">Previous orders</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>

        <div>
          <Tab.Content>
            <Tab.Pane eventKey="ActiveOrderes">
              <ActiveOrders />
            </Tab.Pane>
            <Tab.Pane eventKey="PreviousOrders">
              <PreviousOrders />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
  );
};

export default OrderStatus;
