import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import "./addItemModal.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  CREATE_PRODUCT_IMAGE_RESET,
  DELETE_PRODUCT_IMAGE_RESET,
} from "../../../UpdatedStore/constants/ManagementProductConstants";
import {
  GetProductsAction,
  GetPromotionsAction,
} from "../../../UpdatedStore/actions/ProductManagementActions";
import SpinnerLoader from "../../../UpdatedStore/components/SpinnerLoader";
import {
  CreateProductImageAction,
  deleteProductImageAction,
} from "../../../UpdatedStore/actions/ManagementActions";

const ImagesAction = ({ site, product, key }) => {
  const dispatch = useDispatch();

  const [modalShow, setModalShow] = React.useState(false);

  //   form states
  const [image, setImage] = useState("Upload Image...");
  const [files, setFiles] = useState("");

  const [message, setMessage] = React.useState("");

  const { loading, error, success } = useSelector(
    (state) => state.CreateProductImageReducer
  );

  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = useSelector((state) => state.DeleteProductImageReducer);

  const addNewType = () => {
    setMessage("");
    if (product.images.length < 5) {
      if (files) {
        var formData = new FormData();
        formData.append("image", files);
        formData.append("site", site?.name);
        formData.append("id", product._id);
        dispatch(CreateProductImageAction(formData));
      } else {
        setMessage("Please pick a valid Image!");
      }
    }
  };

  const DeleteImageHandler = (id) => {
    setMessage("");

    if (product.images.length > 1) {
      dispatch(deleteProductImageAction({ site: site?.name, id: id }));
    } else {
      setMessage("You can have a minimum of 1 image!");
      window.scrollTo(0, 100);
    }
  };

  useEffect(() => {
    if (success) {
      setMessageTimeOut();
    }

    if (successDelete) {
      setCustomTimeOut();
    }
    setImage("Upload Image...");
    setFiles("");

    setMessage("");
  }, [success, successDelete, modalShow]);

  const setMessageTimeOut = () => {
    setTimeout(() => {
      dispatch({ type: CREATE_PRODUCT_IMAGE_RESET });
      setImage("Upload Image...");
      setFiles("");

      setModalShow(false);
      dispatch(GetProductsAction(site));
      dispatch(GetPromotionsAction(site));
    }, 2000);
  };

  const setCustomTimeOut = () => {
    setTimeout(() => {
      dispatch({ type: DELETE_PRODUCT_IMAGE_RESET });
      setImage("Upload Image...");
      setFiles("");

      setModalShow(false);
      dispatch(GetProductsAction(site));
      dispatch(GetPromotionsAction(site));
    }, 2000);
  };

  return (
    <>
      <button onClick={() => setModalShow(true)} key={key}>Add/Delete Images</button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="_delete-modal"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        key={key}
      >
        <Modal.Header>
          <h1>Images</h1>

          <button onClick={() => setModalShow(false)} className="_close-modal">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="28" height="28" rx="8" fill="#E8ECF0" />
              <path
                d="M20.698 8.23344L19.7383 7.28517C19.4786 7.02549 19.0496 7.02549 18.7786 7.28517L13.9915 12.0721L9.1142 7.19484C8.85441 6.93505 8.42537 6.93505 8.15452 7.19484L7.19484 8.15452C6.93505 8.4142 6.93505 8.84323 7.19484 9.1142L12.061 13.9803L7.28517 18.7787C7.02549 19.0384 7.02549 19.4674 7.28517 19.7384L8.24484 20.6981C8.50452 20.9577 8.93355 20.9577 9.20452 20.6981L13.9915 15.911L18.7786 20.6981C19.0383 20.9577 19.4673 20.9577 19.7383 20.6981L20.698 19.7384C20.9576 19.4787 20.9576 19.0497 20.698 18.7787L15.8996 13.9916L20.6867 9.20463C20.9576 8.93344 20.9576 8.50441 20.698 8.23344Z"
                fill="#777C85"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="_hold-contentProduct">
            <h6 className="text-info">*Double Click an Image to delete</h6>
            <h6 className="text-info">
              *You can have a minimum of 1 and maximum of 5 images
            </h6>

            {loadingDelete ? (
              <SpinnerLoader />
            ) : (
              <>
                <div className="IProductImages">
                  {product.images.map((iItem) => (
                    <div
                      className="oNiewq"
                      onDoubleClick={() => DeleteImageHandler(iItem.id)}
                    >
                      <img src={iItem.original} />
                    </div>
                  ))}
                </div>
                <Form.File
                  id="ImageFile"
                  label={image}
                  onChange={(e) => {
                    setImage(e.target.value);
                    setFiles(e.target.files[0]);
                  }}
                  custom
                  className="my-4"
                ></Form.File>

                {error && <h6 className="text-danger my-1">{error}</h6>}
                {errorDelete && (
                  <h6 className="text-danger my-1">{errorDelete}</h6>
                )}
                {message && <h6 className="text-danger my-1">{message}</h6>}
                {success && (
                  <h6 className="text-success my-1">Successful...</h6>
                )}
                {successDelete && (
                  <h6 className="text-success my-1">Successful...</h6>
                )}

                {product.images.length < 5 && (
                  <button
                    className="_yes transcale oiniut"
                    onClick={addNewType}
                    disabled={loading || !files}
                  >
                    {loading ? "Processing..." : "Save Image"}
                  </button>
                )}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ImagesAction;
