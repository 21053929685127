import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_PROMOTIONS_REQUEST,
  GET_PROMOTIONS_SUCCESS,
  GET_PROMOTIONS_FAIL,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAIL,
  CREATE_PRODUCT_RESET,
  CREATE_PROMOTIONS_REQUEST,
  CREATE_PROMOTIONS_SUCCESS,
  CREATE_PROMOTIONS_FAIL,
  CREATE_PROMOTIONS_RESET,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  DELETE_PRODUCT_RESET,
  DELETE_PROMOTIONS_REQUEST,
  DELETE_PROMOTIONS_SUCCESS,
  DELETE_PROMOTIONS_FAIL,
  DELETE_PROMOTIONS_RESET,
  EDIT_PRODUCT_REQUEST,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_FAIL,
  EDIT_PRODUCT_RESET,
  EDIT_PROMOTIONS_REQUEST,
  EDIT_PROMOTIONS_SUCCESS,
  EDIT_PROMOTIONS_FAIL,
  EDIT_PROMOTIONS_RESET,
  UPDATE_ORDER_STATUS_REQUEST,
  UPDATE_ORDER_STATUS_SUCCESS,
  UPDATE_ORDER_STATUS_FAIL,
  UPDATE_ORDER_STATUS_RESET,
  CREATE_STAFF_REQUEST,
  CREATE_STAFF_SUCCESS,
  CREATE_STAFF_FAIL,
  CREATE_STAFF_RESET,
  EDIT_STAFF_REQUEST,
  EDIT_STAFF_SUCCESS,
  EDIT_STAFF_FAIL,
  EDIT_STAFF_RESET,
  DELETE_STAFF_REQUEST,
  DELETE_STAFF_SUCCESS,
  DELETE_STAFF_FAIL,
  DELETE_STAFF_RESET,
  CREATE_STORE_INFO_REQUEST,
  CREATE_STORE_INFO_SUCCESS,
  CREATE_STORE_INFO_FAIL,
  CREATE_STORE_INFO_RESET,
  EDIT_STORE_INFO_REQUEST,
  EDIT_STORE_INFO_SUCCESS,
  EDIT_STORE_INFO_FAIL,
  EDIT_STORE_INFO_RESET,
  DELETE_STORE_INFO_REQUEST,
  DELETE_STORE_INFO_SUCCESS,
  DELETE_STORE_INFO_FAIL,
  DELETE_STORE_INFO_RESET,
  DELETE_FAQ_RESET,
  DELETE_FAQ_FAIL,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_REQUEST,
  EDIT_FAQ_RESET,
  EDIT_FAQ_FAIL,
  EDIT_FAQ_SUCCESS,
  EDIT_FAQ_REQUEST,
  CREATE_FAQ_RESET,
  CREATE_FAQ_FAIL,
  CREATE_FAQ_SUCCESS,
  CREATE_FAQ_REQUEST,
  DELETE_STORE_IMAGE_REQUEST,
  DELETE_STORE_IMAGE_SUCCESS,
  DELETE_STORE_IMAGE_FAIL,
  DELETE_STORE_IMAGE_RESET,
  CREATE_STORE_IMAGE_REQUEST,
  CREATE_STORE_IMAGE_SUCCESS,
  CREATE_STORE_IMAGE_FAIL,
  CREATE_STORE_IMAGE_RESET,
  CREATE_BLOG_REQUEST,
  CREATE_BLOG_SUCCESS,
  CREATE_BLOG_FAIL,
  CREATE_BLOG_RESET,
  EDIT_BLOG_REQUEST,
  EDIT_BLOG_SUCCESS,
  EDIT_BLOG_FAIL,
  EDIT_BLOG_RESET,
  DELETE_BLOG_REQUEST,
  DELETE_BLOG_SUCCESS,
  DELETE_BLOG_FAIL,
  DELETE_BLOG_RESET,
  CREATE_DELIVERY_METHODS_REQUEST,
  CREATE_DELIVERY_METHODS_SUCCESS,
  CREATE_DELIVERY_METHODS_FAIL,
  CREATE_DELIVERY_METHODS_RESET,
  EDIT_DELIVERY_METHODS_REQUEST,
  EDIT_DELIVERY_METHODS_SUCCESS,
  EDIT_DELIVERY_METHODS_FAIL,
  EDIT_DELIVERY_METHODS_RESET,
  DELETE_DELIVERY_METHODS_REQUEST,
  DELETE_DELIVERY_METHODS_SUCCESS,
  DELETE_DELIVERY_METHODS_FAIL,
  DELETE_DELIVERY_METHODS_RESET,
  CREATE_PRODUCT_IMAGE_REQUEST,
  CREATE_PRODUCT_IMAGE_SUCCESS,
  CREATE_PRODUCT_IMAGE_FAIL,
  CREATE_PRODUCT_IMAGE_RESET,
  DELETE_PRODUCT_IMAGE_REQUEST,
  DELETE_PRODUCT_IMAGE_SUCCESS,
  DELETE_PRODUCT_IMAGE_FAIL,
  DELETE_PRODUCT_IMAGE_RESET,
  GET_ANALYTICS_STATICS_REQUEST,
  GET_ANALYTICS_STATICS_SUCCESS,
  GET_ANALYTICS_STATICS_FAIL,
  CREATE_BULK_PRODUCT_REQUEST,
  CREATE_BULK_PRODUCT_SUCCESS,
  CREATE_BULK_PRODUCT_FAIL,
  CREATE_BULK_PRODUCT_RESET,
  CREATE_STORE_CAROUSEL_IMAGE_REQUEST,
  CREATE_STORE_CAROUSEL_IMAGE_SUCCESS,
  CREATE_STORE_CAROUSEL_IMAGE_FAIL,
  CREATE_STORE_CAROUSEL_IMAGE_RESET,
  DELETE_STORE_CAROUSEL_IMAGE_REQUEST,
  DELETE_STORE_CAROUSEL_IMAGE_SUCCESS,
  DELETE_STORE_CAROUSEL_IMAGE_FAIL,
  DELETE_STORE_CAROUSEL_IMAGE_RESET,
} from "../constants/ManagementProductConstants";
import {
  FILTER_ANALYTICS,
  FILTER_ORDERS,
  FILTER_PRODUCTS,
  FILTER_STAFFS,
  FILTER_WITH_OPTIONS,
  REVERSE_PRODUCTS_LIST,
} from "../constants/ManagementsConstants";

export const ProductsReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case GET_PRODUCTS_REQUEST:
      return { loading: true };

    case GET_PRODUCTS_SUCCESS:
      return { loading: false, products: action.payload };

    case GET_PRODUCTS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const PromotionsReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case GET_PROMOTIONS_REQUEST:
      return { loading: true };

    case GET_PROMOTIONS_SUCCESS:
      return { loading: false, products: action.payload };

    case GET_PROMOTIONS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const CreateProductReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PRODUCT_REQUEST:
      return { loading: true };

    case CREATE_PRODUCT_SUCCESS:
      return { loading: false, success: true };

    case CREATE_PRODUCT_FAIL:
      return { loading: false, error: action.payload };

    case CREATE_PRODUCT_RESET:
      return {};

    default:
      return state;
  }
};

export const CreateBulkProductReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_BULK_PRODUCT_REQUEST:
      return { loading: true };

    case CREATE_BULK_PRODUCT_SUCCESS:
      return { loading: false, success: true };

    case CREATE_BULK_PRODUCT_FAIL:
      return { loading: false, error: action.payload };

    case CREATE_BULK_PRODUCT_RESET:
      return {};

    default:
      return state;
  }
};

export const CreatePromotionReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PROMOTIONS_REQUEST:
      return { loading: true };

    case CREATE_PROMOTIONS_SUCCESS:
      return { loading: false, success: true };

    case CREATE_PROMOTIONS_FAIL:
      return { loading: false, error: action.payload };

    case CREATE_PROMOTIONS_RESET:
      return {};

    default:
      return state;
  }
};

export const DeleteProductReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PRODUCT_REQUEST:
      return { loading: true };

    case DELETE_PRODUCT_SUCCESS:
      return { loading: false, success: true };

    case DELETE_PRODUCT_FAIL:
      return { loading: false, error: action.payload };

    case DELETE_PRODUCT_RESET:
      return {};

    default:
      return state;
  }
};

export const DeletePromotionReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PROMOTIONS_REQUEST:
      return { loading: true };

    case DELETE_PROMOTIONS_SUCCESS:
      return { loading: false, success: true };

    case DELETE_PROMOTIONS_FAIL:
      return { loading: false, error: action.payload };

    case DELETE_PROMOTIONS_RESET:
      return {};

    default:
      return state;
  }
};

export const analyticsStaticsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ANALYTICS_STATICS_REQUEST:
      return { loading: true };

    case GET_ANALYTICS_STATICS_SUCCESS:
      return { loading: false, statics: action.payload };

    case GET_ANALYTICS_STATICS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const editProductReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_PRODUCT_REQUEST:
      return { loading: true };

    case EDIT_PRODUCT_SUCCESS:
      return { loading: false, success: true };

    case EDIT_PRODUCT_FAIL:
      return { loading: false, error: action.payload };

    case EDIT_PRODUCT_RESET:
      return {};

    default:
      return state;
  }
};

export const editPromotionReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_PROMOTIONS_REQUEST:
      return { loading: true };

    case EDIT_PROMOTIONS_SUCCESS:
      return { loading: false, success: true };

    case EDIT_PROMOTIONS_FAIL:
      return { loading: false, error: action.payload };

    case EDIT_PROMOTIONS_RESET:
      return {};

    default:
      return state;
  }
};

export const OrderStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ORDER_STATUS_REQUEST:
      return { loading: true };

    case UPDATE_ORDER_STATUS_SUCCESS:
      return { loading: false, success: true };

    case UPDATE_ORDER_STATUS_FAIL:
      return { loading: false, error: action.payload };

    case UPDATE_ORDER_STATUS_RESET:
      return {};

    default:
      return state;
  }
};

export const CreateStaffReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_STAFF_REQUEST:
      return { loading: true };

    case CREATE_STAFF_SUCCESS:
      return { loading: false, success: true };

    case CREATE_STAFF_FAIL:
      return { loading: false, error: action.payload };

    case CREATE_STAFF_RESET:
      return {};

    default:
      return state;
  }
};

export const EditStaffReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_STAFF_REQUEST:
      return { loading: true };

    case EDIT_STAFF_SUCCESS:
      return { loading: false, success: true };

    case EDIT_STAFF_FAIL:
      return { loading: false, error: action.payload };

    case EDIT_STAFF_RESET:
      return {};

    default:
      return state;
  }
};

export const DeleteStaffReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_STAFF_REQUEST:
      return { loading: true };

    case DELETE_STAFF_SUCCESS:
      return { loading: false, success: true };

    case DELETE_STAFF_FAIL:
      return { loading: false, error: action.payload };

    case DELETE_STAFF_RESET:
      return {};

    default:
      return state;
  }
};

export const FilterItemReducer = (state = { text: "" }, action) => {
  switch (action.type) {
    case FILTER_PRODUCTS:
      return { text: action.payload };

    default:
      return state;
  }
};

export const FilterStaffsReducer = (state = { text: "" }, action) => {
  switch (action.type) {
    case FILTER_STAFFS:
      return { text: action.payload };

    default:
      return state;
  }
};

export const FilterOrdersReducer = (
  state = { text: "", store: "store", sort: 0, options: null },
  action
) => {
  switch (action.type) {
    case FILTER_ORDERS:
      return {
        text: action.payload,
        store: action.store,
        sort: action.sort,
        options: action.options,
      };

    default:
      return state;
  }
};

export const FilterAnalyticsReducer = (state = { store: "store" }, action) => {
  switch (action.type) {
    case FILTER_ANALYTICS:
      return { store: action.store };

    default:
      return state;
  }
};

export const InventoryArrangementReducer = (
  state = { descending: false },
  action
) => {
  switch (action.type) {
    case REVERSE_PRODUCTS_LIST:
      return { descending: !state.descending };

    default:
      return state;
  }
};

export const FilterWithOptionsReducer = (state = { options: null }, action) => {
  switch (action.type) {
    case FILTER_WITH_OPTIONS:
      return { options: action.payload };

    default:
      return state;
  }
};

// STORE INFORMATION
export const CreateStoreInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_STORE_INFO_REQUEST:
      return { loading: true };

    case CREATE_STORE_INFO_SUCCESS:
      return { loading: false, success: true };

    case CREATE_STORE_INFO_FAIL:
      return { loading: false, error: action.payload };

    case CREATE_STORE_INFO_RESET:
      return {};

    default:
      return state;
  }
};

export const EditStoreInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_STORE_INFO_REQUEST:
      return { loading: true };

    case EDIT_STORE_INFO_SUCCESS:
      return { loading: false, success: true };

    case EDIT_STORE_INFO_FAIL:
      return { loading: false, error: action.payload };

    case EDIT_STORE_INFO_RESET:
      return {};

    default:
      return state;
  }
};

export const DeleteStoreInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_STORE_INFO_REQUEST:
      return { loading: true };

    case DELETE_STORE_INFO_SUCCESS:
      return { loading: false, success: true };

    case DELETE_STORE_INFO_FAIL:
      return { loading: false, error: action.payload };

    case DELETE_STORE_INFO_RESET:
      return {};

    default:
      return state;
  }
};

export const CreateFaqReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_FAQ_REQUEST:
      return { loading: true };

    case CREATE_FAQ_SUCCESS:
      return { loading: false, success: true };

    case CREATE_FAQ_FAIL:
      return { loading: false, error: action.payload };

    case CREATE_FAQ_RESET:
      return {};

    default:
      return state;
  }
};

export const EditFaqReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_FAQ_REQUEST:
      return { loading: true };

    case EDIT_FAQ_SUCCESS:
      return { loading: false, success: true };

    case EDIT_FAQ_FAIL:
      return { loading: false, error: action.payload };

    case EDIT_FAQ_RESET:
      return {};

    default:
      return state;
  }
};

export const DeleteFaqReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_FAQ_REQUEST:
      return { loading: true };

    case DELETE_FAQ_SUCCESS:
      return { loading: false, success: true };

    case DELETE_FAQ_FAIL:
      return { loading: false, error: action.payload };

    case DELETE_FAQ_RESET:
      return {};

    default:
      return state;
  }
};

export const DeleteStoreImageReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_STORE_IMAGE_REQUEST:
      return { loading: true };

    case DELETE_STORE_IMAGE_SUCCESS:
      return { loading: false, success: true };

    case DELETE_STORE_IMAGE_FAIL:
      return { loading: false, error: action.payload };

    case DELETE_STORE_IMAGE_RESET:
      return {};

    default:
      return state;
  }
};

export const CreateStoreImageReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_STORE_IMAGE_REQUEST:
      return { loading: true };

    case CREATE_STORE_IMAGE_SUCCESS:
      return { loading: false, success: true };

    case CREATE_STORE_IMAGE_FAIL:
      return { loading: false, error: action.payload };

    case CREATE_STORE_IMAGE_RESET:
      return {};

    default:
      return state;
  }
};

export const CreateBlogReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_BLOG_REQUEST:
      return { loading: true };

    case CREATE_BLOG_SUCCESS:
      return { loading: false, success: true };

    case CREATE_BLOG_FAIL:
      return { loading: false, error: action.payload };

    case CREATE_BLOG_RESET:
      return {};

    default:
      return state;
  }
};

export const EditBlogReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_BLOG_REQUEST:
      return { loading: true };

    case EDIT_BLOG_SUCCESS:
      return { loading: false, success: true };

    case EDIT_BLOG_FAIL:
      return { loading: false, error: action.payload };

    case EDIT_BLOG_RESET:
      return {};

    default:
      return state;
  }
};

export const DeleteBlogReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_BLOG_REQUEST:
      return { loading: true };

    case DELETE_BLOG_SUCCESS:
      return { loading: false, success: true };

    case DELETE_BLOG_FAIL:
      return { loading: false, error: action.payload };

    case DELETE_BLOG_RESET:
      return {};

    default:
      return state;
  }
};

export const CreateDeliveryMethodReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_DELIVERY_METHODS_REQUEST:
      return { loading: true };

    case CREATE_DELIVERY_METHODS_SUCCESS:
      return { loading: false, success: true };

    case CREATE_DELIVERY_METHODS_FAIL:
      return { loading: false, error: action.payload };

    case CREATE_DELIVERY_METHODS_RESET:
      return {};

    default:
      return state;
  }
};

export const EditDeliveryMethodReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_DELIVERY_METHODS_REQUEST:
      return { loading: true };

    case EDIT_DELIVERY_METHODS_SUCCESS:
      return { loading: false, success: true };

    case EDIT_DELIVERY_METHODS_FAIL:
      return { loading: false, error: action.payload };

    case EDIT_DELIVERY_METHODS_RESET:
      return {};

    default:
      return state;
  }
};

export const DeleteDeliveryMethodReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_DELIVERY_METHODS_REQUEST:
      return { loading: true };

    case DELETE_DELIVERY_METHODS_SUCCESS:
      return { loading: false, success: true };

    case DELETE_DELIVERY_METHODS_FAIL:
      return { loading: false, error: action.payload };

    case DELETE_DELIVERY_METHODS_RESET:
      return {};

    default:
      return state;
  }
};

export const CreateProductImageReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PRODUCT_IMAGE_REQUEST:
      return { loading: true };

    case CREATE_PRODUCT_IMAGE_SUCCESS:
      return { loading: false, success: true };

    case CREATE_PRODUCT_IMAGE_FAIL:
      return { loading: false, error: action.payload };

    case CREATE_PRODUCT_IMAGE_RESET:
      return {};

    default:
      return state;
  }
};

export const DeleteProductImageReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PRODUCT_IMAGE_REQUEST:
      return { loading: true };

    case DELETE_PRODUCT_IMAGE_SUCCESS:
      return { loading: false, success: true };

    case DELETE_PRODUCT_IMAGE_FAIL:
      return { loading: false, error: action.payload };

    case DELETE_PRODUCT_IMAGE_RESET:
      return {};

    default:
      return state;
  }
};

export const DeleteStoreCarouselImageReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_STORE_CAROUSEL_IMAGE_REQUEST:
      return { loading: true };

    case DELETE_STORE_CAROUSEL_IMAGE_SUCCESS:
      return { loading: false, success: true };

    case DELETE_STORE_CAROUSEL_IMAGE_FAIL:
      return { loading: false, error: action.payload };

    case DELETE_STORE_CAROUSEL_IMAGE_RESET:
      return {};

    default:
      return state;
  }
};

export const CreateStoreCarouselImageReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_STORE_CAROUSEL_IMAGE_REQUEST:
      return { loading: true };

    case CREATE_STORE_CAROUSEL_IMAGE_SUCCESS:
      return { loading: false, success: true };

    case CREATE_STORE_CAROUSEL_IMAGE_FAIL:
      return { loading: false, error: action.payload };

    case CREATE_STORE_CAROUSEL_IMAGE_RESET:
      return {};

    default:
      return state;
  }
};
