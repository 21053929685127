import React from "react";
import "./App.css";

import AllUser from "./pages/allUser/all_user";

import Sidebar from "./components/sideBar/SideBar";
import Home from "./pages/Home/Home";

import Order from "./pages/order/Orders";

import OrderDetails from "./pages/order/OrderDetails";
import Staff from "./pages/staff/Staff";
import Management from "./pages/management/Management";
import MyStore from "./pages/myStore/MyStore";
// import MyStoreDetails from './components/myStore/myStoreDetails/MyStoreDetails'
import { Route, Switch, Redirect } from "react-router-dom";
import Platform from "./pages/Platform";
import MyStoreDetails from "./components/myStore/myStoreDetails/MyStoreDetails";
import Analytics from "./pages/analytics/Analytics";
import { useTranslation } from "react-i18next";

const Loading = (
  <div>
    <p>Loding...!</p>
  </div>
);

function App() {
  const {t,i18n } = useTranslation();

  const onChangeLanguage = (even) => {
    i18n.changeLanguage(even.target.value)
  }
  return (
    <React.Suspense fallback={Loading}>
      <div className="main_app">
        <Sidebar />

        <Switch>
          <Route path="/dashboard/home-active">
            <Home />
          </Route>

          <Route path="/dashboard/orders">
            <Order />
          </Route>

          <Route path="/dashboard/sites/orders/:site/:id" exact>
            <OrderDetails />
          </Route>

          <Route path="/dashboard/my-staffs" component={Staff} />
          <Route path="/dashboard/all-users" component={AllUser} />
          <Route path="/dashboard/management" component={Management} />
          <Route path="/dashboard/analytics" component={Analytics} />

          <Route path="/dashboard/my-store" component={MyStore} />

          <Route path="/dashboard/MyStoreDetails" exact>
            <MyStoreDetails />
          </Route>

          <Route path="/dashboard/Platform" exact>
            <Platform />
          </Route>

          {/* <Route
              path="/dashboard/my-staffs/add-new-staff"
              component={AddNewStaff}
            /> */}

          <Redirect from="/" to="/dashboard/home-active" />
        </Switch>
      </div>
    </React.Suspense>
  );
}

export default App;
