import {
  GET_SITES_REQUEST,
  GET_SITES_SUCCESS,
  GET_SITES_FAIL,
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAIL,
  GET_STAFF_REQUEST,
  GET_STAFF_SUCCESS,
  GET_STAFF_FAIL,
  GET_All_USER_REQUEST,
  GET_All_USER_SUCCESS,
  GET_All_USER_FAIL,
  UPDATE_MERCHANT_REQUEST,
  UPDATE_MERCHANT_SUCCESS,
  AUTHENTICATION_SUCCESS,
  UPDATE_MERCHANT_FAIL,
  GET_USER_STATICS_REQUEST,
  GET_USER_STATICS_SUCCESS,
  GET_USER_STATICS_FAIL,
  GET_PLATFROM_STATICS_REQUEST,
  GET_PLATFROM_STATICS_SUCCESS,
  GET_PLATFROM_STATICS_FAIL,
} from "../constants/constants";

import {BASE_URL} from "../../utils/index"

const domain_url = BASE_URL;

export const GetSitesAction = (user) => async (dispatch) => {
  try {
    dispatch({ type: GET_SITES_REQUEST });

    var url = domain_url + "/merchant/platforms/sites/";
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user}`,
      },
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error("Your session has Expired, please Login Again!");
    }

    dispatch({ type: GET_SITES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_SITES_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const GetOrdersAction = (user) => async (dispatch) => {
  try {
    dispatch({ type: GET_ORDERS_REQUEST });

    var url = domain_url + "/merchant/platforms/sites/orders/";
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user}`,
      },
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error("Your session has Expired, please Login Again!");
    }

    dispatch({ type: GET_ORDERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ORDERS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const GetStaffsAction = (user) => async (dispatch) => {
  try {
    dispatch({ type: GET_STAFF_REQUEST });

    var url = domain_url + "/merchant/platforms/sites/staffs/";
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user}`,
      },
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error("Your session has Expired, please Login Again!");
    }

    dispatch({ type: GET_STAFF_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_STAFF_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const GetAllUserAction = (user) => async (dispatch) => {
  try {
    dispatch({ type: GET_All_USER_REQUEST });

    var url = domain_url + "/merchant/all/user/";
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user}`,
      },
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error("Your session has Expired, please Login Again!");
    }

    dispatch({ type: GET_All_USER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_All_USER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const UpdateUserAction = (dataDetails) => async (dispatch, getState) => {
  try {
    const { user } = getState().AuthenticationReducer;
    dispatch({ type: UPDATE_MERCHANT_REQUEST });

    var url = domain_url + "/merchant/authenticate/user/update/";
    const res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
      body: dataDetails,
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error(
        data.detail
          ? data.detail
          : data.error_description
          ? data.error_description
          : "Your Profile could not be updated!"
      );
    }

    dispatch({ type: UPDATE_MERCHANT_SUCCESS });
    dispatch({ type: AUTHENTICATION_SUCCESS, payload: data });
    localStorage.setItem("userDetails", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: UPDATE_MERCHANT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const GetUserStaticsAction = (user) => async (dispatch) => {
  try {
    dispatch({ type: GET_USER_STATICS_REQUEST });

    var url = domain_url + "/merchant/user/statics/";
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user}`,
      },
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error("Your session has Expired, please Login Again!");
    }

    dispatch({ type: GET_USER_STATICS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_USER_STATICS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const GetPlatfromStaticsAction = (user) => async (dispatch) => {
  try {
    dispatch({ type: GET_PLATFROM_STATICS_REQUEST });

    var url = domain_url + "/merchant/platfrom/statics/";
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user}`,
      },
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error("Your session has Expired, please Login Again!");
    }

    dispatch({ type: GET_PLATFROM_STATICS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_PLATFROM_STATICS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};