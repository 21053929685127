import { useEffect, useState } from "react";
import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa";
import AddItem from "./AddItem";
import DeleteProductType from "./DeleteProductType";
import EditProductType from "./EditProductType";

const ProductTypes = ({ site }) => {
  const [open, setOpen] = useState(false);

  const changeOpenState = () => {
    setOpen((currentState) => !currentState);
  };

  useEffect(() => {}, [site]);
  return (
    <div className="hold-Product_types">
      <div className="_dropdown">
        <h2 className="tint">
          Product Types{" "}
          {open ? (
            <FaChevronCircleUp
              className="tint cursor transcale"
              onClick={changeOpenState}
            />
          ) : (
            <FaChevronCircleDown
              className="tint cursor transcale"
              onClick={changeOpenState}
            />
          )}
        </h2>
        {open && (
          <div className="px-2">
            {site.product_types.map((type_) => (
              <div className="holdType__">
                <div>{type_.name}</div>{" "}
                <EditProductType type={type_} site={site} />
                <DeleteProductType type={type_} site={site} />
              </div>
            ))}
          </div>
        )}
      </div>
      {open && (
        <div className="_add-new-catalog">
          <AddItem site={site} />
        </div>
      )}
    </div>
  );
};

export default ProductTypes;
