import { useEffect, useState } from "react";
import "./sideBar.scss";
import { NavLink, Link, useHistory } from "react-router-dom";
import Icon from "../../../assets/icons/mudita-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { ListGroup } from "react-bootstrap";
import ProductTypes from "./ProductTypes";
import Categories from "./Categories";
import { syncStoreAction } from "../../../UpdatedStore/actions/ManagementActions";
import AddProductModal from "./AddProductModal";
import DeliveryMethods from "./DeliveryMethods";
const InventorySideBar = () => {
  const dispatch = useDispatch();
  const { sites } = useSelector((state) => state.SitesReducer);
  const { loading: loadingSync, error: errorSync } = useSelector(
    (state) => state.syncStoreReducer
  );

  const [siteData, setSiteData] = useState(null);

  const history = useHistory();

  const syncStoreHandler = (name) => {
    dispatch(syncStoreAction({ site: name }));
  };

  useEffect(() => {
    const currentUrl = history.location.search;
    const urlParams = new URLSearchParams(currentUrl);
    const node = urlParams.get("site");

    if (!node) {
      history.push("/dashboard/management");
    }

    if (sites) {
      var fetched_site = sites && sites.find((site) => site.name === node);
      setSiteData(fetched_site);
    }
  }, [history, sites]);

  return (
    <div className="_inventory-sidebar">
      <div className="_head">
        <Link to="/dashboard/management">
          <svg
            width="42"
            height="42"
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="42" height="42" rx="10" fill="#E8ECF0" />
            <path
              d="M13.3165 20.9959C13.3165 20.5659 13.4807 20.136 13.8084 19.8081L24.1244 9.49217C24.7807 8.83594 25.8446 8.83594 26.5006 9.49217C27.1565 10.1481 27.1565 11.2119 26.5006 11.8682L17.3723 20.9959L26.5003 30.1236C27.1562 30.7799 27.1562 31.8435 26.5003 32.4994C25.8443 33.156 24.7803 33.156 24.1241 32.4994L13.808 22.1836C13.4803 21.8556 13.3165 21.4257 13.3165 20.9959Z"
              fill="#9AA1AF"
            />
          </svg>
        </Link>

        <h4>Inventory</h4>
      </div>

      <div className="_mudita-store">
        <div className="_hold-icon">
          <img src={Icon} alt="icon" />
        </div>

        <div className="_title">
          <h4>{siteData ? siteData.name : "Site"}</h4>
          <div className="_website-status">
            <span className="_not-colored">Website</span>

            <span className="_dot">
              <svg
                width="3"
                height="3"
                viewBox="0 0 3 3"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="1.5" cy="1.5" r="1.5" fill="#16284D" />
              </svg>
            </span>
            <span className="_colored">Published</span>
          </div>
        </div>
      </div>
      {/* <div className="_hold-link">Content management</div>
      <div className="_hold-link">Manage Orders</div>
      <div className="_hold-link">Manage Users</div> */}
      <div className="_hold-add-bulk-btn">
        {siteData?.partner.length <= 0 ? (
          <button
            className="_add-new-item text-center"
            onClick={() => syncStoreHandler(siteData?.name)}
            disabled={loadingSync}
          >
            {loadingSync ? "Syncing..." : "Sync Store"}
          </button>
        ) : (
          <button className="_add-new-item text-center" disabled>
            Synced
          </button>
        )}
      </div>
      {errorSync && <h6 className="text-danger my-1">{errorSync}</h6>}

      <div className="_your-items-stocks">
        <ListGroup className="_hold-group-list">
          <ListGroup.Item className="_item">
            <h4>Total stocks</h4>
            <p>{siteData?.inventory.total}</p>
          </ListGroup.Item>
          <ListGroup.Item className="_item">
            <h4>Stock available</h4>
            <p>
              {Number(siteData?.inventory.total) -
                Number(siteData?.inventory.sold)}
            </p>
          </ListGroup.Item>
          <ListGroup.Item className="_item">
            <h4>Stock sold</h4>
            <p>{siteData?.inventory.sold}</p>
          </ListGroup.Item>
        </ListGroup>
      </div>

      {/* PRODUCT TYPES SECTION */}
      <ProductTypes site={siteData} />

      {/* PRODUCT CATEGORIES SECTION */}
      <Categories site={siteData} />

      {/* DELIVERY METHODS SECTION */}
      <DeliveryMethods site={siteData} />
      <div className="_hold-add-bulk-btn">
        {siteData?.partner.length > 0 &&
        siteData?.product_types.length > 0 &&
        siteData?.delivery_methods.length > 0 &&
        siteData?.product_categories.length > 0 ? (
          <>
            <button
              className="_add-new-item cursor transcale"
              onClick={() =>
                history.push(
                  `/merchant/product/create${history.location.search}`
                )
              }
            >
              <span>
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="15" cy="15" r="15" fill="#23AD83" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.75 8.75C14.1977 8.75 13.75 9.19772 13.75 9.75V13.75H9.75C9.19772 13.75 8.75 14.1977 8.75 14.75V15.25C8.75 15.8023 9.19772 16.25 9.75 16.25H13.75V20.25C13.75 20.8023 14.1977 21.25 14.75 21.25H15.25C15.8023 21.25 16.25 20.8023 16.25 20.25V16.25H20.25C20.8023 16.25 21.25 15.8023 21.25 15.25V14.75C21.25 14.1977 20.8023 13.75 20.25 13.75H16.25V9.75C16.25 9.19772 15.8023 8.75 15.25 8.75H14.75Z"
                    fill="white"
                  />
                </svg>
              </span>
              Add new item
            </button>

            <button
              className="_add-new-item cursor transcale"
              onClick={() =>
                history.push(`/inventory/management${history.location.search}`)
              }
            >
              Content Management
            </button>
          </>
        ) : (
          <div>
            <h6 className="tint font-weight-bold">Want to add products?</h6>
            <ul>
              <li className="text-muted font-weight-bold">
                Ensure your store is synced
              </li>
              <li className="text-muted font-weight-bold">
                Add your delivery methods
              </li>
              <li className="text-muted font-weight-bold">
                Create a Product Type
              </li>
              <li className="text-muted font-weight-bold">
                Create categories for your store
              </li>
              <li className="text-muted font-weight-bold">
                Start receiving orders!!
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default InventorySideBar;
