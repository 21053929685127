import React, { useEffect, useState } from "react";
import { Modal, Dropdown, Form } from "react-bootstrap";
import "./staffDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import { EDIT_STAFF_RESET } from "../../../UpdatedStore/constants/ManagementProductConstants";
import { editStaffAction } from "../../../UpdatedStore/actions/ProductManagementActions";
import {
  GetSitesAction,
  GetStaffsAction,
} from "../../../UpdatedStore/actions/SiteActions";
import DeleteStaff from "./DeleteStaff";

const EditStaff = ({ partner, site }) => {
  const dispatch = useDispatch();
  const { sites } = useSelector((state) => state.SitesReducer);
  const { user } = useSelector((state) => state.AuthenticationReducer);

  //   form states
  const [store, setStore] = useState("store");
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");

  const [modalShow, setModalShow] = React.useState(false);

  //   form states
  const [message, setMessage] = React.useState("");

  const { loading, error, success } = useSelector(
    (state) => state.EditStaffReducer
  );

  const createStaffHandler = () => {
    if (store === "store") {
      setMessage("Please select a valid store");
    } else {
      if (fullname) {
        if (email) {
          dispatch(
            editStaffAction({
              id: partner.agent.id,
              name: fullname,
              email: email,
              site: store,
            })
          );
        } else {
          setMessage("Email is invalid!");
        }
      } else {
        setMessage("fullname is invalid!");
      }
    }
  };

  useEffect(() => {
    if (success) {
      setMessageTimeOut();
    }

    if (!sites) {
      dispatch(GetSitesAction(user.access_token));
    }

    setFullName(partner.agent.name);
    setStore(site.name);
    setEmail(partner.agent.email);
  }, [success, modalShow, sites, partner]);

  const setMessageTimeOut = () => {
    setTimeout(() => {
      dispatch({ type: EDIT_STAFF_RESET });
      dispatch(GetStaffsAction(user.access_token));

      setModalShow(false);
    }, 2000);
  };
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          variant="light"
          className="mr-4 tint font-weight-bold"
          id="dropdown-basic"
        >
          Actions
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setModalShow(true)}>
            Edit Staff
          </Dropdown.Item>
          <DeleteStaff partner={partner} site={site} />
        </Dropdown.Menu>
      </Dropdown>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="_delete-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <h1>Edit Staff Details</h1>

          <button onClick={() => setModalShow(false)} className="_close-modal">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="28" height="28" rx="8" fill="#E8ECF0" />
              <path
                d="M20.698 8.23344L19.7383 7.28517C19.4786 7.02549 19.0496 7.02549 18.7786 7.28517L13.9915 12.0721L9.1142 7.19484C8.85441 6.93505 8.42537 6.93505 8.15452 7.19484L7.19484 8.15452C6.93505 8.4142 6.93505 8.84323 7.19484 9.1142L12.061 13.9803L7.28517 18.7787C7.02549 19.0384 7.02549 19.4674 7.28517 19.7384L8.24484 20.6981C8.50452 20.9577 8.93355 20.9577 9.20452 20.6981L13.9915 15.911L18.7786 20.6981C19.0383 20.9577 19.4673 20.9577 19.7383 20.6981L20.698 19.7384C20.9576 19.4787 20.9576 19.0497 20.698 18.7787L15.8996 13.9916L20.6867 9.20463C20.9576 8.93344 20.9576 8.50441 20.698 8.23344Z"
                fill="#777C85"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="_hold-contentProduct">
            <Form.Control
              as="select"
              className="mb-3 shadow-none font-weight-bold"
              value={store}
              onChange={(e) => setStore(e.target.value)}
            >
              <option value="store" selected disabled>
                SELECT STORE
              </option>
              {sites &&
                sites.map((site, index) => (
                  <option value={site.name} key={index}>
                    {site.name}
                  </option>
                ))}
            </Form.Control>

            <Form.Group>
              <Form.Label className="text-muted font-weight-bold">
                Full Name*
              </Form.Label>
              <Form.Control
                value={fullname}
                onChange={(e) => {
                  const arr = e.target.value.split(" ");
                  if (arr.length > 0) {
                    for (var i = 0; i < arr.length; i++) {
                      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                    }
                    const str2 = arr.join(" ");
                    setFullName(str2);
                  } else {
                    setFullName(
                      e.target.value.charAt(0).toUpperCase() +
                        e.target.value.slice(1)
                    );
                  }
                }}
                type="text"
                placeholder="Full Name"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="text-muted font-weight-bold">
                Email*
              </Form.Label>
              <Form.Control
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Email"
              />
            </Form.Group>
            {error && <h6 className="text-danger my-1">{error}</h6>}
            {message && <h6 className="text-danger my-1">{message}</h6>}
            {success && <h6 className="text-success my-1">Successful...</h6>}
            <button
              className="_yes transcale"
              onClick={createStaffHandler}
              disabled={loading}
            >
              {loading ? "Processing..." : "Edit Staff"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditStaff;
