import { Doughnut } from 'react-chartjs-2';



const DoughnutChart = ({data_}) => {
  const data = {
    cutout: 4,
    datasets: [
      {
        data: [data_?.active_user, data_?.inactive_user],
        backgroundColor: [
          '#16284D',
          '#ff0000',
          '#3ED0A3'
  
        ],
        borderColor: [
          '#16284D',
          '#ff0000',
          '#3ED0A3'
        ],
        borderWidth: 1,
      },
    ],
  };
  return (<Doughnut
    data={data}
    width={100}
    height={100}
    plugins={[]}
    options={{
      responsive: true,
      maintainAspectRatio: true,
      cutout: 60
    }} />
  )}

export default DoughnutChart;