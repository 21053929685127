import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Image } from "react-bootstrap";
import "./editAccount.scss";
import Pic from "../../../assets/icons/user.png";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUserAction } from "../../../UpdatedStore/actions/SiteActions";
import SpinnerLoader from "../../../UpdatedStore/components/SpinnerLoader";
import { UPDATE_MERCHANT_RESET } from "../../../UpdatedStore/constants/constants";
import CustomFileInput from "../../CustomFileInput";
const EditAccount: React.FC = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.AuthenticationReducer);
  const { loading, error, success } = useSelector(
    (state) => state.updateUserReducer
  );

  const [modalShow, setModalShow] = React.useState(false);

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [number, setNumber] = React.useState("");

  const [image, setImage] = useState("Upload Image...");
  const [files, setFiles] = useState("");
  const [showImage, setShowImage] = useState(false);

  const setImageTimeOut = () => {
    setTimeout(() => {
      dispatch({ type: UPDATE_MERCHANT_RESET });
      setModalShow(false);
    }, 2000);
  };

  useEffect(() => {
    if (image !== "Upload Image...") {
      setShowImage(true);
    } else {
      setShowImage(false);
    }

    if (success) {
      setImageTimeOut();
    }

    if (error) {
      setImageTimeOut();
    }
  }, [image, success, error]);

  const updateUserHandler = () => {
    const formData = new FormData();
    formData.append("image", files);
    formData.append("name", name == '' ? user?.name : name);
    formData.append("email", email == '' ? user?.email : email);
    formData.append("number", number == '' ? user?.Details.PhoneNumber : number);
    formData.append("token", user?.access_token);
    dispatch(UpdateUserAction(formData));
    setImage("Upload Image...");
  };

  return (
    <>
      <button onClick={() => setModalShow(true)} className="_edit-profile">
        Edit profile
      </button>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="_edit-account-modal"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h2>Edit account information</h2>
          <button onClick={() => setModalShow(false)} className="_close-modal">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="28" height="28" rx="8" fill="#E8ECF0" />
              <path
                d="M20.698 8.23344L19.7383 7.28517C19.4786 7.02549 19.0496 7.02549 18.7786 7.28517L13.9915 12.0721L9.1142 7.19484C8.85441 6.93505 8.42537 6.93505 8.15452 7.19484L7.19484 8.15452C6.93505 8.4142 6.93505 8.84323 7.19484 9.1142L12.061 13.9803L7.28517 18.7787C7.02549 19.0384 7.02549 19.4674 7.28517 19.7384L8.24484 20.6981C8.50452 20.9577 8.93355 20.9577 9.20452 20.6981L13.9915 15.911L18.7786 20.6981C19.0383 20.9577 19.4673 20.9577 19.7383 20.6981L20.698 19.7384C20.9576 19.4787 20.9576 19.0497 20.698 18.7787L15.8996 13.9916L20.6867 9.20463C20.9576 8.93344 20.9576 8.50441 20.698 8.23344Z"
                fill="#777C85"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="_edit-content">
            <div className="_profile-picture">
              <div className="_image">
                <Image src={user?.Details.ProfilePicture} />
              </div>
              <div className="act__">
                {loading ? (
                  <SpinnerLoader />
                ) : (
                  <>
                    <CustomFileInput
                      title="Upload Picture"
                      style={{ borderRadius: '25px', backgroundColor: '#3ed0a3' }}
                      name={image}
                      onChange={(e) => {
                        setImage(e.target.value);
                        setFiles(e.target.files[0]);
                      }}
                    />
                    {/* {showImage && (
                     
                    )} */}
                    <button
                      className="_change1" style={{ backgroundColor: '#3ed0a3' }}
                      onClick={updateUserHandler}
                    >
                      Save
                    </button>
                  </>
                )}
              </div>
            </div>

            <div className="_edit-form">
              <Form.Group
                className="_type-text"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={user?.name}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>

              {/* <Form.Group
                className="_type-text"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={user?.email}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group> */}

              <Form.Group
                className="_type-text"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Phone number</Form.Label>
                <Form.Control
                  type="text"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  placeholder={user?.Details.PhoneNumber}
                />
              </Form.Group>

              {error && <h6 className="text-danger text-center">{error}</h6>}

              <button disabled={loading} onClick={updateUserHandler}>
                {!loading ? "Save" : "Processing..."}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditAccount;
