import React, { useEffect, useState } from "react";
import "./analytics.scss";
import Notification from "../../components/Notification/Notification";
import NumberOfVisitor from "../../components/analytics/filterSummary/numberOfVisitor/NumberOfVisitor";
import CurrentInventory from "../../components/analytics/currentInventory/CurrentInventory";
import TotalToDateOrders from "../../components/analytics/totalToDateOrders/TotalToDateOrders";
import { Tab, Nav, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  GetOrdersAction,
  GetSitesAction,
} from "../../UpdatedStore/actions/SiteActions";
import SpinnerLoader from "../../UpdatedStore/components/SpinnerLoader";
import Message from "../../UpdatedStore/components/Message";
import notFound from "../../assets/icons/notfound.png";
import ItemAnalytics from "./ItemAnalytics";
import { getAnalyticsOrder } from "../../UpdatedStore/actions/ProductManagementActions";
import { FaFileExport } from "react-icons/fa";
import StoreSummary from "./StoreSummary";
import ThisMonth from "../../components/analytics/filterSummary/thisMonth/ThisMonth";
import moment from "moment";

const Analytics: React.FC = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.AuthenticationReducer);
  const { store } = useSelector((state) => state.FilterAnalyticsReducer);
  const { loading, sites, error } = useSelector((state) => state.SitesReducer);
  const { statics, success } = useSelector(
    (state) => state.analyticsStaticsReducer
  );

  // orders reducer

  const {
    loading: loadingOrders,
    orders,
    error: errorOrders,
  } = useSelector((state) => state.OrdersReducer);

  const [siteInventory, setSiteInventory] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [time, setTime] = useState("All Time");

  const [siteAnalytics, setAnalytics] = useState(null);

  // site orders
  const [siteOrders, setSiteOrders] = useState({
    total: 0,
    completed: 0,
    cancelled: 0,
    active: 0,
    price: 0,
  });

  // general site analytics for super admin

  const [generalAnalytics, setGeneralAnalytics] = useState({
    users: 0,
    total: 0,
    completed: 0,
    cancelled: 0,
    active: 0,
    price: 0,
    inventoryTotal: 0,
    inventorySold: 0,
  });

  // loading store details
  const loadStore = () => {
    var Inventory = sites && sites.find((site) => site.name === store);
    setSiteInventory(Inventory);

    setSelectedSite(
      JSON.parse(Inventory.layout).layout.find((node) => node.type === "Navbar")
    );

    let customOrders = orders.find(
      (orderSiteObject) => orderSiteObject.site.name === store
    );

    if (customOrders) {
      let totalOrders_ = customOrders.orders.filter((order_item_) => {
        if (!order_item_.isCancelled) {
          if (!order_item_.isDelivered) {
            return order_item_;
          }
        }
      });
      let completedOrders_ = customOrders.orders.filter(
        (item) => item.isDelivered
      );
      let cancelledOrders_ = customOrders.orders.filter(
        (item) => item.isCancelled
      );

      let OrdersPrice = customOrders.orders.reduce(
        (acc, item) => acc + Number(item.totalPrice),
        0
      );

      setSiteOrders({
        total: totalOrders_.length,
        completed: completedOrders_.length,
        cancelled: cancelledOrders_.length,
        active: totalOrders_.length,
        price: OrdersPrice,
      });
    } else {
      setSiteOrders({
        total: 0,
        completed: 0,
        cancelled: 0,
        active: 0,
        price: 0,
      });
    }
  };

  const loadGeneralStore = () => {
    let generalStoreUsers =
      sites && sites.reduce((acc, item) => item.users.length - 1 + acc, 0);

    let generalTotalOrders_ = orders.reduce((acc, item) => {
      let calculated_orders = item.orders.filter((order_item_) => {
        if (!order_item_.isCancelled) {
          if (!order_item_.isDelivered) {
            return order_item_;
          }
        }
      });
      return calculated_orders.length + acc;
    }, 0);

    let generalcompletedOrders_ = orders.reduce((acc, item) => {
      let computed_orders = item.orders.filter(
        (order_item_) => order_item_.isDelivered
      );
      return computed_orders.length + acc;
    }, 0);

    let generalcancelledOrders_ = orders.reduce((acc, item) => {
      let computed_orders = item.orders.filter(
        (order_item_) => order_item_.isCancelled
      );
      return computed_orders.length + acc;
    }, 0);

    let generalOrdersPrice = orders.reduce((acc, item) => {
      let computed_price = item.orders.reduce(
        (acc, item__) => acc + Number(item__.totalPrice),
        0
      );
      return computed_price + acc;
    }, 0);

    setGeneralAnalytics({
      users: generalStoreUsers,
      total: generalTotalOrders_,
      completed: generalcompletedOrders_,
      cancelled: generalcancelledOrders_,
      active: generalTotalOrders_,
      price: generalOrdersPrice,
      inventoryTotal: sites
        ? sites.reduce((acc, item) => item.inventory.total + acc, 0)
        : 0,
      inventorySold: sites
        ? sites.reduce((acc, item) => item.inventory.sold + acc, 0)
        : 0,
    });
  };

  const setTimeHandler = (value) => {
    let customOrders1 = orders.find(
      (orderSiteObject) => orderSiteObject.site.name === store
    );

    let customOrders = null;

    if (typeof value === "string") {
      setTime(value);
      if (value === "All Time") {
        customOrders = customOrders1;
      }

      if (value === "Today") {
        let newOrders = customOrders1.orders.filter((item) => {
          if (
            new Date(item.createdAt).getFullYear() ===
              new Date().getFullYear() &&
            new Date(item.createdAt).getMonth() === new Date().getMonth() &&
            new Date(item.createdAt).getDate() === new Date().getDate()
          ) {
            return item;
          }
        });

        customOrders = { ...customOrders1, orders: newOrders };
      }

      if (value === "This week") {
        let newOrders = customOrders1.orders.filter((item) => {
          if (moment().startOf("week").isSame(item.createdAt, "week")) {
            return item;
          }
        });
        customOrders = { ...customOrders1, orders: newOrders };
      }

      if (value === "This month") {
        let newOrders = customOrders1.orders.filter((item) => {
          if (new Date(item.createdAt).getMonth() === new Date().getMonth()) {
            return item;
          }
        });
        customOrders = { ...customOrders1, orders: newOrders };
      }
      if (value === "This Year") {
        let newOrders = customOrders1.orders.filter((item) => {
          if (
            new Date(item.createdAt).getFullYear() === new Date().getFullYear()
          ) {
            return item;
          }
        });

        customOrders = { ...customOrders1, orders: newOrders };
      }
    }

    if (typeof value === "object") {
      if (value.startDate && value.endDate) {
        setTime(value.customTime);

        let newOrders = customOrders1.orders.filter((item) => {
          if (
            Date.parse(item.createdAt) >= Date.parse(value.startDate) &&
            Date.parse(item.createdAt) <= Date.parse(value.endDate)
          ) {
            return item;
          }
        });
        customOrders = { ...customOrders1, orders: newOrders };
      } else {
        setTime(value.customTime);
        customOrders = customOrders1;
      }
    }

    if (customOrders) {
      let totalOrders_ = customOrders.orders.filter((order_item_) => {
        if (!order_item_.isCancelled) {
          if (!order_item_.isDelivered) {
            return order_item_;
          }
        }
      });
      let completedOrders_ = customOrders.orders.filter(
        (item) => item.isDelivered
      );
      let cancelledOrders_ = customOrders.orders.filter(
        (item) => item.isCancelled
      );

      let OrdersPrice = customOrders.orders.reduce(
        (acc, item) => acc + Number(item.totalPrice),
        0
      );

      setSiteOrders({
        total: totalOrders_.length,
        completed: completedOrders_.length,
        cancelled: cancelledOrders_.length,
        active: totalOrders_.length,
        price: OrdersPrice,
      });
    } else {
      setSiteOrders({
        total: 0,
        completed: 0,
        cancelled: 0,
        active: 0,
        price: 0,
      });
    }

    return;
  };

  useEffect(() => {
    if (!sites) {
      dispatch(GetSitesAction(user.access_token));
    }

    if (!orders) {
      dispatch(GetOrdersAction(user.access_token));
    }

    if (sites && orders && store !== "store") {
      loadStore();
    }
    if (sites && orders && store === "store" && user.isSuperUser) {
      loadGeneralStore();
    }
    if (!statics) {
      let Inventory = sites && sites.find((site) => site.name === store);
      dispatch(getAnalyticsOrder(Inventory));
    } else {
      setAnalytics(statics);
    }
  }, [sites, store, orders, statics]);

  return (
    <div className="_main-analytics">
      <div className="_analytics-header">
        <h2>Store's analytics</h2>
        <Notification />
      </div>

      <div className="detAnalytics">
        {(loading || loadingOrders) && <SpinnerLoader />}
        {error && <Message variant="danger">{error}</Message>}
        {errorOrders && !error && (
          <Message variant="danger">
            There was an error fetching your orders!
          </Message>
        )}

        {store === "store" &&
          !loading &&
          !loadingOrders &&
          !error &&
          !errorOrders &&
          !user.isSuperUser && (
            <>
              <div className="_hold-pic">
                <Image src={notFound} alt="not found picture" />
              </div>

              <div className="_info">
                <h3>Please select a valid store!</h3>
              </div>
            </>
          )}
      </div>

      {store === "store" &&
        !loading &&
        !loadingOrders &&
        !error &&
        !errorOrders &&
        user.isSuperUser && (
          <div>
            <Tab.Container defaultActiveKey="StoreSummary">
              <div className="hold-analytics-tabs">
                <Nav variant="pills" className="_tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="StoreSummary">
                      Overall Statistics
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content>
                  <Tab.Pane eventKey="StoreSummary">
                    <div className="_store-summary">
                      <div className="_head">
                        <h3> Overall Statistics</h3>
                      </div>
                      <div className="_hold-numbers-of-visitor">
                        <NumberOfVisitor
                          title="All Users"
                          data={generalAnalytics.users}
                        />
                        <NumberOfVisitor
                          title="Total Inventory"
                          data={generalAnalytics.inventoryTotal}
                        />
                        <NumberOfVisitor
                          title="Active Orders"
                          data={generalAnalytics.total}
                        />
                        <NumberOfVisitor
                          title="Total Sales"
                          data={`$ ${Number(generalAnalytics.price).toFixed(
                            2
                          )}`}
                        />
                      </div>
                    </div>

                    <div className="_current-inventory-total-to-date-order">
                      <CurrentInventory
                        status={{
                          total: generalAnalytics.inventoryTotal,
                          sold: generalAnalytics.inventorySold,
                          for_sale:
                            Number(generalAnalytics.inventoryTotal) -
                            Number(generalAnalytics.inventorySold),
                        }}
                      />
                      <TotalToDateOrders
                        orders={generalAnalytics}
                        ratio={generalAnalytics.users}
                      />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        )}

      {!loading && !loadingOrders && !error && !errorOrders && (
        <div>
          {store !== "store" && (
            <Tab.Container defaultActiveKey="StoreSummary">
              <div className="hold-analytics-tabs">
                <Nav variant="pills" className="_tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="StoreSummary">Store summary</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="ItemSummary">Item summary</Nav.Link>
                  </Nav.Item>
                  <Nav.Item></Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="StoreSummary">
                    <div className="_store-summary">
                      <div className="_head">
                        <h3>Store summary</h3>

                        <div className="_hold-filters">
                          <ThisMonth time={time} setTime={setTimeHandler} />
                          <StoreSummary
                            name={selectedSite?.data.title}
                            siteInventory={siteInventory}
                            orders={siteOrders}
                            time={time}
                          />

                          {/* <AllLocation />
                          <AllLanguage /> */}
                        </div>
                      </div>
                      <div className="_hold-numbers-of-visitor">
                        <NumberOfVisitor
                          title="Total Users"
                          data={
                            siteInventory
                              ? siteInventory?.users.length - 1
                              : "0"
                          }
                        />
                        <NumberOfVisitor
                          title="Total Inventory"
                          data={
                            siteInventory ? siteInventory?.inventory.total : 0
                          }
                        />
                        <NumberOfVisitor
                          title="Active Orders"
                          data={siteOrders.total}
                        />
                        <NumberOfVisitor
                          title="Total Sales"
                          data={`$ ${Number(siteOrders.price).toFixed(2)}`}
                        />
                      </div>
                    </div>

                    <div className="_current-inventory-total-to-date-order">
                      <CurrentInventory
                        status={{
                          total: siteInventory?.inventory.total,
                          sold: siteInventory?.inventory.sold,
                          for_sale:
                            Number(siteInventory?.inventory.total) -
                            Number(siteInventory?.inventory.sold),
                        }}
                      />
                      <TotalToDateOrders
                        orders={siteOrders}
                        ratio={
                          siteInventory ? siteInventory?.users.length - 1 : "0"
                        }
                      />
                    </div>
                  </Tab.Pane>

                  {/* ITEM--SUMMARY */}
                  <Tab.Pane eventKey="ItemSummary">
                    <ItemAnalytics fetched_site={siteInventory} />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Tab.Container>
          )}
        </div>
      )}
    </div>
  );
};

export default Analytics;
