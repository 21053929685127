import React, { useState, useEffect } from "react";
import { FormControl } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createSiteAction } from "../actions/ApiAction";
import PopUPModel from "../../components/logOut/buildPopUpModel";
import Message from "./Message";
import { GetSitesAction } from "../../UpdatedStore/actions/SiteActions";

const CanvasTopBox = () => {
  const history = useHistory();
  const [modalShow, setModalShow] = React.useState(false);
  const auth = useSelector((state) => state.AuthenticationReducer);

  const token = auth.user?.access_token;
  var { user } = useSelector((state) => state.BuilderConfirmReducer);

  const { present } = useSelector((state) => state.MarkupLayoutReducer);

  const { theme } = useSelector((state) => state.EditThemeReducer);

  const { success, message, loading } = useSelector(
    (state) => state.CreateSiteReducer
  );

  const dispatch = useDispatch();

  const [name, setSiteName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleRedirect = () => {
    dispatch(GetSitesAction(auth.user?.access_token));
    setModalShow(true);
  };

  useEffect(() => {
    if (loading) {
      setErrorMessage("");
    }

    if (success) {
      handleRedirect();
    }
  }, [user, loading, message, success]);

  const createSite = (e) => {
    if (user) {
      setErrorMessage("");

      e.preventDefault();
      if (name === "") {
        setErrorMessage("Site Name is not Valid");
      } else {
        if (name.length <= 36) {
          if (present.layout.length === 0) {
            setErrorMessage("Your Site Layout is Empty");
          } else if (!/^[a-z0-9]*$/g.test(name)) {
            setErrorMessage(
              "Your Site Name can only contain lower case alphabets!"
            );
          } else {
            dispatch(
              createSiteAction(
                { name, layout: JSON.stringify(present), theme },
                token
              )
            );
          }
        } else {
          setErrorMessage(
            `Site name is too long, Expects 36 characters but gets ${name.length}! `
          );
        }
      }
    } else {
      setErrorMessage("Your session has expired, please login again!");
    }
  };

  return (
    <div className="mainScreenTopBox mb-4">
      {modalShow && (
        <PopUPModel sitename={message && success ? message.name : null} />
      )}
      <div className="topBoxContainer">
        <FormControl
          type="text"
          className="template_build_name mr-3"
          placeholder="Template Name..."
          aria-label="Template Name"
          aria-describedby="basic-addon1"
          value={name}
          onChange={(e) => setSiteName(e.target.value)}
        />
        {message && !success && <Message variant="danger">{message}</Message>}
        {message && success && (
          <Message variant="success">{message.message}</Message>
        )}
        {errorMessage && <Message variant="danger">{errorMessage}</Message>}
      </div>
      <button
        className={`${
          !loading ? "publishButton" : "disabledpublishButton"
        } font-weight-bold`}
        onClick={(e) => createSite(e)}
        disabled={loading}
      >
        Publish Template
      </button>
      <h4 className="undoRedo">
        <FaUserCircle className="tint" />
        <div className={`tint font-weight-bold ml-3`}>
          {auth ? auth.user.name.split(" ")[0] : "Merchant"}
        </div>
      </h4>
    </div>
  );
};

export default CanvasTopBox;
