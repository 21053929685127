import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import PIC from "../../../../assets/icons/store-pic.png";
import "./holdCards.scss";
import {
  Vanilla,
  Nairobi,
  Fukushima,
  Quebec,
} from "../../../../pages/management/images";
import { useSelector } from "react-redux";
import StatusChangeModal from "../../../StatusChangeModal/StatusChangeModal";

export const HoldCards: React.FC = ({ site, key }) => {
  const auth = useSelector((state) => state.AuthenticationReducer);
  const [popModal, setPopModal] = useState(false);
  const [siteStatus, setsiteStatus] = useState(site.active);
  const history = useHistory();

  const redirectHandler = () => {
    var randomstring = require("randomstring");
    var config = randomstring.generate(100);
    var hash = randomstring.generate(35);
    const url = `/inventory/all-items?site=${site.name}&wIM=${auth.user?.access_token}&hash=${hash}`;
    history.push(url);
  };
  return (
    <div className="_hold-store-management-info transcale cursor" key={key}>
      <div className="_pic" onClick={redirectHandler}>
        {site.theme === "vanilla" && <Image src={Vanilla} />}
        {site.theme === "second" && <Image src={Nairobi} />}
        {site.theme === "third" && <Image src={Fukushima} />}
        {site.theme === "fourth" && <Image src={Quebec} />}
      </div>

      <div className="_info" key={key}>
        <h3 className={"text-uppercase"}>{site.name}</h3>
        <div className="_hold-buttons">
          <button className="_website" onClick={redirectHandler}>
            Website
          </button>
          <button
            className={
              siteStatus ? "_on-building active" : "_on-building in-active"
            }
            onClick={() => {
              setPopModal(true);
            }}
          >
            {siteStatus ? "Active" : "Inactive"}
          </button>
        </div>
      </div>
      {popModal ? (
        <StatusChangeModal
          data={site}
          onclose={setPopModal}
          status={{ setsiteStatus, status: siteStatus }}
        />
      ) : (
        ""
      )}
    </div>
  );
};
