import { useEffect, useState } from "react";
import { Chart, ArcElement } from "chart.js";
import { useSelector, useDispatch } from "react-redux";
import DoughnutChart from "../../components/charts/Dougnut";
import { GetUserStaticsAction } from "../../UpdatedStore/actions/SiteActions";
import "./styles.scss";

Chart.register(ArcElement);
const data: any = [];
const UsersStats = () => {
  const { loading, statics, success, error } = useSelector(
    (state) => state.UserStaticsReducer
  );
  const { user } = useSelector((state) => state.AuthenticationReducer);
  const [alluserLength, setAllUserLength] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!statics) {
      dispatch(GetUserStaticsAction(user.access_token));
    }
    if (statics) {
      setAllUserLength(statics);
    }
  }, [statics]);

  return (
    <div className="user-stats">
      <div className="row align-items-center">
        <div className="col-md-2  text-center">
          <div
            style={{
              width: "100%",
              height: "50%",
              float: "left",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "40px",
                position: "absolute",
                top: "50%",
                left: " 0",
                marginTop: "-20px",
                lineHeight: "19px",
                textAlign: "center",
              }}
            >
              <h5 className="primary-color fw-bold m-0">Current</h5>
              <h5 className="primary-color fw-bold m-0"> Users</h5>
            </div>
            <DoughnutChart data_={alluserLength} />
          </div>
        </div>
        <div className="col-12 col-md-3 text-center">
          <div>
            <span className="user-text">Total Users</span>
            <h4 className="user-figure">
              {alluserLength ? alluserLength?.total_user : 0}
            </h4>
            <span className="gray-dot"></span>
          </div>
        </div>
        <div className="col-12 col-md-3 text-center">
          <div>
            <span className="user-text">Active Users</span>
            <h4 className="user-figure">
              {alluserLength ? alluserLength?.active_user : 0}
            </h4>
            <span className="green-dot"></span>
          </div>
        </div>
        <div className="col-12 col-md-3 text-center">
          <div>
            <span className="user-text">Inactive Users</span>
            <h4 className="user-figure">
              {alluserLength ? alluserLength?.inactive_user : 0}
            </h4>
            <span className="disabled-dot"></span>
          </div>
        </div>
        {/* <div className="col-12 col-md-2 text-center">
                    <div className="d-flex flex-column  justify-content-between">
                        <FilterButton className="mb-2" filterName="All" currentFilter={currentUserFilter} handleFilter={handleUserFilter} />
                        <FilterButton className="my-1" filterName="Buyers" currentFilter={currentUserFilter}  handleFilter={handleUserFilter} />
                        <FilterButton className="mt-2" filterName="Sellers" currentFilter={currentUserFilter}  handleFilter={handleUserFilter} />
                    </div>
                </div> */}
      </div>
    </div>
  );
};

export default UsersStats;
