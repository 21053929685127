import React, { useState } from "react";
import "./currentInventory.scss";
import { PieChart } from "react-minimal-pie-chart";

const CurrentInventory: React.FC = ({ status }) => {
  var xData = [
    { title: "Sold", value: status.sold, color: "#3ED0A3" },
    { title: "For sale", value: status.for_sale, color: "#16284D" },
  ];

  const [selected, setSelected] = useState<number | undefined>(0);
  const [hovered, setHovered] = useState<number | undefined>(undefined);

  const data = xData.map((entry, i) => {
    if (hovered === i) {
      return {
        ...entry,
        color: "grey",
      };
    }
    return entry;
  });

  const lineWidth = 60;

  return (
    <div className="_current-inventory">
      <div className="_circle">
        <PieChart
          style={{
            fontFamily:
              '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
            fontSize: "8px",
          }}
          data={data}
          viewBoxSize={[100, 100]}
          radius={PieChart.defaultProps.radius - 6}
          lineWidth={60}
          segmentsStyle={{ transition: "stroke .3s", cursor: "pointer" }}
          segmentsShift={(index) => (index === selected ? 6 : 1)}
          animate
          label={({ dataEntry }) => Math.round(dataEntry.percentage) + "%"}
          labelPosition={100 - lineWidth / 2}
          labelStyle={{
            fill: "#fff",
            opacity: 0.75,
            pointerEvents: "none",
          }}
          onClick={(_, index) => {
            setSelected(index === selected ? undefined : index);
          }}
          onMouseOver={(_, index) => {
            setHovered(index);
          }}
          onMouseOut={() => {
            setHovered(undefined);
          }}
        />
      </div>

      <div className="_data">
        <div className="_total">
          <h2>Total inventory</h2>
          <span>{status.total}</span>
        </div>

        <div className="_items-for-sale">
          <div className="_item-sold">
            <h3>Item sold</h3>
            <span>{status.sold}</span>
          </div>
          <div className="_item-for-sale">
            <h3>Item for sale</h3>
            <span>{status.for_sale}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentInventory;
