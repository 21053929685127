import React, { useEffect, useState } from "react";
import Notification from "../../components/Notification/Notification";
import { Tab, Nav } from "react-bootstrap";
import AllPlatforms from "../../components/myStore/allPlatforms/AllPlatforms";
import { Website } from "../../components/management/website/Website";

import MobileApplication from "../../components/myStore/mobileApplication/MobileApplication";
import "./myStore.scss";
import PlatfromSearch from "../../components/order/topBar/search/PlatfromSearch";
import PlatfromSort from "../../components/management/sort/PlatfromSort";

const MyStore: React.FC = () => {
  const [filteredData, setFilteredData] = useState("");
  const [filteredDatachange, setFilteredDataChange] = useState(false);

  const countchange = () => {
    setFilteredDataChange(!filteredDatachange);
  };
  const setPlatefromData = (props) => {
    setFilteredData(props);
    countchange();
  };
  useEffect(() => {}, [filteredDatachange]);

  return (
    <div className="_my-store-page">
      <header>
        <h2>My stores</h2>
        <Notification />
      </header>

      <div className="_hold-store-tabs">
        <Tab.Container defaultActiveKey="AllPlatforms">
          <div className="wrap-tabs">
            <Nav variant="pills" className="_tabs">
              <Nav.Item>
                <Nav.Link eventKey="AllPlatforms"> All platforms </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Website">Website</Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="MobileApplication">
                  Mobile application
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <div className="_hold-search-sort">
              <PlatfromSearch callback={setPlatefromData} />
              <PlatfromSort callback={setPlatefromData} />
            </div>
          </div>

          <div>
            <Tab.Content>
              <Tab.Pane eventKey="AllPlatforms">
                <AllPlatforms filteredData={filteredData} />
              </Tab.Pane>
              <Tab.Pane eventKey="Website">
                <AllPlatforms filteredData={filteredData} />
              </Tab.Pane>
              <Tab.Pane eventKey="MobileApplication">
                <Website />
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
    </div>
  );
};

export default MyStore;
