import * as React from "react";
import { Form } from "react-bootstrap";
import { useField, ErrorMessage } from "formik";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface Props {
    value: string;
    label: string;
    type: string;
    placholder: string;
    name: string;
    onChange: any;
    handleMouseDownPassword: any;
    handleClickShowPassword: any;
    values: any;
}

export const TextFeildPassword: React.FC<Props> = ({
    label,
    type,
    handleClickShowPassword,
    handleMouseDownPassword,
    values,
    placholder,
    ...props
}) => {
    const [field, meta] = useField(props);
   
    return (
        <div style={{ position: "relative" }}>
            <Form.Group controlId="formBasicEmail">
                <Form.Label>{label}</Form.Label>
                <Form.Control
                    className={`${meta.touched && meta.error && "is-invalid"}`}
                    type={type}
                    placeholder={placholder}
                    autoComplete="off"
                 
                    {...field}
                    // {...props}
                />
             
                <IconButton
                    sx={{
                        marginTop: "-39px",
                        float: "right",
                       
                    }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    style={{marginRight:'20px'}}
                    className="eye-icon-alignment"
                >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
               
            </Form.Group>
            <ErrorMessage name={field.name} component="div" className="error" />
           
        </div>
    );
};
