import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { FILTER_WITH_OPTIONS } from "../../../UpdatedStore/constants/ManagementsConstants";

import "./filter.scss";

const Filter: React.FC = ({ site }) => {
  const dispatch = useDispatch();
  const [filterVisibilty, setFilterVisibilty] = useState(false);
  const [filterAction, setFilterAction] = useState(false);
  //   form states
  const [productType, setProductType] = React.useState(0);
  const [minStock, setMinStock] = React.useState(0);
  const [maxStock, setMaxStock] = React.useState(0);
  const [minPrice, setMinPrice] = React.useState(0);
  const [maxPrice, setMaxPrice] = React.useState(0);

  const [message, setMessage] = React.useState("");

  const FilterAction = () => {
    if (productType > 0) {
      if (minStock >= 0 && maxStock > minStock) {
        if (minPrice >= 0 && maxPrice > minPrice) {
          dispatch({
            type: FILTER_WITH_OPTIONS,
            payload: {
              productType,
              minStock,
              maxStock,
              minPrice,
              maxPrice,
            },
          });
          setFilterAction(true);
          setFilterVisibilty(false);
        } else {
          setMessage("Price range is not valid!");
        }
      } else {
        setMessage("Stock range is not valid!");
      }
    } else {
      setMessage("Product type is Invalid!");
    }
  };

  const resetFilterAction = () => {
    dispatch({ type: FILTER_WITH_OPTIONS, payload: null });
    setFilterVisibilty(false);
    setFilterAction(false);
  };

  useEffect(() => {
    setMessage("");
  }, [filterVisibilty]);
  return (
    <div className="_hold-management-filter">
      <button className="_button mr-4" onClick={() => setFilterVisibilty(true)}>
        <svg
          width="66"
          height="48"
          viewBox="0 0 66 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="66"
            height="48"
            rx="10"
            fill={filterAction ? "#16284D" : "#F6F7F8"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46 15.9222C46 16.9837 45.1524 17.8444 44.1063 17.8444H21.8937C20.8479 17.8444 20 16.984 20 15.9222C20 14.8607 20.8476 14 21.8937 14H44.1066C45.1524 14 46 14.8607 46 15.9222ZM42.8548 24.0002C42.8548 25.0617 42.0072 25.9224 40.9611 25.9224H25.0389C23.9931 25.9224 23.1452 25.062 23.1452 24.0002C23.1452 22.9387 23.9928 22.078 25.0389 22.078H40.9614C42.0072 22.078 42.8548 22.9387 42.8548 24.0002ZM36.2433 34C37.2894 34 38.137 33.1393 38.137 32.0778C38.137 31.0163 37.2894 30.1556 36.2436 30.1556H29.7567C28.7106 30.1556 27.863 31.0163 27.863 32.0778C27.863 33.1396 28.7109 34 29.7567 34H36.2433Z"
            fill={filterAction ? "#f6f7f8" : "#16284D"}
          />
        </svg>
      </button>

      {filterVisibilty ? (
        <div className="_filter">
          <div className="_head">
            <h3>Filter</h3>
            <button
              className="_close"
              onClick={() => setFilterVisibilty(false)}
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="28" height="28" rx="8" fill="#E2F8F1" />
                <path
                  d="M13.9973 8.76045C13.7151 8.76045 13.433 8.86819 13.2178 9.08322L6.44799 15.8531C6.01734 16.2838 6.01734 16.982 6.44799 17.4125C6.87846 17.843 7.57655 17.843 8.00724 17.4125L13.9973 11.4221L19.9874 17.4123C20.418 17.8428 21.116 17.8428 21.5465 17.4123C21.9773 16.9818 21.9773 16.2836 21.5465 15.8529L14.7767 9.08301C14.5615 8.86794 14.2794 8.76045 13.9973 8.76045Z"
                  fill="#3ED0A3"
                />
              </svg>
            </button>
          </div>

          <div className="_current-stocks mt-3">
            <h3> Type</h3>

            <div className="_hold-inputs">
              <Form.Group>
                <Form.Control
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                  as="select"
                >
                  <option value={0} disabled selected>
                    --- Type ---
                  </option>
                  {site?.product_types.map((type_) => (
                    <option value={type_.id}>{type_.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
          </div>

          <div className="_current-stocks">
            <h3>Current stocks</h3>
            <div className="_hold-inputs">
              <input
                type="text"
                placeholder="Minimum"
                value={minStock}
                onChange={(e) => setMinStock(e.target.value)}
              />
              <input
                type="text"
                placeholder="Maximum"
                value={maxStock}
                onChange={(e) => setMaxStock(e.target.value)}
              />
            </div>
          </div>

          <div className="_price">
            <h3>Price</h3>

            <div className="_hold-all-inputs">
              <div className="_hold-prices-inputs">
                <span>$</span>
                <input
                  type="text"
                  placeholder="Min. price"
                  value={minPrice}
                  onChange={(e) => setMinPrice(e.target.value)}
                />
              </div>

              <div className="_hold-prices-inputs">
                <span>$</span>
                <input
                  type="text"
                  placeholder="Max. price"
                  value={maxPrice}
                  onChange={(e) => setMaxPrice(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="_hold-act-button">
            {" "}
            {message && (
              <small className="text-danger text-center my-2">{message}</small>
            )}
            <button className="_aplly-btn transcale" onClick={FilterAction}>
              Apply
            </button>
            <button
              className="_reset-filter transcale"
              onClick={resetFilterAction}
            >
              Reset filter
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Filter;
