import React, { useState, useEffect } from "react";
import { Col, Form, Row, Modal, Button } from "react-bootstrap";
import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createFaqAction } from "../../../UpdatedStore/actions/ManagementActions";
import SpinnerLoader from "../../../UpdatedStore/components/SpinnerLoader";
import close_icon from "../../../assets/icons/close_icon.png";
import addplat from "../../../assets/icons/addplat.png";
import { CREATE_FAQ_RESET } from "../../../UpdatedStore/constants/ManagementProductConstants";
import DeleteFaq from "./DeleteFaq";
import EditFaq from "./EditFaq";

import "./NewBlog.scss";

const FaqTips = ({ site }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { sites } = useSelector((state) => state.SitesReducer);
  
    const {
      loading: loadingCreate,
      success: successCreate,
      error: errorCreate,
    } = useSelector((state) => state.CreateFaqReducer);
  
    const [title, setTitle] = useState("");
    const [answer, setAnswer] = useState("");
    const [siteData, setSiteData] = useState(null);
    const [submissionError, setSubmissionError] = useState("");
    const [show, setShow] = useState(false);
    const [showAvailable, setShowAvailable] = useState(false);
  
    const reverseShow = () => {
      setShow(!show);
    };
  
    const reverseShowAvailable = () => {
      setShowAvailable(!showAvailable);
    };

    useEffect(() => {
      //   if (!site) history.push("/dashboard/management");
    
      const currentUrl = history.location.search;
      const urlParams = new URLSearchParams(currentUrl);
      const node = urlParams.get("site");

      if (sites) {
        var fetched_site = sites && sites.find((site) => site.name === node);
        setSiteData(fetched_site);
      }

        if (successCreate) setMessageTimeOut();
      }, [site, history, successCreate, sites]);
  
    const handleSubmit = () => {
      const currentUrl = history.location.search;
      const urlParams = new URLSearchParams(currentUrl);
      const node = urlParams.get("site");
      setSubmissionError("");
      if (title) {
        if (answer) {
          dispatch(
            createFaqAction({ site:  node, title: title, answer: answer })
          );
        } else {
          setSubmissionError("Answer is Invalid");
        }
      } else {
        setSubmissionError("Title is Invalid");
      }
    };
  
    
  
    const setMessageTimeOut = () => {
      setTimeout(() => {
        setTitle("");
        setAnswer("");
        setShow(false);
        dispatch({ type: CREATE_FAQ_RESET });
      }, 2000);
    };

  //   function UploadImage(){
  //   console.log("ikitfhdfsa")
  //               const image_input = document.querySelector("#image-input");
  // image_input.addEventListener("change", function() {
  //   const reader = new FileReader();
  //   reader.addEventListener("load", () => {
  //     const uploaded_image = reader.result;
  //     document.querySelector("#display-image").style.backgroundImage = `url(${uploaded_image})`;
  //   });
  //   reader.readAsDataURL(this.files[0]);
  // });

  //   }

  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <div className="button-container" onClick={() => setModalShow(true)}>
        <img src={addplat} alt="button-icon" />
        <div className="create-new-button">Add new content</div>
      </div>

      <Modal
        show={modalShow}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="_remove-modal"
        // onClick={props.onHide}
      >
        <Modal.Body>
          <div className="AddRow">
            <div className="d-flex justify-content-between">
              <div className="tint font-weight-bold">Add new blog</div>
              <div className="cursor">
                <img src={close_icon} onClick={() => setModalShow(false)} />
              </div>
            </div>
            <div>
              <div>
                <Form.Group>
                  <Form.Label className="tint font-weight-bold">
                    Question*
                  </Form.Label>
                  <Form.Control
                    className="feild-area-bootstrap"
                    value={title}
                    onChange={(e) => {
                      const arr = e.target.value.split(" ");
                      if (arr.length > 0) {
                        for (var i = 0; i < arr.length; i++) {
                          arr[i] =
                            arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                        }
                        const str2 = arr.join(" ");
                        setTitle(str2);
                      } else {
                        setTitle(
                          e.target.value.charAt(0).toUpperCase() +
                            e.target.value.slice(1)
                        );
                      }
                    }}
                    type="text"
                    placeholder="Question"
                    as="textarea"
                    rows="5"
                    style={{ resize: "none" }}
                  />
                </Form.Group>
              </div>

              <div>
                <Form.Group>
                  <Form.Label className="tint font-weight-bold">
                    Answer*
                  </Form.Label>
                  <Form.Control
                    className="feild-area-bootstrap"
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                    as="textarea"
                    rows="5"
                    style={{ resize: "none" }}
                    placeholder="Answer"
                  />
                </Form.Group>
              </div>
            </div>
            {submissionError && (
              <h5 className="text-danger text-center font-weight-bold">
                {submissionError}
              </h5>
            )}
            {errorCreate && (
              <h5 className="text-danger text-center font-weight-bold">
                {errorCreate}
              </h5>
            )}
            {successCreate && (
              <h5 className="text-success text-center font-weight-bold">
                Tip was added successfully
              </h5>
            )}
            <div className="btn-holder">
              {loadingCreate ? (
                <SpinnerLoader />
              ) : (
                <Button
                  className="button-save"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default FaqTips;
