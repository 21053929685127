import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { FaFileExport } from "react-icons/fa";

const StoreSummary = (props) => {
  const [csvData, setCsvData] = useState([]);

  const loadCsv = () => {
    setCsvData([
      [
        "STORE NAME",
        "STATUS",
        "TOTAL INVENTORY",
        "ITEMS SOLD",
        "ITEMS FOR SALE",
        "TOTAL ORDERS",
        "ACTIVE ORDERS",
        "COMPLETED ORDERS",
        "CANCELLED ORDERS",
        "TOTAL SALES",
      ],
      [
        props.name,
        "Active",
        props.siteInventory?.inventory.total,
        props.siteInventory?.inventory.sold,
        Number(props.siteInventory?.inventory.total) -
          Number(props.siteInventory?.inventory.sold),
        props.orders?.total + props.orders?.completed + props.orders?.cancelled,
        props.orders?.active,
        props.orders?.completed,
        props.orders?.cancelled,
        `$ ${Number(props.orders?.price).toFixed(2)}`,
      ],
    ]);
  };

  useEffect(() => {
    loadCsv();
  }, [props.name, props.time]);
  return (
    <div className="mx-3">
      <CSVLink data={csvData} filename={"store-summary.csv"}>
        Export Data
        <FaFileExport className="ml-2" />
      </CSVLink>
    </div>
  );
};

export default StoreSummary;
