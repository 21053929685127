import { Modal } from 'react-bootstrap';
import Button from "../../form/Buttons";
import '../styles.scss'



const DeactivateModal = (props: any) => {

    return (
        <Modal
            {...props}
            onHide={props.onHide}
            size="md"
            aria-labelledby="notification-modal-title"
            id="remove-platform"
            centered
        >

            <Modal.Body className="text-center">
                <div className="my-5">
                    <h5 className="fw-bold primary-color">Deactivate this platform</h5>
                    <p className="mt-1 mb-3" style={{fontSize: '0.9rem'}}>
                    Are you sure you want to deactivate this platform? This platform will no longer be visible to user.
                    </p>
                </div>
                <button type="button" onClick={props.onHide} className="w-100 py-3 oss-btn fw-bold" >Yes</button>
                <button type="button" onClick={props.onHide} className="w-100 mt-3 no-btn py-3 fw-bold" >No</button>
            </Modal.Body>
        </Modal>
    )
}
export default DeactivateModal;