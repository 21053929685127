import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-dropdown/assets/index.css";
import { useState } from "react";
import { useSelector } from "react-redux";

import "./sort.scss";

const PlatfromSort = (props) => {
  const { loading, sites, error } = useSelector((state) => state.SitesReducer);
  const [choose, SetChoose] = useState(false);
  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(!visible);
  };

  const menuCallback = () => (
    <Menu onSelect={onSelect}>
      <div className="_manag-head-select">
        <h3>Sort</h3>
        <button onClick={showModal}>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="28" height="28" rx="8" fill="#E2F8F1" />
            <path
              d="M13.9973 8.7602C13.7151 8.7602 13.433 8.86794 13.2178 9.08297L6.44799 15.8529C6.01734 16.2835 6.01734 16.9818 6.44799 17.4122C6.87846 17.8427 7.57655 17.8427 8.00724 17.4122L13.9973 11.4218L19.9874 17.412C20.418 17.8425 21.116 17.8425 21.5465 17.412C21.9773 16.9816 21.9773 16.2833 21.5465 15.8527L14.7767 9.08276C14.5615 8.8677 14.2794 8.7602 13.9973 8.7602Z"
              fill="#3ED0A3"
            />
          </svg>
        </button>
      </div>
      <MenuItem key="1">Recently added</MenuItem>
      <MenuItem key="2">Earlier added</MenuItem>
      <MenuItem key="3">A-Z name</MenuItem>
      <MenuItem key="4">Z-A name</MenuItem>
    </Menu>
  );

  const onSelect = ({ key }) => {
    console.log(key);
    const sort_result = sites?.sort((a, b) => {
      if (key === "3") {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      } else if (key === "4") {
        return -1 * a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      } else if (key === "1") {
        return Date.parse(b.created_on) - Date.parse(a.created_on);
      } else if (key === "2") {
        return Date.parse(a.created_on) - Date.parse(b.created_on);
      }
    });
    props.callback(sort_result);
  };

  return (
    <div className="_wrap-sort">
      <Dropdown
        trigger={["click"]}
        overlay={menuCallback}
        animation="slide-up"
        visible={visible}
      >
        <button className="_mang-sort-button" onClick={showModal}>
          <svg
            width="66"
            height="48"
            viewBox="0 0 66 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="66" height="48" rx="10" fill="#F6F7F8" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M46 15.9373C46 17.0071 45.1524 17.8745 44.1063 17.8745H21.8937C20.8479 17.8745 20 17.0074 20 15.9373C20 14.8674 20.8476 14 21.8937 14H44.1066C45.1524 14 46 14.8674 46 15.9373ZM39.7096 24C39.7096 25.0698 38.862 25.9373 37.8159 25.9373H21.8937C20.8479 25.9373 20 25.0701 20 24C20 22.9302 20.8476 22.0627 21.8937 22.0627H37.8162C38.862 22.0627 39.7096 22.9302 39.7096 24ZM28.3804 34C29.4264 34 30.2741 33.1326 30.2741 32.0627C30.2741 30.9929 29.4264 30.1255 28.3807 30.1255H21.8937C20.8476 30.1255 20 30.9929 20 32.0627C20 33.1329 20.8479 34 21.8937 34H28.3804Z"
              // fill="#16284D"
              fill={choose ? "#3ED0A3" : "#16284D"}
            />
          </svg>
        </button>
      </Dropdown>
    </div>
  );
};

export default PlatfromSort;
