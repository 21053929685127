import { useState } from "react";
import { HexColorPicker } from "react-colorful";
import {
  InputGroup,
  FormControl,
  Row,
  Col,
  Modal,
  Button,
  Image,
} from "react-bootstrap";
import { UpdateNodeAction } from "../../actions/ApiAction";
import { SHOW_MODAL_STATE } from "../../constants/ApiConstants";
import { useDispatch } from "react-redux";

const EditCarousel = ({ nodee, props }) => {
  const dispatch = useDispatch();
  const [node, setNode] = useState(nodee);

  const [TitleColor, setTitleColor] = useState(node.styles.titleColor);

  const saveChanges = () => {
    node.styles.titleColor = TitleColor;
    dispatch(UpdateNodeAction(node));
    dispatch({ type: SHOW_MODAL_STATE });
  };

  const editImageUrl = (url) => {
    let updatedNode = { ...node.data };

    updatedNode.images = [url];

    setNode((oldNode) => {
      let newNode = { ...oldNode };
      newNode.data = updatedNode;
      return newNode;
    });
  };

  const editbannerText = (text) => {
    let updatedNode = { ...node.data };

    updatedNode.banner_text = text;

    setNode((oldNode) => {
      let newNode = { ...oldNode };
      newNode.data = updatedNode;
      return newNode;
    });
  };

  const changeButtontext = (text) => {
    let updatedNode = { ...node.data.button };

    updatedNode.text = text;

    setNode((oldNode) => {
      let newNode = { ...oldNode };
      newNode.data.button = updatedNode;
      return newNode;
    });
  };

  const changeButtonurl = (url) => {
    let updatedNode = { ...node.data.button };

    updatedNode.url = url;

    setNode((oldNode) => {
      let newNode = { ...oldNode };
      newNode.data.button = updatedNode;
      return newNode;
    });
  };

  return (
    <>
      <Modal.Body>
        <h4 className="tint opacity font-weight-bold mb-4">PROPERTIES</h4>

        <h6 className="tint font-weight-bold mb-2">*BANNER IMAGE</h6>
        <Col>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text
                id="inputGroup-sizing-default"
                className="tint font-weight-bold"
              >
                PASTE IMAGE URL
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              aria-label="Default"
              value={node.data.images}
              onChange={(e) => editImageUrl(e.target.value)}
              aria-describedby="inputGroup-sizing-default"
            />
          </InputGroup>
        </Col>
        <Col>
          <Image
            src={node.data.images}
            className="img-thumbnail"
            width="200px"
            height="200px"
            rounded
            fluid
          />
        </Col>
        <hr />
        <h6 className="tint font-weight-bold mb-2 mt-2">*BANNER TEXT</h6>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text
              id="inputGroup-sizing-default"
              className="tint font-weight-bold"
            >
              BANNER TEXT
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            aria-label="Default"
            value={node.data.banner_text}
            onChange={(e) => editbannerText(e.target.value)}
            aria-describedby="inputGroup-sizing-default"
          />
        </InputGroup>

        <hr />
        <h6 className="tint font-weight-bold mb-2 mt-2">*BANNER BUTTON</h6>
        <Row>
          <Col>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text
                  id="inputGroup-sizing-default"
                  className="tint font-weight-bold"
                >
                  TEXT
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                aria-label="Default"
                value={node.data.button.text}
                onChange={(e) => changeButtontext(e.target.value)}
                aria-describedby="inputGroup-sizing-default"
              />
            </InputGroup>
          </Col>
          <Col>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text
                  id="inputGroup-sizing-default"
                  className="tint font-weight-bold"
                >
                  URL
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                value={node.data.button.url}
                onChange={(e) => changeButtonurl(e.target.value)}
              />
            </InputGroup>
          </Col>
        </Row>

        <h6 className="tint font-weight-bold mt-3 mb-1">*STYLES</h6>
        <hr />
        <Row>
          <Col>
            <b className="tint">*TITLE COLOR</b>
            <HexColorPicker color={TitleColor} onChange={setTitleColor} />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="modalRow">
          <Button
            variant="dark"
            className="font-weight-bold rounded"
            onClick={props.onHide}
          >
            Close
          </Button>
          <Button
            className="bg-tint text-white font-weight-bold"
            onClick={saveChanges}
          >
            Save
          </Button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default EditCarousel;
