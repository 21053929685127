import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FaAngleLeft, FaEllipsisH } from "react-icons/fa";
import { Form } from "react-bootstrap";
import NewLayoutBox from "./NewLayoutBox";
import { EDIT_THEME_STATE, NODE_RESET } from "../constants/ApiConstants";
import { useDispatch } from "react-redux";
import EmptyLayoutBox from "./EmptyLayoutBox";
import SecondLayoutBox from "../secondTemplate/components/SecondLayoutBox";
import ThirdLayoutBox from "../thirdTemplate/components/ThirdLayoutBox";
import FourthLayoutBox from "../fourthTemplate/components/FourthLayoutBox";

const SideBar = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const redirectHandler = (editor) => {
    if (editor === "home") {
      history.push("/dashboard");
    }
  };

  const [theme, setTheme] = useState("template");

  const changeTheme = (value) => {
    dispatch({ type: NODE_RESET });
    dispatch({ type: EDIT_THEME_STATE, payload: value });
    setTheme(value);
  };

  useEffect(() => {}, [history]);

  return (
    <div className="sideBar">
      <div className="unScrollableSideBar">
        <div className="arrowBoxGrid mb-4">
          <div className="arrowBox" onClick={() => redirectHandler("home")}>
            <FaAngleLeft />
          </div>
          <h4 className="font-weight-bold">Editor</h4>
        </div>

        <div className="LogoGrid mb-4">
          <div>
            <img src="/multiLogo.png" alt="website Logo" />
          </div>
          <div>
            <h6 className="font-weight-bold">New Site</h6>
            <small>Website •building</small>
          </div>
        </div>

        <div className="ActivateBtnGrid mb-3">
          <button className="activateButton font-weight-bold" disabled>
            Activate
          </button>
        </div>

        <h6 className="font-weight-bold tint">Theme</h6>

        <Form.Control
          as="select"
          className="mb-3 bg-light"
          value={theme}
          onChange={(e) => changeTheme(e.target.value)}
        >
          <option value="template" disabled></option>
          <option value="vanilla">Vanilla Theme</option>
          <option value="second">Second Theme</option>
          <option value="third">Third Theme</option>
          <option value="fourth">Fourth Theme</option>
        </Form.Control>

        <h6 className="font-weight-bold tint">Layout</h6>
      </div>{" "}
      {theme && theme === "template" && <EmptyLayoutBox />}
      {theme && theme === "vanilla" && <NewLayoutBox />}
      {theme && theme === "second" && <SecondLayoutBox />}
      {theme && theme === "third" && <ThirdLayoutBox />}
      {theme && theme === "fourth" && <FourthLayoutBox />}
    </div>
  );
};

export default SideBar;
