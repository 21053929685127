import React, { useEffect, useState } from "react";
import "./thisMonth.scss";
import { Form, Modal } from "react-bootstrap";
import StartDate from "./date/StartDate";
import EndDate from "./date/EndDate";

const ThisMonth: React.FC = ({ time, setTime }) => {
  const [modalShow, setModalShow] = React.useState(false);

  const [customTime, setCustomTime] = React.useState(time);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleChange = (e) => {
    setCustomTime(e.target.value);
  };

  const handleSubmit = () => {
    if (customTime === "Custom") {
      setTime({ startDate, endDate, customTime });
    } else {
      setTime(customTime);
    }
    setModalShow(false);
  };

  return (
    <>
      <button
        onClick={() => setModalShow(true)}
        className="_this-month-btn"
        type="button"
      >
        <span className="_text">{time}</span>
        <span className="_icon">
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.74287 5.79485L7.785 2.78708C7.92559 2.61123 8 2.43405 8 2.28678C8 2.00206 7.73333 1.82593 7.28695 1.82593L0.712013 1.82593C0.266155 1.82593 0 2.00183 0 2.28589C0 2.43338 0.0744715 2.60774 0.215449 2.78397L3.25752 5.79352C3.45348 6.03851 3.71717 6.17418 4.00036 6.17418C4.28335 6.17424 4.54698 6.04012 4.74287 5.79485Z"
              fill="#3E4452"
            />
          </svg>
        </span>
      </button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="_this-month"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Select time
          </Modal.Title>

          <button onClick={() => setModalShow(false)} className="_close-modal">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="28" height="28" rx="8" fill="#E8ECF0" />
              <path
                d="M20.698 8.23344L19.7383 7.28517C19.4786 7.02549 19.0496 7.02549 18.7786 7.28517L13.9915 12.0721L9.1142 7.19484C8.85441 6.93505 8.42537 6.93505 8.15452 7.19484L7.19484 8.15452C6.93505 8.4142 6.93505 8.84323 7.19484 9.1142L12.061 13.9803L7.28517 18.7787C7.02549 19.0384 7.02549 19.4674 7.28517 19.7384L8.24484 20.6981C8.50452 20.9577 8.93355 20.9577 9.20452 20.6981L13.9915 15.911L18.7786 20.6981C19.0383 20.9577 19.4673 20.9577 19.7383 20.6981L20.698 19.7384C20.9576 19.4787 20.9576 19.0497 20.698 18.7787L15.8996 13.9916L20.6867 9.20463C20.9576 8.93344 20.9576 8.50441 20.698 8.23344Z"
                fill="#777C85"
              />
            </svg>
          </button>
        </Modal.Header>
        <div className="_hold-this-month-content">
          <div className="_select-time">
            <h2>All Time</h2>
            <label>
              <Form.Check
                type="radio"
                id="time0"
                name="radio-group"
                value="All Time"
                onChange={handleChange}
                checked={customTime === "All Time"}
              />
            </label>
          </div>
          <div className="_select-time">
            <h2>Today</h2>
            <label>
              <Form.Check
                type="radio"
                id="time1"
                name="radio-group"
                value="Today"
                onChange={handleChange}
                checked={customTime === "Today"}
              />
            </label>
          </div>
          <div className="_select-time">
            <h2>This week</h2>
            <label>
              <Form.Check
                type="radio"
                id="time2"
                name="radio-group"
                value="This week"
                onChange={handleChange}
                checked={customTime === "This week"}
              />{" "}
            </label>
          </div>
          <div className="_select-time">
            <h2 className="h2">This month</h2>
            <label>
              <Form.Check
                type="radio"
                id="time3"
                name="radio-group"
                value="This month"
                onChange={handleChange}
                checked={customTime === "This month"}
              />{" "}
            </label>
          </div>
          <div className="_select-time">
            <h2>This year</h2>
            <label>
              <Form.Check
                type="radio"
                id="time4"
                name="radio-group"
                value="This Year"
                onChange={handleChange}
                checked={customTime === "This Year"}
              />{" "}
            </label>
          </div>
          <div className="_select-time">
            <h2>Custom</h2>
            <label>
              <Form.Check
                type="radio"
                id="time4"
                name="radio-group"
                value="Custom"
                onChange={handleChange}
                checked={customTime === "Custom"}
              />{" "}
            </label>
          </div>
          {customTime === "Custom" && (
            <div className="_date">
              <h2>Custome date range</h2>
              <div className="_hold-date">
                <StartDate startDate={startDate} setStartDate={setStartDate} />
                <EndDate endDate={endDate} setEndDate={setEndDate} />
              </div>
            </div>
          )}
          <div className="_confirm">
            <button onClick={handleSubmit}>Confirm</button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ThisMonth;
