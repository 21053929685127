import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { FaTrash, FaPencilAlt } from "react-icons/fa";
import { deleteNodeAction } from "../../actions/ApiAction";
import { useDispatch } from "react-redux";
import { SHOW_MODAL_STATE } from "../../constants/ApiConstants";
import { BsArrowsMove } from "react-icons/bs";

const _style = makeStyles(function (theme) {
  return {
    grow: {
      flexGrow: 1,
    },
    root: (props) => ({
      position: "relative",
      overflow: "hidden",
      height: "90vh",
    }),
    carouselImage: {
      width: "100%",
      height: "100%",
    },
    title: function (props) {
      return {
        position: "absolute",
        bottom: "20%",
        left: "2rem",
        width: "70%",
        color: props.titleColor,
        fontSize: "5.5rem",
        [theme.breakpoints.down("lg")]: {
          fontSize: "4rem",
        },
        [theme.breakpoints.down("md")]: {
          fontSize: "3.5rem",
        },
        [theme.breakpoints.down("sm")]: {
          bottom: "15%",
          fontSize: "2.5rem",
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "1.8rem",
        },
      };
    },
    button: function (props) {
      return {
        color: "#000",
        fontSize: "2rem",
        textTransform: "none",
        [theme.breakpoints.down("lg")]: {
          fontSize: "1.2rem",
        },
        [theme.breakpoints.down("md")]: {
          fontSize: "1rem",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "0.5rem",
        },
      };
    },
  };
});

export default function Carousel({ nodeData }) {
  let classes = _style({
    ...nodeData.styles,
    imgUrl: nodeData.data.images[0],
  });

  const dispatch = useDispatch();

  const EditFunctionality = () => {
    dispatch({ type: SHOW_MODAL_STATE, payload: nodeData });
  };

  const deleteNodeHandler = (node) => {
    dispatch(deleteNodeAction(node));
  };

  return (
    <Draggable key={nodeData.id} draggableId="Carousel" index={nodeData.id}>
      {(provided) => (
        <div
          className={(classes.grow, "componentBuildBox")}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <div className="cmpMoveIcon" {...provided.dragHandleProps}>
            <BsArrowsMove className="cmp" />
          </div>
          <div className="deleteFlex">
            <div className="editIDiv" onClick={EditFunctionality}>
              <FaPencilAlt className="editIcon" />
            </div>
            <div
              className="deleteIDiv"
              onClick={() => deleteNodeHandler(nodeData)}
            >
              <FaTrash className="deleteIcon" />
            </div>
          </div>

          <div className={classes.root}>
            <img
              src={nodeData.data.images[0]}
              className={classes.carouselImage}
              alt="img"
            />
            <div className={classes.title}>
              <div>{nodeData.data.banner_text}</div>
              <Button
                variant="contained"
                className={classes.button}
                disableElevation
              >
                {nodeData.data.button.text}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}
