import {
  InputGroup,
  FormControl,
  Row,
  Col,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import { useState } from "react";
import { UpdateNodeAction } from "../../actions/ApiAction";
import { SHOW_MODAL_STATE } from "../../constants/ApiConstants";
import { useDispatch } from "react-redux";

const EditFooter = ({ nodee, props }) => {
  const dispatch = useDispatch();
  const [node, setNode] = useState(nodee);

  const saveChanges = () => {
    dispatch(UpdateNodeAction(node));
    dispatch({ type: SHOW_MODAL_STATE });
  };

  const handleNameThirdColumnChange = (ObjName, ObjIndex) => {
    let updatedNav = node.data.column_three.map((item, index) => {
      if (index === ObjIndex) {
        return { ...item, name: ObjName };
      } else {
        return item;
      }
    });
    setNode((oldNode) => {
      let newNode = { ...oldNode };
      newNode.data.column_three = updatedNav;
      return newNode;
    });
  };

  const handleUrlThirdColumnChange = (ObjName, ObjIndex) => {
    let updatedNav = node.data.column_three.map((item, index) => {
      if (index === ObjIndex) {
        return { ...item, url: ObjName };
      } else {
        return item;
      }
    });
    setNode((oldNode) => {
      let newNode = { ...oldNode };
      newNode.data.column_three = updatedNav;
      return newNode;
    });
  };

  const handleAddressChange = (value) => {
    let updatedNav = node.data.column_four;
    updatedNav[0].address = value;
    setNode((oldNode) => {
      let newNode = { ...oldNode };
      newNode.data.column_four = updatedNav;
      return newNode;
    });
  };

  const handleLogoChange = (value) => {
    let updatedNav = node.data.logo;
    updatedNav = value;
    setNode((oldNode) => {
      let newNode = { ...oldNode };
      newNode.data.logo = updatedNav;
      return newNode;
    });
  };

  return (
    <>
      <Modal.Body>
        <h4 className="tint opacity font-weight-bold mb-4">PROPERTIES</h4>

        <h6 className="tint font-weight-bold mb-1">*LOGO</h6>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text
              id="inputGroup-sizing-default"
              className="tint font-weight-bold"
            >
              LOGO
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            aria-label="Default"
            value={node.data.logo}
            onChange={(e) => handleLogoChange(e.target.value)}
            aria-describedby="inputGroup-sizing-default"
          />
        </InputGroup>

        <hr className="bg-tint opacity" />

        <h6 className="tint font-weight-bold mt-3 mb-1">*QUICK LINKS</h6>
        {node.data.column_three.map((Ilink, index) => (
          <Row>
            <Col>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text
                    id="inputGroup-sizing-default"
                    className="tint font-weight-bold"
                  >
                    NAME
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  aria-label="Default"
                  value={Ilink.name}
                  onChange={(e) =>
                    handleNameThirdColumnChange(e.target.value, index)
                  }
                  aria-describedby="inputGroup-sizing-default"
                />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text
                    id="inputGroup-sizing-default"
                    className="tint font-weight-bold"
                  >
                    URL
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  value={Ilink.url}
                  onChange={(e) =>
                    handleUrlThirdColumnChange(e.target.value, index)
                  }
                />
              </InputGroup>
            </Col>
          </Row>
        ))}

        <hr className="bg-tint opacity" />

        <h6 className="tint font-weight-bold mt-3 mb-1">*ADDRESS DETAILS</h6>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text
              id="inputGroup-sizing-default"
              className="tint font-weight-bold"
            >
              Address
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            value={node.data.column_four[0].address}
            onChange={(e) => handleAddressChange(e.target.value)}
          />
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <div className="modalRow">
          <Button
            variant="dark"
            className="btn-dark font-weight-bold rounded"
            onClick={props.onHide}
          >
            Close
          </Button>
          <Button
            className="bg-tint text-white font-weight-bold"
            onClick={saveChanges}
          >
            Save
          </Button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default EditFooter;
