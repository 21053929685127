import { Box, Container, Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { BsArrowsMove } from "react-icons/bs";
import { FaTrash, FaPencilAlt } from "react-icons/fa";
import { Draggable } from "react-beautiful-dnd";
import { deleteNodeAction } from "../../actions/ApiAction";
import { SHOW_MODAL_STATE } from "../../constants/ApiConstants";
import { useDispatch } from "react-redux";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

const _style = makeStyles(function (theme) {
  return {
    grow: {
      flexGrow: 1,
    },
    root: {
      background: "#ffffff",
      color: "#000000",
      padding: "20px 0",
    },

    title: {
      marginTop: "10px",
      marginBottom: "40px",
      "& h3": {
        fontSize: theme.typography.pxToRem("24"),
        fontWeight: "bold",
        color: "#000000",
        fontFamily: `'Montserrat', 'sans-serif'`,
      },
    },
    img: {
      width: "250px",
    },

    ParentSwipper: {
      "& .swiper-slide": {
        marginRight: "0 !important",
        width: "auto !important",
      },
    },

    Wrapper: {
      paddingRight: " 0 !important",
    },
    Name: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: "normal",
      color: "#000000",
      marginTop: "15px",
      marginBottom: "10px",
    },
    HoldPrices: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    CurrentPrice: {
      fontSize: theme.typography.pxToRem(20),
      color: "#000000",
      marginRight: "8px",
      fontWeight: "bold",
      fontFamily: `'Montserrat', 'sans-serif'`,
    },
    RealPrice: {
      fontSize: theme.typography.pxToRem(16),
      color: "#090909",
      fontWeight: "normal",
      fontFamily: `'Montserrat', 'sans-serif'`,
    },
  };
});

export default function Promotions({ nodeData }) {
  let classes = _style(nodeData.styles);

  const [data, setData] = useState([...nodeData.data.promotion_list]);

  useEffect(() => {
    // getData();
  }, [nodeData]);

  async function getData() {
    var response = await axios.get(nodeData.data.api.url);
    // nodeData.data = response.data;
    setData(response.data);
  }

  const dispatch = useDispatch();

  const deleteNodeHandler = (node) => {
    dispatch(deleteNodeAction(node));
  };

  const EditFunctionality = () => {
    dispatch({ type: SHOW_MODAL_STATE, payload: nodeData });
  };

  return (
    <Draggable key={nodeData.id} draggableId="promotions" index={nodeData.id}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={(classes.grow, "componentBuildBox")}
        >
          <div {...provided.dragHandleProps} className="cmpMoveIcon">
            <BsArrowsMove className="cmp" />
          </div>
          <div className="deleteFlex">
            <div className="editIDiv" onClick={EditFunctionality}>
              <FaPencilAlt className="editIcon" />
            </div>
            <div
              className="deleteIDiv"
              onClick={() => deleteNodeHandler(nodeData)}
            >
              <FaTrash className="deleteIcon" />
            </div>
          </div>

          <div className={classes.root}>
            <Container maxWidth="xl" className={classes.Wrapper}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} align="center" className={classes.title}>
                  <Typography component="h3">{nodeData.data.title}</Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  align="center"
                  className={classes.CollectBox}
                >
                  <Swiper
                    slidesPerView={3}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                    className={classes.ParentSwipper}
                  >
                    {data.map((item, index) => (
                      <SwiperSlide
                        key={index}
                        lg={2}
                        style={{
                          marginRight: 0,
                        }}
                      >
                        <Box>
                          <img
                            src={item.image}
                            alt={index}
                            className={classes.img}
                          />
                        </Box>
                        <div>
                          <Typography component="h4" className={classes.Name}>
                            {item.name}
                          </Typography>
                          <Typography
                            component="div"
                            className={classes.HoldPrices}
                          >
                            <Typography
                              component="p"
                              className={classes.CurrentPrice}
                            >
                              {item.price}
                            </Typography>
                            <Typography
                              component="p"
                              className={classes.RealPrice}
                            >
                              {item.discountPrice}
                            </Typography>
                          </Typography>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      )}
    </Draggable>
  );
}
