import React, { useEffect, useState } from "react";
import { Modal, Form, Col, Button } from "react-bootstrap";
import "./editModal.scss";

import { FaPencilAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { editStoreBlogAction } from "../../../UpdatedStore/actions/ManagementActions";
import { EDIT_BLOG_RESET } from "../../../UpdatedStore/constants/ManagementProductConstants";
import SpinnerLoader from "../../../UpdatedStore/components/SpinnerLoader";
import CustomFileInput from "../../CustomFileInput";

const EditBlog = ({ blog, site }) => {
  const dispatch = useDispatch();

  const [modalShow, setModalShow] = React.useState(false);
  const [image, setImage] = useState("Upload Image...");
  const [files, setFiles] = useState("");

  const [title, setTitle] = useState("");
  const [answer, setAnswer] = useState("");
  const [message, setMessage] = React.useState("");

  const { loading, error, success } = useSelector(
    (state) => state.EditBlogReducer
  );

  const editItemHandler = () => {
    setMessage("");
    if (title) {
      if (answer) {
        var formData = new FormData();
        formData.append("image", files);
        formData.append("site", site?.name);
        formData.append("id", blog._id);
        formData.append("title", title);
        formData.append("body", answer);

        dispatch(editStoreBlogAction(formData));
      } else {
        setMessage("Post is Invalid!");
      }
    } else {
      setMessage("Title is Invalid!");
    }
  };

  useEffect(() => {
    dispatch({ type: EDIT_BLOG_RESET });
    setImage("Upload Image...");
    setFiles("");

    if (success) {
      setMessageTimeOut();
    }

    setMessage("");
    setTitle(blog.title);
    setAnswer(blog.body);
  }, [success, modalShow, blog, site]);

  const setMessageTimeOut = () => {
    setTimeout(() => {
      setImage("Upload Image...");
      setFiles("");

      dispatch({ type: EDIT_BLOG_RESET });
      setModalShow(false);
    }, 2000);
  };

  return (
    <>

      <button style={{width:'127px', height:'34px', border:'none'}} className="blog-edit-button"
        onClick={() => setModalShow(true)}
      >
        Edit
      </button>
      {/* <FaPencilAlt
        className="tint cursor mx-4"
        onClick={() => setModalShow(true)}
      /> */}

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="_delete-modal"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1>Edit Post</h1>

          <button onClick={() => setModalShow(false)} className="_close-modal">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="28" height="28" rx="8" fill="#E8ECF0" />
              <path
                d="M20.698 8.23344L19.7383 7.28517C19.4786 7.02549 19.0496 7.02549 18.7786 7.28517L13.9915 12.0721L9.1142 7.19484C8.85441 6.93505 8.42537 6.93505 8.15452 7.19484L7.19484 8.15452C6.93505 8.4142 6.93505 8.84323 7.19484 9.1142L12.061 13.9803L7.28517 18.7787C7.02549 19.0384 7.02549 19.4674 7.28517 19.7384L8.24484 20.6981C8.50452 20.9577 8.93355 20.9577 9.20452 20.6981L13.9915 15.911L18.7786 20.6981C19.0383 20.9577 19.4673 20.9577 19.7383 20.6981L20.698 19.7384C20.9576 19.4787 20.9576 19.0497 20.698 18.7787L15.8996 13.9916L20.6867 9.20463C20.9576 8.93344 20.9576 8.50441 20.698 8.23344Z"
                fill="#777C85"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="_hold-content">
            <CustomFileInput
              title="Browse"
              name={image}
              onChange={(e) => {
                setImage(e.target.value);
                setFiles(e.target.files[0]);
              }}
            />
            <Col className="mt-4">
              <Form.Group>
                <Form.Label className="text-muted font-weight-bold">
                  Title*
                </Form.Label>
                <Form.Control
                  value={title}
                  onChange={(e) => {
                    const arr = e.target.value.split(" ");
                    if (arr.length > 0) {
                      for (var i = 0; i < arr.length; i++) {
                        arr[i] =
                          arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                      }
                      const str2 = arr.join(" ");
                      setTitle(str2);
                    } else {
                      setTitle(
                        e.target.value.charAt(0).toUpperCase() +
                          e.target.value.slice(1)
                      );
                    }
                  }}
                  type="text"
                  placeholder="Title"
                  as="textarea"
                  rows="5"
                  style={{ resize: "none" }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label className="text-muted font-weight-bold">
                  Post*
                </Form.Label>
                <Form.Control
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                  as="textarea"
                  rows="5"
                  style={{ resize: "none" }}
                  placeholder="Post"
                />
              </Form.Group>
            </Col>

            {error && <h6 className="text-danger my-1">{error}</h6>}
            {message && <h6 className="text-danger my-1">{message}</h6>}
            {success && <h6 className="text-success my-1">Successful...</h6>}

            {loading ? (
              <SpinnerLoader />
            ) : (
              <button
                className="_yes transcale"
                onClick={editItemHandler}
                disabled={loading}
              >
                {loading ? "Processing..." : "Save"}
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditBlog;
