import React, { useState, useEffect } from "react";
import {
  FaCamera,
  FaChevronCircleDown,
  FaChevronCircleUp,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Form } from "react-bootstrap";
import {
  createStoreCarouselImageAction,
  createStoreImageAction,
  createStoreInfoAction,
  editStoreInfoAction,
} from "../../../UpdatedStore/actions/ManagementActions";
import SpinnerLoader from "../../../UpdatedStore/components/SpinnerLoader";
import {
  CREATE_STORE_CAROUSEL_IMAGE_RESET,
  CREATE_STORE_IMAGE_RESET,
  CREATE_STORE_INFO_RESET,
  EDIT_STORE_INFO_RESET,
} from "../../../UpdatedStore/constants/ManagementProductConstants";
import DeleteStoreInfo from "./DeleteStoreInfo";
import DeleteImage from "./DeleteImage";
import CustomFileInput from "../../CustomFileInput";
import DeleteCarouselImage from "./DeleteCarouselImage";

const Information = ({ site }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { sites } = useSelector((state) => state.SitesReducer);
  const {
    loading: loadingCreate,
    success: successCreate,
    error: errorCreate,
  } = useSelector((state) => state.CreateStoreInfoReducer);

  const {
    loading: loadingCreateImage,
    success: successCreateImage,
    error: errorCreateImage,
  } = useSelector((state) => state.CreateStoreImageReducer);

  const {
    loading: loadingCreateCarouselImage,
    success: successCreateCarouselImage,
    error: errorCreateCarouselImage,
  } = useSelector((state) => state.CreateStoreCarouselImageReducer);

  const {
    loading: loadingEdit,
    success: successEdit,
    error: errorEdit,
  } = useSelector((state) => state.EditStoreInfoReducer);

  const { loading: loadingDelete } = useSelector(
    (state) => state.DeleteStoreInfoReducer
  );

  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [submissionError, setSubmissionError] = useState("");
  const [image, setImage] = useState("Upload Image...");
  const [files, setFiles] = useState("");

  const [imageCarousel, setImageCarousel] = useState("Upload Image...");
  const [filesCarousel, setFilesCarousel] = useState("");

  const [show, setShow] = useState(false);
  const [showCarousel, setShowCarousel] = useState(false);
  const [showAvailable, setShowAvailable] = useState(false);

  const reverseShow = () => {
    setShow(!show);
  };

  const reverseShowCarousel = () => {
    setShowCarousel(!showCarousel);
  };

  const reverseShowAvailable = () => {
    setShowAvailable(!showAvailable);
  };

  const handleSubmit = () => {
    if (!loadingCreate && !loadingDelete && !loadingEdit) {
      setSubmissionError("");
      if (description) {
        if (location) {
          if (site?.store_information.length > 0) {
            dispatch(
              editStoreInfoAction({
                site: site.name,
                description: description,
                location: location,
              })
            );
          } else {
            dispatch(
              createStoreInfoAction({
                site: site.name,
                description: description,
                location: location,
              })
            );
          }
        } else {
          setSubmissionError("Location is Invalid");
        }
      } else {
        setSubmissionError("Description is Invalid");
      }
    }
  };

  const handleSubmitImage = () => {
    var formData = new FormData();
    formData.append("image", files);
    formData.append("site", site?.name);

    dispatch(createStoreImageAction(formData));
  };

  const handleSubmitCarouselImage = () => {
    var formData = new FormData();
    formData.append("image", filesCarousel);
    formData.append("site", site?.name);

    dispatch(createStoreCarouselImageAction(formData));
  };

  const initialiseStates = () => {
    setDescription(
      site?.store_information[0] ? site.store_information[0].description : ""
    );
    setLocation(
      site?.store_information[0] ? site.store_information[0].location : ""
    );
  };

  useEffect(() => {
    dispatch({ type: CREATE_STORE_IMAGE_RESET });
    dispatch({ type: CREATE_STORE_CAROUSEL_IMAGE_RESET });

    setImage("Upload Image...");
    setFiles("");

    setImageCarousel("Upload Image...");
    setFilesCarousel("");

    if (!site) history.push("/dashboard/management");
    initialiseStates();
    if (successCreate) {
      setTimeout(() => {
        dispatch({ type: CREATE_STORE_INFO_RESET });
      }, 2000);
    }
    if (successEdit) {
      setTimeout(() => {
        dispatch({ type: EDIT_STORE_INFO_RESET });
      }, 2000);
    }
    if (successCreateImage) {
      dispatch({ type: CREATE_STORE_IMAGE_RESET });
      setImage("Upload Image...");
      setFiles("");
    }
    if (successCreateCarouselImage) {
      dispatch({ type: CREATE_STORE_CAROUSEL_IMAGE_RESET });
      setImageCarousel("Upload Image...");
      setFilesCarousel("");
    }
  }, [
    site,
    history,
    sites,
    successCreate,
    successEdit,
    successCreateImage,
    successCreateCarouselImage,
  ]);

  return (
    <div className="faq">
      <h4>
        Store Images{" "}
        {show ? (
          <FaChevronCircleUp
            className="cursor transcale"
            onClick={reverseShow}
          />
        ) : (
          <FaChevronCircleDown
            className="cursor transcale"
            onClick={reverseShow}
          />
        )}
      </h4>
      {show && (
        <div className="AddRow">
          <p className="text-danger text-center my-3 font-weight-bold">
            Quick Tip: Click on an image to delete it
          </p>
          <div className="imageBox">
            {site.store_images.map((img, index) => (
              <DeleteImage site={site} img={img} key={index} />
            ))}
            <CustomFileInput
              title="Browse"
              name={image}
              onChange={(e) => {
                setImage(e.target.value);
                setFiles(e.target.files[0]);
              }}
            />
          </div>
          {errorCreateImage && (
            <h5 className="text-danger text-center my-4 font-weight-bold">
              {errorCreateImage}
            </h5>
          )}
          {files && (
            <div className="btn-holder my-4">
              {loadingCreateImage ? (
                <SpinnerLoader />
              ) : (
                <>
                  <button
                    className="_add-new-item cursor transcale mr-4"
                    onClick={handleSubmitImage}
                  >
                    Save
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      )}

      <h4>
        Banner Images{" "}
        {showCarousel ? (
          <FaChevronCircleUp
            className="cursor transcale"
            onClick={reverseShowCarousel}
          />
        ) : (
          <FaChevronCircleDown
            className="cursor transcale"
            onClick={reverseShowCarousel}
          />
        )}
      </h4>
      {showCarousel && (
        <div className="AddRow">
          <p className="text-danger text-center my-3 font-weight-bold">
            Quick Tip: Click on an image to delete it
          </p>
          <div className="imageBox">
            {site.carousel_images.map((img, index) => (
              <DeleteCarouselImage site={site} img={img} key={index} />
            ))}
            <CustomFileInput
              title="Browse"
              name={imageCarousel}
              onChange={(e) => {
                setImageCarousel(e.target.value);
                setFilesCarousel(e.target.files[0]);
              }}
            />
          </div>
          {errorCreateCarouselImage && (
            <h5 className="text-danger text-center my-4 font-weight-bold">
              {errorCreateCarouselImage}
            </h5>
          )}
          {filesCarousel && (
            <div className="btn-holder my-4">
              {loadingCreateCarouselImage ? (
                <SpinnerLoader />
              ) : (
                <>
                  <button
                    className="_add-new-item cursor transcale mr-4"
                    onClick={handleSubmitCarouselImage}
                  >
                    Save
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      )}

      <h4 className="mt-4">
        Store Information
        {showAvailable ? (
          <FaChevronCircleUp
            className="cursor transcale"
            onClick={reverseShowAvailable}
          />
        ) : (
          <FaChevronCircleDown
            className="cursor transcale"
            onClick={reverseShowAvailable}
          />
        )}
      </h4>

      {showAvailable && (
        <div className="AddRow">
          <Col>
            <Form.Group>
              <Form.Label className="text-muted font-weight-bold">
                Store's Description*
              </Form.Label>
              <Form.Control
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                type="text"
                placeholder="Store's Description"
                as="textarea"
                rows="5"
                style={{ resize: "none" }}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label className="text-muted font-weight-bold">
                Store's Location*
              </Form.Label>
              <Form.Control
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                as="textarea"
                rows="5"
                style={{ resize: "none" }}
                placeholder="Store's Location"
              />
            </Form.Group>
          </Col>
          {submissionError && (
            <h5 className="text-danger text-center font-weight-bold">
              {submissionError}
            </h5>
          )}
          {errorCreate && (
            <h5 className="text-danger text-center font-weight-bold">
              {errorCreate}
            </h5>
          )}
          {errorEdit && (
            <h5 className="text-danger text-center font-weight-bold">
              {errorEdit}
            </h5>
          )}
          {successCreate && (
            <h5 className="text-success text-center font-weight-bold">
              Information was added successfully!
            </h5>
          )}

          {successEdit && (
            <h5 className="text-success text-center font-weight-bold">
              Information was updated successfully!
            </h5>
          )}

          <div className="btn-holder2">
            {loadingCreate || loadingEdit ? (
              <SpinnerLoader />
            ) : (
              <>
                <button
                  className="_add-new-item cursor transcale mr-4"
                  onClick={handleSubmit}
                >
                  Save
                </button>
                {site?.store_information.length > 0 && (
                  <DeleteStoreInfo site={site} />
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Information;
