import React from "react";
import { Modal, Image, Form } from "react-bootstrap";
import "./addItemModal.scss";

const ViewProductModal = ({ product, product_, promotion }) => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <button onClick={() => setModalShow(true)}>See details</button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="_delete-modal"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h1>Product Details</h1>

          <button onClick={() => setModalShow(false)} className="_close-modal">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="28" height="28" rx="8" fill="#E8ECF0" />
              <path
                d="M20.698 8.23344L19.7383 7.28517C19.4786 7.02549 19.0496 7.02549 18.7786 7.28517L13.9915 12.0721L9.1142 7.19484C8.85441 6.93505 8.42537 6.93505 8.15452 7.19484L7.19484 8.15452C6.93505 8.4142 6.93505 8.84323 7.19484 9.1142L12.061 13.9803L7.28517 18.7787C7.02549 19.0384 7.02549 19.4674 7.28517 19.7384L8.24484 20.6981C8.50452 20.9577 8.93355 20.9577 9.20452 20.6981L13.9915 15.911L18.7786 20.6981C19.0383 20.9577 19.4673 20.9577 19.7383 20.6981L20.698 19.7384C20.9576 19.4787 20.9576 19.0497 20.698 18.7787L15.8996 13.9916L20.6867 9.20463C20.9576 8.93344 20.9576 8.50441 20.698 8.23344Z"
                fill="#777C85"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="_hold-contentProduct">
            <Image
              src={
                product_ ? product.imageUrl : promotion ? product.ImgUrl : ""
              }
              style={{
                width: "100%",
                height: "300px",
                objectFit: "fill",
                border: "0.5px solid #16284d",
                borderRadius: "10px",
              }}
            />

            <Form.Group className="mt-2">
              <Form.Label className="text-muted font-weight-bold">
                Product Name:
              </Form.Label>
              <h2>{product.name}</h2>
            </Form.Group>

            <Form.Group className="mt-2">
              <Form.Label className="text-muted font-weight-bold">
                Product Type:
              </Form.Label>
              <h2>{product.type}</h2>
            </Form.Group>

            <Form.Group className="mt-2">
              <Form.Label className="text-muted font-weight-bold">
                Brand:
              </Form.Label>
              <h2>{product.brand}</h2>
            </Form.Group>

            <Form.Group className="mt-2">
              <Form.Label className="text-muted font-weight-bold">
                Product Category:
              </Form.Label>
              <h2>{product.categories}</h2>
            </Form.Group>

            <Form.Group className="mt-2">
              <Form.Label className="text-muted font-weight-bold">
                Price:
              </Form.Label>
              <h2>
                {" "}
                $
                {Number(
                  product_
                    ? product.price
                    : promotion
                    ? product.CurrentPirce
                    : 0
                ).toFixed(2)}{" "}
                {promotion && (
                  <small
                    className="text-muted"
                    style={{ textDecoration: "line-through" }}
                  >
                    {product.RealPrice}
                  </small>
                )}
              </h2>
            </Form.Group>

            <Form.Group className="mt-2">
              <Form.Label className="text-muted font-weight-bold">
                Product Description:
              </Form.Label>
              <h2>
                {product.description
                  ? product.description
                  : "No Product Description Provided"}
              </h2>
            </Form.Group>

            <Form.Group className="mt-2">
              <Form.Label className="text-muted font-weight-bold">
                Quantity in Stock:
              </Form.Label>
              <h2>{product.quantity}</h2>
            </Form.Group>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewProductModal;
