import { Modal } from 'react-bootstrap';
import Button from "../../form/Buttons";
import '../styles.scss'



const RemoveModal = (props: any) => {

    return (
        <Modal
            {...props}
            onHide={props.onHide}
            size="md"
            aria-labelledby="notification-modal-title"
            id="remove-platform"
            centered
        >

            <Modal.Body className="text-center">
                <div className="my-5">
                    <h5 className="fw-bold primary-color">Deleting this platform</h5>
                    <p className="mt-1 mb-3" style={{fontSize: '0.9rem'}}>Are you sure you want to delete this platform? You
                     won’t be able to recover any thing after deleting.</p>
                </div>
                <Button type="button" onClick={props.onHide} className="w-100 py-3" >Yes</Button>
                <button type="button" onClick={props.onHide} className="w-100 mt-3 no-btn py-3" >No</button>
            </Modal.Body>
        </Modal>
    )
}
export default RemoveModal;