import React from 'react';
import styled from '@mui/system/styled';
import { Grid } from '@mui/material';
import Images from "./Images/Images"
import { useTranslation } from "react-i18next";


const ProcessContainer = styled(Grid)(({ theme }) => ({

    margin:theme.spacing(10),
    textAlign:'center',
    // justifyContent:'center',
    '.goal-heading':{
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: '24px',
        lineHeight: '40px',
        letterSpacing: '0.3px',
        color: '#16284D',
        // width:'640px',
        textAlign:'center',
        paddingBottom:theme.spacing(8),
    },
    '.goal-card':{
        display:'flex',
        justifyContent:'center',
    },
    '.goal-card-info-container': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '.goal-card-info': {
            display: 'inline-flex',
            flexDirection: 'column',
            textAlign: 'center',
            width: '270px',
            marginLeft: theme.spacing(5),
            '.goal-card-title': {
                fontSize: theme.spacing(2.25),
                fontWeight: 'bold',
                lineHeight: theme.spacing(3.75),
                color: '#16284D',
                paddingBottom: theme.spacing(0.75),
            },
            '.goal-card-desc': {
                fontSize: theme.spacing(2.25),
                fontWeight: 'normal',
                lineHeight: theme.spacing(3.75),
                color: '#3E4452',
                paddingBottom: theme.spacing(1.25),
            },
        },
        '.goal-card-info:first-child': {
            marginLeft: theme.spacing(0),
        }
    },
    '.div-hide-show':{
        display:'none',
    },
    '.goal-card-img':{
        margin:'0px 10px',
    },

    '@media (max-width: 575.98px)' :{
        padding:theme.spacing(3),
            
        '.goal-heading':{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: '18px',
            lineHeight: '40px',
            letterSpacing: '0.3px',
            color: '#16284D',
            paddingBottom:theme.spacing(4),
        },
        '.goal-card-title': {
            fontSize: theme.spacing(2),
            fontWeight: 'bold',
            lineHeight: theme.spacing(3.75),
            color: '#16284D',
        },
        '.goal-card-desc': {
            fontSize: theme.spacing(2),
            fontWeight: 'normal',
            lineHeight: theme.spacing(3.75),
            color: '#3E4452',
        },
            '.div-Show-hide':{
                display:'none',
            },
            '.div-hide-show':{
                display:'contents',
            },
            '.goal-card':{
                alignItems:'center',
            }
        },

    '@media (min-width: 576px) and (max-width: 768px)' :{
    padding:theme.spacing(3),
        
        '.div-Show-hide':{
            display:'none',
        },
        '.div-hide-show':{
            display:'contents',
        },
        '.goal-card':{
            alignItems:'center',
        }
    },

'@media only screen and (max-width: 992px)' :{
    '.div-Show-hide':{
        display:'none',
    },
    '.div-hide-show':{
        display:'contents',
    },
    '.goal-card':{
        alignItems:'center',
    }
},

'@media only screen and (max-width: 1200px)' :{
    '.div-Show-hide':{
        display:'none',
    },
    '.div-hide-show':{
        display:'contents',
    },
    '.goal-card':{
        alignItems:'center',
    }
},

}));

const GoalCardImg = ({ imgSrc,  }) => (
    <div className='goal-card-img'><img src={imgSrc} alt="img" /></div>
);

const GoalCardInfo = ({ title, desc, linkText }) => (
    <div className="goal-card-info">
        <div className="goal-card-title">{title}</div>
        <div className="goal-card-desc">{desc}</div>
    </div>
)



export default function Process(props){
    const { t, i18n } = useTranslation();
return (
    <ProcessContainer >
        <div className='goal-heading' >
            {props.name}
        </div>
        <div className='div-Show-hide' >
            <div className='goal-card'>
                <GoalCardImg imgSrc={Images.account} />
                <img src={Images.Rectangle} alt='vector7' />
                <GoalCardImg imgSrc={Images.platform} />
                <img src={Images.Rectangle} alt='vector7' />
                <GoalCardImg imgSrc={Images.Golive} />
                <img src={Images.Rectangle} alt='vector7' />
                <GoalCardImg imgSrc={Images.manageorders} />
            </div>
            <div className='goal-card-info-container'>
                <GoalCardInfo title={t('process.createAccount')} desc={t('process.cardDescription.createAccount')}  />
                <GoalCardInfo title={t('process.configurePlateform')}  desc={t('process.cardDescription.configurePlateform')}  />
                <GoalCardInfo title={t('process.live')} desc={t('process.cardDescription.live')}  />
                <GoalCardInfo title={t('process.receiveOrders')} desc={t('process.cardDescription.receiveOrders')}  />
                
            </div>
        </div>
        <Grid  className='div-hide-show' >
            <Grid container >
                <Grid item xs={12} sm={12} md={6} className='goal-card'>
                    <GoalCardImg imgSrc={Images.account} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} className='goal-card'>
                    <GoalCardInfo title={t('process.createAccount')} desc={t('process.cardDescription')} />
                </Grid>

                <Grid item xs={12} sm={12} md={6} className='goal-card'>
                    <GoalCardImg imgSrc={Images.platform} /> 
                </Grid>
                <Grid item xs={12} sm={12} md={6} className='goal-card'>  
                    <GoalCardInfo title={t('process.configurePlateform')}  desc={t('process.cardDescription')}  />
                </Grid>
                <GoalCardInfo title={t('process.configurePlateform')} desc={t('process.cardDescription')}  />
            </Grid>

            <Grid container >
                <Grid item xs={12} sm={12} md={6} className='goal-card'>
                    <GoalCardImg imgSrc={Images.Golive} />
                    
                </Grid>
                <Grid item xs={12} sm={12} md={6} className='goal-card'>
                    <GoalCardInfo title={t('process.live')} desc={t('process.cardDescription')}  />
                    
                </Grid>
                <GoalCardInfo title={t('process.live')} desc={t('process.cardDescription')}  />

                <Grid item xs={12} sm={12} md={6} className='goal-card'>
                    <GoalCardImg imgSrc={Images.manageorders} />
                 
                </Grid>
                <Grid item xs={12} sm={12} md={6} className='goal-card'>
                    <GoalCardInfo title={t('process.receiveOrders')} desc={t('process.cardDescription')}  />
                </Grid>
                <GoalCardInfo title={t('process.receiveOrders')} desc={t('process.cardDescription')}  />
            </Grid>
        </Grid>
        
    </ProcessContainer>
  )
}
