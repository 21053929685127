import { Col } from "react-bootstrap";
import { useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IUserItem } from "../../types";
import UserDetailsModal from "../modals/User/Details";


import './styles.scss'

const UserItem = ({
    userType,
    email,
    phone,
    name,
    details,
    status,
    is_active
}: IUserItem) => {
    const data = {
        userType,
        email,
        phone,
        name,
        details,
        status,
        is_active,
    }

    return (
        <div className="user-items-container mt-4">
            <div className="d-flex ">

                <Col md={5} className="pe-0">
                    <div className="d-flex purchase-info-container">
                        <div className="user-item-image">
                            <img src={details?.ProfilePicture} className="img-fluid user-item-image" alt="Profile Image" />
                        </div>
                        <div className="user-item-info">
                            <div className="text-bold-700 mb-2"> {name}</div>
                            <p className="mb-0 fw-bold"><span className="primary-color-light">{userType}</span> <span className="user-dot"></span> <span className={`text-bold-600 ${is_active ? 'secondary-color' : 'primary-color-light'}`} >{is_active?"Active":"Inactive"}</span></p>
                        </div>
                    </div>
                </Col>

                <Col md={7}>
                    <div className="d-flex justify-content-between ms-5">
                        <div className="d-flex flex-column justify-space-between">
                            <div>
                                <p className="mb-0 gray-color text-size-13">Email</p>
                                <p className="mb-0 text-bold-600">{email}</p>
                            </div>
                            <div className="mt-5">
                                <p className="mb-0 mt-2 gray-color text-size-13">Phone Number</p>
                                <p className="mb-0 text-bold-600">{details?.PhoneNumber}</p>
                            </div>
                        </div>

                    </div>
                </Col>
            </div>
        </div>
    )
}
export default UserItem;
