import "./index.scss";

import NewBuilderScreen from "./screens/NewBuilderScreen";

export function App() {
  return (
    <div className="mainContainer">
      <NewBuilderScreen />
    </div>
  );
}
