import { Box, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import axios from "axios";
import { BsArrowsMove } from "react-icons/bs";
import { FaTrash, FaPencilAlt } from "react-icons/fa";
import { Draggable } from "react-beautiful-dnd";
import { deleteNodeAction } from "../../actions/ApiAction";
import { SHOW_MODAL_STATE } from "../../constants/ApiConstants";
import { useDispatch, useSelector } from "react-redux";

import { BrowserRouter as Router, Link } from "react-router-dom";
import { markupJson } from "../MarkupRenderer";

const _style = makeStyles(function (theme) {
  return {
    grow: {
      flexGrow: 1,
    },
    root: function (props) {
      return {
        background: props.bgColor,
        color: props.titleColor,
        paddingTop: "105px",
      };
    },

    FristCol: function (props) {
      return {
        "& a": {
          fontSize: theme.typography.pxToRem(16),
          color: props.titleColor,
          fontWeight: "normal",
          fontFamily: `'Montserrat', 'sans-serif'`,
          textDecoration: "none",
        },
        "& h5": {
          fontWeight: "bold",
          fontSize: theme.typography.pxToRem(16),
          color: props.titleColor,
        },
      };
    },
    ThreeCol: function (props) {
      return {
        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "space-between",
        "& a": {
          fontSize: theme.typography.pxToRem(16),
          fontWeight: "bold",
          textDecoration: "none",
          color: props.titleColor,
        },
      };
    },

    FourCol: function (props) {
      return {
        "& h4": {
          fontSize: theme.typography.pxToRem(14),
          color: props.titleColor,
          fontFamily: `'Montserrat', 'sans-serif'`,
          fontWeight: "normal",
          marginBottom: "55px",
          paddingTop: "24px",
        },
      };
    },

    HoldSocial: {
      display: "flex",
      alignItems: "cenetr",
      "& a": {
        marginRight: "35px",
      },
    },
  };
});

export default function Footer({ nodeData }) {
  const layout = markupJson.layout.find((item) => item.type === "Navbar");

  const { layout: NavLayout } = useSelector(
    (state) => state.MarkupLayoutReducer.present
  );

  const NavNode = NavLayout.find((item) => item.type === "Navbar");

  let classes = _style(NavNode ? NavNode.styles : layout.styles);

  // const [data, setData] = useState([...nodeData.data.footer_links]);

  useEffect(() => {
    // getData();
  }, [nodeData]);

  async function getData() {
    var response = await axios.get(nodeData.data.api.url);
    // nodeData.data = response.data;
    // setData(response.data);
  }

  const dispatch = useDispatch();

  const deleteNodeHandler = (node) => {
    dispatch(deleteNodeAction(node));
  };

  const EditFunctionality = () => {
    dispatch({ type: SHOW_MODAL_STATE, payload: nodeData });
  };

  return (
    <Draggable key={nodeData.id} draggableId="Footer" index={nodeData.id}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={(classes.grow, "componentBuildBox")}
        >
          <div {...provided.dragHandleProps} className="cmpMoveIcon">
            <BsArrowsMove className="cmp" />
          </div>
          <div className="deleteFlex">
            <div className="editIDiv" onClick={EditFunctionality}>
              <FaPencilAlt className="editIcon" />
            </div>
            <div
              className="deleteIDiv"
              onClick={() => deleteNodeHandler(nodeData)}
            >
              <FaTrash className="deleteIcon" />
            </div>
          </div>

          <div className={classes.root}>
            <Container maxWidth="xl">
              <Grid container justifyContent="center">
                <Router>
                  <Grid item xs={12} sm={3} lg={3} className={classes.FristCol}>
                    {nodeData.data.our_porducts.map((item, index) => (
                      <Box m={2} key={index} xs={4}>
                        <Typography component="h5">{item.title}</Typography>
                        <Link to={item.url}>{item.name}</Link>
                      </Box>
                    ))}
                  </Grid>
                  <Grid item xs={12} sm={4} lg={3} className={classes.FristCol}>
                    {nodeData.data.column_two.map((item, index) => (
                      <Box m={2} key={index} xs={3}>
                        <Link to={item.url}>{item.name}</Link>
                      </Box>
                    ))}
                  </Grid>

                  <Grid item xs={12} sm={4} lg={3} className={classes.ThreeCol}>
                    {nodeData.data.column_three.map((item, index) => (
                      <Box m={2} key={index} xs={3}>
                        <Link to={item.url}>{item.name}</Link>
                      </Box>
                    ))}
                  </Grid>

                  <Grid item xs={12} sm={4} lg={3} className={classes.FourCol}>
                    {nodeData.data.column_four.map((item, index) => (
                      <Box m={2} key={index} xs={3}>
                        <div>
                          <img src={item.logo} alt="Logo" />
                        </div>
                        <Typography component="h4">{item.address}</Typography>

                        <Box className={classes.HoldSocial}>
                          <Link to="/instagram.com">
                            <img src={item.instagram} alt="instagram" />
                          </Link>
                          <Link to="/twitter.com">
                            <img src={item.twitter} alt="twitter" />
                          </Link>
                          <Link to="/facebook.com">
                            <img src={item.facebook} alt="facebook" />
                          </Link>
                        </Box>
                      </Box>
                    ))}
                  </Grid>
                </Router>
              </Grid>
            </Container>
          </div>
        </div>
      )}
    </Draggable>
  );
}
