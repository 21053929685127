import React from "react";
import "./totalToDateOrders.scss";

const TotalToDateOrders: React.FC = ({ orders, ratio = 0 }) => {
  return (
    <div className="_total-to-date-orders">
      <div className="_total-date">
        <span className="_text">Total to-date orders</span>
        <span className="_number">
          {orders?.total + orders?.completed + orders?.cancelled}
        </span>
      </div>
      <div className="_total-date">
        <span className="_text">Purchase ratio</span>
        <span className="_number">
          {orders?.total + orders?.completed + orders?.cancelled} : {ratio}
        </span>
      </div>

      <div className="_active-order">
        <div className="_active-order">
          <div className="_hold-text">
            <h4>Active order</h4>
            <span className="_number">{orders?.active}</span>
          </div>

          <div>
            <svg
              width="100%"
              height="26"
              viewBox="0 0 654 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="100%"
                height="26"
                rx="13"
                transform="matrix(-1 0 0 1 100% 0)"
                fill="#d8d8d8"
              />
              <rect
                width={`${
                  (orders.active /
                    (orders?.total + orders?.completed + orders?.cancelled)) *
                  100
                }%`}
                height="26"
                rx="13"
                transform={`matrix(-1 0 0 1 ${
                  (orders.active /
                    (orders?.total + orders?.completed + orders?.cancelled)) *
                  100
                }% 0)`}
                fill={orders?.active > 0 ? "#16284d" : "#d8d8d8"}
              />
            </svg>
          </div>
        </div>

        <div className="_active-order">
          <div className="_hold-text">
            <h4>Complete order</h4>
            <span className="_number">{orders?.completed}</span>
          </div>

          <div>
            <svg
              width="100%"
              height="26"
              viewBox="0 0 654 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="100%"
                height="26"
                rx="13"
                transform="matrix(-1 0 0 1 100% 0)"
                fill="#d8d8d8"
              />

              <rect
                width={`${
                  (orders.completed /
                    (orders?.total + orders?.completed + orders?.cancelled)) *
                  100
                }%`}
                height="26"
                rx="13"
                transform={`matrix(-1 0 0 1 ${
                  (orders.completed /
                    (orders?.total + orders?.completed + orders?.cancelled)) *
                  100
                }% 0)`}
                fill={orders?.completed > 0 ? "#3ED0A3" : "#d8d8d8"}
              />
            </svg>
          </div>
        </div>

        <div className="_active-order">
          <div className="_hold-text">
            <h4>Cancelled order</h4>
            <span className="_number">{orders?.cancelled}</span>
          </div>

          <div>
            <svg
              width="100%"
              height="26"
              viewBox="0 0 654 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="100%"
                height="26"
                rx="13"
                transform="matrix(-1 0 0 1 100% 0)"
                fill="#d8d8d8"
              />
              <rect
                width={`${
                  (orders.cancelled /
                    (orders?.total + orders?.completed + orders?.cancelled)) *
                  100
                }%`}
                height="26"
                rx="13"
                transform={`matrix(-1 0 0 1 ${
                  (orders.cancelled /
                    (orders?.total + orders?.completed + orders?.cancelled)) *
                  100
                }% 0)`}
                fill={orders?.cancelled > 0 ? "#F2482F" : "#d8d8d8"}
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalToDateOrders;
