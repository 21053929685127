import React, { useState, useEffect, useRef } from "react";
import "./language.scss";
import Chevron from "../../../../assets/icons/chevron.svg";

export default function Language() {
  const [toggle, setToggle] = useState(false);
  const [heightEl, setHeightEl] = useState();

  const refHeight = useRef();

  useEffect(() => {
    // console.log(refHeight);
    setHeightEl(`${refHeight.current.scrollHeight}px`);
  }, []);

  const toggleState = () => {
    setToggle(!toggle);
  };

  const [customLanguage, setLanguage] = useState(
    localStorage.getItem("language")
  );

  const handleChange = (e) => {
    setLanguage(e.target.value);
    localStorage.setItem("language", e.target.value);
    window.loadDirection();
  };
  return (
    <div className="_hold-lang">
      <button onClick={toggleState} className="_accordion-button">
        <span>Language</span>
        <img className={toggle && "active"} src={Chevron} alt="icon" />
      </button>

      <div
        className={toggle ? "_accordion-toggle _animated" : "_accordion-toggle"}
        style={{ height: toggle ? `${heightEl}` : "0px" }}
        ref={refHeight}
      >
        <div
          // className={toggle ? "_content-wrapper" : "none"}
          aria-hidden={toggle ? "true" : "false"}
          className="_content-wrapper"
        >
          <span className="_hold-border">
            <svg
              width="439"
              height="2"
              viewBox="0 0 439 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 1H439" stroke="#DCDEE3" stroke-dasharray="4" />
            </svg>
          </span>

          <div className="_hold-all-choices">
            <li>
              <span>English</span>

              <input
                type="radio"
                id="lang1"
                name="radio-group"
                value="English"
                checked={customLanguage === "English"}
                onChange={handleChange}
              />
              <label htmlFor="lang1"></label>
            </li>
            <li>
              <span>Arabic</span>

              <input
                type="radio"
                id="lang2"
                name="radio-group"
                value="Arabic"
                checked={customLanguage === "Arabic"}
                onChange={handleChange}
              />
              <label htmlFor="lang2"></label>
            </li>{" "}
          </div>

          <div></div>
        </div>
      </div>
    </div>
  );
}
