import { useState } from "react";
import { HexColorPicker } from "react-colorful";
import {
  InputGroup,
  FormControl,
  Row,
  Col,
  Modal,
  Button,
  Image,
} from "react-bootstrap";
import { UpdateNodeAction } from "../../actions/ApiAction";
import { SHOW_MODAL_STATE } from "../../constants/ApiConstants";
import { useDispatch } from "react-redux";

const EditBanner = ({ nodee, props }) => {
  const dispatch = useDispatch();
  const [node, setNode] = useState(nodee);

  const saveChanges = () => {
    dispatch(UpdateNodeAction(node));
    dispatch({ type: SHOW_MODAL_STATE });
  };

  const editImageUrl = (url) => {
    let updatedNode = { ...node.data };

    updatedNode.images = [url];

    setNode((oldNode) => {
      let newNode = { ...oldNode };
      newNode.data = updatedNode;
      return newNode;
    });
  };

  return (
    <>
      <Modal.Body>
        <h4 className="tint opacity font-weight-bold mb-4">PROPERTIES</h4>

        <h6 className="tint font-weight-bold mb-2">*BANNER IMAGE</h6>
        <Col>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text
                id="inputGroup-sizing-default"
                className="tint font-weight-bold"
              >
                PASTE IMAGE URL
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              aria-label="Default"
              value={node.data.images}
              onChange={(e) => editImageUrl(e.target.value)}
              aria-describedby="inputGroup-sizing-default"
            />
          </InputGroup>
        </Col>
        <Col>
          <Image
            src={node.data.images}
            className="img-thumbnail"
            width="200px"
            height="200px"
            rounded
            fluid
          />
        </Col>
        <hr />
      </Modal.Body>
      <Modal.Footer>
        <div className="modalRow">
          <Button
            variant="dark"
            className="btn-dark font-weight-bold rounded"
            onClick={props.onHide}
          >
            Close
          </Button>
          <Button
            className="bg-tint text-white font-weight-bold"
            onClick={saveChanges}
          >
            Save
          </Button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default EditBanner;
