import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { FaPencilAlt } from "react-icons/fa";
import EditNavbar from "../editComponents/EditNavbar";
import EditBanner from "../editComponents/EditBanner";
import EditCategory from "../editComponents/EditCategory";
import EditNewItems from "../editComponents/EditNewItems";
import EditCollections from "../editComponents/EditCollections";
import EditPromotions from "../editComponents/EditPromotions";
import EditFooter from "../editComponents/EditFooter";

const EditModal = (props) => {
  const { node } = useSelector((state) => state.EditModalReducer);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      centered
      scrollable="true"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3 className="text-uppercase font-weight-bold tint text-center">
            {node ? node.type : "component"} <FaPencilAlt />
          </h3>
        </Modal.Title>
      </Modal.Header>
      {node && node.type === "Navbar" ? (
        <EditNavbar nodee={node} props={props} />
      ) : node && node.type === "Banner" ? (
        <EditBanner nodee={node} props={props} />
      ) : node && node.type === "Category" ? (
        <EditCategory nodee={node} props={props} />
      ) : node && node.type === "NewItems" ? (
        <EditNewItems nodee={node} props={props} />
      ) : node && node.type === "Collection" ? (
        <EditCollections nodee={node} props={props} />
      ) : node && node.type === "Promotions" ? (
        <EditPromotions nodee={node} props={props} />
      ) : node && node.type === "Footer" ? (
        <EditFooter nodee={node} props={props} />
      ) : (
        <>
          <Modal.Body>
            <h4 className="tint opacity font-weight-bold mb-4">PROPERTIES</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-dark font-weight-bold rounded"
              onClick={props.onHide}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default EditModal;
