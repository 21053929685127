import React from "react";
import CanvasTopBox from "../components/CanvasTopBox";

const DefaultCanvas = () => {
  return (
    <div className="mainScreen">
      <CanvasTopBox />

      <div className="markupDiv">
        <div className="text-center previewTXT">
          <h1 className="font-weight-bold text-dark opacity">
            New Template
            <br />
            <small>
              Please select a theme to be able to add components to the canvas
            </small>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default DefaultCanvas;
