import React from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../store";

const ProtectedRoute = (props: RouteProps) => {
  const auth = useSelector((state: RootState) => state.AuthenticationReducer);

  if (auth.user) {
    if (props.path === "/login"  || props.path === "/" || props.path ==="/sign-up") {
      return <Redirect to={"/dashboard"} />;
    }
    return <Route {...props} />;
  } else if (!auth.user) {
    return <Redirect to={"/login"} />;
  } else {
    return <div>Not found</div>;
  }
};

export default ProtectedRoute;