import React, { useState, useEffect } from "react";
import { Card, Image, Button } from "react-bootstrap";
import "../activeOrders/ActiveOrders.scss";

import { useSelector, useDispatch } from "react-redux";
import { GetOrdersAction } from "../../../../UpdatedStore/actions/SiteActions";
import Message from "../../../../UpdatedStore/components/Message";
import Loader from "../../../../UpdatedStore/components/Loader";
import { useHistory } from "react-router-dom";

import NoStaffInThisRole from "../../noStaffAddOne/NoStaffInThisRole";
import OrderPdf from "../../../../pages/order/OrderPdf";

const PreviousOrders: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [ordersLength, setOrdersLength] = useState(true);
  const [filteredOrders, setFilteredOrders] = useState(null);

  const { user } = useSelector((state) => state.AuthenticationReducer);

  const {
    text: searchText,
    store: searchStore,
    sort: sortStore,
    options: filterOptions,
  } = useSelector((state) => state.FilterOrdersReducer);

  const { loading, orders, error } = useSelector(
    (state) => state.OrdersReducer
  );

  const redirectOrderHandler = (order) => {
    history.push(`/dashboard/sites/orders/${order.sitename}/${order._id}`);
  };

  const sortCurrentStore = (key) => {
    var shallowOrders = [...filteredOrders];
    setFilteredOrders((currentOrders) => {
      var sort_result = currentOrders.sort((a, b) => {
        if (key > 0) {
          if (key === 1) {
            return Date.parse(b.createdAt) - Date.parse(a.createdAt);
          } else if (key === 2) {
            return Date.parse(a.createdAt) - Date.parse(b.createdAt);
          } else if (key === 3) {
            return a.shipping.fullname
              .toLowerCase()
              .localeCompare(b.shipping.fullname.toLowerCase());
          } else if (key === 4) {
            return (
              -1 *
              a.shipping.fullname
                .toLowerCase()
                .localeCompare(b.shipping.fullname.toLowerCase())
            );
          } else if (key === 5) {
            return Number(a.totalPrice) > Number(b.totalPrice) ? 1 : -1;
          } else if (key === 6) {
            return Number(a.orders.length) > Number(b.orders.length) ? -1 : 1;
          } else if (key === 7) {
            return Number(a.orders.length) < Number(b.orders.length) ? -1 : 1;
          }
        }
      });

      return sort_result;
    });
  };

  const filterCurrentOrders = (options) => {
    if (options.key === 0) {
      setFilteredOrders((current_filter) => {
        var copiedOrders = [...orders];
        var list_to_add = [];

        var newOrdersListUpdated3 = copiedOrders.map((obj) => {
          var newOrdersList = obj.orders.filter((order_item) => {
            if (order_item.isDelivered || order_item.isCancelled) {
              list_to_add.push({
                sitename: obj.site.name,
                sitetitle: JSON.parse(obj.site.layout).layout.find(
                  (node) => node.type === "Navbar"
                ).data.title,
                ...order_item,
              });
              return { sitename: obj.site.name, ...order_item };
            }
          });
          return { ...obj, orders: newOrdersList };
        });

        return list_to_add;
      });
    }

    if (options.key === 1) {
      if (options.status === "cancelled") {
        setFilteredOrders((currentOrders) => {
          let filtered_items = currentOrders.filter((order_item) => {
            if (order_item.isCancelled) {
              return order_item;
            }
          });
          return filtered_items;
        });
        return;
      }
      if (options.status === "completed") {
        setFilteredOrders((currentOrders) => {
          let filtered_items = currentOrders.filter((order_item) => {
            if (order_item.isDelivered && !order_item.isCancelled) {
              return order_item;
            }
          });
          return filtered_items;
        });
        return;
      }
    }

    if (options.key === 2) {
      setFilteredOrders((currentOrders) => {
        let filtered_items = currentOrders.filter((item) => {
          if (
            item.totalPrice >= options.minPrice &&
            item.totalPrice <= options.maxPrice
          ) {
            return item;
          }
        });
        return filtered_items;
      });
    }

    if (options.key === 3) {
      setFilteredOrders((currentOrders) => {
        let filtered_items = currentOrders.filter((item) => {
          if (
            Date.parse(item.createdAt) >= Date.parse(options.minDate) &&
            Date.parse(item.createdAt) <= Date.parse(options.maxDate)
          ) {
            return item;
          }
        });
        return filtered_items;
      });
    }

    if (options.key === 4) {
      setFilteredOrders((currentOrders) => {
        let filtered_items = currentOrders.filter((item) => {
          if (
            item.orders.reduce((acc, item_) => acc + item_.qty, 0) >=
              options.minStock &&
            item.orders.reduce((acc, item_) => acc + item_.qty, 0) <=
              options.maxStock
          ) {
            return item;
          }
        });
        return filtered_items;
      });
    }

    if (options.key === 5) {
      setFilteredOrders((currentOrders) => {
        let filtered_items = currentOrders.filter((item) => {
          if (options.payment === "paypal") {
            if (item.paymentMethod === "PayPal") {
              return item;
            }
          }
          if (options.payment === "card") {
            if (item.paymentMethod === "Card(One Tap Payments)") {
              return item;
            }
          }
        });
        return filtered_items;
      });
    }
  };

  useEffect(() => {
    if (!orders) {
      dispatch(GetOrdersAction(user.access_token));
    }

    if (orders && !filteredOrders) {
      orders.map((order) => {
        if (order.orders.length > 0) {
          setOrdersLength(false);
        }
      });

      setFilteredOrders((current_filter) => {
        var copiedOrders = [...orders];
        var list_to_add = [];

        var newOrdersListUpdated3 = copiedOrders.map((obj) => {
          var newOrdersList = obj.orders.filter((order_item) => {
            if (order_item.isDelivered || order_item.isCancelled) {
              list_to_add.push({
                sitename: obj.site.name,
                sitetitle: JSON.parse(obj.site.layout).layout.find(
                  (node) => node.type === "Navbar"
                ).data.title,
                ...order_item,
              });
              return { sitename: obj.site.name, ...order_item };
            }
          });
          return { ...obj, orders: newOrdersList };
        });

        return list_to_add;
      });
    }

    if (orders && filteredOrders) {
      if (searchText) {
        const filtext = searchText.toLowerCase();
        setFilteredOrders((currentOrders) => {
          var shallowOrders = [...orders];
          var list_to_add = [];
          var newOrdersListUpdated = shallowOrders.map((obj) => {
            var newOrdersList = obj.orders.filter((order_item) => {
              const filterConstant = order_item.shipping.fullname.toLowerCase();
              if (filterConstant.indexOf(filtext) != -1) {
                list_to_add.push({
                  sitename: obj.site.name,
                  sitetitle: JSON.parse(obj.site.layout).layout.find(
                    (node) => node.type === "Navbar"
                  ).data.title,
                  ...order_item,
                });
                return { sitename: obj.site.name, ...order_item };
              }
            });
            return { ...obj, orders: newOrdersList };
          });

          return list_to_add;
        });
      } else {
        setFilteredOrders((current_filter) => {
          var copiedOrders = [...orders];
          var list_to_add = [];

          var newOrdersListUpdated3 = copiedOrders.map((obj) => {
            var newOrdersList = obj.orders.filter((order_item) => {
              if (order_item.isDelivered || order_item.isCancelled) {
                list_to_add.push({
                  sitename: obj.site.name,
                  sitetitle: JSON.parse(obj.site.layout).layout.find(
                    (node) => node.type === "Navbar"
                  ).data.title,
                  ...order_item,
                });
                return { sitename: obj.site.name, ...order_item };
              }
            });
            return { ...obj, orders: newOrdersList };
          });

          return list_to_add;
        });
      }

      if (searchStore) {
        if (searchStore !== "store") {
          setFilteredOrders((currentOrders2) => {
            var shallowOrders2 = [...currentOrders2];
            var newOrdersListUpdated2 = shallowOrders2.filter((obj) => {
              if (obj.sitename === searchStore) {
                return obj;
              }
            });

            return newOrdersListUpdated2;
          });
        }
      }

      if (filteredOrders && sortStore) {
        sortCurrentStore(sortStore);
      }

      if (filterOptions) {
        filterCurrentOrders(filterOptions);
      }
    }
  }, [orders, searchText, searchStore, sortStore, filterOptions]);

  return (
    <>
      {loading && <Loader />}

      {error && <Message variant="danger">{error}</Message>}

      {!loading && (
        <>
          {orders && ordersLength && (
            <NoStaffInThisRole text="You have no orders yet!" />
          )}
        </>
      )}

      {filteredOrders &&
        orders &&
        !ordersLength &&
        filteredOrders.length <= 0 && (
          <NoStaffInThisRole text="Nothing to see here!" />
        )}

      {filteredOrders && (
        <>
          {filteredOrders.map((item) => (
            <Card
              className="active-order-card"
              id={`${item._id}_${item.sitename}`}
            >
              <div className="_hold-inof-details">
                <div className="_name-id">
                  <div className="_picture">
                    <Image src={item.orders[0].image} />
                  </div>
                  <div className="_info">
                    <div className="_name">
                      <span>
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="11" cy="11" r="11" fill="#CFF4E8" />
                          <mask id="mask0" x="0" y="0" width="22" height="22">
                            <circle cx="11" cy="11" r="11" fill="white" />
                          </mask>
                          <g mask="url(#mask0)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M6.8 9.2C6.8 6.88412 8.68412 5 11 5C13.3159 5 15.2 6.88412 15.2 9.2C15.2 11.5159 13.3159 13.4 11 13.4C8.68412 13.4 6.8 11.5159 6.8 9.2ZM11.9333 14.3333C13.5514 14.3333 15.0757 14.9763 16.2256 16.1438C17.3698 17.3057 18 18.8392 18 20.4622C18 20.7199 17.7911 20.9289 17.5333 20.9289C17.5333 20.9289 13.0887 23 11 23C8.73319 23 4.46667 20.9289 4.46667 20.9289C4.20894 20.9289 4 20.7199 4 20.4622C4 18.8392 4.63016 17.3057 5.77439 16.1438C6.92426 14.9763 8.44864 14.3333 10.0667 14.3333H11.9333Z"
                              fill="#3ED0A3"
                            />
                          </g>
                        </svg>
                      </span>
                      <h3>{item.shipping.fullname}</h3>
                    </div>
                    <h2 className="_id">
                      ID:{"  "}
                      {item._id}{" "}
                    </h2>
                    <div className="_purchased">
                      <p>{item.sitename.substring(0, 5)}</p>
                      <span>
                        <svg
                          width="3"
                          height="3"
                          viewBox="0 0 3 3"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="1.5" cy="1.5" r="1.5" fill="#16284D" />
                        </svg>
                      </span>
                      <p className="">
                        {item?.isPaid &&
                          !item?.isShipped &&
                          !item?.isDelivered &&
                          !item?.isCancelled &&
                          "Purchased"}
                        {item?.isPaid &&
                          item?.isShipped &&
                          !item?.isDelivered &&
                          !item?.isCancelled &&
                          "Out For Delivery"}
                        {item?.isPaid &&
                          item?.isShipped &&
                          item?.isDelivered &&
                          !item?.isCancelled &&
                          "Delivered"}
                        {item?.isCancelled && "Cancelled"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="_status-details">
                  <div className="_data-payment-info">
                    <div>
                      <h4>Transaction date</h4>
                      <h5>
                        {item?.paidAt.substring(0, 10)}{" "}
                        {item?.paidAt.substring(11, 19)}
                      </h5>
                    </div>
                    <div>
                      <h6>Payment</h6>
                      <p>
                        {item.paymentMethod
                          ? item.paymentMethod
                          : "Not specified"}
                      </p>
                    </div>
                  </div>

                  <div className="_items_">
                    <div className="_numbers">
                      <h4>No. of items</h4>
                      <p>
                        {" "}
                        {item.orders.reduce(
                          (acc, item_) => acc + item_.qty,
                          0
                        )}{" "}
                      </p>
                    </div>
                    <div className="_total-price">
                      <h4>Total price</h4>
                      <p>$ {item && Number(item.totalPrice).toFixed(2)}</p>
                    </div>
                  </div>

                  <div className="_actions">
                    {item && (
                      <OrderPdf
                        selectedOrder={item}
                        storeName={item.sitetitle}
                      />
                    )}

                    <div className="_btn-actions">
                      <Button
                        onClick={() => redirectOrderHandler(item)}
                        className="_btn-proceed"
                      >
                        See Details
                      </Button>

                      {/* <Proceed OrderItem={item} siteDetails={site} /> */}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </>
      )}
    </>
  );
};
export default PreviousOrders;
