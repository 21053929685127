import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { BsChevronUp } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { FILTER_ORDERS } from "../../../../UpdatedStore/constants/ManagementsConstants";

import "./Filter.scss";

const filterFormState = {
  startPrice: 0,
  endPrice: 0,
  startDate: "",
  endDate: "",
  startNumItems: "",
  endNumItems: "",
  paymentType: "Debit Card",
};

const FilterOrders: React.FC = () => {
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);

  const [minStock, setMinStock] = React.useState(0);
  const [maxStock, setMaxStock] = React.useState(0);
  const [minPrice, setMinPrice] = React.useState(0);
  const [maxPrice, setMaxPrice] = React.useState(0);
  const [minDate, setMinDate] = React.useState("");
  const [maxDate, setMaxDate] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [payment, setPayment] = React.useState("");

  const [message, setMessage] = React.useState("");

  const handleOrdersFilter = (key) => {
    if (key === 0) {
      // reset filters
      dispatch({
        type: FILTER_ORDERS,
        options: {
          key: 0,
        },
      });

      setMinStock(0);
      setMaxStock(0);
      setMinPrice(0);
      setMaxPrice(0);
      setMinDate("");
      setMaxDate("");
      setStatus("");
      setPayment("");

      setMessage("");
    }

    if (key === 1) {
      // status filters
      dispatch({
        type: FILTER_ORDERS,
        options: {
          status,
          key: 1,
        },
      });
    }

    if (key === 2) {
      // price filters
      if (minPrice >= 0 && maxPrice > minPrice) {
        dispatch({
          type: FILTER_ORDERS,
          options: {
            minPrice,
            maxPrice,
            key: 2,
          },
        });
      } else {
        setMessage("Price range is not valid");
      }
    }

    if (key === 3) {
      // date range filters

      if (minDate && maxDate) {
        if (
          Date.parse(minDate) >= 0 &&
          Date.parse(maxDate) > Date.parse(minDate)
        ) {
          dispatch({
            type: FILTER_ORDERS,
            options: {
              minDate,
              maxDate,
              key: 3,
            },
          });
        } else {
          setMessage("Date range is not valid!");
        }
      } else {
        setMessage("Date is invalid");
      }
    }

    if (key === 4) {
      // items filter
      if (minStock >= 0 && maxStock > minStock) {
        dispatch({
          type: FILTER_ORDERS,
          options: {
            minStock,
            maxStock,
            key: 4,
          },
        });
      } else {
        setMessage("Stock range is not valid!");
      }
    }

    if (key === 5) {
      // payment type filters
      dispatch({
        type: FILTER_ORDERS,
        options: {
          payment,
          key: 5,
        },
      });
    }
    setShowFilter(false);
  };

  return (
    <div className="custom-filter-wrapperf">
      <svg
        className="_open-filter shadow-none cursor"
        onClick={() => {
          setShowFilter(true);
        }}
        width="66"
        height="48"
        viewBox="0 0 66 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="66" height="48" rx="10" fill="#F6F7F8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46 15.9222C46 16.9837 45.1524 17.8444 44.1063 17.8444H21.8937C20.8479 17.8444 20 16.984 20 15.9222C20 14.8607 20.8476 14 21.8937 14H44.1066C45.1524 14 46 14.8607 46 15.9222ZM42.8548 24.0002C42.8548 25.0617 42.0072 25.9224 40.9611 25.9224H25.0389C23.9931 25.9224 23.1452 25.062 23.1452 24.0002C23.1452 22.9387 23.9928 22.078 25.0389 22.078H40.9614C42.0072 22.078 42.8548 22.9387 42.8548 24.0002ZM36.2433 34C37.2894 34 38.137 33.1393 38.137 32.0778C38.137 31.0163 37.2894 30.1556 36.2436 30.1556H29.7567C28.7106 30.1556 27.863 31.0163 27.863 32.0778C27.863 33.1396 28.7109 34 29.7567 34H36.2433Z"
          fill="#16284D"
        />
      </svg>

      {showFilter && (
        <div className="filter-container">
          <div className="filter-form-container">
            <div id="filter-form">
              <div className="d-flex px-3 mb-4 justify-content-between">
                <div className="filter-drawer ">Filter</div>

                <div
                  onClick={() => setShowFilter(false)}
                  className="close-sort-container"
                >
                  <BsChevronUp className="secondary-color" />
                </div>
              </div>
              <div className="px-3">
                <div className="topRowFilter p-3 gx-3">
                  Status
                  <button
                    className="applyFiltersBox"
                    onClick={() => handleOrdersFilter(1)}
                    disabled={!status}
                  >
                    Apply
                  </button>
                </div>
                <Row>
                  <Col className="col-md-4">
                    <button
                      onClick={() => setStatus("active")}
                      className={`btn filter-item filter-btn-order ${
                        status === "active" ? "selected" : ""
                      }`}
                      name="paymentType"
                    >
                      Active
                    </button>
                  </Col>
                  <Col className="col-md-4">
                    <button
                      onClick={() => setStatus("completed")}
                      className={`btn filter-item filter-btn-order ${
                        status === "completed" ? "selected" : ""
                      }`}
                      name="paymentType"
                    >
                      Completed
                    </button>
                  </Col>
                  <Col className="col-md-4">
                    <button
                      onClick={() => setStatus("cancelled")}
                      className={`btn filter-item filter-btn-order ${
                        status === "cancelled" ? "selected" : ""
                      }`}
                      name="paymentType"
                    >
                      Cancelled
                    </button>
                  </Col>
                </Row>

                <div className="topRowFilter p-3 gx-3">
                  Price
                  <button
                    className="applyFiltersBox"
                    onClick={() => handleOrdersFilter(2)}
                    disabled={!(minPrice >= 0 && maxPrice > minPrice)}
                  >
                    Apply
                  </button>
                </div>
                <Row className="p-3 gx-2">
                  <Row>
                    <Col>
                      <input
                        type="number"
                        value={minPrice}
                        onChange={(e) => setMinPrice(parseInt(e.target.value))}
                        name="startPrice"
                        className="form-control price-min-max"
                        placeholder="$"
                      />
                    </Col>
                    <Col>
                      <input
                        type="number"
                        value={maxPrice}
                        onChange={(e) => setMaxPrice(parseInt(e.target.value))}
                        name="endPrice"
                        className="form-control price-min-max"
                        placeholder="$"
                      />
                    </Col>
                  </Row>
                </Row>

                <div className="topRowFilter p-3 gx-3">
                  Date Range
                  <button
                    className="applyFiltersBox"
                    onClick={() => handleOrdersFilter(3)}
                    disabled={!(minDate && maxDate)}
                  >
                    Apply
                  </button>
                </div>
                <Row className="p-3 gx-2">
                  <Row>
                    <Col>
                      <input
                        type="date"
                        onChange={(e) => setMinDate(e.target.value)}
                        value={minDate}
                        name="startDate"
                        className="form-control price-min-max"
                        placeholder="$"
                      />
                    </Col>
                    <Col>
                      <input
                        type="date"
                        onChange={(e) => setMaxDate(e.target.value)}
                        value={maxDate}
                        name="endDate"
                        className="form-control price-min-max"
                        placeholder="$"
                      />
                    </Col>
                  </Row>
                </Row>

                <div className="topRowFilter p-3 gx-3">
                  Number of items
                  <button
                    className="applyFiltersBox"
                    onClick={() => handleOrdersFilter(4)}
                    disabled={!(minStock >= 0 && maxStock > minStock)}
                  >
                    Apply
                  </button>
                </div>
                <Row className="p-3 gx-2">
                  <Row>
                    <Col>
                      <input
                        type="number"
                        name="startNumItems price-min-max"
                        className="form-control"
                        placeholder="Minimum"
                        onChange={(e) => setMinStock(parseInt(e.target.value))}
                        value={minStock}
                      />
                    </Col>
                    <Col>
                      <input
                        type="number"
                        onChange={(e) => setMaxStock(parseInt(e.target.value))}
                        value={maxStock}
                        name="endNumItems price-min-max"
                        className="form-control"
                        placeholder="Maximum"
                      />
                    </Col>
                  </Row>
                </Row>

                <div className="topRowFilter p-3 gx-3">
                  Payment type
                  <button
                    className="applyFiltersBox"
                    onClick={() => handleOrdersFilter(5)}
                    disabled={!payment}
                  >
                    Apply
                  </button>
                </div>
                <Row className="p-3 gx-2"></Row>
                <Row>
                  <Col>
                    <button
                      onClick={() => setPayment("paypal")}
                      className={`btn filter-item filter-btn-order ${
                        payment === "paypal" ? "selected" : ""
                      }`}
                      name="paymentType"
                    >
                      Paypal
                    </button>
                  </Col>
                  <Col>
                    <button
                      onClick={() => setPayment("card")}
                      className={`btn filter-item filter-btn-order ${
                        payment === "card" ? "selected" : ""
                      }`}
                      name="paymentType"
                    >
                      Card
                    </button>
                  </Col>
                </Row>
                <Row className="pt-3 px-3 ">
                  <Col>
                    {message && (
                      <p className="text-danger text-center py-2">{message}</p>
                    )}
                    <button
                      type="submit"
                      onClick={() => handleOrdersFilter(0)}
                      className="oss-primary-btnd p-3 w-100 secondary-color"
                    >
                      Reset
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterOrders;
