import React, { useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import "./addItemModal.scss";
import { useDispatch, useSelector } from "react-redux";
import ImageUploader from "react-images-upload";
import { EDIT_PRODUCT_RESET } from "../../../UpdatedStore/constants/ManagementProductConstants";
import {
  editProductAction,
  GetProductsAction,
  GetPromotionsAction,
} from "../../../UpdatedStore/actions/ProductManagementActions";
import { FaPlusCircle, FaMinusCircle, FaTrash } from "react-icons/fa";
import validateColor from "validate-color";

const EditProductModal = ({ site, product, _promotion, _product }) => {
  const dispatch = useDispatch();

  const [modalShow, setModalShow] = React.useState(false);
  const [showColorForm, setShowColorForm] = React.useState(false);

  const [typeState, setTypeState] = React.useState("");

  //   form states
  const [productID, setProductID] = React.useState("");
  const [productType, setProductType] = React.useState(0);
  const [productCategory, setProductCategory] = React.useState(0);
  const [productName, setProductName] = React.useState("");
  const [productBrand, setProductBrand] = React.useState("");
  const [productPrice, setProductPrice] = React.useState("");
  const [productSizes, setProductSizes] = React.useState([]);
  const [productColors, setProductColors] = React.useState([]);
  const [productInfo, setProductInfo] = React.useState("");
  const [productQuantity, setProductQuantity] = React.useState("");
  const [productDescription, setProductDescription] = React.useState("");

  const [compileColor, setCompileColor] = React.useState([]);
  const [compileSizes, setCompileSizes] = React.useState([]);
  const [compileColorValue, setCompileColorValue] = React.useState("");
  const [compileSizeValue, setCompileSizeValue] = React.useState("");
  const [colorError, setColorError] = React.useState("");

  const [message, setMessage] = React.useState("");

  const { loading, error, success } = useSelector(
    (state) => state.editProductReducer
  );

  const addNewType = () => {
    setMessage("");
    if (productType > 0) {
      if (productCategory > 0) {
        if (productName) {
          if (productBrand) {
            if (productDescription) {
              if (productPrice && productPrice > 0) {
                if (productQuantity && productQuantity > 0) {
                  const formData = new FormData();
                  formData.append("id", productID);
                  formData.append("site", site.name);
                  formData.append("product_type", productType);
                  formData.append("product_category", productCategory);
                  formData.append("partner_id", site.partner[0].id);
                  formData.append("title", productName);
                  formData.append("description", productDescription);
                  formData.append(
                    "slug",
                    productName.replace(/\s+/g, "-").toLowerCase()
                  );
                  formData.append("price", productPrice);
                  formData.append(
                    "brand",
                    productBrand.charAt(0).toUpperCase() + productBrand.slice(1)
                  );
                  formData.append("manufacturer_info", productInfo);
                  formData.append("quantity", productQuantity);
                  formData.append("size", JSON.stringify(productSizes));

                  formData.append("color", JSON.stringify(productColors));
                  dispatch(editProductAction(formData));
                } else {
                  setMessage("The quantity field is required!");
                }
              } else {
                setMessage("Please Enter A Valid Price!");
              }
            } else {
              setMessage("You need to provide a valid product description!");
            }
          } else {
            setMessage("You need to specify a brand!");
          }
        } else {
          setMessage("Product Name Is Not Valid!");
        }
      } else {
        setMessage("Please choose a category");
      }
    } else {
      setMessage("Please select a valid product type!");
    }
  };

  useEffect(() => {
    if (success) {
      setMessageTimeOut();
    }

    var prev_type = site.product_types.find(
      (obj) => obj.name.toLowerCase() == product.type.toLowerCase()
    );
    var prev_category = site.product_categories.find(
      (obj) => obj.name.toLowerCase() == product.categories[0].toLowerCase()
    );

    setTypeState("");
    setProductType(prev_type?.id);
    setProductCategory(prev_category?.id);
    setProductID(product._id);
    setProductName(product.name);
    setProductBrand(product.brand);
    setProductPrice(
      _product ? product.price : _promotion ? product.CurrentPirce : 0
    );
    setProductQuantity(product.quantity);
    setProductDescription(product.description);
    setProductInfo(product.manufacturer_info);
    setMessage("");
    setCompileColorValue("");
    setCompileSizeValue("");
    setColorError("");
    loadCompileColors(product.color_variants);
    loadProductSizes(product.size);
  }, [success, modalShow]);

  const setMessageTimeOut = () => {
    setTimeout(() => {
      dispatch({ type: EDIT_PRODUCT_RESET });
      setModalShow(false);
      dispatch(GetProductsAction(site));
      dispatch(GetPromotionsAction(site));
    }, 2000);
  };

  const handleColorForm = () => {
    setShowColorForm(!showColorForm);
  };

  const submitColorForm = (e) => {
    e.preventDefault();
    setColorError(false);
    if (validateColor(compileColorValue)) {
      let count = compileColor.length;
      setCompileColor((current) => {
        let exists = current.find(
          (sin_) => sin_.value.toLowerCase() === compileColorValue.toLowerCase()
        );
        if (exists) {
          setColorError("Color exists already*");
          return [...current];
        } else {
          setProductColors([...productColors, compileColorValue]);
          setShowColorForm(false);
          setCompileColorValue("");
          return [...current, { id: count++, value: compileColorValue }];
        }
      });
    } else {
      setColorError("Invalid Color*");
    }
  };

  const submitSizeForm = (e) => {
    e.preventDefault();
    if (compileSizeValue) {
      setProductSizes((previousSizes) => {
        return [...previousSizes, compileSizeValue];
      });
      setCompileSizeValue("");
      setCompileSizes((current) => {
        let count = current.length;
        return [
          ...current,
          { id: count + 1 + compileSizeValue, value: compileSizeValue },
        ];
      });
    }
  };

  const loadCompileColors = (custom) => {
    let newColors = custom.map((cMap, index) => {
      return { id: index + 1, value: cMap.option };
    });
    setCompileColor(newColors);

    let updated_colors = custom.map((cMap, index) => {
      return cMap.option;
    });
    setProductColors(updated_colors);
  };

  const loadProductSizes = (sizes) => {
    let newSizes = sizes.map((cMap, index) => {
      return { id: index + 1, value: cMap.option };
    });
    setCompileSizes(newSizes);

    let updated_sizes = sizes.map((cMap, index) => {
      return cMap.option;
    });
    setProductSizes(updated_sizes);
  };

  const deleteColor = (id) => {
    let newList = compileColor.filter((sin_) => {
      if (sin_.id != id) {
        return sin_;
      }
    });
    setCompileColor(newList);

    let updated_colors = newList.map((cMap, index) => {
      return cMap.value;
    });

    setProductColors(updated_colors);
  };

  const deleteSize = (id) => {
    let newList = compileSizes.filter((sin_) => {
      if (sin_.id != id) {
        return sin_;
      }
    });
    setCompileSizes(newList);

    let updated_sizes = newList.map((cMap, index) => {
      return cMap.value;
    });

    setProductSizes(updated_sizes);
  };

  return (
    <>
      <button onClick={() => setModalShow(true)}>Edit item details</button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="_delete-modal"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <h1>Edit Product</h1>

          <button onClick={() => setModalShow(false)} className="_close-modal">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="28" height="28" rx="8" fill="#E8ECF0" />
              <path
                d="M20.698 8.23344L19.7383 7.28517C19.4786 7.02549 19.0496 7.02549 18.7786 7.28517L13.9915 12.0721L9.1142 7.19484C8.85441 6.93505 8.42537 6.93505 8.15452 7.19484L7.19484 8.15452C6.93505 8.4142 6.93505 8.84323 7.19484 9.1142L12.061 13.9803L7.28517 18.7787C7.02549 19.0384 7.02549 19.4674 7.28517 19.7384L8.24484 20.6981C8.50452 20.9577 8.93355 20.9577 9.20452 20.6981L13.9915 15.911L18.7786 20.6981C19.0383 20.9577 19.4673 20.9577 19.7383 20.6981L20.698 19.7384C20.9576 19.4787 20.9576 19.0497 20.698 18.7787L15.8996 13.9916L20.6867 9.20463C20.9576 8.93344 20.9576 8.50441 20.698 8.23344Z"
                fill="#777C85"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="_hold-contentProduct">
            <Form.Group>
              <Form.Label className="text-muted font-weight-bold">
                Select Product Type
              </Form.Label>
              <Form.Control
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
                as="select"
                disabled
              >
                <option value={0} disabled selected>
                  --- Type ---
                </option>
                {site?.product_types.map((type_) => (
                  <option value={type_.id}>{type_.name}</option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label className="text-muted font-weight-bold">
                Select Product Category
              </Form.Label>
              <Form.Control
                value={productCategory}
                onChange={(e) => setProductCategory(e.target.value)}
                as="select"
              >
                <option value={0} disabled selected>
                  --- Category ---
                </option>
                {site?.product_categories.map((type_) => (
                  <option value={type_.id}>{type_.name}</option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label className="text-muted font-weight-bold">
                Product Name*
              </Form.Label>
              <Form.Control
                value={productName}
                onChange={(e) => {
                  const arr = e.target.value.split(" ");
                  if (arr.length > 0) {
                    for (var i = 0; i < arr.length; i++) {
                      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                    }
                    const str2 = arr.join(" ");
                    setProductName(str2);
                  } else {
                    setProductName(
                      e.target.value.charAt(0).toUpperCase() +
                        e.target.value.slice(1)
                    );
                  }
                }}
                type="text"
                placeholder="Product Name"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="text-muted font-weight-bold">
                Brand*
              </Form.Label>
              <Form.Control
                value={productBrand}
                onChange={(e) => setProductBrand(e.target.value.split(" ")[0])}
                type="text"
                placeholder="Brand"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="text-muted font-weight-bold">
                Price*
              </Form.Label>
              <Form.Control
                value={productPrice}
                onChange={(e) => setProductPrice(e.target.value)}
                type="number"
                placeholder="Price"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="text-muted font-weight-bold">
                Quantity Available*
              </Form.Label>
              <Form.Control
                value={productQuantity}
                onChange={(e) => setProductQuantity(e.target.value)}
                type="number"
                placeholder="Quantity in Stock"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="text-muted font-weight-bold">
                Product Description
              </Form.Label>
              <Form.Control
                value={productDescription}
                onChange={(e) => setProductDescription(e.target.value)}
                as="textarea"
                rows="5"
                style={{ resize: "none" }}
                placeholder="Product Description"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="text-muted font-weight-bold">
                Manufacturer's Information
              </Form.Label>
              <Form.Control
                value={productInfo}
                onChange={(e) => setProductInfo(e.target.value)}
                as="textarea"
                rows="5"
                style={{ resize: "none" }}
                placeholder="Manufacturer's Information"
              />
            </Form.Group>

            <div className="colorsRow">
              <h6>Colors</h6>
              {showColorForm ? (
                <FaMinusCircle
                  className="text-danger cursor"
                  onClick={handleColorForm}
                />
              ) : (
                <FaPlusCircle
                  className="tint cursor"
                  onClick={handleColorForm}
                />
              )}
            </div>
            {showColorForm && (
              <form onSubmit={(e) => submitColorForm(e)}>
                <Form.Group>
                  <Form.Label className="text-muted font-weight-bold">
                    Type a valid color and hit enter
                  </Form.Label>
                  <Form.Control
                    value={compileColorValue}
                    onChange={(e) => setCompileColorValue(e.target.value)}
                    placeholder="color"
                    className="shadow-none"
                  />
                </Form.Group>
                {colorError && (
                  <h6 className="text-danger text-center">{colorError}</h6>
                )}
              </form>
            )}
            <div className="colorVariants mb-4">
              {compileColor.map((variant, index) => (
                <div
                  key={index}
                  style={{ backgroundColor: variant.value }}
                  onClick={() => deleteColor(variant.id)}
                ></div>
              ))}
            </div>

            <form className="mt-3" onSubmit={(e) => submitSizeForm(e)}>
              <Form.Group>
                <Form.Label className="text-muted font-weight-bold">
                  *Add Size and hit enter
                </Form.Label>
                <Form.Control
                  value={compileSizeValue}
                  onChange={(e) => setCompileSizeValue(e.target.value)}
                  type="text"
                  placeholder="Add Size"
                />
              </Form.Group>
            </form>

            <div className="sizeVariants_">
              {compileSizes.map((sVariant, index) => (
                <div className="holdSizeType">
                  <div>{sVariant.value}</div>
                  <div>
                    <FaTrash
                      className="text-danger cursor"
                      onClick={() => deleteSize(sVariant.id)}
                    />
                  </div>
                </div>
              ))}
            </div>
            {error && <h6 className="text-danger my-1">{error}</h6>}
            {message && <h6 className="text-danger my-1">{message}</h6>}
            {success && <h6 className="text-success my-1">Successful...</h6>}
            <button
              className="_yes transcale"
              onClick={addNewType}
              disabled={loading}
            >
              {loading ? "Processing..." : "Edit Product"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditProductModal;
