import NOTFOUND from "../../../assets/icons/notfound.png";
import { Button, Image } from "react-bootstrap";
import "./noStaffInThisRole.scss";
import { Link } from "react-router-dom";
const NoStaffInThisRole: React.FC = ({ text }) => {
  return (
    <div className="_hold-not-found-staff">
      <div className="_icon">
        <Image src={NOTFOUND} />
      </div>

      <div className="_hold-text-info">
        <h3>{text}</h3>
      </div>
    </div>
  );
};

export default NoStaffInThisRole;
