import React, { useEffect } from "react";
import "../sideBar/addItemModal.scss";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import { CREATE_PRODUCT_RESET } from "../../../UpdatedStore/constants/ManagementProductConstants";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import validateColor from "validate-color";
import {
  createProductAction,
  GetProductsAction,
} from "../../../UpdatedStore/actions/ProductManagementActions";

const ItemDetails = ({ site, fetchImages, fetchCaptions }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showColorForm, setShowColorForm] = React.useState(false);

  //   form states
  const [productType, setProductType] = React.useState(0);
  const [productCategory, setProductCategory] = React.useState(0);
  const [productName, setProductName] = React.useState("");
  const [productBrand, setProductBrand] = React.useState("");
  const [productPrice, setProductPrice] = React.useState("");
  const [productSize, setProductSize] = React.useState([]);
  const [productColors, setProductColors] = React.useState([]);
  const [productInfo, setProductInfo] = React.useState("");
  const [productQuantity, setProductQuantity] = React.useState("");
  const [productDescription, setProductDescription] = React.useState("");

  const [compileColor, setCompileColor] = React.useState([]);
  const [compileColorValue, setCompileColorValue] = React.useState("");
  const [compileSizeValue, setCompileSizeValue] = React.useState("");
  const [colorError, setColorError] = React.useState("");

  const [message, setMessage] = React.useState("");

  const { loading, error, success } = useSelector(
    (state) => state.CreateProductReducer
  );

  const addNewType = () => {
    setMessage("");
    if (productType > 0) {
      if (productCategory > 0) {
        if (productName) {
          if (productBrand) {
            if (productDescription) {
              if (productPrice && productPrice > 0) {
                if (productQuantity && productQuantity > 0) {
                  if (fetchImages().length > 0) {
                    if (fetchImages().length <= 5) {
                      const formData = new FormData();
                      formData.append("image", fetchImages()[0]);
                      formData.append("image2", fetchImages()[1]);
                      formData.append("image3", fetchImages()[2]);
                      formData.append("image4", fetchImages()[3]);
                      formData.append("image5", fetchImages()[4]);
                      formData.append("site", site.name);
                      formData.append("product_type", productType);
                      formData.append("product_category", productCategory);
                      formData.append("partner_id", site.partner[0].id);
                      formData.append("title", productName);
                      formData.append(
                        "description",
                        productDescription ? productDescription : ""
                      );
                      formData.append(
                        "slug",
                        productName.replace(/\s+/g, "-").toLowerCase()
                      );
                      formData.append("price", productPrice);
                      formData.append("size", JSON.stringify(productSize));
                      formData.append("color", JSON.stringify(productColors));
                      formData.append(
                        "captions",
                        JSON.stringify(fetchCaptions())
                      );
                      formData.append("manufacturer_info", productInfo);
                      formData.append(
                        "brand",
                        productBrand.charAt(0).toUpperCase() +
                          productBrand.slice(1)
                      );
                      formData.append("quantity", productQuantity);
                      dispatch(createProductAction(formData));
                    } else {
                      setMessage("Please Upload at most 5 images!");
                    }
                  } else {
                    setMessage("Please Upload an Image!");
                  }
                } else {
                  setMessage("The quantity field is required!");
                }
              } else {
                setMessage("Please Enter A Valid Price!");
              }
            } else {
              setMessage("You need to provide a valid product description!");
            }
          } else {
            setMessage("You need to specify a brand!");
          }
        } else {
          setMessage("Product Name Is Not Valid!");
        }
      } else {
        setMessage("Please choose a category");
      }
    } else {
      setMessage("Please select a valid product type!");
    }
  };

  useEffect(() => {
    if (success) {
      setMessageTimeOut();
    }

    setProductType(0);
    setProductCategory(0);
    setProductName("");
    setProductBrand("");
    setProductPrice("");
    setProductColors([]);
    setProductSize([]);
    setProductInfo("");
    setProductQuantity("");
    setProductDescription("");
    setMessage("");
    setCompileColorValue("");
    setColorError("");
  }, [success, history]);

  const setMessageTimeOut = () => {
    setTimeout(() => {
      dispatch({ type: CREATE_PRODUCT_RESET });
      dispatch(GetProductsAction(site));
      history.push("/inventory");
    }, 2000);
  };

  const handleColorForm = () => {
    setShowColorForm(!showColorForm);
  };

  const submitColorForm = (e) => {
    e.preventDefault();
    setColorError(false);
    if (validateColor(compileColorValue)) {
      let count = compileColor.length;
      setCompileColor((current) => {
        let exists = current.find(
          (sin_) => sin_.value.toLowerCase() === compileColorValue.toLowerCase()
        );
        if (exists) {
          setColorError("Color exists already*");
          return [...current];
        } else {
          setProductColors([...productColors, compileColorValue]);
          setShowColorForm(false);
          setCompileColorValue("");
          return [...current, { id: count++, value: compileColorValue }];
        }
      });
    } else {
      setColorError("Invalid Color*");
    }
  };

  const submitSizeForm = (e) => {
    e.preventDefault();

    if (compileSizeValue) {
      setProductSize((previousSizes) => {
        return [...previousSizes, compileSizeValue];
      });
      setCompileSizeValue("");
    }
  };

  const deleteCustomSize = (id) => {
    let NewSizes = productSize.filter((itemX, index) => index != id);
    setProductSize(NewSizes);
  };

  const deleteColor = (id) => {
    let newList = compileColor.filter((sin_) => {
      if (sin_.id != id) {
        return sin_;
      }
    });
    setCompileColor(newList);
    setProductColors(newList);
  };

  return (
    <div className="details_i">
      <h6>Item Details</h6>

      <div className="_hold-contentProduct">
        <Form.Group>
          <Form.Label className="text-muted font-weight-bold">
            Select Product Type
          </Form.Label>
          <Form.Control
            value={productType}
            onChange={(e) => setProductType(e.target.value)}
            as="select"
          >
            <option value={0} disabled selected>
              --- Type ---
            </option>
            {site?.product_types.map((type_) => (
              <option value={type_.id}>{type_.name}</option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label className="text-muted font-weight-bold">
            Select Product Category
          </Form.Label>
          <Form.Control
            value={productCategory}
            onChange={(e) => setProductCategory(e.target.value)}
            as="select"
          >
            <option value={0} disabled selected>
              --- Category ---
            </option>
            {site?.product_categories.map((type_) => (
              <option value={type_.id}>{type_.name}</option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label className="text-muted font-weight-bold">
            Product Name*
          </Form.Label>
          <Form.Control
            value={productName}
            onChange={(e) => {
              const arr = e.target.value.split(" ");
              if (arr.length > 0) {
                for (var i = 0; i < arr.length; i++) {
                  arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                }
                const str2 = arr.join(" ");
                setProductName(str2);
              } else {
                setProductName(
                  e.target.value.charAt(0).toUpperCase() +
                    e.target.value.slice(1)
                );
              }
            }}
            type="text"
            placeholder="Product Name"
          />
        </Form.Group>

        <Form.Group>
          <Form.Label className="text-muted font-weight-bold">
            Brand*
          </Form.Label>
          <Form.Control
            value={productBrand}
            onChange={(e) => setProductBrand(e.target.value.split(" ")[0])}
            type="text"
            placeholder="Brand"
          />
        </Form.Group>

        <Form.Group>
          <Form.Label className="text-muted font-weight-bold">
            Price*
          </Form.Label>
          <Form.Control
            value={productPrice}
            onChange={(e) => setProductPrice(e.target.value)}
            type="number"
            placeholder="Price"
          />
        </Form.Group>

        <Form.Group>
          <Form.Label className="text-muted font-weight-bold">
            Quantity Available*
          </Form.Label>
          <Form.Control
            value={productQuantity}
            onChange={(e) => setProductQuantity(e.target.value)}
            type="number"
            placeholder="Quantity in Stock"
          />
        </Form.Group>

        <Form.Group>
          <Form.Label className="text-muted font-weight-bold">
            Product Description
          </Form.Label>
          <Form.Control
            value={productDescription}
            onChange={(e) => setProductDescription(e.target.value)}
            as="textarea"
            rows="5"
            style={{ resize: "none" }}
            placeholder="Product Description"
          />
        </Form.Group>

        <Form.Group>
          <Form.Label className="text-muted font-weight-bold">
            Manufacturer's Information
          </Form.Label>
          <Form.Control
            value={productInfo}
            onChange={(e) => setProductInfo(e.target.value)}
            as="textarea"
            rows="5"
            style={{ resize: "none" }}
            placeholder="Manufacturer's Information"
          />
        </Form.Group>

        <div className="colorsRow">
          <h6>Colors</h6>
          {showColorForm ? (
            <FaMinusCircle
              className="text-danger cursor"
              onClick={handleColorForm}
            />
          ) : (
            <FaPlusCircle className="tint cursor" onClick={handleColorForm} />
          )}
        </div>
        {showColorForm && (
          <form onSubmit={(e) => submitColorForm(e)}>
            <Form.Group>
              <Form.Label className="text-muted font-weight-bold">
                Type a valid color and hit enter
              </Form.Label>
              <Form.Control
                value={compileColorValue}
                onChange={(e) => setCompileColorValue(e.target.value)}
                placeholder="color"
                className="shadow-none"
              />
            </Form.Group>
            {colorError && (
              <h6 className="text-danger text-center">{colorError}</h6>
            )}
          </form>
        )}
        <div className="colorVariants">
          {compileColor.map((variant, index) => (
            <div
              key={index}
              style={{ backgroundColor: variant.value }}
              onClick={() => deleteColor(variant.id)}
            ></div>
          ))}
        </div>

        <form className="mt-3" onSubmit={(e) => submitSizeForm(e)}>
          <Form.Group>
            <Form.Label className="text-muted font-weight-bold">
              *Add Size and hit enter
            </Form.Label>
            <Form.Control
              value={compileSizeValue}
              onChange={(e) => setCompileSizeValue(e.target.value)}
              type="text"
              placeholder="Add Size"
            />
          </Form.Group>
        </form>

        <div className="sizeVariants_">
          {productSize.map((sVariant, index) => (
            <div className="holdSizeType">
              <div>{sVariant}</div>
              <div>
                <FaTrash
                  className="text-danger cursor"
                  onClick={() => deleteCustomSize(index)}
                />
              </div>
            </div>
          ))}
        </div>
        {error && <h6 className="text-danger my-3">{error}</h6>}
        {message && <h6 className="text-danger my-3">{message}</h6>}
        {success && <h6 className="text-success my-3">Successful...</h6>}
        <button className="transcale" onClick={addNewType} disabled={loading}>
          {loading ? "Processing..." : "Create Product"}
        </button>
      </div>
    </div>
  );
};

export default ItemDetails;
