import { Draggable } from "react-beautiful-dnd";
import { FaTrash, FaPencilAlt } from "react-icons/fa";
import { deleteNodeAction } from "../../actions/ApiAction";
import { useDispatch } from "react-redux";
import { SHOW_MODAL_STATE } from "../../constants/ApiConstants";
import { BsArrowsMove } from "react-icons/bs";
import { makeStyles } from "@material-ui/core/styles";
import { Image } from "react-bootstrap";

const _style = makeStyles(function (theme) {
  return {
    grow: {
      flexGrow: 1,
    },
    root: function (props) {
      return {
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        justifyContent: "center",
        gap: "20px",
        backgroundColor: "#ffffff",
        padding: "20px",
        [theme.breakpoints.down("md")]: {
          gridTemplateColumns: "repeat(2, 1fr)",
        },
        [theme.breakpoints.down("sm")]: {
          gridTemplateColumns: "repeat(1, 1fr)",
        },
      };
    },
    back: function (props) {
      return {
        backgroundColor: "#ffffff",
        padding: "20px",
      };
    },
    categoryDIV: function (props) {
      return {
        backgroundColor: "inherit",
      };
    },
    ItemName: function (props) {
      return {
        color: "#16284D",
        fontSize: "20px",
        fontWeight: "400",
        padding: "10px",
      };
    },
    priceName: function (props) {
      return {
        display: "flex",
        alignItems: "center",
        color: "#16284D",
        padding: "10px",
      };
    },
    real: function (props) {
      return {
        fontWeight: "bold",
        fontSize: "15px",
      };
    },
    discount: function (props) {
      return {
        fontSize: "15px",
        textDecoration: "line-through",
      };
    },
  };
});

const Promotions = ({ nodeData }) => {
  let classes = _style(nodeData.styles);

  const dispatch = useDispatch();

  const EditFunctionality = () => {
    dispatch({ type: SHOW_MODAL_STATE, payload: nodeData });
  };

  const deleteNodeHandler = (node) => {
    dispatch(deleteNodeAction(node));
  };

  return (
    <Draggable key={nodeData.id} draggableId="promotions" index={nodeData.id}>
      {(provided) => (
        <div
          className={(classes.grow, "componentBuildBox")}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <div className="cmpMoveIcon" {...provided.dragHandleProps}>
            <BsArrowsMove className="cmp" />
          </div>
          <div className="deleteFlex">
            <div className="editIDiv" onClick={EditFunctionality}>
              <FaPencilAlt className="editIcon" />
            </div>
            <div
              className="deleteIDiv"
              onClick={() => deleteNodeHandler(nodeData)}
            >
              <FaTrash className="deleteIcon" />
            </div>
          </div>
          <div className={classes.back}>
            {" "}
            <h6 className="font-weight-bold text-center">Current Promotions</h6>
            <div className={classes.root}>
              {nodeData.data.products.map((item, index) => {
                return (
                  <div key={index} className={classes.categoryDIV}>
                    <Image src={item.image} width="100%" fluid />
                    <div className={classes.ItemName}>{item.name}</div>
                    <div className={`${classes.priceName} `}>
                      <div className={`${classes.real} ml-2`}>{item.price}</div>
                      <div className={`${classes.discount} ml-2`}>
                        {item.discountPrice}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default Promotions;
