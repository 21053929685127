import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { BsArrowsMove } from "react-icons/bs";
import { Draggable } from "react-beautiful-dnd";
import { FaTrash, FaPencilAlt } from "react-icons/fa";
import { deleteNodeAction } from "../../actions/ApiAction";
import { useDispatch } from "react-redux";
import { SHOW_MODAL_STATE } from "../../constants/ApiConstants";

const _style = makeStyles(function (theme) {
  return {
    grow: {
      flexGrow: 1,
    },
    root: {
      background: "#ffffff",
      color: "#090909",
    },
    categoryItem: {
      borderRight: "1px solid #D7D7D7",
      [theme.breakpoints.down("sm")]: {
        borderRight: "none",
      },
    },
  };
});

export default function Carousel({ nodeData }) {
  let classes = _style(nodeData.styles);

  const dispatch = useDispatch();

  const EditFunctionality = () => {
    dispatch({ type: SHOW_MODAL_STATE, payload: nodeData });
  };

  const deleteNodeHandler = (node) => {
    dispatch(deleteNodeAction(node));
  };

  return (
    <Draggable key={nodeData.id} draggableId="CategoryList" index={nodeData.id}>
      {(provided) => (
        <div
          className={(classes.grow, "componentBuildBox")}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <div className={classes.root}>
            <div className="cmpMoveIcon" {...provided.dragHandleProps}>
              <BsArrowsMove className="cmp" />
            </div>
            <div className="deleteFlex">
              <div className="editIDiv" onClick={EditFunctionality}>
                <FaPencilAlt className="editIcon" />
              </div>
              <div
                className="deleteIDiv"
                onClick={() => deleteNodeHandler(nodeData)}
              >
                <FaTrash className="deleteIcon" />
              </div>
            </div>

            <Grid container justifyContent="center" alignItems="center">
              {nodeData.data.category_list.map((item, index) => (
                <Grid item xs={12} sm={12} md={2} key={index}>
                  <Box
                    p={2}
                    my={2}
                    align="center"
                    className={
                      index !== nodeData.data.category_list.length - 1
                        ? classes.categoryItem
                        : ""
                    }
                  >
                    {item.name}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      )}
    </Draggable>
  );
}
