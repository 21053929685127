import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { FaTrash, FaPencilAlt } from "react-icons/fa";
import { deleteNodeAction } from "../../actions/ApiAction";
import { useDispatch } from "react-redux";
import { SHOW_MODAL_STATE } from "../../constants/ApiConstants";
import { BsArrowsMove } from "react-icons/bs";

const _style = makeStyles(function (theme) {
  return {
    grow: {
      flexGrow: 1,
    },
    root: (props) => ({
      position: "relative",
      overflow: "hidden",
      height: "90vh",
    }),
    carouselImage: {
      width: "100%",
      height: "100%",
    },
  };
});

export default function Banner({ nodeData }) {
  let classes = _style({
    ...nodeData.styles,
    imgUrl: nodeData.data.images[0],
  });

  const dispatch = useDispatch();

  const EditFunctionality = () => {
    dispatch({ type: SHOW_MODAL_STATE, payload: nodeData });
  };

  const deleteNodeHandler = (node) => {
    dispatch(deleteNodeAction(node));
  };

  return (
    <Draggable key={nodeData.id} draggableId="Carousel" index={nodeData.id}>
      {(provided) => (
        <div
          className={(classes.grow, "componentBuildBox")}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <div className="cmpMoveIcon" {...provided.dragHandleProps}>
            <BsArrowsMove className="cmp" />
          </div>
          <div className="deleteFlex">
            <div className="editIDiv" onClick={EditFunctionality}>
              <FaPencilAlt className="editIcon" />
            </div>
            <div
              className="deleteIDiv"
              onClick={() => deleteNodeHandler(nodeData)}
            >
              <FaTrash className="deleteIcon" />
            </div>
          </div>

          <div className={classes.root}>
            <img
              src={nodeData.data.images[0]}
              className={classes.carouselImage}
              alt="img"
            />
          </div>
        </div>
      )}
    </Draggable>
  );
}
