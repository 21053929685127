import React, { useState } from "react";
import styled from "@mui/system/styled";
import { Link, useHistory } from "react-router-dom";
import { useTheme, useMediaQuery } from "@material-ui/core";
import images from "./Images/Images";
import MenuIcon from "@mui/icons-material/Menu";
import { Drawer, Button } from "@material-ui/core";
import { Container, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { blue } from "@material-ui/core/colors";
import Language from "../../components/freeAccount/settings/language/Language";

const DrawerHeaderDiv = styled("Grid")(({ theme }) => ({
  // justifyContent: "left",
  // textAlign: "center",
  // width:'50%',

  ".ul-li-lists": {
    // textDecoration:'none',
    padding: 0,
    listStyle: "none",
  },

  ".smallTitle-mobile": {
    textDecoration: "none",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "32px",
    letterSpacing: "0.225px",
    marginTop: "50px",
    color: "#777C85",
    "&:hover": {
      color: "#16284D",
      fontWeight: "bold",
    },
  },

  ".Login": {
    backgroundColor: "#16284D",
    borderRadius: "20px",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "20px",
    width: "100%",
    /* identical to box height */
    textTransform: "initial",
    letterSpacing: "0.2px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#16284D",
    },
  },

  ".SignUpheader": {
    backgroundColor: "#3ED0A3",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "20px",
    borderRadius: "20px",
    marginTop: "10px",
    width: "100%",
    /* identical to box height */
    letterSpacing: "0.2px",
    textTransform: "initial",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#3ED0A3",
    },
  },

  //  "@media screen and (max-width: 1680px)":{

  //  }

  "@media screen and (max-width: 1199.98px)": {
    ".header-container": {
      justifyContent: "space-between",
    },
    ".Header-image": {
      marginLeft: 0,
      border: "1px solid red",
      // marginLeft:'-60px',
      paddingRight: "30px",
      marginTop: "-15px",
    },
  },
  Link: {
    color: "green",
  },
}));

function DrawerHeader() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { user } = useSelector((state) => state.AuthenticationReducer);

  const history = useHistory();

  const onChangeLanguage = (even) => {
    i18n.changeLanguage(even.target.value);
  };
  const { t, i18n } = useTranslation();
  return (
    <>
      <Drawer
        className="drawer-class"
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <DrawerHeaderDiv>
          <ul className="ul-li-lists">
            <li>
              {/* <div className='smallTitle' >Home</div> */}
              <Link to="/" className="smallTitle-mobile">
                {t("header.home")}
              </Link>
            </li>
            <li>
              <Link to="/about" className="smallTitle-mobile">
                {t("header.about")}
              </Link>
            </li>
            <li>
              {/* <div className='smallTitle' >Pricing</div> */}
              <Link to="/pricing" className="smallTitle-mobile">
                {t("header.pricing")}
              </Link>
            </li>
            <li>
              {/* <div className='smallTitle' >Learn more</div> */}
              <Link to="/learnmore" className="smallTitle-mobile">
                {t("header.learnMore")}
              </Link>
            </li>
            <li>
              {/* <div className='smallTitle' >Help</div> */}
              <Link to="/faq" className="smallTitle-mobile">
                {t("header.help")}
              </Link>
            </li>
          </ul>
          {!user ? (
            <div className="right-side">
              <div>
                <Button
                  className="Login"
                  onClick={() => history.push("/login")}
                >
                  {t("header.buttonText")}
                </Button>
              </div>
              <div>
                <Button
                  className="SignUpheader"
                  onClick={() => history.push("/sign-up")}
                >
                  {t("header.signUpButton")}
                </Button>
              </div>
            </div>
          ) : (
            <div className="right-side">
              <div>
                <Button
                  className="SignUpheader"
                  onClick={() => history.push("/dashboard")}
                >
                  Dashboard
                </Button>
              </div>
            </div>
          )}
        </DrawerHeaderDiv>
      </Drawer>
      <div onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon />
      </div>
    </>
  );
}

const HeaderContainer = styled("Grid")(({ theme }) => ({
  ".header-container": {
    // width: '100vw',
    display: "flex",
    alignItems: "center",
    ".right-side": {
      display: "flex",
      alignItems: "center",
      justifyContent: "right",
      gap: "20px",

      ".language-option-btn": {
        background: "blue",
        color: "white",
        height: "40px",
        borderRadius: " 20px",
        textAlign: "center",
        width:"100px"
      },
    },
  },

  // ".Header-image": {
  //   marginLeft: "4.5rem",
  // },

  ".all-Header-link": {
    display: "flex",
    alignItems: "center",
  },

  ul: {
    // textDecoration:'none',
    listStyle: "none",
    display: "flex",
    // justifyContent: "space-between",
    gap: "30px",
    marginTop: "14px",
  },
  ".smallTitle": {
    textDecoration: "none",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "18px",
    letterSpacing: "0.225px",
    color: "#777C85",
    "&:hover": {
      color: "#16284D",
      fontWeight: "bold",
    },
    // "&:active": {
    //   color: "16284D",
    //   backgroundColor: 'blue',
    //   fontWeight: "bold",
    // },
  },

  ".Login": {
    backgroundColor: "#16284D",
    borderRadius: "20px",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "20px",
    /* identical to box height */
    textTransform: "initial",
    letterSpacing: "0.2px",
    width: "93px",
    height: "40px",
    // padding:'5px',
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#16284D",
    },
  },

  ".SignUpheader": {
    backgroundColor: "#3ED0A3",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "20px",
    borderRadius: "20px",
    /* identical to box height */
    letterSpacing: "0.2px",
    textTransform: "initial",
    color: "#FFFFFF",
    width: "174px",
    height: "40px",
    "&:hover": {
      backgroundColor: "#3ED0A3",
    },
  },
  ".Header-image": {
    // marginLeft: 0,
    marginLeft: "60px",
    paddingRight: "30px",
  },

  "@media screen and (max-width: 1680px)": {
    ".header-container": {
      justifyContent: "space-between",
      marginLeft: "-60px",
    },
  },
  "@media screen and (max-width: 1400px)": {
    ".header-container": {
      justifyContent: "space-between",
      marginLeft: "0px",
    },
  },
  "@media screen and (max-width: 600px)": {
    ".header-container": {
      marginLeft: "-60px",
    },
  },
}));

export default function Header(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { user } = useSelector((state) => state.AuthenticationReducer);
  const [customLanguage, setLanguage] = useState(
    localStorage.getItem("language")
  );
  const history = useHistory();
  const onChangeLanguage = (even) => {
    i18n.changeLanguage(even.target.value)
    if (even.target.value=="en"){
      localStorage.setItem("language", "English");
      setLanguage("English")
      window.loadDirection()
    }
    if(even.target.value=="ar"){
      localStorage.setItem("language", "Arabic");
      setLanguage("Arabic")
      window.loadDirection()
    }
  
  }
  const { t, i18n } = useTranslation();

  return (
    <HeaderContainer>
      <Container maxWidth="xl">
        <div className="header-container">
          <div className="Header-image">
            {!(props.images === "true") ? (
              <img src={images.SecondTypeHeader} alt="images" />
            ) : (
              <img src={images.OneStopShop_header} alt="images" width="200px" />
            )}
          </div>
          {isMobile ? (
            <DrawerHeader />
          ) : (
            <Grid container className="all-Header-link">
              <Grid item lg={7} xl={7}>
                <ul>
                  <li>
                    {/* <div className='smallTitle' >Home</div> */}
                    <Link to="/" className="smallTitle">
                      {t("header.home")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" className="smallTitle">
                      {t("header.about")}
                    </Link>
                  </li>
                  <li>
                    {/* <div className='smallTitle' >Pricing</div> */}
                    <Link to="/pricing" className="smallTitle">
                      {t("header.pricing")}
                    </Link>
                  </li>
                  <li>
                    {/* <div className='smallTitle' >Learn more</div> */}
                    <Link to="/learnmore" className="smallTitle">
                      {t("header.learnMore")}
                    </Link>
                  </li>
                  <li>
                    {/* <div className='smallTitle' >Help</div> */}
                    <Link to="/faq" className="smallTitle">
                      {t("header.help")}
                    </Link>
                  </li>
                </ul>
              </Grid>
              <Grid item lg={5} xl={5}>
                {user ? (
                  <div className="right-side">
                    {/* <Language/>  */}
                    <select className="language-option-btn" onChange={onChangeLanguage}>
                     <option value={"en"}>{t('header.english')}</option>
                      <option value={"ar"}>{t('header.arabic')}</option>
                    </select>
                  {/* <p>{t('english')}</p>  */}
                    <div>
                      <Button
                        className="Login"
                        onClick={() => history.push("/login")}
                      >
                        {t("header.loginButton")}
                      </Button>
                    </div>
                    <div>
                      <Button
                        className="SignUpheader"
                        onClick={() => history.push("/sign-up")}
                      >
                        {t("header.signUpButton")}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="right-side">
                    <div>
                      <Button
                        className="SignUpheader"
                        onClick={() => history.push("/dashboard")}
                      >
                        Dashboard
                      </Button>
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          )}
        </div>
      </Container>
    </HeaderContainer>
  );
}
