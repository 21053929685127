import React, { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { Form } from "react-bootstrap";

const ItemPhotos = ({
  images,
  deleteImage,
  onChange,
  caption1,
  caption2,
  caption3,
  caption4,
  caption5,
  setCaption1,
  setCaption2,
  setCaption3,
  setCaption4,
  setCaption5,
}) => {
  const [customImages, setCustomImages] = useState([]);

  const loadImages = () => {
    let loadedImages = images.map((imageItem) =>
      URL.createObjectURL(imageItem)
    );
    setCustomImages(loadedImages);
  };

  useEffect(() => {
    loadImages();
  }, [images]);

  return (
    <div className="photos">
      <h6>Item Photos</h6>

      <div className="photo-blocks">
        <div className="block active">
          <input
            type="file"
            id="actual-btn"
            accept="image/*"
            hidden
            onChange={(e) => onChange(e)}
          />

          <label for="actual-btn">
            <svg
              width="66"
              height="66"
              viewBox="0 0 66 66"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="cursor transcale"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 33C0 14.8031 14.8031 0 33 0C51.1969 0 66 14.8031 66 33C66 51.1969 51.1969 66 33 66C14.8031 66 0 51.1969 0 33ZM35.7199 35.7199H47.2801C48.7818 35.7199 50 34.5016 50 33C50 31.4984 48.7818 30.2801 47.2801 30.2801H35.7199V18.7199C35.7199 17.2182 34.5016 16 33 16C31.4984 16 30.2801 17.2182 30.2801 18.7199V30.2801H18.7199C17.2182 30.2801 16 31.4984 16 33C16 34.5016 17.2182 35.7199 18.7199 35.7199H30.2801V47.2801C30.2801 48.7818 31.4984 50 33 50C34.5016 50 35.7199 48.7818 35.7199 47.2801V35.7199Z"
                fill="#23AD83"
              />
            </svg>
          </label>
        </div>

        <div>
          <div
            className="block not-active"
            style={{
              backgroundImage: `url(${customImages[0] ? customImages[0] : ""})`,
            }}
            onClick={() => (customImages[0] ? deleteImage(0) : console.log())}
          >
            {customImages[0] && (
              <div className="trashItem">
                <FaTrash
                  color="red"
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
            )}
          </div>

          {customImages[0] && (
            <Form.Control
              value={caption1}
              placeholder="Caption"
              onChange={(e) => setCaption1(e.target.value)}
              className="shadow-none mt-3"
            />
          )}
        </div>

        <div>
          <div
            className="block not-active"
            style={{
              backgroundImage: `url(${customImages[1] ? customImages[1] : ""})`,
            }}
            onClick={() => (customImages[1] ? deleteImage(1) : console.log())}
          >
            {customImages[1] && (
              <div className="trashItem">
                <FaTrash
                  color="red"
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
            )}
          </div>
          {customImages[1] && (
            <Form.Control
              placeholder="Caption"
              value={caption2}
              onChange={(e) => setCaption2(e.target.value)}
              className="shadow-none mt-3"
            />
          )}
        </div>

        <div>
          <div
            className="block not-active"
            style={{
              backgroundImage: `url(${customImages[2] ? customImages[2] : ""})`,
            }}
            onClick={() => (customImages[2] ? deleteImage(2) : console.log())}
          >
            {customImages[2] && (
              <div className="trashItem">
                <FaTrash
                  color="red"
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
            )}
          </div>

          {customImages[2] && (
            <Form.Control
              value={caption3}
              placeholder="Caption"
              onChange={(e) => setCaption3(e.target.value)}
              className="shadow-none mt-3"
            />
          )}
        </div>

        <div>
          <div
            className="block not-active"
            style={{
              backgroundImage: `url(${customImages[3] ? customImages[3] : ""})`,
            }}
            onClick={() => (customImages[3] ? deleteImage(3) : console.log())}
          >
            {customImages[3] && (
              <div className="trashItem">
                <FaTrash
                  color="red"
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
            )}
          </div>

          {customImages[3] && (
            <Form.Control
              placeholder="Caption"
              value={caption4}
              onChange={(e) => setCaption4(e.target.value)}
              className="shadow-none mt-3"
            />
          )}
        </div>

        <div>
          <div
            className="block not-active"
            style={{
              backgroundImage: `url(${customImages[4] ? customImages[4] : ""})`,
            }}
            onClick={() => (customImages[4] ? deleteImage(4) : console.log())}
          >
            {customImages[4] && (
              <div className="trashItem">
                <FaTrash
                  color="red"
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
            )}
          </div>
          {customImages[4] && (
            <Form.Control
              placeholder="Caption"
              value={caption5}
              onChange={(e) => setCaption5(e.target.value)}
              className="shadow-none mt-3"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemPhotos;
