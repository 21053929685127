import React from "react";

import "./freeAccount.scss";
import { Link } from "react-router-dom";
import LogOut from "../logOut/LogOut";

const FreeAccount: React.FC = () => {
  return (
    <div className="_free-account">
      <header>
        <div className="_title">
          <Link to="/dashboard">
            <span>
              <svg
                width="42"
                height="42"
                viewBox="0 0 42 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="42" height="42" rx="10" fill="#E8ECF0" />
                <path
                  d="M13.3165 20.9959C13.3165 20.5659 13.4807 20.136 13.8084 19.8082L24.1244 9.4922C24.7807 8.83597 25.8446 8.83597 26.5006 9.4922C27.1565 10.1482 27.1565 11.2119 26.5006 11.8682L17.3723 20.9959L26.5003 30.1237C27.1562 30.7799 27.1562 31.8435 26.5003 32.4994C25.8443 33.156 24.7803 33.156 24.1241 32.4994L13.808 22.1836C13.4803 21.8557 13.3165 21.4257 13.3165 20.9959Z"
                  fill="#9AA1AF"
                />
              </svg>
            </span>
          </Link>
          <h3>My account</h3>
        </div>
        <LogOut />
        {/* <button >Log out</button> */}
      </header>
    </div>
  );
};
export default FreeAccount;
