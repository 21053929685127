import React, { useEffect, useState } from "react";
import "./allPlatforms.scss";
import { HoldCards } from "./holdCards/HoldCards";

import { Image } from "react-bootstrap";
import notFound from "../../../assets/icons/notfound.png";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { GetSitesAction } from "../../../UpdatedStore/actions/SiteActions";
import Loader from "../../../UpdatedStore/components/Loader";
import Message from "../../../UpdatedStore/components/Message";
import SpinnerLoader from "../../../UpdatedStore/components/SpinnerLoader";

const AllPlatforms: React.FC = (props) => {
  const { loading, sites, error } = useSelector((state) => state.SitesReducer);
  const { user } = useSelector((state) => state.AuthenticationReducer);

  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    if (!sites) {
      dispatch(GetSitesAction(user.access_token));
    }
  }, [sites]);

  const [buildLoading, setLoading] = useState(false);

  const buildFunctionality = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      history.push("/store/builder");
    }, 3000);
  };

  return (
    <div className="_all-platforms">
      {loading && <Loader />}

      {!loading && (
        <>
          {(sites && sites?.length === 0) ||
            (props.filteredData !== "" && props.filteredData.length === 0 && (
              <div className="_management-website">
                <div className="_hold-pic">
                  <Image src={notFound} alt="not found picture" />
                </div>

                <div className="_info">
                  <h3>You don’t have any of this platform type</h3>
                  {buildLoading && <SpinnerLoader />}
                  {!buildLoading && (
                    <button
                      className="_add-new-platform"
                      onClick={buildFunctionality}
                    >
                      Add new platform
                    </button>
                  )}
                </div>
              </div>
            ))}
        </>
      )}

      {!loading && (
        <>
          {sites && sites?.length > 0 && (
            <div className="_management-all-platfrom">
              {props.filteredData === "" &&
                sites.map((site, index) => (
                  <HoldCards site={site} key={index} />
                ))}

              {props.filteredData !== "" &&
                props.filteredData.map((site, index) => (
                  <HoldCards site={site} key={index} />
                ))}
            </div>
          )}
        </>
      )}
      {error && <Message variant="danger">{error}</Message>}
    </div>
  );
};

export default AllPlatforms;
