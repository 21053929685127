import React from "react";
import "./Staff.scss";
import Notification from "../../components/Notification/Notification";
import Search from "./search/Search";
import StaffMembers from "../../components/staff/staffMember/StaffMembers";
import AddStaff from "./addStaff/AddStaff";

const Staff: React.FC = () => {
  return (
    <div className="staff_page">
      <div className="_header">
        <h1 className="_title">My staffs</h1>
        <Notification />
      </div>

      <div className="_sort-search-bar-add-new">
        <div className="_search-filter">
          <Search />
        </div>

        <div className="_add-new-staff">
          <AddStaff />
        </div>
      </div>

      <StaffMembers />
    </div>
  );
};

export default Staff;
