import React, { useState } from "react";
import "./Home.scss";
import "../../components/HomeStatistics/styles.scss";
import "../../components/newUserPlatformLive/newUserPlatformLive.scss";
import Notification from "../../components/Notification/Notification";
import { Button, Card, Image } from "react-bootstrap";
import standingGril from "../../assets/icons/girl.svg";
import { useSelector } from "react-redux";
import NewUserPlatformLive from "../../components/newUserPlatformLive/NewUserPlatformLive";
import SpinnerLoader from "../../UpdatedStore/components/SpinnerLoader";
import { useHistory } from "react-router-dom";
import ActiveOrder from "../../components/newUserPlatformLive/activeOrder/ActiveOrder";
import YourPlatforms from "../../components/newUserPlatformLive/yourPlatforms/YourPlatforms";
import { UserStats } from "../../components/Users";
import LatestPlatform from "../../components/HomeStatistics/LatestPlatform";
const Home: React.FC = () => {
  const { user } = useSelector((state) => state.AuthenticationReducer);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const buildFunctionality = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      history.push("/store/builder");
    }, 3000);
  };
  return (
    <div className="home_page">
      <div className="_header">
        <h1 className="_title">Home</h1>
        <Notification />
      </div>

      {!user.isSuperUser && (
        <>
          <div className="_build-platform">
            <div>
              <Image src={standingGril} />
            </div>
            <div className="_box-content">
              <Card.Title>Start build your platform</Card.Title>
              <Card.Text>
                Create website or mobile app to grow your business
              </Card.Text>
              {loading && <SpinnerLoader />}
              {!loading && (
                <button className="btni transcale" onClick={buildFunctionality}>
                  Build now
                </button>
              )}
            </div>
          </div>
        </>
      )}

      {user.isSuperUser ? (
        <>
          <UserStats />
          <div className="_platform-is-live">
            <div className="_hold-your-platform-active-order2">
              <div className="col-md-12">
                <div className="edge-border">
                  <div className="latest-platform">
                    <LatestPlatform />
                  </div>
                </div>
              </div>
              {/* <ActiveOrder /> */}
            </div>
          </div>
        </>
      ) : (
        <NewUserPlatformLive />
      )}
    </div>
  );
};

export default Home;
