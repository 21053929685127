import { useState } from "react";
import {
  FaChevronCircleDown,
  FaChevronCircleUp,
  FaPencilAlt,
  FaTrash,
} from "react-icons/fa";
import AddCategory from "./AddCategory";
import DeleteProductCategory from "./DeleteProductCategory";
import EditProductCategory from "./EditProductCategory";

const Categories = ({ site }) => {
  const [open, setOpen] = useState(false);

  const changeOpenState = () => {
    setOpen((currentState) => !currentState);
  };

  return (
    <div className="hold-Product_types">
      <div className="_dropdown">
        <h2 className="tint">
          Product Categories{" "}
          {open ? (
            <FaChevronCircleUp
              className="tint cursor transcale"
              onClick={changeOpenState}
            />
          ) : (
            <FaChevronCircleDown
              className="tint cursor transcale"
              onClick={changeOpenState}
            />
          )}
        </h2>
        {open && (
          <div className="px-2">
            {site.product_categories.map((type_) => (
              <div className="holdType__">
                <div>{type_.name}</div>{" "}
                <EditProductCategory type={type_} site={site} />
                <DeleteProductCategory type={type_} site={site} />
              </div>
            ))}
          </div>
        )}
      </div>
      {open && (
        <div className="_add-new-catalog">
          <AddCategory site={site} />
        </div>
      )}
    </div>
  );
};

export default Categories;
