import {
  NEW_LAYOUT_REQUEST,
  NEW_LAYOUT_SUCCESS,
  NEW_LAYOUT_EDIT_SUCCESS,
  NEW_LAYOUT_FAIL,
  COMPONENT_DELETE,
  SHOW_MODAL_STATE,
  EDIT_THEME_STATE,
  EDIT_NODE_SUCCESS,
  NODE_RESET,
  BUILDER_CONFIRM_SUCCESS,
  BUILDER_CONFIRM_FAIL,
  CREATE_SITE_REQUEST,
  CREATE_SITE_SUCCESS,
  CREATE_SITE_FAIL,
} from "../constants/ApiConstants";
import undoable from "redux-undo";

export const NewLayoutReducer = (state = { layout: [] }, action) => {
  switch (action.type) {
    case NEW_LAYOUT_REQUEST:
      return { loading: true };

    case NEW_LAYOUT_SUCCESS:
      let items = action.payload;

      items.forEach((item, index) => {
        item.id = index;
      });

      return { loading: false, layout: items };

    case EDIT_NODE_SUCCESS:
      return { loading: false, layout: action.payload };

    case COMPONENT_DELETE:
      let refreshedItems = action.payload;

      refreshedItems.forEach((item, index) => {
        item.id = index;
      });

      return { loading: false, layout: refreshedItems };

    case NEW_LAYOUT_EDIT_SUCCESS:
      let newitems = action.payload;

      newitems.forEach((item, index) => {
        item.id = index;
      });

      return { loading: false, layout: newitems };

    case NEW_LAYOUT_FAIL:
      return { loading: false, error: action.payload };

    case NODE_RESET:
      return { layout: [] };
    default:
      return state;
  }
};

const MarkupLayoutReducer = (
  state = {
    type: "Root",
    layout: [],
    data: [],
    methods: [],
  },
  action
) => {
  switch (action.type) {
    case NEW_LAYOUT_EDIT_SUCCESS:
      let newitems = action.payload;

      newitems.forEach((item, index) => {
        item.id = index;
      });

      return { ...state, layout: newitems };

    case EDIT_NODE_SUCCESS:
      return { ...state, layout: action.payload };

    case COMPONENT_DELETE:
      let refreshedItems = action.payload;

      refreshedItems.forEach((item, index) => {
        item.id = index;
      });

      return { ...state, layout: refreshedItems };

    case NEW_LAYOUT_SUCCESS:
      let items = action.payload;

      items.forEach((item, index) => {
        item.id = index;
      });

      return { ...state, layout: items };

    case NODE_RESET:
      return { type: "Root", layout: [], data: [], methods: [] };

    default:
      return state;
  }
};

export const undoableMarkupLayout = undoable(MarkupLayoutReducer);

export const EditModalReducer = (state = { show: false }, action) => {
  switch (action.type) {
    case SHOW_MODAL_STATE:
      return { ...state, show: !state.show, node: action.payload };

    default:
      return state;
  }
};

export const EditThemeReducer = (state = { theme: "template" }, action) => {
  switch (action.type) {
    case EDIT_THEME_STATE:
      return { theme: action.payload };

    default:
      return state;
  }
};

export const BuilderConfirmReducer = (state = { confirm: true }, action) => {
  switch (action.type) {
    case BUILDER_CONFIRM_SUCCESS:
      return { confirm: action.payload, user: action.user };

    case BUILDER_CONFIRM_FAIL:
      return { confirm: action.payload };

    default:
      return state;
  }
};

export const CreateSiteReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_SITE_REQUEST:
      return { loading: true };

    case CREATE_SITE_SUCCESS:
      return {
        loading: false,
        success: action.payload,
        message: action.message,
      };

    case CREATE_SITE_FAIL:
      return {
        loading: false,
        success: action.payload,
        message: action.message,
      };

    default:
      return state;
  }
};
