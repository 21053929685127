import { useState } from "react";
import { Modal } from 'react-bootstrap';
import Button from "../../form/Buttons";
import '../styles.scss'



const RenameModal = (props: any) => {
  const [name, setName] = useState<string>('');
  const [errors, setErrors] = useState<string>('')

  const handleNameChange = (e: any) => {
    setName(e.target.value)
    console.log(name)
  }
  
  const handleResetSubmit = (e: any) => {
    e.preventDefault()
    if (!name) {
      setErrors("Provide Name")
    }
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Rename Platform
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleResetSubmit} >
              <div className="d-flex flex-column">
                <input type="text"
                  placeholder="Enter platform name"
                  className="form-control mb-2 modal-input p-3"
                  onChange={handleNameChange}
                  name="name"
                  value={name} />
                <small className="text-danger">{errors}</small>
                <Button className="p-3 mt-4" disabled={!name} type="submit"> Save </Button>
              </div>
            </form>
          </Modal.Body>
    </Modal>
  )

}
export default RenameModal;