import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "./date.scss";
import "react-datepicker/dist/react-datepicker.css";

const EndDate = ({ endDate, setEndDate }) => {
  return (
    <div className="_date-input">
      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        showDisabledMonthNavigation
        placeholderText="Start date"
      />

      <span className="_icon-date">
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.2929 5.13568C10.9229 4.50572 10.4767 3.42857 9.58579 3.42857L2.41421 3.42857C1.52331 3.42857 1.07714 4.50572 1.70711 5.13568L4.58579 8.01436C5.36683 8.79541 6.63316 8.79541 7.41421 8.01436L10.2929 5.13568Z"
            fill="#9AA1AF"
          />
        </svg>
      </span>
    </div>
  );
};

export default EndDate;
