import React, { useState, useEffect } from "react";

import {
  Box,
  Container,
  Grid,
  Modal,
  Fade,
  Backdrop,
  Typography,
  Button,
  OutlinedInput,
  IconButton,
  InputAdornment,
} from "@material-ui/core";

import { VisibilityOff, Visibility } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import SelectNumber from "./SelectNumber.js";

import axios from "axios";
import { FaTimes } from "react-icons/fa";
// import Close from "/public/close.svg";

const style = {
  position: "absolute",
  top: "30px",
  right: "90px",
  width: 400,
  bgcolor: "#f5f5f5",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: "100%",
  overflow: "scroll",
};

const useStyles = makeStyles((theme) => ({
  roots: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },

    // "& .MuiOutlinedInput-root": {
    //   position: "relative",
    //   width: "100%",
    //   paddingRight: "0 !important",
    // },
  },
  LogIn: {
    fontSize: theme.typography.pxToRem(16),
    color: "#fff",
    fontFamily: `'Montserrat', 'sans-serif'`,
    fontWeight: "normal",
    textTransform: "initial",
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "43px",

    "& h3": {
      fontSize: theme.typography.pxToRem(24),
      color: "#16284D",
      fontFamily: `'Montserrat', 'sans-serif'`,

      fontWeight: "bold",
    },
  },

  HoldForm: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& div": {
      width: "100%",
    },
    "& input": {
      width: "100%",
      border: "1px solid #16284D",
      backgroundColor: "#f5f5f5",
      height: "40px",
      paddingLeft: "14px",
      color: "#16284D",
      fontSize: theme.typography.pxToRem(16),
      fontFamily: `'Montserrat', 'sans-serif'`,
      "&::placeholder": {
        color: "#3E3E3E",
      },
    },
  },
  HoldForgetPassLogIn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    paddingTop: "50px",
    paddingBottom: "75px",
  },

  ForgetPass: {
    backgroundColor: "transparent",
    border: "1px solid #16284D",
    fontSize: theme.typography.pxToRem(16),
    color: "#fff",
    fontWeight: "normal",
    fontFamily: `'Montserrat', 'sans-serif'`,
  },
  LogInButton: {
    width: "100%",
    height: "52px",
    fontSize: theme.typography.pxToRem(18),
    fontWeight: "600",
    fontFamily: `'Montserrat', 'sans-serif'`,
    color: "#f5f5f5",
    backgroundColor: "#16284D",
    "&:hover": {
      backgroundColor: "#16284D",
    },
  },

  DontHAccount: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: "bold",
    fontFamily: `'Montserrat', 'sans-serif'`,
    color: "#16284D",
    width: "100%",
    border: "1px solid #16284D",
    backgroundColor: "transparent",
    // marginTop: "40px",
    textAlign: "center",
  },

  CustomePass: {
    paddingRight: "0",
    "& input": {
      padding: 0,
      width: "100%",
      border: "1px solid #16284D",
      backgroundColor: "#f5f5f5",
      height: "40px",
      paddingLeft: "14px",
      color: "#16284D",
      fontSize: theme.typography.pxToRem(16),
      fontFamily: `'Montserrat', 'sans-serif'`,
      "&::placeholder": {
        color: "#3E3E3E",
      },
    },

    "& .MuiIconButton-edgeEnd": {
      position: "absolute",
      right: "20px",
      marginRight: "0",
    },
    " & .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiIconButton-label": {
      color: "#16284D",
    },

    "& .MuiInputAdornment-positionEnd": {
      width: "auto",
    },
  },
  SmallLabel: {
    fontSize: theme.typography.pxToRem(12),
    color: "#16284D",
    fontWeight: "normal",
    opacity: ".5",
    display: "block",
    marginBottom: "8px",
  },

  Error: {
    textAlign: "center",
    color: "red",
    fontWeight: "normal",
    fontFamily: `'Montserrat', 'sans-serif'`,
    fontSize: theme.typography.pxToRem(12),
    marginTop: "20px",
  },
  HoldPhoneName: {
    display: "flex",
    alignItems: "center",

    "& .makeStyles-SelectBox-313": {
      marginRight: "20px",
    },
  },

  DontHAccountLogIN: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: "bold",
    border: "1px solid #16284D",
    fontFamily: `'Montserrat', 'sans-serif'`,
    color: "#16284D",
    backgroundColor: "transparent",
    width: "100%",
    marginTop: "40px",
    textAlign: "center",
  },
}));

export default function SignIn({ nodeData }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const classes = useStyles();

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={classes.roots}>
      <Button onClick={handleOpen} className={classes.DontHAccount}>
        Don’t have account? Sign up
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box className={classes.Header}>
              <Typography component="h3">Create account</Typography>
              <Button className="closeNairobiBtn" onClick={handleClose}>
                <FaTimes />
              </Button>
            </Box>

            <form className={classes.HoldForm}>
              <div
                style={{
                  marginBottom: "30px",
                }}
              >
                <Typography component="span" className={classes.SmallLabel}>
                  Full name
                </Typography>
                {/* <input placeholder="Input your email" type="text" /> */}

                <OutlinedInput
                  type="text"
                  className={classes.CustomePass}
                  placeholder="Input your first name"
                />
              </div>

              <div
                style={{
                  marginBottom: "30px",
                }}
              >
                <Typography component="span" className={classes.SmallLabel}>
                  Last name
                </Typography>
                {/* <input placeholder="Input your email" type="text" /> */}

                <OutlinedInput
                  type="text"
                  className={classes.CustomePass}
                  placeholder="Input your last name"
                />
              </div>

              <div
                style={{
                  marginBottom: "30px",
                }}
              >
                <Typography component="span" className={classes.SmallLabel}>
                  Email
                </Typography>
                {/* <input placeholder="Input your email" type="text" /> */}

                <OutlinedInput
                  type="text"
                  className={classes.CustomePass}
                  placeholder="Input your email"
                />
              </div>
              {/* select box */}

              <div
                style={{
                  marginBottom: "30px",
                }}
              >
                <Typography component="span" className={classes.SmallLabel}>
                  Phone number
                </Typography>
                {/* <input placeholder="Input your email" type="text" /> */}
                <div className={classes.HoldPhoneName}>
                  <SelectNumber />
                  <OutlinedInput
                    type="text"
                    className={classes.CustomePass}
                    placeholder="Input phone number"
                  />
                </div>
              </div>

              <div>
                <Typography component="span" className={classes.SmallLabel}>
                  Password
                </Typography>
                {/* <input placeholder="Type your password" type="password" /> */}
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("password")}
                  className={classes.CustomePass}
                  placeholder="Type your password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />

                <Typography
                  component="span"
                  className={classes.SmallLabel}
                  style={{
                    marginTop: "30px",
                  }}
                >
                  Confirm password
                </Typography>
                {/* <input placeholder="Type your password" type="password" /> */}
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("password")}
                  className={classes.CustomePass}
                  placeholder="Type your password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>

              <div className={classes.HoldForgetPassLogIn}>
                <Button className={classes.LogInButton}>Sign up</Button>
                <Button className={classes.DontHAccountLogIN}>
                  Already have account? Log on
                </Button>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
