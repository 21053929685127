export const NEW_LAYOUT_REQUEST = "NEW_LAYOUT_REQUEST";
export const NEW_LAYOUT_SUCCESS = "NEW_LAYOUT_SUCCESS";
export const NEW_LAYOUT_EDIT_SUCCESS = "NEW_LAYOUT_EDIT_SUCCESS";
export const NEW_LAYOUT_FAIL = "NEW_LAYOUT_FAIL";
export const NODE_RESET = "NODE_RESET";

export const COMPONENT_DELETE = "COMPONENT_DELETE";
export const EDIT_NODE_SUCCESS = "EDIT_NODE_SUCCESS";

export const SHOW_MODAL_STATE = "SHOW_MODAL_STATE";

export const EDIT_THEME_STATE = "EDIT_THEME_STATE";

export const BUILDER_CONFIRM_SUCCESS = "BUILDER_CONFIRM_SUCCESS";
export const BUILDER_CONFIRM_FAIL = "BUILDER_CONFIRM_FAIL";

export const CREATE_SITE_REQUEST = "CREATE_SITE_REQUEST";
export const CREATE_SITE_SUCCESS = "CREATE_SITE_SUCCESS";
export const CREATE_SITE_FAIL = "CREATE_SITE_FAIL";
