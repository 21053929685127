import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { FaTrash, FaPencilAlt } from "react-icons/fa";
import { deleteNodeAction } from "../../actions/ApiAction";
import { useDispatch } from "react-redux";
import { SHOW_MODAL_STATE } from "../../constants/ApiConstants";
import { BsArrowsMove } from "react-icons/bs";
import { Image } from "react-bootstrap";

const _style = makeStyles(function (theme) {
  return {
    grow: {
      flexGrow: 1,
    },
    root: (props) => ({
      position: "relative",
      overflow: "hidden",
      minHeight: "90vh",
      padding: "10px 20px",
      backgroundColor: "#ffffff",
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gap: "10px",
      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "repeat(1, 1fr)",
      },
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "repeat(1, 1fr)",
      },
    }),
  };
});

export default function Banner({ nodeData }) {
  let classes = _style({
    ...nodeData.styles,
    imgUrl: nodeData.data.images[0],
  });

  const dispatch = useDispatch();

  const EditFunctionality = () => {
    dispatch({ type: SHOW_MODAL_STATE, payload: nodeData });
  };

  const deleteNodeHandler = (node) => {
    dispatch(deleteNodeAction(node));
  };

  return (
    <Draggable key={nodeData.id} draggableId="Carousel" index={nodeData.id}>
      {(provided) => (
        <div
          className={(classes.grow, "componentBuildBox")}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <div className="cmpMoveIcon" {...provided.dragHandleProps}>
            <BsArrowsMove className="cmp" />
          </div>
          <div className="deleteFlex">
            <div className="editIDiv" onClick={EditFunctionality}>
              <FaPencilAlt className="editIcon" />
            </div>
            <div
              className="deleteIDiv"
              onClick={() => deleteNodeHandler(nodeData)}
            >
              <FaTrash className="deleteIcon" />
            </div>
          </div>

          <div className={`${classes.root}`}>
            <div className="fourthBannerGrid1">
              <div>
                {" "}
                <Image
                  height="100%"
                  width="100%"
                  src={nodeData.data.images[0]}
                />
              </div>
              <div>
                {" "}
                <Image
                  height="100%"
                  width="100%"
                  src={nodeData.data.images[3]}
                />
              </div>
            </div>
            <div style={{ height: "100%" }}>
              <Image height="100%" width="100%" src={nodeData.data.images[1]} />
            </div>
            <div className="fourthBannerGrid2">
              <div>
                <Image
                  height="100%"
                  width="100%"
                  src={nodeData.data.images[2]}
                />
              </div>
              <div>
                <Image
                  height="100%"
                  width="100%"
                  src={nodeData.data.images[4]}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}
