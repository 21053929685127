export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL";

export const GET_PROMOTIONS_REQUEST = "GET_PROMOTIONS_REQUEST";
export const GET_PROMOTIONS_SUCCESS = "GET_PROMOTIONS_SUCCESS";
export const GET_PROMOTIONS_FAIL = "GET_PROMOTIONS_FAIL";

export const CREATE_PRODUCT_REQUEST = "CREATE_PRODUCT_REQUEST";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAIL = "CREATE_PRODUCT_FAIL";
export const CREATE_PRODUCT_RESET = "CREATE_PRODUCT_RESET";

export const CREATE_BULK_PRODUCT_REQUEST = "CREATE_BULK_PRODUCT_REQUEST";
export const CREATE_BULK_PRODUCT_SUCCESS = "CREATE_BULK_PRODUCT_SUCCESS";
export const CREATE_BULK_PRODUCT_FAIL = "CREATE_BULK_PRODUCT_FAIL";
export const CREATE_BULK_PRODUCT_RESET = "CREATE_BULK_PRODUCT_RESET";

export const CREATE_PROMOTIONS_REQUEST = "CREATE_PROMOTIONS_REQUEST";
export const CREATE_PROMOTIONS_SUCCESS = "CREATE_PROMOTIONS_SUCCESS";
export const CREATE_PROMOTIONS_FAIL = "CREATE_PROMOTIONS_FAIL";
export const CREATE_PROMOTIONS_RESET = "CREATE_PROMOTIONS_RESET";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";
export const DELETE_PRODUCT_RESET = "DELETE_PRODUCT_RESET";

export const DELETE_PROMOTIONS_REQUEST = "DELETE_PROMOTIONS_REQUEST";
export const DELETE_PROMOTIONS_SUCCESS = "DELETE_PROMOTIONS_SUCCESS";
export const DELETE_PROMOTIONS_FAIL = "DELETE_PROMOTIONS_FAIL";
export const DELETE_PROMOTIONS_RESET = "DELETE_PROMOTIONS_RESET";

export const EDIT_PRODUCT_REQUEST = "EDIT_PRODUCT_REQUEST";
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_FAIL = "EDIT_PRODUCT_FAIL";
export const EDIT_PRODUCT_RESET = "EDIT_PRODUCT_RESET";

export const EDIT_PROMOTIONS_REQUEST = "EDIT_PROMOTIONS_REQUEST";
export const EDIT_PROMOTIONS_SUCCESS = "EDIT_PROMOTIONS_SUCCESS";
export const EDIT_PROMOTIONS_FAIL = "EDIT_PROMOTIONS_FAIL";
export const EDIT_PROMOTIONS_RESET = "EDIT_PROMOTIONS_RESET";

// ORDERS
export const UPDATE_ORDER_STATUS_REQUEST = "UPDATE_ORDER_STATUS_REQUEST";
export const UPDATE_ORDER_STATUS_SUCCESS = "UPDATE_ORDER_STATUS_SUCCESS";
export const UPDATE_ORDER_STATUS_FAIL = "UPDATE_ORDER_STATUS_FAIL";
export const UPDATE_ORDER_STATUS_RESET = "UPDATE_ORDER_STATUS_RESET";

export const GET_ANALYTICS_STATICS_REQUEST = "GET_ANALYTICS_STATICS_REQUEST";
export const GET_ANALYTICS_STATICS_SUCCESS = "GET_ANALYTICS_STATICS_SUCCESS";
export const GET_ANALYTICS_STATICS_FAIL = "GET_ANALYTICS_STATICS_FAIL";

// STAFFS
export const CREATE_STAFF_REQUEST = "CREATE_STAFF_REQUEST";
export const CREATE_STAFF_SUCCESS = "CREATE_STAFF_SUCCESS";
export const CREATE_STAFF_FAIL = "CREATE_STAFF_FAIL";
export const CREATE_STAFF_RESET = "CREATE_STAFF_RESET";

export const EDIT_STAFF_REQUEST = "EDIT_STAFF_REQUEST";
export const EDIT_STAFF_SUCCESS = "EDIT_STAFF_SUCCESS";
export const EDIT_STAFF_FAIL = "EDIT_STAFF_FAIL";
export const EDIT_STAFF_RESET = "EDIT_STAFF_RESET";

export const DELETE_STAFF_REQUEST = "DELETE_STAFF_REQUEST";
export const DELETE_STAFF_SUCCESS = "DELETE_STAFF_SUCCESS";
export const DELETE_STAFF_FAIL = "DELETE_STAFF_FAIL";
export const DELETE_STAFF_RESET = "DELETE_STAFF_RESET";

export const CREATE_FAQ_REQUEST = "CREATE_FAQ_REQUEST";
export const CREATE_FAQ_SUCCESS = "CREATE_FAQ_SUCCESS";
export const CREATE_FAQ_FAIL = "CREATE_FAQ_FAIL";
export const CREATE_FAQ_RESET = "CREATE_FAQ_RESET";

export const EDIT_FAQ_REQUEST = "EDIT_FAQ_REQUEST";
export const EDIT_FAQ_SUCCESS = "EDIT_FAQ_SUCCESS";
export const EDIT_FAQ_FAIL = "EDIT_FAQ_FAIL";
export const EDIT_FAQ_RESET = "EDIT_FAQ_RESET";

export const DELETE_FAQ_REQUEST = "DELETE_FAQ_REQUEST";
export const DELETE_FAQ_SUCCESS = "DELETE_FAQ_SUCCESS";
export const DELETE_FAQ_FAIL = "DELETE_FAQ_FAIL";
export const DELETE_FAQ_RESET = "DELETE_FAQ_RESET";

export const CREATE_BLOG_REQUEST = "CREATE_BLOG_REQUEST";
export const CREATE_BLOG_SUCCESS = "CREATE_BLOG_SUCCESS";
export const CREATE_BLOG_FAIL = "CREATE_BLOG_FAIL";
export const CREATE_BLOG_RESET = "CREATE_BLOG_RESET";

export const EDIT_BLOG_REQUEST = "EDIT_BLOG_REQUEST";
export const EDIT_BLOG_SUCCESS = "EDIT_BLOG_SUCCESS";
export const EDIT_BLOG_FAIL = "EDIT_BLOG_FAIL";
export const EDIT_BLOG_RESET = "EDIT_BLOG_RESET";

export const DELETE_BLOG_REQUEST = "DELETE_BLOG_REQUEST";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";
export const DELETE_BLOG_FAIL = "DELETE_BLOG_FAIL";
export const DELETE_BLOG_RESET = "DELETE_BLOG_RESET";

export const CREATE_STORE_INFO_REQUEST = "CREATE_STORE_INFO_REQUEST";
export const CREATE_STORE_INFO_SUCCESS = "CREATE_STORE_INFO_SUCCESS";
export const CREATE_STORE_INFO_FAIL = "CREATE_STORE_INFO_FAIL";
export const CREATE_STORE_INFO_RESET = "CREATE_STORE_INFO_RESET";

export const EDIT_STORE_INFO_REQUEST = "EDIT_STORE_INFO_REQUEST";
export const EDIT_STORE_INFO_SUCCESS = "EDIT_STORE_INFO_SUCCESS";
export const EDIT_STORE_INFO_FAIL = "EDIT_STORE_INFO_FAIL";
export const EDIT_STORE_INFO_RESET = "EDIT_STORE_INFO_RESET";

export const DELETE_STORE_INFO_REQUEST = "DELETE_STORE_INFO_REQUEST";
export const DELETE_STORE_INFO_SUCCESS = "DELETE_STORE_INFO_SUCCESS";
export const DELETE_STORE_INFO_FAIL = "DELETE_STORE_INFO_FAIL";
export const DELETE_STORE_INFO_RESET = "DELETE_STORE_INFO_RESET";

export const CREATE_STORE_IMAGE_REQUEST = "CREATE_STORE_IMAGE_REQUEST";
export const CREATE_STORE_IMAGE_SUCCESS = "CREATE_STORE_IMAGE_SUCCESS";
export const CREATE_STORE_IMAGE_FAIL = "CREATE_STORE_IMAGE_FAIL";
export const CREATE_STORE_IMAGE_RESET = "CREATE_STORE_IMAGE_RESET";

export const DELETE_STORE_IMAGE_REQUEST = "DELETE_STORE_IMAGE_REQUEST";
export const DELETE_STORE_IMAGE_SUCCESS = "DELETE_STORE_IMAGE_SUCCESS";
export const DELETE_STORE_IMAGE_FAIL = "DELETE_STORE_IMAGE_FAIL";
export const DELETE_STORE_IMAGE_RESET = "DELETE_STORE_IMAGE_RESET";

export const CREATE_STORE_CAROUSEL_IMAGE_REQUEST =
  "CREATE_STORE_CAROUSEL_IMAGE_REQUEST";
export const CREATE_STORE_CAROUSEL_IMAGE_SUCCESS =
  "CREATE_STORE_CAROUSEL_IMAGE_SUCCESS";
export const CREATE_STORE_CAROUSEL_IMAGE_FAIL =
  "CREATE_STORE_CAROUSEL_IMAGE_FAIL";
export const CREATE_STORE_CAROUSEL_IMAGE_RESET =
  "CREATE_STORE_CAROUSEL_IMAGE_RESET";

export const DELETE_STORE_CAROUSEL_IMAGE_REQUEST =
  "DELETE_STORE_CAROUSEL_IMAGE_REQUEST";
export const DELETE_STORE_CAROUSEL_IMAGE_SUCCESS =
  "DELETE_STORE_CAROUSEL_IMAGE_SUCCESS";
export const DELETE_STORE_CAROUSEL_IMAGE_FAIL =
  "DELETE_STORE_CAROUSEL_IMAGE_FAIL";
export const DELETE_STORE_CAROUSEL_IMAGE_RESET =
  "DELETE_STORE_CAROUSEL_IMAGE_RESET";

export const CREATE_DELIVERY_METHODS_REQUEST =
  "CREATE_DELIVERY_METHODS_REQUEST";
export const CREATE_DELIVERY_METHODS_SUCCESS =
  "CREATE_DELIVERY_METHODS_SUCCESS";
export const CREATE_DELIVERY_METHODS_FAIL = "CREATE_DELIVERY_METHODS_FAIL";
export const CREATE_DELIVERY_METHODS_RESET = "CREATE_DELIVERY_METHODS_RESET";

export const EDIT_DELIVERY_METHODS_REQUEST = "EDIT_DELIVERY_METHODS_REQUEST";
export const EDIT_DELIVERY_METHODS_SUCCESS = "EDIT_DELIVERY_METHODS_SUCCESS";
export const EDIT_DELIVERY_METHODS_FAIL = "EDIT_DELIVERY_METHODS_FAIL";
export const EDIT_DELIVERY_METHODS_RESET = "EDIT_DELIVERY_METHODS_RESET";

export const DELETE_DELIVERY_METHODS_REQUEST =
  "DELETE_DELIVERY_METHODS_REQUEST";
export const DELETE_DELIVERY_METHODS_SUCCESS =
  "DELETE_DELIVERY_METHODS_SUCCESS";
export const DELETE_DELIVERY_METHODS_FAIL = "DELETE_DELIVERY_METHODS_FAIL";
export const DELETE_DELIVERY_METHODS_RESET = "DELETE_DELIVERY_METHODS_RESET";

export const CREATE_CUSTOM_VOUCHER_REQUEST = "CREATE_CUSTOM_VOUCHER_REQUEST";
export const CREATE_CUSTOM_VOUCHER_SUCCESS = "CREATE_CUSTOM_VOUCHER_SUCCESS";
export const CREATE_CUSTOM_VOUCHER_FAIL = "CREATE_CUSTOM_VOUCHER_FAIL";
export const CREATE_CUSTOM_VOUCHER_RESET = "CREATE_CUSTOM_VOUCHER_RESET";

export const EDIT_CUSTOM_VOUCHER_REQUEST = "EDIT_CUSTOM_VOUCHER_REQUEST";
export const EDIT_CUSTOM_VOUCHER_SUCCESS = "EDIT_CUSTOM_VOUCHER_SUCCESS";
export const EDIT_CUSTOM_VOUCHER_FAIL = "EDIT_CUSTOM_VOUCHER_FAIL";
export const EDIT_CUSTOM_VOUCHER_RESET = "EDIT_CUSTOM_VOUCHER_RESET";

export const DELETE_CUSTOM_VOUCHER_REQUEST = "DELETE_CUSTOM_VOUCHER_REQUEST";
export const DELETE_CUSTOM_VOUCHER_SUCCESS = "DELETE_CUSTOM_VOUCHER_SUCCESS";
export const DELETE_CUSTOM_VOUCHER_FAIL = "DELETE_CUSTOM_VOUCHER_FAIL";
export const DELETE_CUSTOM_VOUCHER_RESET = "DELETE_CUSTOM_VOUCHER_RESET";

export const CREATE_PRODUCT_IMAGE_REQUEST = "CREATE_PRODUCT_IMAGE_REQUEST";
export const CREATE_PRODUCT_IMAGE_SUCCESS = "CREATE_PRODUCT_IMAGE_SUCCESS";
export const CREATE_PRODUCT_IMAGE_FAIL = "CREATE_PRODUCT_IMAGE_FAIL";
export const CREATE_PRODUCT_IMAGE_RESET = "CREATE_PRODUCT_IMAGE_RESET";

export const DELETE_PRODUCT_IMAGE_REQUEST = "DELETE_PRODUCT_IMAGE_REQUEST";
export const DELETE_PRODUCT_IMAGE_SUCCESS = "DELETE_PRODUCT_IMAGE_SUCCESS";
export const DELETE_PRODUCT_IMAGE_FAIL = "DELETE_PRODUCT_IMAGE_FAIL";
export const DELETE_PRODUCT_IMAGE_RESET = "DELETE_PRODUCT_IMAGE_RESET";
