import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createBulkProductAction,
  GetProductsAction,
} from "../../../UpdatedStore/actions/ProductManagementActions";
import "./multiple.scss";
import ProductsMockup from "./ProductsMockup.xlsx";
import placeholder from "./placeholder.jpg";
import { CREATE_BULK_PRODUCT_RESET } from "../../../UpdatedStore/constants/ManagementProductConstants";
import { useHistory } from "react-router-dom";

const MultipleProducts: React.FC = ({ site }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalShow, setModalShow] = React.useState(false);
  const [image2, setImage2] = useState("Upload Image...");
  const [files2, setFiles2] = useState("");
  const [message, setMessage] = React.useState("");

  const { loading, error, success } = useSelector(
    (state) => state.CreateBulkProductReducer
  );

  const saveChanges = () => {
    if (files2) {
      var formData = new FormData();
      formData.append("excel_file", files2);
      formData.append("image", placeholder);
      formData.append("site", site.name);
      formData.append("partner_id", site.partner[0].id);
      dispatch(createBulkProductAction(formData));
    } else {
      setMessage("Please pick a valid file");
    }
  };

  useEffect(() => {
    setImage2("Upload Image...");
    setFiles2("");

    if (success) {
      setMessageTimeOut();
    }
  }, [modalShow, success]);

  const setMessageTimeOut = () => {
    setTimeout(() => {
      dispatch({ type: CREATE_BULK_PRODUCT_RESET });
      setModalShow(false);
      dispatch(GetProductsAction(site));
      history.push("/inventory");
    }, 2000);
  };

  return (
    <>
      <button onClick={() => setModalShow(true)}>Bulk Upload </button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="_log-multi-modal"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <button onClick={() => setModalShow(false)} className="_close-modal">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="28" height="28" rx="8" fill="#E8ECF0" />
              <path
                d="M20.698 8.23344L19.7383 7.28517C19.4786 7.02549 19.0496 7.02549 18.7786 7.28517L13.9915 12.0721L9.1142 7.19484C8.85441 6.93505 8.42537 6.93505 8.15452 7.19484L7.19484 8.15452C6.93505 8.4142 6.93505 8.84323 7.19484 9.1142L12.061 13.9803L7.28517 18.7787C7.02549 19.0384 7.02549 19.4674 7.28517 19.7384L8.24484 20.6981C8.50452 20.9577 8.93355 20.9577 9.20452 20.6981L13.9915 15.911L18.7786 20.6981C19.0383 20.9577 19.4673 20.9577 19.7383 20.6981L20.698 19.7384C20.9576 19.4787 20.9576 19.0497 20.698 18.7787L15.8996 13.9916L20.6867 9.20463C20.9576 8.93344 20.9576 8.50441 20.698 8.23344Z"
                fill="#777C85"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="_content">
            <h3>Upload Products</h3>
          </div>
          <div className="_act">
            <a href={ProductsMockup} download="ProductsMockup.xlsx">
              <Button className="_no" onClick={() => setModalShow(false)}>
                Download format file
              </Button>
            </a>

            <div className="uploadActions">
              <h6>Upload File</h6>

              <div className="customFileInputExcel">
                <input
                  type="file"
                  id="actual-btn2"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  hidden
                  onChange={(e) => {
                    setImage2(e.target.value);
                    setFiles2(e.target.files[0]);
                  }}
                />

                <label for="actual-btn2">Select File</label>
              </div>
            </div>
            {error && <h6 className="text-danger text-center my-3">{error}</h6>}
            {message && (
              <h6 className="text-danger text-center  my-3">{message}</h6>
            )}
            {success && (
              <h6 className="text-success text-center  my-3">Successful...</h6>
            )}
            {image2 !== "Upload Image..." && (
              <Button className="_yes" disabled={loading} onClick={saveChanges}>
                {loading ? "Processing..." : "Upload"}
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MultipleProducts;
