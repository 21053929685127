export const GET_SITES_REQUEST = "GET_SITES_REQUEST";
export const GET_SITES_SUCCESS = "GET_SITES_SUCCESS";
export const GET_SITES_FAIL = "GET_SITES_FAIL";

export const SYNC_STORE_REQUEST = "SYNC_STORE_REQUEST";
export const SYNC_STORE_SUCCESS = "SYNC_STORE_SUCCESS";
export const SYNC_STORE_FAIL = "SYNC_STORE_FAIL";

export const DELETE_STORE_REQUEST = "DELETE_STORE_REQUEST";
export const DELETE_STORE_SUCCESS = "DELETE_STORE_SUCCESS";
export const DELETE_STORE_FAIL = "DELETE_STORE_FAIL";
export const DELETE_STORE_RESET = "DELETE_STORE_RESET";

export const DELETE_NOTIFICATION_REQUEST = "DELETE_NOTIFICATION_REQUEST";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAIL = "DELETE_NOTIFICATION_FAIL";
export const DELETE_NOTIFICATION_RESET = "DELETE_NOTIFICATION_RESET";

export const GET_USER_STATICS_REQUEST = "GET_USER_STATICS_REQUEST";
export const GET_USER_STATICS_SUCCESS = "GET_USER_STATICS_SUCCESS";
export const GET_USER_STATICS_FAIL = "GET_USER_STATICS_FAIL";

export const GET_PLATFROM_STATICS_REQUEST = "GET_PLATFROM_STATICS_REQUEST";
export const GET_PLATFROM_STATICS_SUCCESS = "GET_PLATFROM_STATICS_SUCCESS";
export const GET_PLATFROM_STATICS_FAIL = "GET_USER_STATICS_FAIL";

export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL";

export const GET_STAFF_REQUEST = "GET_STAFF_REQUEST";
export const GET_STAFF_SUCCESS = "GET_STAFF_SUCCESS";
export const GET_STAFF_FAIL = "GET_STAFF_FAIL";

export const GET_All_USER_REQUEST = "GET_All_USER_REQUEST";
export const GET_All_USER_SUCCESS = "GET_All_USER_SUCCESS";
export const GET_All_USER_FAIL = "GET_All_USER_FAIL";
export const FILTER_ALLUSER = "FILTER_ALLUSER";

export const AUTHENTICATION_REQUEST = "AUTHENTICATION_REQUEST";
export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";
export const AUTHENTICATION_FAIL = "AUTHENTICATION_FAIL";
export const AUTHENTICATION_LOGOUT = "AUTHENTICATION_LOGOUT";

export const UPDATE_MERCHANT_REQUEST = "UPDATE_MERCHANT_REQUEST";
export const UPDATE_MERCHANT_SUCCESS = "UPDATE_MERCHANT_SUCCESS";
export const UPDATE_MERCHANT_FAIL = "UPDATE_MERCHANT_FAIL";
export const UPDATE_MERCHANT_RESET = "UPDATE_MERCHANT_RESET";

export const UPDATE_STORE_PHOTO = "UPDATE_STORE_PHOTO";
