import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./StatusChangeModal.scss";

import { BASE_URL } from "../../utils";
import Loader from "../../UpdatedStore/components/Loader";
import { boolean } from "yup";

const StatusChangeModal: React.FC = (props) => {
  const auth = useSelector((state) => state.AuthenticationReducer);

  const token = auth.user?.access_token;
  const [modalShow, setModalShow] = React.useState(true);
  const [message, setMessage] = React.useState('');
  const [status_code, setStatusCode] = React.useState(0);

  const handleStatus = async () => {

    var url = BASE_URL + "/merchant/site-status/" + props.data.id + "/";
    const res = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ "active": !props.status.status }),
    });
    const data = await res.json();
    setStatusCode(res.status)

    if (res.status === 403 || res.status === 401) {
      setMessage("You do not have permission to perform this action.")
    }
    else if (res.status === 200) {
      props.status.setsiteStatus(data.active)
      setMessage("You have changed website status successefully")
    }
    else {
      setMessage("Server Error ")
    }
    setTimeout(() => { props.onclose(false) }, 5000)
  };

  const onCloseModal = () => {
    setModalShow(false);
    props.onclose(false)
  }

  return (
    <>
      <Modal
        show={modalShow}
        onHide={onCloseModal}
        className="_log-out-modal"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <button onClick={onCloseModal} className="_close-modal">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="28" height="28" rx="8" fill="#E8ECF0" />
              <path
                d="M20.698 8.23344L19.7383 7.28517C19.4786 7.02549 19.0496 7.02549 18.7786 7.28517L13.9915 12.0721L9.1142 7.19484C8.85441 6.93505 8.42537 6.93505 8.15452 7.19484L7.19484 8.15452C6.93505 8.4142 6.93505 8.84323 7.19484 9.1142L12.061 13.9803L7.28517 18.7787C7.02549 19.0384 7.02549 19.4674 7.28517 19.7384L8.24484 20.6981C8.50452 20.9577 8.93355 20.9577 9.20452 20.6981L13.9915 15.911L18.7786 20.6981C19.0383 20.9577 19.4673 20.9577 19.7383 20.6981L20.698 19.7384C20.9576 19.4787 20.9576 19.0497 20.698 18.7787L15.8996 13.9916L20.6867 9.20463C20.9576 8.93344 20.9576 8.50441 20.698 8.23344Z"
                fill="#777C85"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          {message && (<>
            <div className={status_code === 200 ? "alert alert-success" : "alert alert-danger"}>
              {message}
            </div>
          </>)}
          <div className="_content">
            <h3>Change PlatFrom Status</h3>
            <p>Are you sure you want to <b>{!props.status.status ? "Activate" : "Deactivate"} {props.data.name}</b> website?</p>
          </div>
          <div className="_act">
            <Button className="_yes" onClick={handleStatus}>
              yes
            </Button>
            <Button className="_no" onClick={onCloseModal}>
              no
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StatusChangeModal;
