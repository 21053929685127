import React, { useEffect, useState } from "react";
import Notification from "../../components/Notification/Notification";
import { Tab, Nav } from "react-bootstrap";

import "./management.scss";
import PlatfromSearch from "../../components/order/topBar/search/PlatfromSearch";
import PlatfromSort from "../../components/management/sort/PlatfromSort";
import { AllPlatforms } from "../../components/management/allPlatforms/AllPlatforms";
import { Website } from "../../components/management/website/Website";

const Management: React.FC = () => {
  const [filteredData, setFilteredData] = useState("");
  const [filteredDatachange, setFilteredDataChange] = useState(false);
  const setPlatefromData = (props) => {
    setFilteredData(props);
    console.log(filteredData);
  };
  const setPlatefromDataSort = (props) => {
    setFilteredData(props);
    setFilteredDataChange(!filteredDatachange);
  };

  useEffect(() => {}, [filteredDatachange]);

  return (
    <div className="_management">
      <header>
        <h2>Management</h2>
        <Notification />
      </header>

      <div className="_hold-management-tabs">
        <Tab.Container defaultActiveKey="AllPlatforms">
          <div className="wrap-tabs">
            <Nav variant="pills" className="_tabs">
              <Nav.Item>
                <Nav.Link eventKey="AllPlatforms"> All platforms </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Website">Website</Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="MobileApplication">
                  Mobile application
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <div className="d-flex">
              <PlatfromSearch callback={setPlatefromData} />
              <PlatfromSort callback={setPlatefromDataSort} />
            </div>
          </div>

          <div>
            <Tab.Content>
              <Tab.Pane eventKey="AllPlatforms">
                <AllPlatforms filteredData={filteredData} />
              </Tab.Pane>
              <Tab.Pane eventKey="Website">
                <AllPlatforms filteredData={filteredData} />
              </Tab.Pane>
              <Tab.Pane eventKey="MobileApplication">
                <Website />
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
    </div>
  );
};

export default Management;
