import { Grid } from "@mui/material";
import Images from "../Images/Images";
import Carousel from "react-material-ui-carousel";
import { Paper, Button } from "@mui/material";
import { Container } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";


export const MainCarousel = () => {

  const history = useHistory();


  var items = [
    {
      image: Images.card1,
    },
    {
      image: Images.card2,
    },
    {
      image: Images.card3,
    },
    {
      image: Images.card4,
    },


  ];
  const { t, i18n } = useTranslation();

  return (
    <div className="carousel-center" >

      <Container>
        <Grid  >
          <div className="home-carousel-goal-heading" >
            {/* Explore our theme that you can configure to <br></br> meet you
            business needs */}
          </div>
          <div className="home-medium-content-h2 home-carousel-sub-heading" >
            {t('carousel.products')}
          </div>
        </Grid>
        <Carousel

          navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
            style: {
              marginTop: '-160px',
              "@media (max-width:600px)":
              {
                marginTop: '140px',
              }
            }
          }}

          navButtonsAlwaysVisible
          autoPlay={true}
          cycleNavigation={true}
          className="carousel-home-div"
          NextIcon={<img src={Images.oval_right} className="navButton" />}
          PrevIcon={<img src={Images.oval_left} className="navButton1" />}
          style={{ border: '1px solid red' }}
        >
          {items.map((item, i) => (
            <Item key={i} item={item}  />
          ))}
        </Carousel>
        <Button onClick={() => history.push("/learnmore")} className="StartNow header-Carousel-availableButton" >
         {t('carousel.seeOrders')}
        </Button>
      </Container>
    </div>
  );
};

function Item(props) {
  return (
    <Paper className="carousel-paper">
      <img src={props.item.image} alt="Images does not appear here" />
    </Paper>
  );
}
