import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createFaqAction } from "../../../UpdatedStore/actions/ManagementActions";
import SpinnerLoader from "../../../UpdatedStore/components/SpinnerLoader";
import { CREATE_FAQ_RESET } from "../../../UpdatedStore/constants/ManagementProductConstants";
import DeleteFaq from "./DeleteFaq";
import EditFaq from "./EditFaq";

const Faq = ({ site }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { sites } = useSelector((state) => state.SitesReducer);

  const {
    loading: loadingCreate,
    success: successCreate,
    error: errorCreate,
  } = useSelector((state) => state.CreateFaqReducer);

  const [title, setTitle] = useState("");
  const [answer, setAnswer] = useState("");
  const [submissionError, setSubmissionError] = useState("");
  const [show, setShow] = useState(false);
  const [showAvailable, setShowAvailable] = useState(false);

  const reverseShow = () => {
    setShow(!show);
  };

  const reverseShowAvailable = () => {
    setShowAvailable(!showAvailable);
  };

  const handleSubmit = () => {
    setSubmissionError("");
    if (title) {
      if (answer) {
        dispatch(
          createFaqAction({ site: site.name, title: title, answer: answer })
        );
      } else {
        setSubmissionError("Answer is Invalid");
      }
    } else {
      setSubmissionError("Title is Invalid");
    }
  };

  useEffect(() => {
    if (!site) history.push("/dashboard/management");

    if (successCreate) setMessageTimeOut();
  }, [site, history, successCreate, sites]);

  const setMessageTimeOut = () => {
    setTimeout(() => {
      setTitle("");
      setAnswer("");
      setShow(false);
      dispatch({ type: CREATE_FAQ_RESET });
    }, 2000);
  };

  return (
    <div className="faq">
      {/* <h4>
        Add Tips{" "}
        {show ? (
          <FaChevronCircleUp
            className="cursor transcale"
            onClick={reverseShow}
          />
        ) : (
          <FaChevronCircleDown
            className="cursor transcale"
            onClick={reverseShow}
          />
        )}
      </h4>

      {show && (
        <div className="AddRow">
          <Row>
            <Col>
              <Form.Group>
                <Form.Label className="text-muted font-weight-bold">
                  Question*
                </Form.Label>
                <Form.Control
                  value={title}
                  onChange={(e) => {
                    const arr = e.target.value.split(" ");
                    if (arr.length > 0) {
                      for (var i = 0; i < arr.length; i++) {
                        arr[i] =
                          arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                      }
                      const str2 = arr.join(" ");
                      setTitle(str2);
                    } else {
                      setTitle(
                        e.target.value.charAt(0).toUpperCase() +
                          e.target.value.slice(1)
                      );
                    }
                  }}
                  type="text"
                  placeholder="Question"
                  as="textarea"
                  rows="5"
                  style={{ resize: "none" }}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label className="text-muted font-weight-bold">
                  Answer*
                </Form.Label>
                <Form.Control
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                  as="textarea"
                  rows="5"
                  style={{ resize: "none" }}
                  placeholder="Answer"
                />
              </Form.Group>
            </Col>
          </Row>
          {submissionError && (
            <h5 className="text-danger text-center font-weight-bold">
              {submissionError}
            </h5>
          )}
          {errorCreate && (
            <h5 className="text-danger text-center font-weight-bold">
              {errorCreate}
            </h5>
          )}
          {successCreate && (
            <h5 className="text-success text-center font-weight-bold">
              Tip was added successfully
            </h5>
          )}
          <div className="btn-holder">
            {loadingCreate ? (
              <SpinnerLoader />
            ) : (
              <button
                className="_add-new-item cursor transcale"
                onClick={handleSubmit}
              >
                Save
              </button>
            )}
          </div>
        </div>
      )} */}

      {/* <h4>
        Available Tips{" "}
        {showAvailable ? (
          <FaChevronCircleUp
            className="cursor transcale"
            onClick={reverseShowAvailable}
          />
        ) : (
          <FaChevronCircleDown
            className="cursor transcale"
            onClick={reverseShowAvailable}
          />
        )}
      </h4> */}

      {/* {showAvailable && ( */}
        <div className="AddRow">
          {site && site.store_help.length <= 0 && (
            <h4 className="text-center tint font-weight-bold">
              Nothing to see here!
            </h4>
          )}

          {site &&
            site.store_help.map((help) => (
              <div className="tipBox">
                <h6 className="tint font-weight-bold">{help.title}</h6>{" "}
                <div>
                  <EditFaq site={site} faq={help} />
                  <DeleteFaq site={site} faq={help} />
                </div>
              </div>
            ))}
        </div>
      {/* )} */}
    </div>
  );
};

export default Faq;
