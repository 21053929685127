import React from 'react';
import Images from './Images/Images';
import { Link } from 'react-router-dom';
import { TextField, Button } from '@mui/material';
import { useTranslation } from "react-i18next";



export default function Footer() {
    const [open, setOpen] = React.useState(false);
    // const handleOpen = () => setOpen(true);



    function signUpMail(){
        var form = document.getElementById('sheetdb-form');
        form.addEventListener("submit", e => {
          e.preventDefault();
          fetch(form.action, {
              method : "POST",
              body: new FormData(document.getElementById("sheetdb-form")),
          }).then(
              response => response.json()
          ).then((html) => {
            // you can put any JS code here
            alert('Thanks for subscribing us ')
          });
        });
    }
    const { t, i18n } = useTranslation();
    return (
        <div>
            <div className='footer_container ' >
                <div className='center'>
                    <img src={Images.OneStopShop} alt="" className='margin-one' />
                </div>
                <div className='centering'>
                    <ul className='footer_ul'>
                        <li className='footer_li'>
                            <Link to='/' className='footer_link' >{t('footer_container.home')}</Link>
                        </li>
                        <li className='footer_li'>
                            <Link to='/about' className='footer_link' >{t('footer_container.about')}</Link>

                        </li>
                        <li className='footer_li'>
                            <Link to='/pricing' className='footer_link' >{t('footer_container.pricing')}</Link>
                        </li>
                        <li className='footer_li'>
                            <Link to='/learnmore' className='footer_link' >{t('footer_container.learn_more')}</Link>
                        </li>
                        <li className='footer_li'>
                            <Link to='/faq' className='footer_link' >{t('footer_container.help')}</Link>
                        </li>
                    </ul>
                </div>

                <div className='centering'>
                    <ul className='footer_ul'>
                        <li className='footer_li'><img src={Images.fb} alt="" className='margin-one social-icon' /></li>
                        <li className='footer_li'><img src={Images.insta} alt="" className='margin-one social-icon' /></li>
                        <li className='footer_li'><img src={Images.twitter} alt="" className='margin-one social-icon' /></li>
                    </ul>
                </div>

                <div className='centering'>
                    {/* <TextField
                        type="input"
                        placeholder="Enter your email address"
                        size="small"
                        name="email"
                        className="footer_sign_textfield"
                        onChange={(e) => { setEmail(e.target.value) }}
                    /> */}
                    {/* <Button className="footer_sign_button" >sign up now</Button> */}

                    <form method="post" id="sheetdb-form"
                        action="https://sheetdb.io/api/v1/uozq2k8n279s1">
                        {/* Email: <input type="text" name="data[Email]" /> */}

                        <TextField
                        type="input"
                        placeholder={t('footer_container.placeholder')}
                        size="small"
                        name="data[Email]"
                        className="footer_sign_textfield"
                       
                    />
                            {/* <button type="submit">Submit</button> */}
                            <Button type="submit" className="footer_sign_button" onClick={signUpMail}>{t('footer_container.signUp')}</Button>
                    </form>

                    {/* <script>
                        var form = document.getElementById('sheetdb-form');
  form.addEventListener("submit", e => {
                            e.preventDefault();
                        fetch(form.action, {
                            method : "POST",
                        body: new FormData(document.getElementById("sheetdb-form")),
    }).then(
        response => response.json()
    ).then((html) => {
                            // you can put any JS code here
                            alert('Thanks for subscribing us ')
                        });
  });
                    </script> */}


                </div>
                <div className='horizonLining'>
                    <hr className='hr_line' />
                    <span className='privacy_terms'>{t('footer_container.horizonLining')}</span>
                    <span className='float-right privacy_terms'>{t('footer_container.privacy')}</span>
                </div>
            </div>

        </div>
    )
}
