import {
  NEW_LAYOUT_REQUEST,
  NEW_LAYOUT_SUCCESS,
  NEW_LAYOUT_EDIT_SUCCESS,
  COMPONENT_DELETE,
  NEW_LAYOUT_FAIL,
  EDIT_NODE_SUCCESS,
  BUILDER_CONFIRM_SUCCESS,
  BUILDER_CONFIRM_FAIL,
  CREATE_SITE_REQUEST,
  CREATE_SITE_SUCCESS,
  CREATE_SITE_FAIL,
} from "../constants/ApiConstants";
import {BASE_URL} from "../../utils/index"

const domain_url = BASE_URL;

export const createLayoutAction = (newItem) => async (dispatch, getState) => {
  try {
    let { layout } = getState().NewLayoutReducer;
    dispatch({ type: NEW_LAYOUT_REQUEST });

    const existItem = layout.find((node) => node.type === newItem.type);

    if (existItem) {
      dispatch({ type: NEW_LAYOUT_SUCCESS, payload: layout });
    }

    if (!existItem) {
      layout.push(newItem);
      dispatch({ type: NEW_LAYOUT_SUCCESS, payload: layout });
    }
  } catch (error) {
    dispatch({
      type: NEW_LAYOUT_FAIL,
      payload: error,
    });
  }
};

export const deleteNodeAction = (node) => async (dispatch, getState) => {
  try {
    let { layout } = getState().NewLayoutReducer;

    const existItem = layout.find((nodee) => nodee.type === node.type);

    if (existItem) {
      let newLayout = layout.filter((iNode) => iNode.type !== node.type);
      dispatch({ type: COMPONENT_DELETE, payload: newLayout });
    }

    if (!existItem) {
      dispatch({ type: COMPONENT_DELETE, payload: layout });
    }
  } catch (error) {
    dispatch({
      type: NEW_LAYOUT_FAIL,
      payload: error,
    });
  }
};

export const EditNewLayoutAction = (newList) => async (dispatch) => {
  try {
    dispatch({ type: NEW_LAYOUT_REQUEST });

    dispatch({ type: NEW_LAYOUT_EDIT_SUCCESS, payload: newList });
  } catch (error) {
    dispatch({
      type: NEW_LAYOUT_FAIL,
      payload: error,
    });
  }
};

export const UpdateNodeAction = (object) => async (dispatch, getState) => {
  try {
    let { layout } = getState().NewLayoutReducer;

    const NewLayout = layout.map((node) => {
      if (node.type === object.type) {
        return object;
      } else {
        return node;
      }
    });

    dispatch({ type: NEW_LAYOUT_REQUEST });

    dispatch({ type: EDIT_NODE_SUCCESS, payload: NewLayout });
  } catch (error) {
    dispatch({
      type: NEW_LAYOUT_FAIL,
      payload: error,
    });
  }
};

export const checkUser = (token) => async (dispatch) => {
  try {
    var url = domain_url + "/merchant/authenticate/builder/";
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error(
        data.detail ? data.detail : "Request Aborted, Try Again!"
      );
    }

    dispatch({ type: BUILDER_CONFIRM_SUCCESS, payload: true, user: data });
  } catch (error) {
    dispatch({
      type: BUILDER_CONFIRM_FAIL,
      payload: false,
    });
  }
};

export const createSiteAction = (object, token) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_SITE_REQUEST });

    var url = domain_url + "/merchant/create/site/";
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(object),
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error(
        data.detail ? data.detail : "Request Aborted, Try Again!"
      );
    }

    dispatch({
      type: CREATE_SITE_SUCCESS,
      payload: true,
      message: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_SITE_FAIL,
      payload: false,
      message:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
