import React, { useEffect, useState } from "react";
import "./website.scss";
import notFound from "../../../assets/icons/notfound.png";
import { useSelector, useDispatch } from "react-redux";
import { GetSitesAction } from "../../../UpdatedStore/actions/SiteActions";

import { Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import SpinnerLoader from "../../../UpdatedStore/components/SpinnerLoader";
import Message from "../../../UpdatedStore/components/Message";
import { FaDownload } from "react-icons/fa";
import { GenerateApkAction } from "../../../UpdatedStore/actions/ManagementActions";
import { GENERATE_APK_RESET } from "../../../UpdatedStore/constants/ManagementsConstants";

export const Website: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { loading, sites, error } = useSelector((state) => state.SitesReducer);

  const {
    loading: loadingGeneration,
    error: errorGeneration,
    success: successGeneration,
  } = useSelector((state) => state.createApkReducer);

  const { user } = useSelector((state) => state.AuthenticationReducer);

  const [buildLoading, setLoading] = useState(false);

  const buildFunctionality = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      history.push("/store/builder");
    }, 3000);
  };

  const generateHandler = (site) => {
    if (!loadingGeneration) {
      dispatch(GenerateApkAction({ site: site.name }));
    }
  };

  useEffect(() => {
    if (!sites) {
      dispatch(GetSitesAction(user.access_token));
    }
    if (successGeneration) {
      setMessageTimeOut();
    }
  }, [sites, successGeneration]);

  const setMessageTimeOut = () => {
    dispatch({ type: GENERATE_APK_RESET });
  };

  return (
    <>
      {loading && <SpinnerLoader />}
      {!loading && (
        <>
          {sites && sites.length === 0 && (
            <div className="_management-website">
              <div className="_hold-pic">
                <Image src={notFound} alt="not found picture" />
              </div>

              <div className="_info">
                <h3>You don’t have any of this platform type</h3>
                {buildLoading && <SpinnerLoader />}
                {!buildLoading && (
                  <button
                    className="_add-new-platform"
                    onClick={buildFunctionality}
                  >
                    Add new platform
                  </button>
                )}
              </div>
            </div>
          )}
        </>
      )}

      {error && <Message variant="danger">{error}</Message>}
      {errorGeneration && <Message variant="danger">{errorGeneration}</Message>}
      {!loading && (
        <>
          {sites && sites.length > 0 && (
            <div className="_management-all_mobile-apps">
              {sites.map((site, index) => (
                <div className="boxMember">
                  <h4 className="tint font-weight-bold mb-4">{site.name}</h4>
                  {site.mobile_application.length <= 0 && (
                    <button
                      onClick={() => generateHandler(site)}
                      disabled={loadingGeneration}
                    >
                      {loadingGeneration ? "Generating..." : "Generate Apk"}
                    </button>
                  )}
                  {site.mobile_application.length > 0 && (
                    <a href={site.mobile_application[0].apk} download>
                      <button>
                        Download Apk{" "}
                        <FaDownload className="ml-2 cursor transcale" />
                      </button>
                    </a>
                  )}
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};
