import { Container } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import "./inventory.scss";
import AllItems from "./holdStore/allItems/AllItems";
import MainScreen from "./siteManagement/MainScreen";

import InventorySideBar from "./sideBar/InventorySideBar.js";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Search from "./search/Search";
import Filter from "./filterMangament/Filter";
import Sort from "./sortMangament/Sort";
import {
  GetProductsAction,
  GetPromotionsAction,
} from "../../UpdatedStore/actions/ProductManagementActions";

const Inventory: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.AuthenticationReducer);
  const { sites } = useSelector((state) => state.SitesReducer);

  const [siteData, setSiteData] = useState(null);

  useEffect(() => {
    const currentUrl = history.location.search;
    const urlParams = new URLSearchParams(currentUrl);
    const node = urlParams.get("site");

    if (!node) {
      history.push("/dashboard/management");
    }

    if (sites) {
      var fetched_site = sites && sites.find((site) => site.name === node);
      setSiteData(fetched_site);
      dispatch(GetProductsAction(siteData));
      dispatch(GetPromotionsAction(siteData));
    }

    if (!sites) {
      history.push("/dashboard/management");
    }

    if (!user) {
      history.push("/");
    }
  }, [sites, siteData, history]);
  return (
    <Container fluid>
      <React.Suspense fallback={<div> Loading</div>}>
        <div className="_hold-inventory">
          <InventorySideBar />
          <div className="_inventory-items-wrapper">
            <Switch>
              <Route path="/inventory/all-items">
                <div className="_hold-nav">
                  <Search />
                  <Filter site={siteData} />
                  <Sort />
                </div>

                <AllItems siteData={siteData} />
              </Route>
              <Route path="/inventory/management">
                <MainScreen site={siteData} />
              </Route>
              <Redirect from="/" to="/inventory/all-items" />
            </Switch>
          </div>
        </div>

        {/* <AnSidebar /> */}
      </React.Suspense>
    </Container>
  );
};

export default Inventory;
