import React, { useEffect } from "react";
import "./Login.scss";
import { Row, Col, Image, Figure, Container } from "react-bootstrap";
import AuthPhoto from "../../assets/icons/authPhoto.svg";
import logo from "../../assets/icons/logo.svg";
import LoginForm from "./LoginForm";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Login: React.FC = () => {
  const { user } = useSelector((state) => state.AuthenticationReducer);

  const history = useHistory();

  useEffect(() => {
    if (user) {
      history.push("/dashboard");
    }
  }, [user, history]);

  return (
    <Container>
     
      <div className="_login_page">
    
        <Row>
        <span onClick={() => history.push("/")}  className="pushBackButton">
           <ArrowBackIcon sx={{fontSize:'40px', }} /> 
        </span>
          <Col className="login-section">
            <div className="app_login_title" >
              <Figure>
                <Image src={logo} />
              </Figure>
              <h1>Grow your business by selling online</h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
            </div>

            <Figure>
              <Image src={AuthPhoto} />
            </Figure>
          </Col>

          <Col className="_form_wrapper">
            <LoginForm />
          </Col>
        </Row>
      </div>
    </Container>
  );
};
export default Login;
