import { useEffect, useState } from "react";
import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa";
import AddDeliveryItem from "./AddDeliveryItem";
import DeleteDeliveryItem from "./DeleteDeliveryItem";
import EditDeliveryItem from "./EditDeliveryItem";

const DeliveryMethods = ({ site }) => {
  const [open, setOpen] = useState(false);

  const changeOpenState = () => {
    setOpen((currentState) => !currentState);
  };

  useEffect(() => {}, [site]);
  return (
    <div className="hold-Product_types">
      <div className="_dropdown">
        <h2 className="tint">
          Delivery Methods{" "}
          {open ? (
            <FaChevronCircleUp
              className="tint cursor transcale"
              onClick={changeOpenState}
            />
          ) : (
            <FaChevronCircleDown
              className="tint cursor transcale"
              onClick={changeOpenState}
            />
          )}
        </h2>
        {open && (
          <div className="px-2">
            {site.delivery_methods.map((type_) => (
              <div className="holdType__">
                <div>{type_.title}</div>{" "}
                <EditDeliveryItem type={type_} site={site} />
                <DeleteDeliveryItem type={type_} site={site} />
              </div>
            ))}
          </div>
        )}
      </div>
      {open && (
        <div className="_add-new-catalog">
          <AddDeliveryItem site={site} />
        </div>
      )}
    </div>
  );
};

export default DeliveryMethods;
