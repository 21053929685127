import React, { useState } from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { BsArrowsMove } from "react-icons/bs";
import { FaTrash, FaPencilAlt } from "react-icons/fa";
import { Draggable } from "react-beautiful-dnd";
import { deleteNodeAction } from "../../actions/ApiAction";
import { SHOW_MODAL_STATE } from "../../constants/ApiConstants";
import { useDispatch } from "react-redux";
import {
  InputBase,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Hidden,
  Drawer,
  Box,
} from "@material-ui/core";

import ShoppingCart from "@material-ui/icons/ShoppingCart";
import SearchIcon from "@material-ui/icons/Search";
import MenuIcon from "@material-ui/icons/Menu";
import SignIn from "./SignIn";

const _style = makeStyles(function (theme) {
  return {
    grow: {
      flexGrow: 1,
    },
    // root: (props) => ({
    //   background: props.bgColor,
    // }),
    root: function (props) {
      return {
        background: props.bgColor,
      };
    },
    title: (props) => ({
      color: props.titleColor,
      fontWeight: 600,
      marginRight: "30px",
      [theme.breakpoints.down("sm")]: {
        margin: "1rem auto 1rem 1rem",
      },
    }),
    menuButton: {
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    button: {
      marginLeft: "auto",
      marginRight: "10px",
    },
    navlink: {
      margin: theme.spacing(2),
      color: "#090909",
    },
    appBarDisplay: {
      display: "block",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: "#465F5A",
      "&:hover": {
        backgroundColor: "#465F5A",
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    shoppingCart: {
      margin: "auto 1rem",
      color: "#090909",
    },
    // toolbar: theme.mixins.toolbar, //toobar show below app bar
  };
});

export default function NavBar({ window, nodeData }) {
  let classes = _style(nodeData.styles);

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <List>
        {nodeData.data.navlinks.map((item, index) => (
          <ListItem button key={item.name + index}>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
        <ListItem button key="login">
          <ListItemText primary="Login/Sign up" />
        </ListItem>
      </List>
    </div>
  );

  const dispatch = useDispatch();

  const deleteNodeHandler = (node) => {
    dispatch(deleteNodeAction(node));
  };

  const EditFunctionality = () => {
    dispatch({ type: SHOW_MODAL_STATE, payload: nodeData });
  };

  return (
    <Draggable key={nodeData.id} draggableId="Navbar" index={nodeData.id}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={(classes.grow, "componentBuildBox")}
        >
          <div {...provided.dragHandleProps} className="cmpMoveIcon">
            <BsArrowsMove className="cmp" />
          </div>
          <div className="deleteFlex">
            <div className="editIDiv" onClick={EditFunctionality}>
              <FaPencilAlt className="editIcon" />
            </div>
            <div
              className="deleteIDiv"
              onClick={() => deleteNodeHandler(nodeData)}
            >
              <FaTrash className="deleteIcon" />
            </div>
          </div>
          <AppBar position="static" className={classes.root}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" className={` ${classes.title}`}>
                {nodeData.data.title}
              </Typography>

              {nodeData.data.navlinks.map((item, index) => {
                return (
                  <Box
                    className={`${classes.navlink} ${classes.appBarDisplay}`}
                    key={index}
                  >
                    {item.name}
                  </Box>
                );
              })}
              <div className={`${classes.search} ${classes.appBarDisplay}`}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                />
              </div>
              <div className={`${classes.button} ${classes.appBarDisplay}`}>
                <SignIn />
              </div>
              <ShoppingCart className={classes.shoppingCart} />
            </Toolbar>
          </AppBar>
          <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor="left"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
        </div>
      )}
    </Draggable>
  );
}
