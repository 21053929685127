import React from "react";
import Images from "../component/Images/Images";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, Button, Container } from "@mui/material";
import Process from "../component/process";
import Header from "../component/Header";
import ModalImage from './ModalImage';
import { useState } from "react";
import { useTranslation } from "react-i18next";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};



export default function LearnMore() {
 const [ImageNumber, setImageNumber] = useState('');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCardClick = (img) => {
    handleOpen()
    console.log('this is image', img)
    setImageNumber(img)
  }

  const accordion = [
    {
      title: "Creating your own platform",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam molestias ducimus natus, eveniet laboriosam voluptate repellat dolorem voluptates cupiditate sit aliquid numquam quaerat beatae incidunt tempore eligendi modi quas vero.",
      listitem: [],
    },
    {
      title: "Choosing the right color scheme",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam molestias ducimus natus, eveniet laboriosam voluptate repellat dolorem voluptates cupiditate sit aliquid numquam quaerat beatae incidunt tempore eligendi modi quas vero.",
      listitem: [],

    },
    {
      title: "Uploading your first content and items",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam molestias ducimus natus, eveniet laboriosam voluptate repellat dolorem voluptates cupiditate sit aliquid numquam quaerat beatae incidunt tempore eligendi modi quas vero.",
      listitem: [],
    },
    {
      title: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam molestias ducimus natus, eveniet laboriosam voluptate repellat dolorem voluptates cupiditate sit aliquid numquam quaerat beatae incidunt tempore eligendi modi quas vero.",
      listitem: [
        "consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
        "et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation",
        "consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
        "et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation",
      ],
    },
    {
      title: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam molestias ducimus natus, eveniet laboriosam voluptate repellat dolorem voluptates cupiditate sit aliquid numquam quaerat beatae incidunt tempore eligendi modi quas vero.",
      listitem: [],
    },
    {
      title: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam molestias ducimus natus, eveniet laboriosam voluptate repellat dolorem voluptates cupiditate sit aliquid numquam quaerat beatae incidunt tempore eligendi modi quas vero.",
      listitem: [],
    },
    {
      title: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam molestias ducimus natus, eveniet laboriosam voluptate repellat dolorem voluptates cupiditate sit aliquid numquam quaerat beatae incidunt tempore eligendi modi quas vero.",
      listitem: [],
    },
    {
      title: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam molestias ducimus natus, eveniet laboriosam voluptate repellat dolorem voluptates cupiditate sit aliquid numquam quaerat beatae incidunt tempore eligendi modi quas vero.",
      listitem: [],
    },
    {
      title: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam molestias ducimus natus, eveniet laboriosam voluptate repellat dolorem voluptates cupiditate sit aliquid numquam quaerat beatae incidunt tempore eligendi modi quas vero.",
      listitem: [],
    },
  ];
  const learnmore_card_data = [

    {
      img: Images.cardImage1,
      card_title: 'learn_more.fukushimaTheme',
      card_discription: 'learn_more.fukushimaDescription',
      card_buttom: [
        {
          title: "primaryButton.cardButton",
        },
        {
          title: "primaryButton.accessories",
        },
      ],
      modalimg: Images.cardImage1,
    },

    {
      img: Images.cardImage2,
      card_title: 'learn_more.helsinskyTheme',
      card_discription: 'learn_more.helsinskyThemeDescription',
      card_buttom: [
        {
          title:"primaryButton.sporty",
        },
        {
          title:"primaryButton.clothing",
        },
      ],
      modalimg: Images.cardImage2,
    },

    {
      img: Images.cardImage3,
      card_title: 'learn_more.quebecTheme',
      card_discription: 'learn_more.quebecThemeDescription',
      card_buttom: [
        {
          title:"primaryButton.clasic&simple",
        },
        {
          title:"primaryButton.utilities",
        },
      ],
      modalimg: Images.cardImage3,
    },

    {
      img: Images.cardImage4,
      card_title: 'learn_more.nairobiTheme',
      card_discription: 'learn-more.nairobiThemeDescription',
      card_buttom: [
        {
          title: "primaryButton.modern",
        },
        {
          title:"primaryButton.fashion",
        },
      ],
      modalimg: Images.cardImage4,
    },

  ];
 

  const { t, i18n } = useTranslation();
  return (
    <div>
      <Header />
      <div className="learnmore-header">
        <div className="learnmore-headings">
          <p className="main-heading">
           {t('learn_more.configureBusiness')}
          </p>
          <p className="submain-heading">
           {t('learn_more.requireeElectronicStore')}
           </p>
        
        </div>

        <Container>
          <div className="learnmore-theme-card" >
            {learnmore_card_data.map((card_data_item, i) => (

              <Box sx={{ maxWidth: 500 }} >
                <Card className="learnmore-cardimages">

                  {/* <Button onClick={handleCardClick}/> */}
                  {/* {console.log('hell',ImageNumber)} */}

                  <Button onClick={() => handleCardClick(card_data_item.img)}>
                    <CardMedia
                      component="img"
                      image={card_data_item.img}
                      alt="green iguana"
                    />
                  </Button>
                  {open && <ModalImage modal_img={ImageNumber} open={open} handleClose={handleClose} />}

                </Card>
                <div className="learnmore-card-content">
                  <Typography gutterBottom component="div" className="lizard">
                    {/* {card_data_item.card_title} */}
                    {t(card_data_item.card_title)}
                  </Typography>
                  <Typography className="lizard-desc">
                    {t(card_data_item.card_discription)}
                  </Typography>
                </div>
                <div className="Learnmore-Cards-Button" >
                  {card_data_item.card_buttom.map((buttom_item, i) => (
                  <div>
                      <Button className="Card-button"> {t(buttom_item.title)} </Button>
                    </div>
                  ))}
                </div>
              </Box>
            ))}

          {/* {learnmore_card_data.map((card_data_item, i) => ( */}
            {/* <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  <CardMedia
                    component="img"
                    image={ImageNumber}
                    alt={ImageNumber}
                  />
                </Typography>
              </Box>
            </Modal> */}
            {/* ))} */}


          </div>
        </Container>

      </div>
      <div className="learnMore-sellingproduct">
        <div>
          <Process name={t('learn_more.process')} />
        </div>
        {/* <Container maxWidth="xl">
          <div>
            {accordion.map((item, id) => {
              return accordion.length === 1 ? (
                <Accordion
                  className=".MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.accord_radius_new.css-1wc0u5o-MuiPaper-root-MuiAccordion-root"
                  disableGutters
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="accordian-title">
                      {item.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="accord_desc">
                      {item.description}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ) : (
                <Accordion className="accord_radius" disableGutters>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="accordian-title">
                      {item.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="accord_desc">
                      {item.description}
                      <ol>
                        {item.listitem.map((listitem_data, i) => (

                          <li>{listitem_data}</li>
                        ))}

                      </ol>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </Container> */}
      </div>
    </div>
  );
}
