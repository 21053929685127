import Images from "../component/Images/Images";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Modal from '@mui/material/Modal';
import { Box } from "@mui/material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  textAlign:'center',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};



export default function ModalImage({ modal_img, open, handleClose }) {

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      className="modal-width">
        <Box sx={style} className="box-width">
          <Typography id="modal-modal-title" variant="h6" component="h2" >
            <CardMedia
              component="img"
              image={modal_img}
              alt={"not found"} className="card-component"
            />
         
              <img className="ModalClose image-modal-size" src={Images.cancel} alt="hell" onClick={handleClose}/>
       
          </Typography>
        </Box>
      </Modal>

    </>
  );

}