import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { FormControl, InputBase } from "@mui/material";
import styled from "@mui/system/styled";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@material-ui/core/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Button, Container } from "@mui/material";
import Header from "../component/Header";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import { useTranslation } from "react-i18next";

// import Images from '../component/Images/Images'
import Images from "../component/Images/Images"



const BootstrapInput = styled(InputBase)(({ theme }) => ({
  marginTop: "20px",
  "label + &": {

    color: "#474848",
    fontSize: 14,
  },
  "& .MuiInputBase-input": {
    fontFamily: "Montserrat",
    borderRadius: 8,
    border: "1px solid #DCDEE3",

    backgroundColor: "#FFFFFF",
    fontSize: 14,

    width: "860px",

    padding: "10px 20px",
    color: "#9AA1AF",
  },

  "@media screen and (max-width: 600px)": {
    "& .MuiInputBase-input": {
      fontFamily: "Montserrat",
      borderRadius: 8,
      border: "1px solid #DCDEE3",
      backgroundColor: "#FFFFFF",
      fontSize: 14,
      width: "160px",
      padding: "10px 20px",
      color: "#9AA1AF",
      marginLeft:"-8px",
    },
  },
}));

export default function Help(props) {

  function mailSend() {
    var form = document.getElementById('sheetdb-form');
    form.addEventListener("submit", e => {
      e.preventDefault();
      fetch(form.action, {
        method: "POST",
        body: new FormData(document.getElementById("sheetdb-form")),
      }).then(
        response => response.json()
      ).then((html) => {
        alert('Thank you!')
      });
    });
  }

  const buttonList = [
    {
      id: "1",
      name: "buttonlist.account",
      game: ""
    },
    {
      id: "2",
      name: "buttonlist.lorem",
      game: ""
    },
    {
      id: "3",
      name: "buttonlist.epsome",
      game: ""
    },
    {
      id: "4",
      name: "buttonlist.loremButton",
      game: ""
    },
    {
      id: "5",
      name: "buttonlist.account",
      game: ""
    },
    {
      id: "6",
      name: "buttonlist.creating",
      game: ""
    },
    {
      id: "7",
      name: "buttonlist.epsome",
      game: ""
    },
    {
      id: "8",
      name: "buttonlist.loremButton",
      game: ""
    },
    {
      id: "9",
      name: "buttonlist.account",
      game: ""
    },
    {
      id: "10",
      name: "buttonlist.lorem",
      game: ""
    },
    {
      id: "11",
      name: "buttonlist.uploading",
      game: ""
    },
    {
      id: "12",
      name: "buttonlist.loremButton",
      game: ""
    },

  ];

  // accordion data
  const accordion = [
    {
      title: "frequently_asked_questions.shopRelatedQuestion",
      description: "frequently_asked_questions.shopRelatedQuestionAns",
      nothing: "nothing Found",
      listitem: [],
    },
    {
      title: "frequently_asked_questions.serviceRelatedQuestion",
      description:"frequently_asked_questions.serviceRelatedQuestionAns",
      nothing: "nothing Found",
      listitem: [],

    },
    {
      title: "frequently_asked_questions.platformRelatedQuestion",
      description: "frequently_asked_questions.platformRelatedQuestionAns",
      nothing: "nothing Found",
      listitem: [],
    },
    {
      title: "frequently_asked_questions.howToSubscribe",
      description:
        "frequently_asked_questions.howToSubscribeAns",
      listitem: [],
    },
    {
      title: "frequently_asked_questions.priceRelatedQuestion",
      description: "frequently_asked_questions.priceRelatedQuestionAns",
      nothing: "nothing Found",
      listitem: [],
    },
    {
      title: "frequently_asked_questions.paymentRelatedQuestion",
      description: "frequently_asked_questions.paymentRelatedQuestionAns",
      nothing: "nothing Found",
      listitem: [],
    },
    {
      title: "frequently_asked_questions.price",
      description:"frequently_asked_questions.priceAns",
      nothing: "nothing Found",
      listitem: [],
    },
    // {
    //   title: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
    //   description:
    //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam molestias ducimus natus, eveniet laboriosam voluptate repellat dolorem voluptates cupiditate sit aliquid numquam quaerat beatae incidunt tempore eligendi modi quas vero.",
    //   nothing: "nothing Found",
    //   listitem: [],
    // },
    // {
    //   title: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
    //   description:
    //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam molestias ducimus natus, eveniet laboriosam voluptate repellat dolorem voluptates cupiditate sit aliquid numquam quaerat beatae incidunt tempore eligendi modi quas vero.",
    //   nothing: "nothing Found",
    //   listitem: [],
    // },
  ];
  const [searchValue, setValue] = useState(accordion)
  const [searchTerm, setSearchTerm] = useState('');

  const serachHandler = (value) => {
    const result = accordion.filter((val) => {
      return val.title.toLowerCase().includes(value.toLocaleLowerCase())

    })
    setValue(result)
    if (value === "") {
      setValue(accordion)
    }
  }

  const searchHandler1 = (event) => {

    serachHandler(event.target.value)
    var element = document.getElementById(event.target.id);

    buttonList.map((b) => {
      var element1 = b.id;
      var element2 = document.getElementById(element1)

      if (event.target.id == b.id) {
        element.classList.add("kk");
      }
      else {
        element2.classList.remove("kk");
      }
    })
  }


  function isEmpty() {

    let username = document.getElementById("username").value;
    let password = document.getElementById("email").value;
    let inquiry = document.getElementById("inquiry").value;

    if (username != "" && password != "" && inquiry != "") {
      document.getElementById("btn").classList.add("enquryFieldButtonColor")
    } if (username == "" | password == "" | inquiry == "") {
      document.getElementById("btn").classList.remove("enquryFieldButtonColor")
    }
  }
  const { t, i18n } = useTranslation();


  return (
    <>
      <Header />
      <div className="faq-centeralize">
        <Container maxWidth="xl">
          <div className="f_heading">
            <p>{t('help.inquires')}</p>
          </div>
          <div className="faqForm">
            <Box
              component="form"
              autoComplete="off"
            >
              <div>
                <BootstrapInput
                  type="input"
                  placeholder={t('help.searchPlaceholder')}
                  size="small"
                  className="searchField"
                  hiddenLabel={true}
                  label=""
                  id="myInput"
                  onChange={e => { serachHandler(e.target.value) }}
                />

                <Button variant="" className="searchButton">
                  {t('help.search')}
                </Button>
              </div>
              <Box mt={3} className="box-alignment-mobile">
                {buttonList.filter((val) => {
                  if (val.name.toLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                    return val
                  }
                }).map((item) => {
                  return (
                    <Button variant="" className="buttonAfterSearch" value={item.name} onClick={(event) => { searchHandler1(event) }} id={(item.id)}>
                      {t(item.name)}
                    </Button>
                  );
                  // )
                })}
              </Box>
            </Box>
            <br />
          </div>
        </Container>

        <Container maxWidth="xl" id="cont">

          {
            searchValue.map((item, id) => {
              return (
                <Accordion className="accord_radius" disableGutters>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="accordian-title">
                      {t(item.title)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="accord_desc">
                      {t(item.description)}
                      <ol>
                        {item.listitem.map((listitem_data, i) => (

                          <li>{listitem_data}</li>
                        ))}

                      </ol>
                    </Typography>
                  </AccordionDetails>
                  <div>

                  </div>
                </Accordion>
              );
            })
          }

        </Container>

        {searchValue.length === 0 && (
          <>
            <div id="myDIV2" class="mt-3 SearchStyling">

              <Container maxWidth="xl">
                <img src={Images.not_found_image} />
                  {t('help.searchFound')}
                  {t('help.keywords')}
                {/* <h2>Your search can’t be found</h2> */}
                {/* <p>Please make sure your keyword is correct or try another keywords</p> */}
              </Container>
            </div>
          </>
        )}
        {/* Enqyuiry Form */}
        <div className="faqEnquiry">
          <Box component="form" autoComplete="off">
            <Typography variant="h1" className="enquiryText">
              {t('help.inquires')}
            </Typography>
            <div>

              <form method="post" id="sheetdb-form"
                action="https://sheetdb.io/api/v1/jpr3ideyu3uwx">

                <FormControl variant="standard">
                  <BootstrapInput
                    defaultValue=""
                    id="username"
                    name="data[Name]"
                    placeholder={t('help.namePlaceholder')}
                    onKeyUp={() => { isEmpty() }}
                  />

                  <BootstrapInput
                    defaultValue=""
                    id="email"
                    name="data[Email]"
                    placeholder={t('help.emailPlaceholder')}
                    onKeyUp={() => { isEmpty() }}
                  />

                  <BootstrapInput
                    type="input"
                    name="data[Inquiry]"
                    id="inquiry"
                    placeholder={t('help.inquiries')}
                    size="larger"
                    className="enquiryField"
                    multiline="true"
                    minRows="6"
                    onKeyUp={() => { isEmpty() }}
                  />
                </FormControl>

                <Button type="submit" id="btn" className="enquryFieldButton" onClick={mailSend}>
                  {t('help.submit')}
                </Button>
              </form>
            </div>
          </Box>
        </div>
      </div>


    </>
  );
}
