import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { FaFileExport } from "react-icons/fa";

const ItemsSummary = (props) => {
  const [csvData, setCsvData] = useState([]);

  const loadCsv = () => {
    let orders = props.orders;
    let ListData = [
      [
        "#ID",
        "PRODUCT NAME",
        "PRICE",
        "DISCOUNT PRICE",
        "QUANTITY AVAILABLE",
        "QUANTITY SOLD",
      ],
    ];

    props.products.map((productItem) => {
      let quantityX = orders.orders.map((site_orders_) => {
        return site_orders_;
      });

      let updatedValues = quantityX.map((singleOrder) => {
        let listUpdatedItems = singleOrder.orders.filter(
          (orderItem) => orderItem.product === productItem._id
        );

        let sold = listUpdatedItems.reduce((acc, itemk) => acc + itemk.qty, 0);

        return sold;
      });

      let x = [
        productItem._id,
        productItem.name,
        `$ ${Number(productItem.price).toFixed(2)}`,
        0,
        productItem.quantity,
        updatedValues,
      ];
      ListData.push(x);
    });
    props.promotions.map((productItem) => {
      let quantityX = orders.orders.map((site_orders_) => {
        return site_orders_;
      });

      let updatedValues = quantityX.map((singleOrder) => {
        let listUpdatedItems = singleOrder.orders.filter(
          (orderItem) => orderItem.product === productItem._id
        );

        let sold = listUpdatedItems.reduce((acc, itemk) => acc + itemk.qty, 0);

        return sold;
      });

      let x = [
        productItem._id,
        productItem.name,
        `$ ${Number(productItem.CurrentPirce).toFixed(2)}`,
        `$ ${Number(
          Number(productItem.RealPrice) - Number(productItem.CurrentPirce)
        ).toFixed(2)}`,
        productItem.quantity,
        updatedValues,
      ];
      ListData.push(x);
    });
    setCsvData(ListData);
  };

  useEffect(() => {
    loadCsv();
  }, [props.name]);
  return (
    <CSVLink data={csvData} filename={"store-item-summary.csv"}>
      Export Data
      <FaFileExport className="ml-2" />
    </CSVLink>
  );
};

export default ItemsSummary;
