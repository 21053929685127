import React, { useEffect, useState } from "react";
import { HoldCards } from "./holdCards/HoldCards";
import "./allPlatforms.scss";
import { useSelector, useDispatch } from "react-redux";
import { GetSitesAction } from "../../../UpdatedStore/actions/SiteActions";
import Loader from "../../../UpdatedStore/components/Loader";
import Message from "../../../UpdatedStore/components/Message";
import "../website/website.scss";
import notFound from "../../../assets/icons/notfound.png";
import { Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import SpinnerLoader from "../../../UpdatedStore/components/SpinnerLoader";
import NoStaffInThisRole from "../../order/noStaffAddOne/NoStaffInThisRole";

export const AllPlatforms: React.FC = (props) => {
  const { loading, sites, error } = useSelector((state) => state.SitesReducer);
  const { user } = useSelector((state) => state.AuthenticationReducer);

  const [buildLoading, setLoading] = useState(false);
  const history = useHistory();
  const buildFunctionality = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      history.push("/store/builder");
    }, 3000);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (!sites) {
      dispatch(GetSitesAction(user.access_token));
    }
  }, [sites]);

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          {sites && sites?.length === 0 && (
            <div className="_management-website">
              <div className="_hold-pic">
                <Image src={notFound} alt="not found picture" />
              </div>

              <div className="_info">
                <h3>You don’t have any of this platform type</h3>
                {buildLoading && <SpinnerLoader />}
                {!buildLoading && (
                  <button
                    className="_add-new-platform"
                    onClick={buildFunctionality}
                  >
                    Add new platform
                  </button>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {error && <Message variant="danger">{error}</Message>}
      {!loading && (
        <>
          {sites && sites.length > 0 && (
            <>
              <div className="_management-all-platfrom">
                {props.filteredData !== "" && props?.filteredData
                  ? props?.filteredData.map((site, index) => (
                      <HoldCards site={site} key={index} />
                    ))
                  : sites.map((site, index) => (
                      <HoldCards site={site} key={index} />
                    ))}
              </div>
              {props.filteredData !== "" &&
                props?.filteredData &&
                props?.filteredData.length === 0 && (
                  <NoStaffInThisRole text="Nothing to see here!" />
                )}
            </>
          )}
        </>
      )}
    </>
  );
};
