import { useLocation } from 'react-router-dom';
import moment from 'moment';

export const BASE_URL = 'https://oss.highpolar.in';
export const DOMAIN = 'oss.highpolar.in';

export const usePathname = () => {
    const location = useLocation();
    return location.pathname;
}

export default class UtilService {
  static formatDate(date: Date) {
    if (!date) return 'NA'
    return moment(new Date(date).toLocaleString('en-us', {timeZone:'Asia/Kuwait'})).format('Do MMMM YYYY, h:mm a');
  }

  static formatDateOnly(date: Date) {
    if (!date) return 'NA'
    return moment(new Date(date).toLocaleDateString('en-us', {timeZone:'Asia/Kuwait'})).format('DD-MM-YYYY')
  }

  static customFormatedDate(date: Date) {
    if (!date) return 'NA'
    return moment(new Date(date).toLocaleString('en-us', {timeZone:'Asia/Kuwait'})).calendar();
  }

  static getImageUrl(url: string) {
    let n_url = url && url.substring(1, url.length)
    return url ? url.includes(BASE_URL) ? url : `${BASE_URL}${n_url}` : 'http://homey-dev2.me-south-1.elasticbeanstalk.com/media/None/default.png';
  }
}
