import { Button, Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Col, Form, Row, Image } from "react-bootstrap";
// import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { syncStoreAction } from "../../../UpdatedStore/actions/ManagementActions";
import { createStoreBlogAction } from "../../../UpdatedStore/actions/ManagementActions";
import SpinnerLoader from "../../../UpdatedStore/components/SpinnerLoader";
import { CREATE_BLOG_RESET } from "../../../UpdatedStore/constants/ManagementProductConstants";
import CustomFileInput from "../../CustomFileInput";
import close_icon from "../../../assets/icons/close_icon.png";
import addplat from "../../../assets/icons/addplat.png";

import "./NewBlog.scss";

const Remove = ({ site }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { sites } = useSelector((state) => state.SitesReducer);
  // const { sites } = useSelector((state) => state.SitesReducer);
  const { loading: loadingSync, error: errorSync } = useSelector(
    (state) => state.syncStoreReducer
  );

  const {
    loading: loadingCreate,
    success: successCreate,
    error: errorCreate,
  } = useSelector((state) => state.CreateBlogReducer);
  const [image, setImage] = useState("Upload Image...");
  const [files, setFiles] = useState("");
  const [siteData, setSiteData] = useState(null);
  const [title, setTitle] = useState("");
  const [answer, setAnswer] = useState("");
  const [submissionError, setSubmissionError] = useState("");
  const [show, setShow] = useState(false);
  const [showAvailable, setShowAvailable] = useState(false);

  const reverseShow = () => {
    setShow(!show);
  };

  const reverseShowAvailable = () => {
    setShowAvailable(!showAvailable);
  };

  const syncStoreHandler = () => {
    const currentUrl = history.location.search;
    const urlParams = new URLSearchParams(currentUrl);
    const node = urlParams.get("site");
    // dispatch(syncStoreAction({ site: name }));
    // console.log("site ka name",urlParams.site);
    console.log("site ka name",currentUrl);
    // console.log("site ka name",currentUrl?.site);
  };

  useEffect(() => {
    dispatch({ type: CREATE_BLOG_RESET });
    setImage("Upload Image...");
    setFiles("");
    const currentUrl = history.location.search;
    const urlParams = new URLSearchParams(currentUrl);
    const node = urlParams.get("site");

    // if (!site) history.push("/dashboard/management");

    if (sites) {
      var fetched_site = sites && sites.find((site) => site.name === node);
      setSiteData(fetched_site);
    }

    if (successCreate) setMessageTimeOut();
  }, [site, history, successCreate, sites]);

  const handleSubmit = () => {
    const currentUrl = history.location.search;
    const urlParams = new URLSearchParams(currentUrl);
    const node = urlParams.get("site");
    setSubmissionError("");
    // syncStoreHandler();
    if (files) {
      if (title) {
        if (answer) {
          var formData = new FormData();
          formData.append("image", files);
          formData.append("site", node);
          formData.append("title", title);
          formData.append("body", answer);
          dispatch(createStoreBlogAction(formData));
        } else {
          setSubmissionError("Post is Invalid!");
        }
      } else {
        setSubmissionError("Title is Invalid!");
      }
    } else {
      setSubmissionError("Please select an image!");
    }
  };

  

  const setMessageTimeOut = () => {
    setTimeout(() => {
      setImage("Upload Image...");
      setFiles("");

      setTitle("");
      setAnswer("");
      setShow(false);
      dispatch({ type: CREATE_BLOG_RESET });
    }, 1000);
  };

  //   function UploadImage(){
  //   console.log("ikitfhdfsa")
  //               const image_input = document.querySelector("#image-input");
  // image_input.addEventListener("change", function() {
  //   const reader = new FileReader();
  //   reader.addEventListener("load", () => {
  //     const uploaded_image = reader.result;
  //     document.querySelector("#display-image").style.backgroundImage = `url(${uploaded_image})`;
  //   });
  //   reader.readAsDataURL(this.files[0]);
  // });

  //   }

  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <div className="button-container" onClick={() => setModalShow(true)}>
        <img src={addplat} alt="button-icon" />
        <div className="create-new-button">Add new content</div>
      </div>

      <Modal
        show={modalShow}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="_remove-modal"
        // onClick={props.onHide}
      >
        <Modal.Body>
          <div className="AddRow">
            <div className="d-flex justify-content-between">
              <div className="tint font-weight-bold">Add new blog</div>
              <div className="cursor">
                <img src={close_icon} onClick={() => setModalShow(false)} />
              </div>
            </div>
            <div className="feild-area-bootstrap image-upload">
              {/* <Image src="" /> */}
              
              <div className="add-image" >
                <input
                  type="file"
                  id="actual-btn"
                  accept="image/*"
                  hidden
                  
                  onChange={(e) => {
                    e.preventDefault();
                    setImage(e.target.value);
                    setFiles(e.target.files[0]);
                  }}
                />

                <label for="actual-btn">Add image</label>
              </div>
              {/* 
              <CustomFileInput
                title="Browse"
                name={image}
                onChange={(e) => {
                  setImage(e.target.value);
                  setFiles(e.target.files[0]);
                }}
              /> */}
            </div>
            {/* <input type="file" id="image-input" accept="image/jpeg, image/png, image/jpg" onClick={UploadImage} />
              <div id="display-image">

              </div> */}
            <div className="blog-image-bottom-text">
              Image size: Max. 360px x 230px
            </div>

            <div className="mt-4">
              <div>
                <Form.Group>
                  <Form.Label className="tint font-weight-bold">
                    Title*
                  </Form.Label>
                  <Form.Control
                    className="feild-area-bootstrap"
                    value={title}
                    onChange={(e) => {
                      const arr = e.target.value.split(" ");
                      if (arr.length > 0) {
                        for (var i = 0; i < arr.length; i++) {
                          arr[i] =
                            arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                        }
                        const str2 = arr.join(" ");
                        setTitle(str2);
                      } else {
                        setTitle(
                          e.target.value.charAt(0).toUpperCase() +
                            e.target.value.slice(1)
                        );
                      }
                    }}
                    type="text"
                    placeholder="Title"
                    // as="textarea"
                    // rows="5"
                    style={{ resize: "none" }}
                  />
                </Form.Group>
              </div>

              <div>
                <Form.Group>
                  <Form.Label className="tint font-weight-bold">
                    Post*
                  </Form.Label>
                  <Form.Control
                    className="feild-area-bootstrap"
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                    as="textarea"
                    rows="15"
                    style={{ resize: "none" }}
                    placeholder="Add body text…"
                  />
                </Form.Group>
              </div>
            </div>
            {submissionError && (
              <h5 className="text-danger text-center font-weight-bold">
                {submissionError}
              </h5>
            )}
            {errorCreate && (
              <h5 className="text-danger text-center font-weight-bold">
                {errorCreate}
              </h5>
            )}
            {successCreate && (
              <h5 className="text-success text-center font-weight-bold">
                Post was created successfully
              </h5>
            )}
            <div className="btn-holder">
              {loadingCreate ? (
                <SpinnerLoader />
              ) : (
                <Button className="button-save" onClick={handleSubmit}>
                  Save
                </Button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Remove;
