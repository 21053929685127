import { combineReducers, applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  SitesReducer,
  OrdersReducer,
  StaffReducer,
  AllUserReducer,
  UserStaticsReducer,
  PlatfromStaticsReducer,
  FilterAllUserReducer,
  DeleteStoreReducer,
  RemoveNotificationReducer,
} from "../slices/SiteSlice";
import { AuthenticationReducer, updateUserReducer } from "../slices/AuthSlice";

import {
  syncStoreReducer,
  createProductTypeReducer,
  editProductTypeReducer,
  deleteProductTypeReducer,
  createProductCategoryReducer,
  editProductCategoryReducer,
  deleteProductCategoryReducer,
  createApkReducer,
} from "../slices/ManagementReducers";

import {
  ProductsReducer,
  PromotionsReducer,
  CreateProductReducer,
  CreateBulkProductReducer,
  CreatePromotionReducer,
  DeleteProductReducer,
  DeletePromotionReducer,
  editProductReducer,
  editPromotionReducer,
  OrderStatusReducer,
  CreateStaffReducer,
  EditStaffReducer,
  DeleteStaffReducer,
  FilterItemReducer,
  FilterStaffsReducer,
  FilterAnalyticsReducer,
  FilterOrdersReducer,
  InventoryArrangementReducer,
  FilterWithOptionsReducer,
  CreateStoreInfoReducer,
  EditStoreInfoReducer,
  DeleteStoreInfoReducer,
  CreateFaqReducer,
  EditFaqReducer,
  DeleteFaqReducer,
  DeleteStoreImageReducer,
  CreateStoreImageReducer,
  DeleteStoreCarouselImageReducer,
  CreateStoreCarouselImageReducer,
  CreateBlogReducer,
  EditBlogReducer,
  DeleteBlogReducer,
  CreateDeliveryMethodReducer,
  EditDeliveryMethodReducer,
  DeleteDeliveryMethodReducer,
  CreateProductImageReducer,
  DeleteProductImageReducer,
  analyticsStaticsReducer,
} from "../slices/ManagementProductReducers";

import {
  NewLayoutReducer,
  undoableMarkupLayout,
  EditModalReducer,
  EditThemeReducer,
  BuilderConfirmReducer,
  CreateSiteReducer,
} from "../../builder/reducers/ApiReducers";

const reducers = combineReducers({
  // site builder reducers
  NewLayoutReducer: NewLayoutReducer,
  MarkupLayoutReducer: undoableMarkupLayout,
  EditModalReducer: EditModalReducer,
  EditThemeReducer: EditThemeReducer,
  BuilderConfirmReducer: BuilderConfirmReducer,
  CreateSiteReducer: CreateSiteReducer,

  DeleteStoreReducer: DeleteStoreReducer,
  RemoveNotificationReducer:RemoveNotificationReducer,

  // dashboard managers
  SitesReducer: SitesReducer,
  AuthenticationReducer: AuthenticationReducer,
  updateUserReducer: updateUserReducer,
  OrdersReducer: OrdersReducer,
  OrderStatusReducer: OrderStatusReducer,
  StaffReducer: StaffReducer,
  AllUserReducer: AllUserReducer,
  UserStaticsReducer: UserStaticsReducer,
  PlatfromStaticsReducer: PlatfromStaticsReducer,
  FilterAllUserReducer: FilterAllUserReducer,
  CreateStaffReducer: CreateStaffReducer,
  EditStaffReducer: EditStaffReducer,
  DeleteStaffReducer: DeleteStaffReducer,

  createApkReducer: createApkReducer,
  syncStoreReducer: syncStoreReducer,
  FilterItemReducer: FilterItemReducer,
  FilterStaffsReducer: FilterStaffsReducer,
  FilterOrdersReducer: FilterOrdersReducer,
  InventoryArrangementReducer: InventoryArrangementReducer,
  FilterWithOptionsReducer: FilterWithOptionsReducer,
  FilterAnalyticsReducer: FilterAnalyticsReducer,
  // management types
  createProductTypeReducer: createProductTypeReducer,
  editProductTypeReducer: editProductTypeReducer,
  deleteProductTypeReducer: deleteProductTypeReducer,
  // management categories
  createProductCategoryReducer: createProductCategoryReducer,
  editProductCategoryReducer: editProductCategoryReducer,
  deleteProductCategoryReducer: deleteProductCategoryReducer,
  // management products
  PromotionsReducer: PromotionsReducer,
  ProductsReducer: ProductsReducer,
  CreateProductReducer: CreateProductReducer,
  CreateBulkProductReducer: CreateBulkProductReducer,
  CreatePromotionReducer: CreatePromotionReducer,
  DeleteProductReducer: DeleteProductReducer,
  DeletePromotionReducer: DeletePromotionReducer,
  editProductReducer: editProductReducer,
  analyticsStaticsReducer: analyticsStaticsReducer,
  editPromotionReducer: editPromotionReducer,
  // ===
  CreateStoreInfoReducer: CreateStoreInfoReducer,
  EditStoreInfoReducer: EditStoreInfoReducer,
  DeleteStoreInfoReducer: DeleteStoreInfoReducer,
  CreateFaqReducer: CreateFaqReducer,
  EditFaqReducer: EditFaqReducer,
  DeleteFaqReducer: DeleteFaqReducer,
  CreateStoreImageReducer: CreateStoreImageReducer,
  DeleteStoreImageReducer: DeleteStoreImageReducer,
  DeleteStoreCarouselImageReducer: DeleteStoreCarouselImageReducer,
  CreateStoreCarouselImageReducer: CreateStoreCarouselImageReducer,
  CreateBlogReducer: CreateBlogReducer,
  EditBlogReducer: EditBlogReducer,
  DeleteBlogReducer: DeleteBlogReducer,
  CreateDeliveryMethodReducer: CreateDeliveryMethodReducer,
  EditDeliveryMethodReducer: EditDeliveryMethodReducer,
  DeleteDeliveryMethodReducer: DeleteDeliveryMethodReducer,
  CreateProductImageReducer: CreateProductImageReducer,
  DeleteProductImageReducer: DeleteProductImageReducer,
});

const userInfoFromStore = localStorage.getItem("userDetails")
  ? JSON.parse(localStorage.getItem("userDetails"))
  : null;

const initialState = {
  AuthenticationReducer: { user: userInfoFromStore },
};
const middleware = [thunk];

const store = createStore(
  reducers,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
