
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetAllUserAction } from "../../../UpdatedStore/actions/SiteActions";
import { Users, UserStats } from "../../../components/Users"
import SpinnerLoader from "../../../UpdatedStore/components/SpinnerLoader";

const AllUserList: React.FC = () => {
  const { loading, alluser, success, error } = useSelector(
    (state) => state.AllUserReducer
  );
  const { user } = useSelector((state) => state.AuthenticationReducer);
  const { text: searchText } = useSelector(
    (state) => state.FilterAllUserReducer
  );

  const [alluserLength, setAllUserLength] = useState(true);
  const [filteredAlluser, setFilteredAllUser] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!alluser) {
      dispatch(GetAllUserAction(user.access_token));
    }

    if (alluser && !filteredAlluser) {
      setAllUserLength(alluser.length)      
      setFilteredAllUser(alluser);
    }
    
    if (alluser && filteredAlluser) {
      if (searchText) {
        const filtext = searchText.toLowerCase();
        setFilteredAllUser((currentStaffs) => {
          var shallowStaffs = [...alluser];
          var newStaffsListUpdated = shallowStaffs.map((obj) => {
            var newStaffsList = obj.partners.filter((staff_item) => {
              const filterConstant = staff_item.agent.name.toLowerCase();
              if (filterConstant.indexOf(filtext) != -1) {
                return staff_item;
              }
            });
            return { ...obj, partners: newStaffsList };
          });

          return newStaffsListUpdated;
        });
      } else {
        setFilteredAllUser(alluser);
      }
    }
  }, [alluser, searchText, success]);

  return (
    <>
    
   <UserStats />
    {filteredAlluser &&
    <Users  users={filteredAlluser} />}
    {loading && <SpinnerLoader />}
    </>
  );
};

export default AllUserList;
