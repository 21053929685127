import React from "react";
import styled from "@mui/system/styled";
import Images from "../component/Images/Images";
import { Container } from "@mui/material";
import { FormControl, InputBase } from "@mui/material";
import { Grid } from "@mui/material";
import Services from "../component/Card/Services";
import { Button } from "@mui/material";
import Process from "../component/process";
import Header from "../component/Header";
import { Typography } from "@mui/material";
import { MainCarousel } from "../component/Card/Carousel";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    // marginTop: theme.spacing(3),
    color: "#474848",
    fontSize: 14,
  },
  "& .MuiInputBase-input": {
    fontFamily: "Montserrat",
    borderRadius: 8,
    border: "1px solid #E8ECF0",
    position: "relative",
    backgroundColor: "#FFFFFF",
    fontSize: 14,
    width: "19.688rem",
    // height:'40px',
    padding: "10px 20px",
    color: "#9AA1AF",
  },

  "@media screen and (max-width: 600px)": {
    
    "& .MuiInputBase-input": {
      marginLeft:'-5px',
      fontFamily: "Montserrat",
      borderRadius: 8,
      border: "1px solid #E8ECF0",
      position: "relative",
      backgroundColor: "#FFFFFF",
      fontSize: 14,
      width:'150px',
      marginRight:'-10Px',
      // width: "19.688rem",
      // height:'40px',
      padding: "10px 20px",
      color: "#9AA1AF",
    },
  },
}));


export default function Index() {
  const { t, i18n } = useTranslation();

  const history = useHistory();

  function signUpMail1(){
    var form = document.getElementById('sheetdb-form');
    form.addEventListener("submit", e => {
      e.preventDefault();
      fetch(form.action, {
          method : "POST",
          body: new FormData(document.getElementById("sheetdb-form")),
      }).then(
          response => response.json()
      ).then((html) => {
        // you can put any JS code here
        alert('Thanks for subscribing us ')
      });
    });
}

function goToPrice() {
  history.push("/pricing");
}
  
function goToLearnMore(){
  history.push("/learnmore");
}

function goToLearnMore(){
  history.push("/learnmore");
}
// const { t } = useTranslation();
  return (
    <div>
      <div className="home-header">
        <Header images="true" />
        <Container maxWidth="xl" >
          <Grid container className="home-headercontainer" >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className="left-header-image"
            >
              <img src={Images.homeHeaderimage} alt="images" />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className="left-side-content"
              
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="headerMainHeading"
                
              >
                <h1>{t('home.mailTitle')}</h1>
                
                
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="Inputfields"
                
              >

              <form method="post" id="sheetdb-form"
                        action="https://sheetdb.io/api/v1/uozq2k8n279s1">
                <FormControl variant="standard">
                  <BootstrapInput
                    defaultValue=""
                    id="bootstrap-input" name="data[Email]"
                    placeholder={t('home.placeholderText')}
                  />

                </FormControl>
                <Button type="submit" className="StartNow" onClick={signUpMail1}>{t('home.signUp')}</Button>
              </form>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="headersmallHeading"
                
              >
                <h4>{t('home.promptBoxTitle')}</h4>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Grid className="home-medium-content" >
        <Grid className="home-medium-content-text">
          <Typography
            className="home-medium-content-h1 line-height-increase">
            {t('home.homeHeading')}<br></br>
          </Typography>
          <Typography className="home-medium-content-h2" >
            {t('home.homeHeadingSecond')}
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.÷ */}
          </Typography>
        </Grid>
      </Grid>
      <Services />
      <MainCarousel />

      {/* <div> */}
        
        <Process name={t('home.businessTitle')} />
      {/* </div> */}

      <Grid className="home-selling-product" >
        <Container>
          <Grid container className="home-selling-product-card">

        
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={3}
              className="home-card-container-one"
              onClick={goToPrice} >
          
          
          
            </Grid>
           
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={3}
              className="home-card-container-one home-card-container-two"
            onClick={goToLearnMore} >
          
             {t('home.theme')}
          
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={3}
              className="home-card-container-one home-card-container-three"
              onClick={goToLearnMore} >
            {t('home.learn_more')}
            </Grid>
          </Grid>

          <div className="home-medium-content-h1 home-sellingheading">
            {t('home.product')}
          </div>
          <div>
            <Button onClick={() => history.push("/sign-up")} className="StartNowNew home-StartNowBottom">{t('home.startButton')}</Button>
          </div>
        </Container>
      </Grid>
    </div>
  );
}
