import {
  GET_SITES_SUCCESS,
  SYNC_STORE_FAIL,
  SYNC_STORE_REQUEST,
  SYNC_STORE_SUCCESS,
  DELETE_STORE_REQUEST,
  DELETE_STORE_SUCCESS,
  DELETE_STORE_FAIL,
  DELETE_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAIL,
} from "../constants/constants";
import {
  CREATE_BLOG_FAIL,
  CREATE_BLOG_REQUEST,
  CREATE_BLOG_SUCCESS,
  CREATE_DELIVERY_METHODS_FAIL,
  CREATE_DELIVERY_METHODS_REQUEST,
  CREATE_DELIVERY_METHODS_SUCCESS,
  CREATE_FAQ_FAIL,
  CREATE_FAQ_REQUEST,
  CREATE_FAQ_SUCCESS,
  CREATE_PRODUCT_IMAGE_FAIL,
  CREATE_PRODUCT_IMAGE_REQUEST,
  CREATE_PRODUCT_IMAGE_SUCCESS,
  CREATE_STORE_CAROUSEL_IMAGE_FAIL,
  CREATE_STORE_CAROUSEL_IMAGE_REQUEST,
  CREATE_STORE_CAROUSEL_IMAGE_SUCCESS,
  CREATE_STORE_IMAGE_FAIL,
  CREATE_STORE_IMAGE_REQUEST,
  CREATE_STORE_IMAGE_SUCCESS,
  CREATE_STORE_INFO_FAIL,
  CREATE_STORE_INFO_REQUEST,
  CREATE_STORE_INFO_SUCCESS,
  DELETE_BLOG_FAIL,
  DELETE_BLOG_REQUEST,
  DELETE_BLOG_SUCCESS,
  DELETE_DELIVERY_METHODS_FAIL,
  DELETE_DELIVERY_METHODS_REQUEST,
  DELETE_DELIVERY_METHODS_SUCCESS,
  DELETE_FAQ_FAIL,
  DELETE_FAQ_REQUEST,
  DELETE_FAQ_SUCCESS,
  DELETE_PRODUCT_IMAGE_FAIL,
  DELETE_PRODUCT_IMAGE_REQUEST,
  DELETE_PRODUCT_IMAGE_SUCCESS,
  DELETE_STORE_CAROUSEL_IMAGE_FAIL,
  DELETE_STORE_CAROUSEL_IMAGE_REQUEST,
  DELETE_STORE_CAROUSEL_IMAGE_SUCCESS,
  DELETE_STORE_IMAGE_FAIL,
  DELETE_STORE_IMAGE_REQUEST,
  DELETE_STORE_IMAGE_SUCCESS,
  DELETE_STORE_INFO_FAIL,
  DELETE_STORE_INFO_REQUEST,
  DELETE_STORE_INFO_SUCCESS,
  EDIT_BLOG_FAIL,
  EDIT_BLOG_REQUEST,
  EDIT_BLOG_SUCCESS,
  EDIT_DELIVERY_METHODS_FAIL,
  EDIT_DELIVERY_METHODS_REQUEST,
  EDIT_DELIVERY_METHODS_RESET,
  EDIT_DELIVERY_METHODS_SUCCESS,
  EDIT_FAQ_FAIL,
  EDIT_FAQ_REQUEST,
  EDIT_FAQ_SUCCESS,
  EDIT_STORE_INFO_FAIL,
  EDIT_STORE_INFO_REQUEST,
  EDIT_STORE_INFO_SUCCESS,
} from "../constants/ManagementProductConstants";
import {
  CREATE_PRODUCT_TYPE_REQUEST,
  CREATE_PRODUCT_TYPE_SUCCESS,
  CREATE_PRODUCT_TYPE_FAIL,
  EDIT_PRODUCT_TYPE_REQUEST,
  EDIT_PRODUCT_TYPE_SUCCESS,
  EDIT_PRODUCT_TYPE_FAIL,
  DELETE_PRODUCT_TYPE_REQUEST,
  DELETE_PRODUCT_TYPE_SUCCESS,
  DELETE_PRODUCT_TYPE_FAIL,
  // CATEGORIES
  CREATE_PRODUCT_CATEGORY_REQUEST,
  CREATE_PRODUCT_CATEGORY_SUCCESS,
  CREATE_PRODUCT_CATEGORY_FAIL,
  EDIT_PRODUCT_CATEGORY_REQUEST,
  EDIT_PRODUCT_CATEGORY_SUCCESS,
  EDIT_PRODUCT_CATEGORY_FAIL,
  DELETE_PRODUCT_CATEGORY_REQUEST,
  DELETE_PRODUCT_CATEGORY_SUCCESS,
  DELETE_PRODUCT_CATEGORY_FAIL,
  GENERATE_APK_REQUEST,
  GENERATE_APK_SUCCESS,
  GENERATE_APK_FAIL,
} from "../constants/ManagementsConstants";

import { BASE_URL } from "../../utils/index";

const domain_url = BASE_URL;

// const domain_url = "http://localhost:8000";

export const syncStoreAction = (details) => async (dispatch, getState) => {
  try {
    const { user } = getState().AuthenticationReducer;
    dispatch({ type: SYNC_STORE_REQUEST });

    var url = domain_url + "/merchant/create/store/sync/";

    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.access_token}`,
      },
      body: JSON.stringify({ ...details }),
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error("There was an error processing your request!");
    }

    dispatch({ type: SYNC_STORE_SUCCESS });
    dispatch({ type: GET_SITES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SYNC_STORE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createProductTypeAction =
  (details) => async (dispatch, getState) => {
    try {
      const { user } = getState().AuthenticationReducer;
      dispatch({ type: CREATE_PRODUCT_TYPE_REQUEST });

      var url = domain_url + "/merchant/create/product/type/";

      const res = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
        body: details,
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error("There was an error processing your request!");
      }

      dispatch({ type: CREATE_PRODUCT_TYPE_SUCCESS });
      dispatch({ type: GET_SITES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CREATE_PRODUCT_TYPE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const editProductTypeAction =
  (details) => async (dispatch, getState) => {
    try {
      const { user } = getState().AuthenticationReducer;
      dispatch({ type: EDIT_PRODUCT_TYPE_REQUEST });

      var url = domain_url + "/merchant/edit/product/type/";

      const res = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
        body: details,
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error("There was an error processing your request!");
      }

      dispatch({ type: EDIT_PRODUCT_TYPE_SUCCESS });
      dispatch({ type: GET_SITES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: EDIT_PRODUCT_TYPE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const deleteProductTypeAction =
  (details) => async (dispatch, getState) => {
    try {
      const { user } = getState().AuthenticationReducer;
      dispatch({ type: DELETE_PRODUCT_TYPE_REQUEST });

      var url = domain_url + "/merchant/delete/product/type/";

      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.access_token}`,
        },
        body: JSON.stringify({ ...details }),
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error(
          data.detail
            ? data.detail
            : "There was an error processing your request!"
        );
      }

      dispatch({ type: DELETE_PRODUCT_TYPE_SUCCESS });
      dispatch({ type: GET_SITES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: DELETE_PRODUCT_TYPE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

//   categories
export const createProductCategoryAction =
  (details) => async (dispatch, getState) => {
    try {
      const { user } = getState().AuthenticationReducer;
      dispatch({ type: CREATE_PRODUCT_CATEGORY_REQUEST });

      var url = domain_url + "/merchant/create/product/category/";

      const res = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
        body: details,
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error("There was an error processing your request!");
      }

      dispatch({ type: CREATE_PRODUCT_CATEGORY_SUCCESS });
      dispatch({ type: GET_SITES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CREATE_PRODUCT_CATEGORY_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const editProductCategoryAction =
  (details) => async (dispatch, getState) => {
    try {
      const { user } = getState().AuthenticationReducer;
      dispatch({ type: EDIT_PRODUCT_CATEGORY_REQUEST });

      var url = domain_url + "/merchant/edit/product/category/";

      const res = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
        body: details,
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error("There was an error processing your request!");
      }

      dispatch({ type: EDIT_PRODUCT_CATEGORY_SUCCESS });
      dispatch({ type: GET_SITES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: EDIT_PRODUCT_CATEGORY_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const deleteProductCategoryAction =
  (details) => async (dispatch, getState) => {
    try {
      const { user } = getState().AuthenticationReducer;
      dispatch({ type: DELETE_PRODUCT_CATEGORY_REQUEST });

      var url = domain_url + "/merchant/delete/product/category/";

      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.access_token}`,
        },
        body: JSON.stringify({ ...details }),
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error(
          data.detail
            ? data.detail
            : "There was an error processing your request!"
        );
      }

      dispatch({ type: DELETE_PRODUCT_CATEGORY_SUCCESS });
      dispatch({ type: GET_SITES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: DELETE_PRODUCT_CATEGORY_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

// faQ
export const createFaqAction = (details) => async (dispatch, getState) => {
  try {
    const { user } = getState().AuthenticationReducer;
    dispatch({ type: CREATE_FAQ_REQUEST });

    var url = domain_url + "/merchant/create/faq/";

    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.access_token}`,
      },
      body: JSON.stringify({ ...details }),
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error("There was an error processing your request!");
    }

    dispatch({ type: CREATE_FAQ_SUCCESS });
    dispatch({ type: GET_SITES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CREATE_FAQ_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const editFaqAction = (details) => async (dispatch, getState) => {
  try {
    const { user } = getState().AuthenticationReducer;
    dispatch({ type: EDIT_FAQ_REQUEST });

    var url = domain_url + "/merchant/edit/faq/";

    const res = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.access_token}`,
      },
      body: JSON.stringify({ ...details }),
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error("There was an error processing your request!");
    }

    dispatch({ type: EDIT_FAQ_SUCCESS });
    dispatch({ type: GET_SITES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: EDIT_FAQ_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteFaqAction = (details) => async (dispatch, getState) => {
  try {
    const { user } = getState().AuthenticationReducer;
    dispatch({ type: DELETE_FAQ_REQUEST });

    var url = domain_url + "/merchant/delete/faq/";

    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.access_token}`,
      },
      body: JSON.stringify({ ...details }),
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error("There was an error processing your request!");
    }

    dispatch({ type: DELETE_FAQ_SUCCESS });
    dispatch({ type: GET_SITES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_FAQ_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

// store information
export const createStoreInfoAction =
  (details) => async (dispatch, getState) => {
    try {
      const { user } = getState().AuthenticationReducer;
      dispatch({ type: CREATE_STORE_INFO_REQUEST });

      var url = domain_url + "/merchant/create/storeinfo/";

      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.access_token}`,
        },
        body: JSON.stringify({ ...details }),
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error("There was an error processing your request!");
      }

      dispatch({ type: CREATE_STORE_INFO_SUCCESS });
      dispatch({ type: GET_SITES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CREATE_STORE_INFO_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const editStoreInfoAction = (details) => async (dispatch, getState) => {
  try {
    const { user } = getState().AuthenticationReducer;
    dispatch({ type: EDIT_STORE_INFO_REQUEST });

    var url = domain_url + "/merchant/edit/storeinfo/";

    const res = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.access_token}`,
      },
      body: JSON.stringify({ ...details }),
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error("There was an error processing your request!");
    }

    dispatch({ type: EDIT_STORE_INFO_SUCCESS });
    dispatch({ type: GET_SITES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: EDIT_STORE_INFO_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteStoreInfoAction =
  (details) => async (dispatch, getState) => {
    try {
      const { user } = getState().AuthenticationReducer;
      dispatch({ type: DELETE_STORE_INFO_REQUEST });

      var url = domain_url + "/merchant/delete/storeinfo/";

      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.access_token}`,
        },
        body: JSON.stringify({ ...details }),
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error("There was an error processing your request!");
      }

      dispatch({ type: DELETE_STORE_INFO_SUCCESS });
      dispatch({ type: GET_SITES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: DELETE_STORE_INFO_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const createStoreImageAction =
  (details) => async (dispatch, getState) => {
    try {
      const { user } = getState().AuthenticationReducer;
      dispatch({ type: CREATE_STORE_IMAGE_REQUEST });

      var url = domain_url + "/merchant/create/storeimage/";

      const res = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
        body: details,
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error(
          data ? data.detail : "There was an error processing your request!"
        );
      }

      dispatch({ type: CREATE_STORE_IMAGE_SUCCESS });
      dispatch({ type: GET_SITES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CREATE_STORE_IMAGE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const deleteStoreImageAction =
  (details) => async (dispatch, getState) => {
    try {
      const { user } = getState().AuthenticationReducer;
      dispatch({ type: DELETE_STORE_IMAGE_REQUEST });

      var url = domain_url + "/merchant/delete/storeimage/";

      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.access_token}`,
        },
        body: JSON.stringify({ ...details }),
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error("There was an error processing your request!");
      }

      dispatch({ type: DELETE_STORE_IMAGE_SUCCESS });
      dispatch({ type: GET_SITES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: DELETE_STORE_IMAGE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const createStoreBlogAction =
  (details) => async (dispatch, getState) => {
    try {
      const { user } = getState().AuthenticationReducer;
      dispatch({ type: CREATE_BLOG_REQUEST });

      var url = domain_url + "/merchant/create/storeblog/";

      const res = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
        body: details,
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error(
          data ? data.detail : "There was an error processing your request!"
        );
      }

      dispatch({ type: CREATE_BLOG_SUCCESS });
      dispatch({ type: GET_SITES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CREATE_BLOG_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const editStoreBlogAction = (details) => async (dispatch, getState) => {
  try {
    const { user } = getState().AuthenticationReducer;
    dispatch({ type: EDIT_BLOG_REQUEST });

    var url = domain_url + "/merchant/edit/storeblog/";

    const res = await fetch(url, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
      body: details,
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error(
        data ? data.detail : "There was an error processing your request!"
      );
    }

    dispatch({ type: EDIT_BLOG_SUCCESS });
    dispatch({ type: GET_SITES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: EDIT_BLOG_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteStoreBlogAction =
  (details) => async (dispatch, getState) => {
    try {
      const { user } = getState().AuthenticationReducer;
      dispatch({ type: DELETE_BLOG_REQUEST });

      var url = domain_url + "/merchant/delete/storeblog/";

      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.access_token}`,
        },
        body: JSON.stringify({ ...details }),
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error("There was an error processing your request!");
      }

      dispatch({ type: DELETE_BLOG_SUCCESS });
      dispatch({ type: GET_SITES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: DELETE_BLOG_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const createProductDeliveryAction =
  (details) => async (dispatch, getState) => {
    try {
      const { user } = getState().AuthenticationReducer;
      dispatch({ type: CREATE_DELIVERY_METHODS_REQUEST });

      var url = domain_url + "/merchant/create/store/delivery/";

      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.access_token}`,
        },
        body: JSON.stringify({ ...details }),
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error("There was an error processing your request!");
      }

      dispatch({ type: CREATE_DELIVERY_METHODS_SUCCESS });
      dispatch({ type: GET_SITES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CREATE_DELIVERY_METHODS_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const editProductDeliveryAction =
  (details) => async (dispatch, getState) => {
    try {
      const { user } = getState().AuthenticationReducer;
      dispatch({ type: EDIT_DELIVERY_METHODS_REQUEST });

      var url = domain_url + "/merchant/edit/store/delivery/";

      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.access_token}`,
        },
        body: JSON.stringify({ ...details }),
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error("There was an error processing your request!");
      }

      dispatch({ type: EDIT_DELIVERY_METHODS_SUCCESS });
      dispatch({ type: GET_SITES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: EDIT_DELIVERY_METHODS_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const deleteProductDeliveryAction =
  (details) => async (dispatch, getState) => {
    try {
      const { user } = getState().AuthenticationReducer;
      dispatch({ type: DELETE_DELIVERY_METHODS_REQUEST });

      var url = domain_url + "/merchant/delete/store/delivery/";

      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.access_token}`,
        },
        body: JSON.stringify({ ...details }),
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error(
          data.detail
            ? data.detail
            : "There was an error processing your request!"
        );
      }

      dispatch({ type: DELETE_DELIVERY_METHODS_SUCCESS });
      dispatch({ type: GET_SITES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: DELETE_DELIVERY_METHODS_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const CreateProductImageAction =
  (details) => async (dispatch, getState) => {
    try {
      const { user } = getState().AuthenticationReducer;
      dispatch({ type: CREATE_PRODUCT_IMAGE_REQUEST });

      var url = domain_url + "/merchant/create/product/image/item/";

      const res = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
        body: details,
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error("There was an error processing your request!");
      }

      dispatch({ type: CREATE_PRODUCT_IMAGE_SUCCESS });
      dispatch({ type: GET_SITES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CREATE_PRODUCT_IMAGE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const deleteProductImageAction =
  (details) => async (dispatch, getState) => {
    try {
      const { user } = getState().AuthenticationReducer;
      dispatch({ type: DELETE_PRODUCT_IMAGE_REQUEST });

      var url = domain_url + "/merchant/delete/product/image/item/";

      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.access_token}`,
        },
        body: JSON.stringify({ ...details }),
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error(
          data.detail
            ? data.detail
            : "There was an error processing your request!"
        );
      }

      dispatch({ type: DELETE_PRODUCT_IMAGE_SUCCESS });
      dispatch({ type: GET_SITES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: DELETE_PRODUCT_IMAGE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const DeleteSiteAction = (details) => async (dispatch, getState) => {
  try {
    const { user } = getState().AuthenticationReducer;
    dispatch({ type: DELETE_STORE_REQUEST });

    var url = domain_url + "/merchant/secured/delete/site/";

    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.access_token}`,
      },
      body: JSON.stringify({ ...details }),
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error("There was an error processing your request!");
    }

    dispatch({ type: DELETE_STORE_SUCCESS });
    dispatch({ type: GET_SITES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_STORE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const RemoveNotificationAction = (details) => async (dispatch, getState) => {
  try {
    const { user } = getState().AuthenticationReducer;
    dispatch({ type: DELETE_NOTIFICATION_REQUEST });

    console.log(details)
    var url = domain_url + "/merchant/notification-status/";

    const res = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.access_token}`,
      },
      body: JSON.stringify({...details}),
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error("There was an error processing your request!");
    }

    dispatch({ type: DELETE_NOTIFICATION_SUCCESS });
    dispatch({ type: GET_SITES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_NOTIFICATION_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const GenerateApkAction = (details) => async (dispatch, getState) => {
  try {
    const { user } = getState().AuthenticationReducer;
    dispatch({ type: GENERATE_APK_REQUEST });

    var url = domain_url + "/merchant/generate/site/apk/";

    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.access_token}`,
      },
      body: JSON.stringify({ ...details }),
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error(
        data.detail
          ? data.detail
          : data.error_description
          ? data.error_description
          : "There was an error fetching the products!"
      );
    }

    dispatch({ type: GENERATE_APK_SUCCESS });
    dispatch({ type: GET_SITES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GENERATE_APK_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createStoreCarouselImageAction =
  (details) => async (dispatch, getState) => {
    try {
      const { user } = getState().AuthenticationReducer;
      dispatch({ type: CREATE_STORE_CAROUSEL_IMAGE_REQUEST });

      var url = domain_url + "/merchant/generate/carousel/image/create/";

      const res = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
        body: details,
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error(
          data ? data.detail : "There was an error processing your request!"
        );
      }

      dispatch({ type: CREATE_STORE_CAROUSEL_IMAGE_SUCCESS });
      dispatch({ type: GET_SITES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CREATE_STORE_CAROUSEL_IMAGE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const deleteStoreCarouselImageAction =
  (details) => async (dispatch, getState) => {
    try {
      const { user } = getState().AuthenticationReducer;
      dispatch({ type: DELETE_STORE_CAROUSEL_IMAGE_REQUEST });

      var url = domain_url + "/merchant/generate/carousel/image/delete/";

      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.access_token}`,
        },
        body: JSON.stringify({ ...details }),
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error("There was an error processing your request!");
      }

      dispatch({ type: DELETE_STORE_CAROUSEL_IMAGE_SUCCESS });
      dispatch({ type: GET_SITES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: DELETE_STORE_CAROUSEL_IMAGE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
