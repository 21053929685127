import React from "react";
import { Card } from "react-bootstrap";
import productPic from "../../../assets/icons/product-pic.png";
import DeletItem from "../deletItem/DeleteItem";
import "./holdProduct.scss";
import SetUnPublich from "../setUnPublish/SetUnPublish";
import CreatePromotion from "../setUnPublish/CreatePromotion";
import DeletePromotion from "../setUnPublish/DeletePromotion";
import EditPromotion from "../setUnPublish/EditPromotion";
import ViewProductModal from "../sideBar/ViewProductModal";
import EditProductModal from "../sideBar/EditProductModal";
import ImagesAction from "../sideBar/ImagesAction";

const HoldProducts: React.FC = ({
  site,
  productItem,
  product = false,
  promotion = false,
  key=false
}) => {
  const [details, setDetails] = React.useState(false);
  return (
    <Card className="_product" style={{ width: "280px" }} key={key}>
      <Card.Img
        variant="top"
        src={
          product ? productItem.imageUrl : promotion ? productItem.ImgUrl : ""
        }
        style={{ width: "100%", height: "300px", objectFit: "fill" }}
      />
      <Card.Body>
        <Card.Title>{productItem.name}</Card.Title>
        <Card.Text className="_price-info-sold-item">
          <b>
            $
            {Number(
              product
                ? productItem.price
                : promotion
                ? productItem.CurrentPirce
                : 0
            ).toFixed(2)}{" "}
            {promotion && (
              <small
                className="text-muted"
                style={{ textDecoration: "line-through" }}
              >
                {productItem.RealPrice}
              </small>
            )}
          </b>
          <span>
            <svg
              width="5"
              height="5"
              viewBox="0 0 5 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="2.5" cy="2.5" r="2.5" fill="#16284D" />
            </svg>
          </span>
          <small>{productItem.quantity} available</small>
        </Card.Text>

        <button className="_product-details" onClick={() => setDetails(true)}>
          <svg
            width="20"
            height="4"
            viewBox="0 0 20 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18 0C16.8954 0 16 0.895431 16 2C16 3.10457 16.8954 4 18 4C19.1046 4 20 3.10457 20 2C20 0.895431 19.1046 0 18 0ZM8 2C8 0.895431 8.89543 0 10 0C11.1046 0 12 0.895431 12 2C12 3.10457 11.1046 4 10 4C8.89543 4 8 3.10457 8 2ZM0 2C0 0.895431 0.89543 0 2 0C3.10457 0 4 0.895431 4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2Z"
              fill="#16284D"
            />
          </svg>
        </button>

        {details ? (
          <div className="_details-box">
            <button
              className="_close-detail-box"
              onClick={() => setDetails(false)}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="28" height="28" rx="8" fill="#E2F8F1" />
                <path
                  d="M13.9973 8.76045C13.7151 8.76045 13.433 8.86819 13.2178 9.08322L6.44799 15.8531C6.01734 16.2838 6.01734 16.982 6.44799 17.4125C6.87846 17.843 7.57655 17.843 8.00724 17.4125L13.9973 11.4221L19.9874 17.4123C20.418 17.8428 21.116 17.8428 21.5465 17.4123C21.9773 16.9818 21.9773 16.2836 21.5465 15.8529L14.7767 9.08301C14.5615 8.86794 14.2794 8.76045 13.9973 8.76045Z"
                  fill="#3ED0A3"
                />
              </svg>
            </button>
            <ViewProductModal
              product={productItem}
              product_={product}
              promotion={promotion}
            />
            <EditProductModal
              site={site}
              product={productItem}
              _product={product}
              _promotion={promotion}
            />
            <ImagesAction site={site} product={productItem} key={key}/>
            {product && <CreatePromotion site={site} product={productItem} />}
            {promotion && <EditPromotion site={site} product={productItem} />}
            {promotion && <DeletePromotion site={site} product={productItem} />}
            {/* <SetUnPublich /> */}

            {product && <DeletItem site={site} product={productItem} />}
          </div>
        ) : (
          ""
        )}
      </Card.Body>
    </Card>
  );
};
export default HoldProducts;
