import React from "react";
import "./MyAccountFree.scss";
import { Container } from "react-bootstrap";
import AccountInfo from "../../components/freeAccount/accountInfo/AccountInfo";
import FreeAccount from "../../components/freeAccount/FreeAccount";
import PromoCoupons from "../../components/freeAccount/promoCoupons/PromoCoupons";
import YourSubs from "../../components/freeAccount/yourSubscriptions/YourSubscriptions";
import { useSelector } from "react-redux";

const MyFreeAccount: React.FC = () => {
  const { user } = useSelector((state) => state.AuthenticationReducer);
  return (
    <Container fluid>
      <div className="_hold-account-free">
        <FreeAccount />
        <AccountInfo />
        {!user.isSuperUser && (
          <>
            <div className="_hold-promo-subs">
              {/* <PromoCoupons /> */}
              <YourSubs />
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default MyFreeAccount;
