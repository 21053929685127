import { GET_SITES_SUCCESS } from "../constants/constants";
import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_PROMOTIONS_REQUEST,
  GET_PROMOTIONS_SUCCESS,
  GET_PROMOTIONS_FAIL,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAIL,
  CREATE_PROMOTIONS_REQUEST,
  CREATE_PROMOTIONS_SUCCESS,
  CREATE_PROMOTIONS_FAIL,
  DELETE_PROMOTIONS_REQUEST,
  DELETE_PROMOTIONS_SUCCESS,
  DELETE_PROMOTIONS_FAIL,
  EDIT_PROMOTIONS_REQUEST,
  EDIT_PROMOTIONS_SUCCESS,
  EDIT_PROMOTIONS_FAIL,
  EDIT_PRODUCT_REQUEST,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_FAIL,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  UPDATE_ORDER_STATUS_REQUEST,
  UPDATE_ORDER_STATUS_SUCCESS,
  UPDATE_ORDER_STATUS_FAIL,
  CREATE_STAFF_REQUEST,
  CREATE_STAFF_SUCCESS,
  CREATE_STAFF_FAIL,
  EDIT_STAFF_REQUEST,
  EDIT_STAFF_SUCCESS,
  EDIT_STAFF_FAIL,
  DELETE_STAFF_REQUEST,
  DELETE_STAFF_SUCCESS,
  DELETE_STAFF_FAIL,
  GET_ANALYTICS_STATICS_REQUEST,
  GET_ANALYTICS_STATICS_SUCCESS,
  GET_ANALYTICS_STATICS_FAIL,
  CREATE_BULK_PRODUCT_REQUEST,
  CREATE_BULK_PRODUCT_SUCCESS,
  CREATE_BULK_PRODUCT_FAIL,
} from "../constants/ManagementProductConstants";

import { BASE_URL, DOMAIN } from "../../utils/index";

const domain_url = BASE_URL;
const domain = DOMAIN;

// const domain = "localhost:8000";
// const domain_url = "http://localhost:8000";
export const GetProductsAction = (site_) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_PRODUCTS_REQUEST });

    var url = `https://${site_.name}.${domain}/custom/products/all/`;
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    });

    const data = await res.json();
    if (!res.ok) {
      throw new Error(
        data.detail
          ? data.detail
          : data.error_description
          ? data.error_description
          : "There was an error fetching the products!"
      );
    }
    const UpdatedData = await Promise.all(
      data.map(async (item) => {
        try {
          const response_ = await fetch(item.stockrecords);
          let fetched_data_ = null;
          if (response_) fetched_data_ = await response_.json();
          if (fetched_data_)
            return {
              name: item.title,
              description: item.description,
              imageUrl: item.images[0] ? item.images[0].original : null,
              price: item.attributes.find((attrib) => attrib.name === "Price")
                .value,
              size: item.size_variants,
              stockrecords: item.stockrecords,
              brand: item.attributes.find((attrib) => attrib.name === "Brand")
                .value,
              categories: item.categories,
              type: item.product_class,
              images: item.images,
              manufacturer_info: item.manufacturer_info,
              color_variants: item.color_variants,
              sold_items: item.sold_items,
              _id: item.id,
              quantity: fetched_data_[0].num_in_stock,
            };
          else {
            return null;
          }
        } catch (e) {
          console.log("");
        }
      })
    );
    let updatedDataFixed = UpdatedData.filter((i) => {
      return i != null;
    });
    dispatch({ type: GET_PRODUCTS_SUCCESS, payload: updatedDataFixed });
  } catch (error) {
    dispatch({
      type: GET_PRODUCTS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const GetPromotionsAction = (site_) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_PROMOTIONS_REQUEST });

    var url = `https://${site_.name}.${domain}/custom/products/promotions/`;
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error(
        data.detail
          ? data.detail
          : data.error_description
          ? data.error_description
          : "There was an error fetching the products!"
      );
    }

    const UpdatedData = await Promise.all(
      data.map(async (item) => {
        const response_ = await fetch(item.product_.stockrecords);
        const fetched_data_ = await response_.json();

        return {
          name: item.product_.title,
          ImgUrl: item.product_.images[0]
            ? item.product_.images[0].original
            : null,
          description: item.product_.description,
          RealPrice: Number(
            item.product_.attributes.find((attrib) => attrib.name === "Price")
              .value
          ).toFixed(2),
          CurrentPirce: Number(
            Number(
              item.product_.attributes.find((attrib) => attrib.name === "Price")
                .value
            ) -
              Number(
                item.product_.attributes.find(
                  (attrib) => attrib.name === "Price"
                ).value *
                  (item.percentage / 100)
              )
          ).toFixed(2),
          stockrecords: item.product_.stockrecords,
          brand: item.product_.attributes.find(
            (attrib) => attrib.name === "Brand"
          ).value,
          size: item.product_.size_variants,
          categories: item.product_.categories,
          type: item.product_.product_class,
          images: item.product_.images,
          manufacturer_info: item.product_.manufacturer_info,
          color_variants: item.product_.color_variants,
          sold_items: item.product_.sold_items,
          _id: item.product_.id,
          percentage: item.percentage,
          quantity: fetched_data_[0].num_in_stock,
        };
      })
    );

    dispatch({ type: GET_PROMOTIONS_SUCCESS, payload: UpdatedData });
  } catch (error) {
    dispatch({
      type: GET_PROMOTIONS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createProductAction = (details) => async (dispatch, getState) => {
  try {
    const { user } = getState().AuthenticationReducer;
    dispatch({ type: CREATE_PRODUCT_REQUEST });

    var url = domain_url + "/merchant/create/product/";

    const res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
      body: details,
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error("There was an error processing your request!");
    }

    dispatch({ type: CREATE_PRODUCT_SUCCESS });
    dispatch({ type: GET_SITES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CREATE_PRODUCT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createBulkProductAction =
  (details) => async (dispatch, getState) => {
    try {
      const { user } = getState().AuthenticationReducer;
      dispatch({ type: CREATE_BULK_PRODUCT_REQUEST });

      var url = domain_url + "/merchant/create/product/bulk/";

      const res = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
        body: details,
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error(
          data.detail
            ? data.detail
            : "There was an error processing your request!"
        );
      }

      dispatch({ type: CREATE_BULK_PRODUCT_SUCCESS });
      dispatch({ type: GET_SITES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CREATE_BULK_PRODUCT_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const createPromotionAction =
  (details) => async (dispatch, getState) => {
    try {
      const { user } = getState().AuthenticationReducer;
      dispatch({ type: CREATE_PROMOTIONS_REQUEST });

      var url = domain_url + "/merchant/create/product/promotions/";

      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.access_token}`,
        },
        body: JSON.stringify({ ...details }),
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error("There was an error processing your request!");
      }

      dispatch({ type: CREATE_PROMOTIONS_SUCCESS });
      dispatch({ type: GET_SITES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CREATE_PROMOTIONS_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const deletePromotionAction =
  (details) => async (dispatch, getState) => {
    try {
      const { user } = getState().AuthenticationReducer;
      dispatch({ type: DELETE_PROMOTIONS_REQUEST });

      var url = domain_url + "/merchant/delete/product/promotions/";

      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.access_token}`,
        },
        body: JSON.stringify({ ...details }),
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error(
          data.detail
            ? data.detail
            : "There was an error processing your request!"
        );
      }

      dispatch({ type: DELETE_PROMOTIONS_SUCCESS });
      dispatch({ type: GET_SITES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: DELETE_PROMOTIONS_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const editPromotionAction = (details) => async (dispatch, getState) => {
  try {
    const { user } = getState().AuthenticationReducer;
    dispatch({ type: EDIT_PROMOTIONS_REQUEST });

    var url = domain_url + "/merchant/edit/product/promotions/";

    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.access_token}`,
      },
      body: JSON.stringify({ ...details }),
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error("There was an error processing your request!");
    }

    dispatch({ type: EDIT_PROMOTIONS_SUCCESS });
    dispatch({ type: GET_SITES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: EDIT_PROMOTIONS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const editProductAction = (details) => async (dispatch, getState) => {
  try {
    const { user } = getState().AuthenticationReducer;
    dispatch({ type: EDIT_PRODUCT_REQUEST });

    var url = domain_url + "/merchant/edit/product/item/";

    const res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
      body: details,
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error("There was an error processing your request!");
    }

    dispatch({ type: EDIT_PRODUCT_SUCCESS });
    dispatch({ type: GET_SITES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: EDIT_PRODUCT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteProductAction = (details) => async (dispatch, getState) => {
  try {
    const { user } = getState().AuthenticationReducer;
    dispatch({ type: DELETE_PRODUCT_REQUEST });

    var url = domain_url + "/merchant/delete/product/item/";

    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.access_token}`,
      },
      body: JSON.stringify({ ...details }),
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error(
        data.detail
          ? data.detail
          : "There was an error processing your request!"
      );
    }

    dispatch({ type: DELETE_PRODUCT_SUCCESS });
    dispatch({ type: GET_SITES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateOrderStatusAction =
  (details) => async (dispatch, getState) => {
    try {
      const { user } = getState().AuthenticationReducer;
      dispatch({ type: UPDATE_ORDER_STATUS_REQUEST });

      var url = domain_url + "/merchant/update/orders/status/";

      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.access_token}`,
        },
        body: JSON.stringify({ ...details }),
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error(
          data.detail
            ? data.detail
            : "There was an error processing your request!"
        );
      }

      dispatch({ type: UPDATE_ORDER_STATUS_SUCCESS });
      dispatch({ type: GET_SITES_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: UPDATE_ORDER_STATUS_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const createStaffAction = (details) => async (dispatch, getState) => {
  try {
    const { user } = getState().AuthenticationReducer;
    dispatch({ type: CREATE_STAFF_REQUEST });

    var url = domain_url + "/merchant/create/staff/";

    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.access_token}`,
      },
      body: JSON.stringify({ ...details }),
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error(
        data.detail
          ? data.detail
          : "There was an error processing your request!"
      );
    }

    dispatch({ type: CREATE_STAFF_SUCCESS });
    dispatch({ type: GET_SITES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CREATE_STAFF_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const editStaffAction = (details) => async (dispatch, getState) => {
  try {
    const { user } = getState().AuthenticationReducer;
    dispatch({ type: EDIT_STAFF_REQUEST });

    var url = domain_url + "/merchant/edit/staff/";

    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.access_token}`,
      },
      body: JSON.stringify({ ...details }),
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error(
        data.detail
          ? data.detail
          : "There was an error processing your request!"
      );
    }

    dispatch({ type: EDIT_STAFF_SUCCESS });
    dispatch({ type: GET_SITES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: EDIT_STAFF_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteStaffAction = (details) => async (dispatch, getState) => {
  try {
    const { user } = getState().AuthenticationReducer;
    dispatch({ type: DELETE_STAFF_REQUEST });

    var url = domain_url + "/merchant/delete/staff/";

    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.access_token}`,
      },
      body: JSON.stringify({ ...details }),
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error(
        data.detail
          ? data.detail
          : "There was an error processing your request!"
      );
    }

    dispatch({ type: DELETE_STAFF_SUCCESS });
    dispatch({ type: GET_SITES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_STAFF_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getAnalyticsOrder = (site_) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ANALYTICS_STATICS_REQUEST });
    var url = `https://${site_.name}.${domain}/custom/order/analytics/`;
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    });

    const data = await res.json();

    if (!res.ok) {
      throw new Error(
        data.detail
          ? data.detail
          : data.error_description
          ? data.error_description
          : "There was an error fetching the Order Analytic!"
      );
    }
    dispatch({ type: GET_ANALYTICS_STATICS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ANALYTICS_STATICS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
