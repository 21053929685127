import {
  InputGroup,
  FormControl,
  Row,
  Col,
  Modal,
  Button,
} from "react-bootstrap";
import { useState } from "react";
import { UpdateNodeAction } from "../../actions/ApiAction";
import { SHOW_MODAL_STATE } from "../../constants/ApiConstants";
import { useDispatch } from "react-redux";

const EditPromotions = ({ nodee, props }) => {
  const dispatch = useDispatch();
  const [node, setNode] = useState(nodee);

  const saveChanges = () => {
    dispatch(UpdateNodeAction(node));
    dispatch({ type: SHOW_MODAL_STATE });
  };

  const handleNameChange = (ObjName, ObjIndex) => {
    let updatedNav = node.data.promotion_list.map((item, index) => {
      if (index === ObjIndex) {
        return { ...item, name: ObjName };
      } else {
        return item;
      }
    });
    setNode((oldNode) => {
      let newNode = { ...oldNode };
      newNode.data.promotion_list = updatedNav;
      return newNode;
    });
  };

  const handleUrlChange = (ObjName, ObjIndex) => {
    let updatedNav = node.data.promotion_list.map((item, index) => {
      if (index === ObjIndex) {
        return { ...item, ImgUrl: ObjName };
      } else {
        return item;
      }
    });
    setNode((oldNode) => {
      let newNode = { ...oldNode };
      newNode.data.promotion_list = updatedNav;
      return newNode;
    });
  };

  const handleRealPriceChange = (ObjName, ObjIndex) => {
    let updatedNav = node.data.promotion_list.map((item, index) => {
      if (index === ObjIndex) {
        return { ...item, RealPrice: ObjName };
      } else {
        return item;
      }
    });
    setNode((oldNode) => {
      let newNode = { ...oldNode };
      newNode.data.promotion_list = updatedNav;
      return newNode;
    });
  };

  const handleCurrentPriceChange = (ObjName, ObjIndex) => {
    let updatedNav = node.data.promotion_list.map((item, index) => {
      if (index === ObjIndex) {
        return { ...item, CurrentPirce: ObjName };
      } else {
        return item;
      }
    });
    setNode((oldNode) => {
      let newNode = { ...oldNode };
      newNode.data.promotion_list = updatedNav;
      return newNode;
    });
  };

  return (
    <>
      <Modal.Body>
        <h4 className="tint opacity font-weight-bold mb-4">PROPERTIES</h4>

        <h6 className="tint font-weight-bold mb-1">*PROMOTIONS</h6>

        {node.data.promotion_list.map((Ilink, index) => (
          <>
            <Row className="mt-3">
              <Col>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      id="inputGroup-sizing-default"
                      className="tint font-weight-bold"
                    >
                      NAME
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    aria-label="Default"
                    value={Ilink.name}
                    onChange={(e) => handleNameChange(e.target.value, index)}
                    aria-describedby="inputGroup-sizing-default"
                  />
                </InputGroup>
              </Col>
              <Col>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      id="inputGroup-sizing-default"
                      className="tint font-weight-bold"
                    >
                      PASTE IMAGE URL
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    aria-label="Default"
                    value={Ilink.ImgUrl}
                    onChange={(e) => handleUrlChange(e.target.value, index)}
                    aria-describedby="inputGroup-sizing-default"
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      id="inputGroup-sizing-default"
                      className="tint font-weight-bold"
                    >
                      REAL PRICE
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    aria-label="Default"
                    value={Ilink.RealPrice}
                    onChange={(e) =>
                      handleRealPriceChange(e.target.value, index)
                    }
                    aria-describedby="inputGroup-sizing-default"
                  />
                </InputGroup>
              </Col>
              <Col>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      id="inputGroup-sizing-default"
                      className="tint font-weight-bold"
                    >
                      CURRENT PRICE
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    value={Ilink.CurrentPirce}
                    onChange={(e) =>
                      handleCurrentPriceChange(e.target.value, index)
                    }
                  />
                </InputGroup>
              </Col>
            </Row>

            <hr className="bg-tint opacity" />
          </>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <div className="modalRow">
          <Button
            variant="dark"
            className="font-weight-bold rounded"
            onClick={props.onHide}
          >
            Close
          </Button>
          <Button
            className="bg-tint text-white font-weight-bold"
            onClick={saveChanges}
          >
            Save
          </Button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default EditPromotions;
