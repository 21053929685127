import React from 'react';
import { useState } from 'react';
import { Button, Container } from '@mui/material';
import Images from "../component/Images/Images";
import Header from '../component/Header';
import { useTheme, useMediaQuery } from "@material-ui/core";
import AccrodianPricing from '../component/AccrodianPricing';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useTranslation } from "react-i18next";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



export default function Pricing() {
  const [display, setDisplay] = useState(false);

  const [x, setx] = useState();

  function myFunction() {
    var z = document.getElementById("mySelect").value;
    setx(z);
  }

  const package_data = [
    {
      package_heading: "accrodianPricing.priceOne",
      amount: [
        {
          title: "accrodianPricing.priceOneTitle",
          weight: 700,
          size: '22px',
          color: '#16284D',
        }
      ],

      theme: [
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
      ],

      bottom_button: [
        {
          title:"choosePlan.plan",
          background_color: '#3ED0A3',
        },

      ],


    },

    {
      package_heading: "accrodianPricing.priceTwo",
      amount: [
        {
          title: "accrodianPricing.priceTwoTitle1",
          weight: 700,
          size: '22px',
          color: '#16284D',
        },
        {
          title:"accrodianPricing.priceTwoTitle2",
          weight: 400,
          size: '15px',
          color: '#3E4452',
        }
      ],

      theme: [
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
      ],

      bottom_button: [
        {
          title:"choosePlan.chooseMonthly",
          background_color: '#3ED0A3',
        },
        {
          title:"choosePlan.chooseAnnually",
          background_color: '#16284D',
        },

      ],


    },

    {
      package_heading: "accrodianPricing.priceThree",
      amount: [
        {
          title: "accrodianPricing.priceThreeTitle1",
          weight: 700,
          size: '22px',
          color: '#16284D',
        },
        {
          title: "accrodianPricing.priceThreeTitle2",
          weight: 400,
          size: '15px',
          color: '#3E4452',
        }
      ],

      theme: [
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
      ],

      bottom_button: [
        {
          title:"choosePlan.chooseMonthly",
          background_color: '#3ED0A3',
        },
        {
          title: "choosePlan.chooseAnnually",
          background_color: '#16284D',
        },

      ],


    },

    {
      package_heading: "accrodianPricing.priceFour",
      amount: [
        {
          title:"accrodianPricing.priceFourTitle1",
          weight: 700,
          size: '22px',
          color: '#16284D',
        },
        {
          title:"accrodianPricing.priceFourTitle2",
          weight: 400,
          size: '15px',
          color: '#3E4452',
        }
      ],

      theme: [
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
        "choosePlan.reacharge_plan",
      ],

      bottom_button: [
        {
          title: "choosePlan.chooseMonthly",
          background_color: '#3ED0A3',
        },
        {
          title:"choosePlan.chooseAnnually",
          background_color: '#16284D',
        },

      ],


    },

  ]

  const package_rating_data = [
    {
      id: "1",
      heading:"accrodianPricing.priceOne",
      theme_number: "themeDATA.theme1",
      tick: [
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
      ],
    },

    {
      id: "2",
      heading: "accrodianPricing.priceTwo",
      theme_number: "themeDATA.theme2",
      tick: [
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
      ],
    },

    {
      id: "3",
      heading: "accrodianPricing.priceThree",
      theme_number: "themeDATA.theme3",
      tick: [
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
      ],
    },

    {
      id: "4",
      heading: "accrodianPricing.priceFour",
      theme_number: "themeDATA.theme4",
      tick: [
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
        Images.Shape,
      ],
    }
  ]

  // const column_content = ["choosePlan.reacharge_plan", 'Used uptp 3 theme', 'Used uptp 3 theme',
  //   'Used uptp 3 theme', 'Used uptp 3 theme', 'Used uptp 3 theme', 'Used uptp 3 theme',
  //   'Used uptp 3 theme', 'Used uptp 3 theme', 'Used uptp 3 theme', 'Used uptp 3 theme']
    const column_content = [
      "choosePlan.reacharge_plan",
      "choosePlan.reacharge_plan",
      "choosePlan.reacharge_plan",
      "choosePlan.reacharge_plan",
      "choosePlan.reacharge_plan",
      "choosePlan.reacharge_plan",
      "choosePlan.reacharge_plan",
      "choosePlan.reacharge_plan",
      "choosePlan.reacharge_plan",
      "choosePlan.reacharge_plan",
      "choosePlan.reacharge_plan",
    ]

  const expansionHandler = (e) => {
    setDisplay(!display)
    console.log('clicked')
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Header />
      <p className='pricing-heading' >{t('pricing.headingTop')}
        <br />
        <span className="pricing-heading-para">
          {t('pricing.headingSecond')}
        </span>
      </p>
      {isMobile ? (<AccrodianPricing />) : (
        <div className='column-div' >
          {package_data.map((item, i) => (
            <div className="column-design"  >
              <h4>{t(item.package_heading)}</h4>
              <div className="header-div-package">
                {item.amount.map((amount_item, i) => (
                  <div className="header-div-text" style={{ fontWeight: amount_item.weight, fontSize: amount_item.size, color: amount_item.color }} >
                    {t(amount_item.title)}
                  </div>
                ))}
              </div>
              {item.theme.map((theme_item, id) => {
                return (
                  <div className="header-div header-div-para">
                    <p key={id} className=''>{t(theme_item)}</p>
                  </div>
                )
              })}
              <div className='bottom-buttons-div' >
                {item.bottom_button.map((buttom_theme, id) => (
                  <Button className="column-button" style={{ backgroundColor: buttom_theme.background_color }} >{t(buttom_theme.title)}</Button>
                ))}

              </div>
            </div>
          ))}

        </div>
      )}


      <div className='centering'>
        <Button onClick={expansionHandler} className='for-detailed-button' variant='outlined' > {`${display ? t("choosePlan.viewDetails_1") : t("choosePlan.viewDetails_2")}`}</Button>
      </div>

      {display && <div >
        <div className='container-column-div-expansion hide-in-mobile'>
          <div className='column-div-expansion'>

            <div className="column-design-expansion">
              <div className="header-div-first">
                <p>{t('choosePlan.includeplan')}</p>
              </div>
              <div style={{ textAlign: 'center'}} >
                <p className="theme_classes theme_heading_alignment" >{t('choosePlan.numberOfTheme')}</p>
                <p className='theme_classes theme_classes_first_alignment'>{t('choosePlan.Loremipsum')}</p>
                <p className='theme_classes theme_classes_alignment' >{t('choosePlan.Loremipsum')}</p>
                <p className='theme_classes theme_classes_alignment' >{t('choosePlan.Loremipsum')}</p>
                <p className='theme_classes theme_classes_alignment' >{t('choosePlan.Loremipsum')}</p>
                <p className='theme_classes theme_classes_alignment' >{t('choosePlan.Loremipsum')}</p>
                <p className='theme_classes theme_classes_alignment' >{t('choosePlan.Loremipsum')}</p>
                <p className='theme_classes theme_classes_alignment' >{t('choosePlan.Loremipsum')}</p>
                <p className='theme_classes theme_classes_alignment' >{t('choosePlan.Loremipsum')}</p>
                <p className='theme_classes theme_classes_alignment' >{t('choosePlan.Loremipsum')}</p>
                <p className='theme_classes theme_classes_alignment' >{t('choosePlan.Loremipsum')}</p>
                <p className='theme_classes theme_classes_alignment' >{t('choosePlan.Loremipsum')}</p>
                <p className='theme_classes theme_classes_alignment' >{t('choosePlan.Loremipsum')}</p>
                <p className='theme_classes theme_classes_alignment' >{t('choosePlan.Loremipsum')}</p>
              </div>
            </div>

            {package_rating_data.map((rating_item, i) => (

              <div className="column-design-expansion-two" >
                <div className="header-div header-div-margin-top" >
                  <p>{t(rating_item.heading)}</p>
                </div>
                <p className="theme_classes-theme" >{t(rating_item.theme_number)}</p>
                {rating_item.tick.map((tick_item, i) => (
                  <div className='theme-rows'>
                    <img src={tick_item} alt="not" />
                  </div>
                ))}

              </div>
            ))}

          </div>
        </div>
      </div>
      }


      {display &&
        <Container maxWidth="sm" className="hide-in-desktop">

          <Grid container>
            <Grid item xs={6} >
              <div className='container-mobile'>
                <div className="header-div-first-mobile">
                  <p>{t('choosePlan.includeplan')}</p>
                </div>
                <div style={{ textAlign: 'center' }} >
                  <p className="theme_classes theme_heading_alignment-mobile" >{t('choosePlan.numberOfTheme')}</p>
                  <p className='theme_classes-mobile' >{t('choosePlan.Loremipsum')}</p>
                  <p className='theme_classes-mobile' >{t('choosePlan.Loremipsum')}</p>
                  <p className='theme_classes-mobile' >{t('choosePlan.Loremipsum')}</p>
                  <p className='theme_classes-mobile' >{t('choosePlan.Loremipsum')}</p>
                  <p className='theme_classes-mobile' >{t('choosePlan.Loremipsum')}</p>
                  <p className='theme_classes-mobile' >{t('choosePlan.Loremipsum')}</p>
                  <p className='theme_classes-mobile' >{t('choosePlan.Loremipsum')}</p>
                  <p className='theme_classes-mobile' >{t('choosePlan.Loremipsum')}</p>
                  <p className='theme_classes-mobile' >{t('choosePlan.Loremipsum')}</p>
                  <p className='theme_classes-mobile' >{t('choosePlan.Loremipsum')}</p>
                  <p className='theme_classes-mobile' >{t('choosePlan.Loremipsum')}</p>
                  <p className='theme_classes-mobile' >{t('choosePlan.Loremipsum')}</p>
                  <p className='theme_classes-mobile' >{t('choosePlan.Loremipsum')}</p>
                </div>
              </div>

            </Grid>

            <Grid item xs={6}>
              <div style={{ marginLeft: '4px'}}>
                <div className="column-design-expansion-two" id="mobile">
                  <div className="theme-number-mobile" >
                    <select id="mySelect" onChange={() => myFunction()}>
                      <option value="nothing">{t('choosePlan.selectList')}</option>
                      {package_rating_data.map((rating_item, i) => (

                        <option value={rating_item.theme_number}>{rating_item.heading}</option>

                      ))}

                    </select>
                  </div>
                </div>

                {package_rating_data.map((rating_item, i) => (
                  <div id={rating_item.id} >
                    {console.log('theme', x, rating_item.theme_number)}
                    {
                    
                      (rating_item.theme_number === x) ? (<div className='theme-rows-mobile' >
                        <p className="rating-item-mobile" >{rating_item.theme_number}</p>
                        {rating_item.tick.map((tick_item, i) => (
                          <div className="image-styling-mobile" >
                            <img src={tick_item} alt="not" width="20px" height="20px" className='image-width'/>
                          </div>
                        ))}
                      </div>
                      )
                        : console.log('code is not working')
                    }

                  </div>
                )
                )}
              </div>
            </Grid>
          </Grid>

        </Container>
      }
    </div>

  )
}
