import * as React from "react";
import { Button, Container } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";


export default function ControlledAccordions() {
  const [expanded, setExpanded] = React.useState(false);
  

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const column_content = [
    "Used uptp 3 theme",
    "Used uptp 3 theme",
    "Used uptp 3 theme",
    "Used uptp 3 theme",
    "Used uptp 3 theme",
    "Used uptp 3 theme",
    "Used uptp 3 theme",
    "Used uptp 3 theme",
    "Used uptp 3 theme",
    "Used uptp 3 theme",
    "Used uptp 3 theme",
  ];
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Container>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
            {t('accrodianPricing.priceOne')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="column-design">
              <div className="pricing-accordian-header-div">
                <p className="header-div-text">Free</p>
              </div>
              {column_content.map((item, id) => {
                return (
                  <div className="header-div-para">
                    <p key={id} className="">
                      {item}
                    </p>
                  </div>
                );
              })}
              <br />
              <Button className="column-button">Choose this plan</Button>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
            {t('pricing.priceTwo')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="column-design">
              <div className="pricing-accordian-header-div">
                <p className="header-div-text">
                  $100/month <br />
                  <span className="header-div-desc">
                    $80/month paid annually
                  </span>
                </p>
              </div>
              {column_content.map((item, id) => {
                return (
                  <div className="header-div header-div-para">
                    <p key={id} className="">
                      {item}
                    </p>
                  </div>
                );
              })}
              <div>
                <Button className="column-button">Choose monthly</Button>
                <Button className="column-button">Choose annually</Button>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
        {t('pricing.priceThree')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="column-design">
              <div className="pricing-accordian-header-div">
                <p className="header-div-text">
                  $100/month <br />
                  <span className="header-div-desc">
                    $80/month paid annually
                  </span>
                </p>
              </div>
              {column_content.map((item, id) => {
                return (
                  <div className="header-div header-div-para">
                    <p key={id} className="">
                      {item}
                    </p>
                  </div>
                );
              })}
              <div>
                <div>
                  <Button className="column-button">Choose monthly </Button>
                </div>
                <div>
                  <Button className="column-button">Choose annually</Button>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
             {t('pricing.pricingFour')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="column-design">
              <div className="pricing-accordian-header-div">
                <p className="header-div-text">
                  $100/month <br />
                  <span className="header-div-desc">
                    $80/month paid annually
                  </span>
                </p>
              </div>
              {column_content.map((item, id) => {
                return (
                  <div className="header-div header-div-para">
                    <p key={id} className="">
                      {item}
                    </p>
                  </div>
                );
              })}
              <Button className="column-button">Choose monthly </Button>
              <Button className="column-button">Choose annually</Button>
            </div>
          </AccordionDetails>
        </Accordion>
      </Container>
    </div>
  );
}
