import React from "react";
import "./styles.scss";

const CustomFileInput = ({
  title = "Choose Image",
  name = "No file chosen",
  ...otherProps
}) => {
  return (
    <div className="customFileInput" >
      <input
        type="file"
        id="actual-btn"
        accept="image/*"
        hidden
        {...otherProps}
        style={{backgroundColor:'#3ed0a3'}}
      />

      <label for="actual-btn"  >{title}</label>
      {/* <span id="file-chosen">{name.substring(0, 25)}...</span> */}
    </div>
  );
};

export default CustomFileInput;
