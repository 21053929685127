import { Box, Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { BsArrowsMove } from "react-icons/bs";
import { Draggable } from "react-beautiful-dnd";
import { FaTrash, FaPencilAlt } from "react-icons/fa";
import { deleteNodeAction } from "../../actions/ApiAction";
import { useDispatch } from "react-redux";
import { SHOW_MODAL_STATE } from "../../constants/ApiConstants";

import axios from "axios";

const _style = makeStyles(function (theme) {
  return {
    grow: {
      flexGrow: 1,
    },
    root: {
      background: "#090909",
      color: "#fff",
    },
    title: {
      padding: "2rem 0rem",
      fontSize: "1.5rem",
      fontWeight: 500,
    },
    productTitle: {
      textOverflow: "ellipsis",
      maxLines: 2,
      /* Needed to make it work */
      overflow: "hidden",
      whiteSpace: "nowrap",
      color: "#6B6B6B",
    },
    img: {
      objectFit: "contain",
      maxWidth: "100%",
    },
    dataItem: {},
  };
});

export default function Collections({ nodeData }) {
  let classes = _style(nodeData.styles);

  const [data, setData] = useState([...nodeData.data.collection_list]);

  useEffect(() => {
    // getData();
  }, [nodeData]);


  const dispatch = useDispatch();

  const EditFunctionality = () => {
    dispatch({ type: SHOW_MODAL_STATE, payload: nodeData });
  };

  const deleteNodeHandler = (node) => {
    dispatch(deleteNodeAction(node));
  };

  return (
    <Draggable key={nodeData.id} draggableId="Collections" index={nodeData.id}>
      {(provided) => (
        <div
          className={(classes.grow, "componentBuildBox")}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          {" "}
          <div className="cmpMoveIcon" {...provided.dragHandleProps}>
            <BsArrowsMove className="cmp" />
          </div>
          <div className="deleteFlex">
            <div className="editIDiv" onClick={EditFunctionality}>
              <FaPencilAlt className="editIcon" />
            </div>
            <div
              className="deleteIDiv"
              onClick={() => deleteNodeHandler(nodeData)}
            >
              <FaTrash className="deleteIcon" />
            </div>
          </div>
          <div className={classes.root}>
            <Container>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} align="center" className={classes.title}>
                  {nodeData.data.title}
                </Grid>
                {data.map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2}
                    key={index}
                    align="center"
                  >
                    <Box m={2}>
                      {/* <img src={item.imageUrl} alt={index} className={classes.img} /> */}
                      <img
                        src="https://assets.ajio.com/medias/sys_master/root/20210403/GEud/606885be7cdb8c1f14774922/-473Wx593H-461575169-maroon-MODEL.jpg"
                        alt={index}
                        className={classes.img}
                      />
                    </Box>
                    <Box m={1} align="center" className={classes.productTitle}>
                      {item.title}
                    </Box>
                    <Box my={1} align="center">
                      {item.price}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </div>
        </div>
      )}
    </Draggable>
  );
}
