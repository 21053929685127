import React from "react";
import { Modal } from "react-bootstrap";
import "./subscriptionPlan.scss";
// import Pic from "../../../assets/icons/user.png";

const SubscriptionPlan: React.FC = () => {
  function MyVerticallyCenteredModal(props: any) {
    return (
      <Modal
        {...props}
        className="_change-plan-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="xl"
      >
        <Modal.Header>
          <h2>Subscription plan benefit</h2>
          <button onClick={props.onHide} className="_close-modal">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="28" height="28" rx="8" fill="#E8ECF0" />
              <path
                d="M20.698 8.23344L19.7383 7.28517C19.4786 7.02549 19.0496 7.02549 18.7786 7.28517L13.9915 12.0721L9.1142 7.19484C8.85441 6.93505 8.42537 6.93505 8.15452 7.19484L7.19484 8.15452C6.93505 8.4142 6.93505 8.84323 7.19484 9.1142L12.061 13.9803L7.28517 18.7787C7.02549 19.0384 7.02549 19.4674 7.28517 19.7384L8.24484 20.6981C8.50452 20.9577 8.93355 20.9577 9.20452 20.6981L13.9915 15.911L18.7786 20.6981C19.0383 20.9577 19.4673 20.9577 19.7383 20.6981L20.698 19.7384C20.9576 19.4787 20.9576 19.0497 20.698 18.7787L15.8996 13.9916L20.6867 9.20463C20.9576 8.93344 20.9576 8.50441 20.698 8.23344Z"
                fill="#777C85"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="_subs-plan">
            <div className="_package">
              <h3 className="_title">Package A</h3>
              <div className="_subs-type">
                <h4>Free</h4>
              </div>

              <ul className="_benefits">
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
              </ul>

              <div className="_current-plan">
                <span>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 9C0 4.03759 4.03759 0 9 0C13.9624 0 18 4.03759 18 9C18 13.9624 13.9624 18 9 18C4.03759 18 0 13.9624 0 9ZM8.2782 12.3383L14.0301 6.63158C14.391 6.27068 14.391 5.70677 14.0301 5.34586C13.6692 4.98496 13.1053 4.98496 12.7444 5.34586L7.6015 10.4887L5.18797 8.2782C4.82707 7.93985 4.26316 7.96241 3.92481 8.32331C3.60902 8.68421 3.63158 9.24812 3.99248 9.58647L7.03759 12.3609C7.3985 12.6992 7.93985 12.6767 8.2782 12.3383Z"
                      fill="#777C85"
                    />
                  </svg>
                </span>
                <p>Your current plan</p>
              </div>
            </div>

            <div className="_package">
              <h3 className="_title">Package B</h3>
              <div className="_subs-type">
                <h4 className="_price">$100/month</h4>
                <p>$80/month paid annually</p>
              </div>

              <ul className="_benefits">
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
              </ul>

              <div className="_subs-btn">
                <button className="_monthly">Choose monthly</button>
                <button className="_annually">Choose annually</button>
              </div>
            </div>

            <div className="_package">
              <h3 className="_title">Package B</h3>
              <div className="_subs-type">
                <h4 className="_price">$100/month</h4>
                <p>$80/month paid annually</p>
              </div>

              <ul className="_benefits">
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
              </ul>

              <div className="_subs-btn">
                <button className="_monthly">Choose monthly</button>
                <button className="_annually">Choose annually</button>
              </div>
            </div>

            <div className="_package">
              <h3 className="_title">Package B</h3>
              <div className="_subs-type">
                <h4 className="_price">$100/month</h4>
                <p>$80/month paid annually</p>
              </div>

              <ul className="_benefits">
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
                <li>Used up to 3 theme</li>
              </ul>

              <div className="_subs-btn">
                <button className="_monthly">Choose monthly</button>
                <button className="_annually">Choose annually</button>
              </div>
            </div>
          </div>
          <button className="_view-detail">View detailed comparison</button>
        </Modal.Body>
      </Modal>
    );
  }
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <button onClick={() => setModalShow(true)} className="_change-btn">
        Change
      </button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default SubscriptionPlan;
