import { FaBars, FaAngleDown, FaPlus } from "react-icons/fa";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { markupJson as json } from "../VanillaThemeTemplate/MarkupRenderer";
import { createLayoutAction } from "../actions/ApiAction";

const NewLayoutBox = () => {
  const dispatch = useDispatch();

  // fetching component names from store
  var layoutList = json ? json.layout : null;

  //   states
  const [list, setList] = useState(layoutList);

  useEffect(() => {}, [setList, layoutList, list, dispatch]);

  // adding component to template
  const addComponentToTemplate = (type) => {
    const layoutObject = list.find((item) => item.type === type);
    dispatch(createLayoutAction(layoutObject));
  };

  return (
    <div className="layoutBox">
      {list ? (
        list.map((item, index) => (
          <span key={index}>
            <div className="LayoutGrid2Box mb-2">
              <div
                className="dragHandle2"
                onClick={() => addComponentToTemplate(item.type)}
              >
                <FaPlus className="text-dark" />
              </div>

              <b className="opacity">{item.type}</b>
            </div>
          </span>
        ))
      ) : (
        <div className="LayoutGridBox">
          <FaBars />
          <b className="opacity">----------</b>
          <FaAngleDown className="angleDrop" />
        </div>
      )}

      <button className="activateNewButton mt-3 font-weight-bold">
        Add Section
      </button>
    </div>
  );
};

export default NewLayoutBox;
