import React, { useEffect, useState } from "react";
import "./activeOrder.scss";
import { useSelector, useDispatch } from "react-redux";
import { GetOrdersAction } from "../../../UpdatedStore/actions/SiteActions";
import Message from "../../../UpdatedStore/components/Message";
import { HashLink as Link } from "react-router-hash-link";
import SpinnerLoader from "../../../UpdatedStore/components/SpinnerLoader";
const ActiveOrder: React.FC = () => {
  const { loading, orders, error } = useSelector(
    (state) => state.OrdersReducer
  );
  const { user } = useSelector((state) => state.AuthenticationReducer);

  const [ordersLength, setOrdersLength] = useState(true);
  const [ordersDetails, setOrdersDetails] = useState(0);
  const [ordersCompleted, setOrdersCompleted] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!orders) {
      dispatch(GetOrdersAction(user.access_token));
    }

    if (orders) {
      setOrdersDetails(0);
      setOrdersCompleted(0);
      orders.map((order) => {
        if (order.orders.length > 0) {
          setOrdersLength(false);

          order.orders.filter((item) => {
            setOrdersDetails((current) => (current += 1));
          });
          order.orders.filter((item) => {
            if (item.isDelivered) {
              setOrdersCompleted((current) => (current += 1));
            }
          });
        }
      });
    }
  }, [orders]);

  return (
    <div className="_active-order">
      <div className="_order-status">
        <div className="_order-number">
          <div className="_number">
            <span>Total order</span>
            <h3 className="_cancel">{ordersDetails}</h3>
          </div>
          <div className="_number">
            <span>Complete order</span>
            <h3 className="_big-main-color">{ordersCompleted}</h3>
          </div>
          <div className="_number">
            <span>Active order</span>
            <h3 className="_big-second-main">
              {Number(ordersDetails) - Number(ordersCompleted)}
            </h3>
          </div>
        </div>
        <div className="_grap">
          <svg
            width="100%"
            height="16"
            viewBox="0 0 622 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="622" height="16" rx="8" fill="#3ED0A3" />
            <rect width="468" height="16" rx="8" fill="#16284D" />
            <rect width="37" height="16" rx="8" fill="#F2482F" />
          </svg>
        </div>
      </div>

      <div className="_active">
        <h2>Your recent Orders</h2>
        {loading && <SpinnerLoader />}
        {error && <Message variant="danger">{error}</Message>}
        {!loading && (
          <>
            {orders && ordersLength && (
              <Message variant="primary">
                You have no active orders yet!
              </Message>
            )}
          </>
        )}

        {orders &&
          orders
            .reverse()
            .slice(0, 10)
            .map((site) =>
              site.orders.map((item) => (
                <Link to={`/dashboard/orders#${item._id}_${site.site.name}`}>
                  {" "}
                  <div className="_order-info">
                    <div className="_name">
                      <h3>ID: {item._id}</h3>
                      <div className="_avatar">
                        <span>
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="11" cy="11" r="11" fill="#CFF4E8" />
                            <mask id="mask0" x="0" y="0" width="22" height="22">
                              <circle cx="11" cy="11" r="11" fill="white" />
                            </mask>
                            <g mask="url(#mask0)">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6.8 9.2C6.8 6.88412 8.68412 5 11 5C13.3159 5 15.2 6.88412 15.2 9.2C15.2 11.5159 13.3159 13.4 11 13.4C8.68412 13.4 6.8 11.5159 6.8 9.2ZM11.9333 14.3333C13.5514 14.3333 15.0757 14.9763 16.2256 16.1438C17.3698 17.3057 18 18.8392 18 20.4622C18 20.7199 17.7911 20.9289 17.5333 20.9289C17.5333 20.9289 13.0887 23 11 23C8.73319 23 4.46667 20.9289 4.46667 20.9289C4.20894 20.9289 4 20.7199 4 20.4622C4 18.8392 4.63016 17.3057 5.77439 16.1438C6.92426 14.9763 8.44864 14.3333 10.0667 14.3333H11.9333Z"
                                fill="#3ED0A3"
                              />
                            </g>
                          </svg>
                        </span>

                        <p>
                          <small
                            style={{
                              color: "#16284D",
                            }}
                          >
                            {site.site.name}
                          </small>{" "}
                          •{" "}
                          <small
                            style={{
                              color: "#777C85",
                            }}
                          >
                            {item?.isPaid &&
                              !item?.isShipped &&
                              !item?.isDelivered &&
                              !item?.isCancelled &&
                              "Purchased"}
                            {item?.isPaid &&
                              item?.isShipped &&
                              !item?.isDelivered &&
                              !item?.isCancelled &&
                              "Out For Delivery"}
                            {item?.isPaid &&
                              item?.isShipped &&
                              item?.isDelivered &&
                              !item?.isCancelled &&
                              "Delivered"}
                            {item?.isCancelled && "Cancelled"}
                          </small>
                        </p>
                      </div>
                    </div>

                    <div className="_trans-date">
                      <span>Transaction date</span>
                      <p>
                        {" "}
                        {item?.paidAt.substring(0, 10)} @
                        {item?.paidAt.substring(11, 19)}
                      </p>
                    </div>

                    <div className="_total-price">
                      <span>Total price</span>
                      <p>$ {item && Number(item.totalPrice).toFixed(2)}</p>
                    </div>

                    <span className="_arrow-right">
                      <svg
                        width="14"
                        height="15"
                        viewBox="0 0 14 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.3417 7.49797C10.3417 7.71297 10.2597 7.92793 10.0958 8.09184L4.93779 13.2498C4.60967 13.5779 4.07769 13.5779 3.74971 13.2498C3.42173 12.9218 3.42173 12.39 3.74971 12.0618L8.31383 7.49797L3.74987 2.9341C3.42189 2.60598 3.42189 2.07416 3.74987 1.74621C4.07785 1.41793 4.60983 1.41793 4.93795 1.74621L10.096 6.90411C10.2598 7.0681 10.3417 7.28306 10.3417 7.49797Z"
                          fill="#BEC3CD"
                        />
                      </svg>
                    </span>
                  </div>
                </Link>
              ))
            )}
      </div>
    </div>
  );
};

export default ActiveOrder;
