import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { FaPencilAlt } from "react-icons/fa";
import EditNavbar from "./editComponents/EditNavbar";
import EditCarousel from "./editComponents/EditCarousel";
import EditCategoryList from "./editComponents/EditCategoryList";
import EditNewProducts from "./editComponents/EditNewProducts";
import EditCollection from "./editComponents/EditCollection";
import EditPromotions from "./editComponents/EditPromotions";
import EditFooter from "./editComponents/EditFooter";

const EditModal = (props) => {
  const { node } = useSelector((state) => state.EditModalReducer);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      centered
      scrollable="true"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3 className="text-uppercase font-weight-bold tint text-center">
            {node ? node.type : "component"} <FaPencilAlt />
          </h3>
        </Modal.Title>
      </Modal.Header>
      {node && node.type === "Navbar" ? (
        <EditNavbar nodee={node} props={props} />
      ) : node && node.type === "Carousel" ? (
        <EditCarousel nodee={node} props={props} />
      ) : node && node.type === "CategoryList" ? (
        <EditCategoryList nodee={node} props={props} />
      ) : node && node.type === "NewProducts" ? (
        <EditNewProducts nodee={node} props={props} />
      ) : node && node.type === "Collections" ? (
        <EditCollection nodee={node} props={props} />
      ) : node && node.type === "OurPromotions" ? (
        <EditPromotions nodee={node} props={props} />
      ) : node && node.type === "Footer" ? (
        <EditFooter nodee={node} props={props} />
      ) : (
        <>
          <Modal.Body>
            <h4 className="tint opacity font-weight-bold mb-4">PROPERTIES</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-dark font-weight-bold rounded"
              onClick={props.onHide}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default EditModal;
