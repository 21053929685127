import React from 'react'

interface ToggleProps { 
    children?: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {}; 
};

type Ref = HTMLAnchorElement | null;
type DivRef = HTMLDivElement | null;

interface MenuProps  {
    children: React.ReactNode;
    className?: string;
    id?: string;
    style?: Object;
}

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
export const CustomToggle = React.forwardRef<Ref, ToggleProps>(({ children, onClick }, ref) => (
    <a
        href="/#"
        className="primary-color"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        
    </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
export const CustomMenu = React.forwardRef<DivRef, MenuProps>(
    ({ children, style, className, id }, ref) => {

        return (
            <div
                id={id}
                ref={ref}
                style={style}
                className={className}
            >
                {children}
            </div>
        );
    },
);
