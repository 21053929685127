import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import axios from "axios";
import { BsArrowsMove } from "react-icons/bs";
import { FaTrash, FaPencilAlt } from "react-icons/fa";
import { Draggable } from "react-beautiful-dnd";
import { deleteNodeAction } from "../../actions/ApiAction";
import { SHOW_MODAL_STATE } from "../../constants/ApiConstants";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { markupJson } from "../MarkupRenderer";

const _style = makeStyles(function (theme) {
  return {
    grow: {
      flexGrow: 1,
    },
    root: function (props) {
      return {
        backgroundColor: props.bgColor,
        color: props.titleColor,
        padding: "30px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      };
    },

    FristCol: {
      padding: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "30px",
    },

    ThreeCol: function (props) {
      return {
        display: "flex",
        justifyContent: "center",
        "& a": {
          fontSize: theme.typography.pxToRem(16),
          fontWeight: "bold",
          textDecoration: "none",
          color: props.titleColor,
          margin: "5px 30px",
        },
      };
    },

    FourCol: function (props) {
      return {
        display: "flex",
        justifyContent: "center",
        margin: "20px 0",
        "& h4": {
          fontSize: theme.typography.pxToRem(14),
          color: props.titleColor,
          fontFamily: `'Montserrat', 'sans-serif'`,
          fontWeight: "500",
          textAlign: "center",
        },
      };
    },

    HoldSocial: {
      display: "flex",
      alignItems: "center",
      margin: "20px 0",
      justifyContent: "center",
      "& a": {
        marginRight: "35px",
      },
    },

    lastHead: function (props) {
      return {
        color: props.titleColor,
        fontWeight: "bold",
      };
    },
  };
});

export default function Footer({ nodeData }) {
  const layout = markupJson.layout.find((item) => item.type === "Navbar");

  const { layout: NavLayout } = useSelector(
    (state) => state.MarkupLayoutReducer.present
  );

  const NavNode = NavLayout.find((item) => item.type === "Navbar");

  let classes = _style(NavNode ? NavNode.styles : layout.styles);

  // const [data, setData] = useState([...nodeData.data.footer_links]);

  useEffect(() => {
    // getData();
  }, [nodeData]);

  async function getData() {
    var response = await axios.get(nodeData.data.api.url);
    // nodeData.data = response.data;
    // setData(response.data);
  }

  const dispatch = useDispatch();

  const deleteNodeHandler = (node) => {
    dispatch(deleteNodeAction(node));
  };

  const EditFunctionality = () => {
    dispatch({ type: SHOW_MODAL_STATE, payload: nodeData });
  };

  return (
    <Draggable key={nodeData.id} draggableId="Footer" index={nodeData.id}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={(classes.grow, "componentBuildBox")}
        >
          <div {...provided.dragHandleProps} className="cmpMoveIcon">
            <BsArrowsMove className="cmp" />
          </div>
          <div className="deleteFlex">
            <div className="editIDiv" onClick={EditFunctionality}>
              <FaPencilAlt className="editIcon" />
            </div>
            <div
              className="deleteIDiv"
              onClick={() => deleteNodeHandler(nodeData)}
            >
              <FaTrash className="deleteIcon" />
            </div>
          </div>

          <div className={classes.root}>
            <div className={classes.FristCol}>
              <div>
                <img src={nodeData.data.logo} alt="Logo" />
              </div>
            </div>

            <div className={classes.ThreeCol}>
              {nodeData.data.column_three.map((item, index) => (
                <div key={index}>
                  <Link to={item.url}>{item.name}</Link>
                </div>
              ))}
            </div>

            <div className={classes.FourCol}>
              {nodeData.data.column_four.map((item, index) => (
                <Box m={2} key={index} xs={3}>
                  <Typography component="h4">{item.address}</Typography>

                  <Box className={classes.HoldSocial}>
                    <Link to="/instagram.com">
                      <img
                        src={item.instagram}
                        className={classes.ImageSocial}
                        alt="instagram"
                      />
                    </Link>
                    <Link to="/twitter.com">
                      <img
                        className={classes.ImageSocial}
                        src={item.twitter}
                        alt="twitter"
                      />
                    </Link>
                    <Link to="/facebook.com">
                      <img
                        className={classes.ImageSocial}
                        src={item.facebook}
                        alt="facebook"
                      />
                    </Link>
                  </Box>
                </Box>
              ))}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}
