import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import OrderPic from "../../../../assets/icons/order.png";
import { Image } from "react-bootstrap";
import "./itemSummary.scss";
import { PieChart } from "react-minimal-pie-chart";
import { FaCalculator } from "react-icons/fa";

const ItemSummaryDetails: React.FC = ({
  details,
  promotion = false,
  orders,
}) => {
  const [modalShow, setModalShow] = React.useState(false);

  const [itemTotalProfit, setItemTotalProfit] = useState(0);
  const [itemTotalCost, setItemTotalCost] = useState("");

  const calculateItemProfit = (value) => {
    if (Number(value) <= 0) {
      setItemTotalCost("");
      setItemTotalProfit(0);
      return;
    }
    setItemTotalCost(value);

    if (typeof Number(value) === "number") {
      if (!promotion) {
        let evaluatedValue = (
          Number(details?.price).toFixed(2) * Number(details?.sold_items).toFixed(2) -
          Number(value).toFixed(2)
        ).toFixed(2);

        setItemTotalProfit(
          evaluatedValue ? evaluatedValue : Number("0").toFixed(2)
        );
      } else {
        let evaluatedValue = (
          Number(details?.CurrentPirce).toFixed(2) *
            Number(details?.sold_items).toFixed(2) -
          Number(value).toFixed(2)
        ).toFixed(2);

        setItemTotalProfit(
          evaluatedValue ? evaluatedValue : Number("0").toFixed(2)
        );
      }
    } else {
      setItemTotalProfit(
        Number(details?.price).toFixed(2) * Number(details?.sold_items).toFixed(2)
      );
    }
  };

  var xData = [
    { title: "Sold", value: details?.sold_items, color: "#3ED0A3" },
    { title: "For sale", value: details.quantity, color: "#16284D" },
  ];

  const [selected, setSelected] = useState<number | undefined>(0);
  const [hovered, setHovered] = useState<number | undefined>(undefined);

  const data = xData.map((entry, i) => {
    if (hovered === i) {
      return {
        ...entry,
        color: "grey",
      };
    }
    return entry;
  });

  const lineWidth = 60;

  const loadQuantity = () => {
    if (orders) {
      return
    }
  };

  useEffect(() => {
    loadQuantity();
  }, [details, orders]);
  return (
    <>
      <div className="_items _add-border" onClick={() => setModalShow(true)}>
        <div className="_hold-order-image">
          <Image
            src={!promotion ? details.imageUrl : details.ImgUrl}
            style={{
              width: "135px",
              height: "135px",
              borderRadius: "10px",
              objectFit: "fill",
            }}
          />
        </div>

        <div className="_order-details">
          <span className="_item-name">{details.name}</span>
          <div className="_items-sold">
            <p>
              $
              {Number(
                !promotion ? details.price : details.CurrentPirce
              ).toFixed(2)}{" "}
            </p>
            <span>•</span>
            <p>{details.quantity} items</p>
          </div>
        </div>

        <span className="_icon-down">
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.99997 13.0602C7.71322 13.0602 7.4265 12.9507 7.20788 12.7322L0.328227 5.8525C-0.109409 5.41486 -0.109409 4.70531 0.328227 4.26785C0.765686 3.83039 1.4751 3.83039 1.91277 4.26785L7.99997 10.3554L14.0872 4.26806C14.5249 3.83061 15.2342 3.83061 15.6716 4.26806C16.1095 4.70552 16.1095 5.41507 15.6716 5.85271L8.79207 12.7324C8.57334 12.951 8.28662 13.0602 7.99997 13.0602Z"
              fill="#6B6B6B"
            />
          </svg>
        </span>
      </div>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="_item-summary-details"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Item summary details
          </Modal.Title>

          <button onClick={() => setModalShow(false)} className="_close-modal">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="28" height="28" rx="8" fill="#E8ECF0" />
              <path
                d="M20.698 8.23344L19.7383 7.28517C19.4786 7.02549 19.0496 7.02549 18.7786 7.28517L13.9915 12.0721L9.1142 7.19484C8.85441 6.93505 8.42537 6.93505 8.15452 7.19484L7.19484 8.15452C6.93505 8.4142 6.93505 8.84323 7.19484 9.1142L12.061 13.9803L7.28517 18.7787C7.02549 19.0384 7.02549 19.4674 7.28517 19.7384L8.24484 20.6981C8.50452 20.9577 8.93355 20.9577 9.20452 20.6981L13.9915 15.911L18.7786 20.6981C19.0383 20.9577 19.4673 20.9577 19.7383 20.6981L20.698 19.7384C20.9576 19.4787 20.9576 19.0497 20.698 18.7787L15.8996 13.9916L20.6867 9.20463C20.9576 8.93344 20.9576 8.50441 20.698 8.23344Z"
                fill="#777C85"
              />
            </svg>
          </button>
        </Modal.Header>
        <div className="_hold-this-item-summary-details">
          <div className="_items">
            <div className="_hold-order-image">
              <Image
                src={!promotion ? details.imageUrl : details.ImgUrl}
                style={{
                  width: "135px",
                  height: "135px",
                  borderRadius: "10px",
                  objectFit: "fill",
                }}
              />{" "}
            </div>

            <div className="_order-details">
              <span className="_item-name">{details.name}</span>
              <div className="_items-sold">
                <p>
                  $
                  {Number(
                    !promotion ? details.price : details.CurrentPirce
                  ).toFixed(2)}{" "}
                </p>
                <span>•</span>
                <p>{details?.sold_items} sold</p>
              </div>
            </div>
          </div>

          <div className="_sold-availabilty">
            <div className="graphColumn">
              <div className="_hold-graph">
                <PieChart
                  style={{
                    fontFamily:
                      '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
                    fontSize: "8px",
                  }}
                  data={data}
                  viewBoxSize={[100, 100]}
                  radius={PieChart.defaultProps.radius - 6}
                  lineWidth={60}
                  segmentsStyle={{
                    transition: "stroke .3s",
                    cursor: "pointer",
                  }}
                  segmentsShift={(index) => (index === selected ? 6 : 1)}
                  animate
                  label={({ dataEntry }) =>
                    Math.round(dataEntry.percentage) + "%"
                  }
                  labelPosition={100 - lineWidth / 2}
                  labelStyle={{
                    fill: "#fff",
                    opacity: 0.75,
                    pointerEvents: "none",
                  }}
                  onClick={(_, index) => {
                    setSelected(index === selected ? undefined : index);
                  }}
                  onMouseOver={(_, index) => {
                    setHovered(index);
                  }}
                  onMouseOut={() => {
                    setHovered(undefined);
                  }}
                />
              </div>

              <div className="_item-sold">
                <div className="_sold">
                  <span>
                    <svg
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="4" cy="4" r="4" fill="#3ED0A3" />
                    </svg>
                  </span>
                  <h3>{details?.sold_items} Sold</h3>
                </div>
                <div className="_sold">
                  <span>
                    <svg
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="4" cy="4" r="4" fill="#16284D" />
                    </svg>
                  </span>
                  <h3>{details.quantity} available</h3>
                </div>
              </div>
            </div>

            <div className="itemProfitCalculaterGrid">
              <div className="_profit_card">
                <h6 className="_no">Total Profit</h6>
                <p className="_number">{`$ ${itemTotalProfit}`}</p>
              </div>

              <div className="_profit_card_calc">
                <h6 className="_no">Calculate Item Profit</h6>

                <div className="search-bar">
                  <span className="_search-icon">
                    <FaCalculator width={18} height={18} />
                  </span>
                  <input
                    type="number"
                    disabled={details?.sold_items <= 0}
                    value={itemTotalCost}
                    placeholder="Your Average Cost Price"
                    onChange={(e) => calculateItemProfit(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ItemSummaryDetails;
