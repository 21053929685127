import React from "react";
import "./yourSubscriptions.scss";
import PackageBox from "../../../assets/icons/packagebox.svg";
import { Image } from "react-bootstrap";
import SubscriptionPlan from "../plan/SubscriptionPlan";
import Settings from "../settings/Settings";
import { Link } from 'react-router-dom';

const YourSubs: React.FC = () => {
  return (
    <>
      <div className="_your-subs">
        <div className="_holder">
          <div className="_header">
            <h3>Your subscriptions</h3>
            <SubscriptionPlan />
            {/* <button className="_change">Change</button> */}
          </div>

          <div className="_box-package">
            <div>
              <Image src={PackageBox} />
            </div>

            <div className="_title-package">
              <h3>Package A</h3>
              <div className="_plan-benefits">
                <p>Free plan</p>
                <span>
                  <svg
                    width="3"
                    height="3"
                    viewBox="0 0 3 3"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="1.5" cy="1.5" r="1.5" fill="#3E4452" />
                  </svg>
                </span>

              <Link to="/pricing">
                <button className="_see-benefit">See benefits</button>
              </Link>

              </div>
            </div>
          </div>
        </div>
        <Settings />
        {/* <button className="_setting">Settings</button> */}
      </div>
    </>
  );
};

export default YourSubs;
