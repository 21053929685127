import React from "react";
import "./all_user.scss";
import Notification from "../../components/Notification/Notification";
import Search from "./search/Search";
import AllUserList from "../../components/Users/alluser/allusers";

const AllUser: React.FC = () => {
  return (
    <div className="staff_page">
      <div className="_header">
        <h1 className="_title">All User</h1>
        <Notification />
      </div>

      <div className="_sort-search-bar-add-new">
        <div className="_search-filter">
          <Search />
        </div>
      </div>

      <AllUserList />
    </div>
  );
};

export default AllUser;
