import React, { useEffect, useState } from "react";
import "./analytics.scss";
import Message from "../../UpdatedStore/components/Message";
import NumberOfVisitor from "../../components/analytics/filterSummary/numberOfVisitor/NumberOfVisitor";
import ItemSummary from "../../components/analytics/itemSummary/itemSummaryDetails/ItemSummaryDetails";
import { useDispatch, useSelector } from "react-redux";
import SpinnerLoader from "../../UpdatedStore/components/SpinnerLoader";
import notFound from "../../assets/icons/notfound.png";
import {
  GetProductsAction,
  GetPromotionsAction,
} from "../../UpdatedStore/actions/ProductManagementActions";
import { Form, Image } from "react-bootstrap";
import ItemsSummary from "./ItemsSummary";
import ThisMonth from "../../components/analytics/filterSummary/thisMonth/ThisMonth";
import moment from "moment";
import { FaCalculator } from "react-icons/fa";

const ItemAnalytics = ({ fetched_site }) => {
  const dispatch = useDispatch();

  const { store } = useSelector((state) => state.FilterAnalyticsReducer);
  const { sites } = useSelector((state) => state.SitesReducer);

  const [productType, setProductType_] = useState(0);
  const [storeState, setStoreState] = useState("");
  const [siteOrders, setSiteOrders] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalCost, setTotalCost] = useState("");
  const [totalProducts, setTotalProducts] = useState([]);
  const [totalPromotions, setTotalPromotions] = useState([]);

  const [siteRevenue, setSiteRevenue] = useState(0);
  const [revenueTime, setRevenueTime] = useState("All Time");

  const calculateProfit = (value) => {
    if (Number(value) <= 0) {
      setTotalCost("");
      setTotalProfit(0);
      return;
    }
    setTotalCost(value);

    if (typeof Number(value) === "number") {
      let evaluatedValue = (
        Number(siteRevenue).toFixed(2) - Number(value).toFixed(2)
      ).toFixed(2);
      setTotalProfit(
        evaluatedValue ? evaluatedValue : Number(siteRevenue).toFixed(2)
      );
    } else {
      setTotalProfit(Number(siteRevenue).toFixed(2));
    }
  };

  // products and promotions reducer
  const {
    loading: loadingProducts,
    error: errorProducts,
    products,
  } = useSelector((state) => state.ProductsReducer);

  const { orders } = useSelector((state) => state.OrdersReducer);

  const {
    loading: loadingPromotions,
    error: errorPromotions,
    products: productPromotions,
  } = useSelector((state) => state.PromotionsReducer);

  const [itemSummary, setItemSummary] = useState({
    total: 0,
    products: 0,
    promotions: 0,
  });

  const setProductType = (value) => {
    if (Number(value) === 0) {
      setTotalProducts(products);
      setTotalPromotions(productPromotions);
      setProductType_(value);

      return;
    }
    let prev_type = selectedSite?.product_categories.find(
      (obj) => obj.id === Number(value)
    );

    let products_to_set = products.filter((item) => {
      if (item.categories.indexOf(prev_type.name) > -1) {
        return item;
      }
    });

    setTotalProducts(products_to_set);

    let promotions_to_set = productPromotions.filter((item) => {
      if (item.categories.indexOf(prev_type.name) > -1) {
        return item;
      }
    });

    setTotalPromotions(promotions_to_set);

    setProductType_(value);
  };

  const loadItems = () => {
    var Inventory = sites && sites.find((site) => site.name === store);
    setSelectedSite(Inventory);
    let customOrders = orders.find(
      (orderSiteObject) => orderSiteObject.site.name === store
    );

    if (customOrders) {
      let OrdersRevenue = customOrders.orders.reduce(
        (acc, item) => acc + Number(item.totalPrice),
        0
      );
      setSiteOrders(customOrders);

      setSiteRevenue(OrdersRevenue);
    } else {
      setSiteOrders(null);
    }

    if (store !== storeState) {
      dispatch(GetProductsAction(Inventory));
      dispatch(GetPromotionsAction(Inventory));
      setTotalProducts([]);
      setTotalPromotions([]);
      setProductType_(0);
      setStoreState(store);
    }

    if (store === storeState) {
      if (products && productPromotions) {
        setItemSummary({
          total: products.length + productPromotions.length,
          products: products.length,
          promotions: productPromotions.length,
        });
        setTotalProducts(products);
        setTotalPromotions(productPromotions);
        setProductType_(0);
      }

      if (!loadingProducts && !loadingPromotions) {
        if (!products && !productPromotions) {
          setItemSummary({
            total: 0,
            products: 0,
            promotions: 0,
          });
          setTotalProducts([]);
          setTotalPromotions([]);
          setProductType_(0);
        }
      }
    }
  };

  const setRevenueTimeHandler = (value) => {
    let customOrders1 = orders.find(
      (orderSiteObject) => orderSiteObject.site.name === store
    );

    let customOrders = null;

    if (typeof value === "string") {
      setRevenueTime(value);
      if (value === "All Time") {
        customOrders = customOrders1;
      }

      if (value === "Today") {
        let newOrders = customOrders1.orders.filter((item) => {
          if (
            new Date(item.createdAt).getFullYear() ===
              new Date().getFullYear() &&
            new Date(item.createdAt).getMonth() === new Date().getMonth() &&
            new Date(item.createdAt).getDate() === new Date().getDate()
          ) {
            return item;
          }
        });

        customOrders = { ...customOrders1, orders: newOrders };
      }

      if (value === "This week") {
        let newOrders = customOrders1.orders.filter((item) => {
          if (moment().startOf("week").isSame(item.createdAt, "week")) {
            return item;
          }
        });
        customOrders = { ...customOrders1, orders: newOrders };
      }

      if (value === "This month") {
        let newOrders = customOrders1.orders.filter((item) => {
          if (new Date(item.createdAt).getMonth() === new Date().getMonth()) {
            return item;
          }
        });
        customOrders = { ...customOrders1, orders: newOrders };
      }
      if (value === "This Year") {
        let newOrders = customOrders1.orders.filter((item) => {
          if (
            new Date(item.createdAt).getFullYear() === new Date().getFullYear()
          ) {
            return item;
          }
        });

        customOrders = { ...customOrders1, orders: newOrders };
      }
    }

    if (typeof value === "object") {
      if (value.startDate && value.endDate) {
        setRevenueTime(value.customTime);

        let newOrders = customOrders1.orders.filter((item) => {
          if (
            Date.parse(item.createdAt) >= Date.parse(value.startDate) &&
            Date.parse(item.createdAt) <= Date.parse(value.endDate)
          ) {
            return item;
          }
        });
        customOrders = { ...customOrders1, orders: newOrders };
      } else {
        setRevenueTime(value.customTime);
        customOrders = customOrders1;
      }
    }

    if (customOrders) {
      let OrdersRevenue = customOrders.orders.reduce(
        (acc, item) => acc + Number(item.totalPrice),
        0
      );

      setSiteRevenue(OrdersRevenue);
    } else {
      setSiteRevenue(0);
    }

    return;
  };

  useEffect(() => {
    if (store !== "store") {
      loadItems();
    }
  }, [store, products, productPromotions]);

  return (
    <div>
      <div className="detAnalytics">
        {(loadingProducts || loadingPromotions) && <SpinnerLoader />}
        {(errorProducts || errorPromotions) && (
          <Message variant="danger">
            There was an error fetching your items summary!
          </Message>
        )}

        {itemSummary.total === 0 &&
          !loadingProducts &&
          !loadingPromotions &&
          !errorProducts &&
          !errorPromotions && (
            <>
              <div className="_hold-pic">
                <Image src={notFound} alt="not found picture" />
              </div>

              <div className="_info">
                <h3>Nothing to see here!</h3>
              </div>
            </>
          )}
      </div>

      {!loadingProducts &&
        itemSummary.total > 0 &&
        !loadingPromotions &&
        !errorProducts &&
        !errorPromotions && (
          <div>
            <div className="_store-summary">
              <div className="_head">
                <h3>Items summary</h3>

                <div className="_hold-filters">
                  <ThisMonth
                    time={revenueTime}
                    setTime={setRevenueTimeHandler}
                  />
                  <ItemsSummary
                    products={products}
                    promotions={productPromotions}
                    orders={siteOrders}
                  />
                </div>
              </div>
              <div className="_hold-numbers-of-visitor">
                <NumberOfVisitor title="Total Items" data={itemSummary.total} />
                <NumberOfVisitor title="Products" data={itemSummary.products} />
                <NumberOfVisitor
                  title="Promotions"
                  data={itemSummary.promotions}
                />
                <NumberOfVisitor
                  title="Total Revenue"
                  data={`$ ${Number(siteRevenue).toFixed(2)}`}
                />
              </div>

              <div className="holdTotalProfitCard">
                <div className="_profit_card">
                  <h6 className="_no">Total Profit</h6>
                  <p className="_number">{`$ ${totalProfit}`}</p>
                </div>

                <div className="_profit_card_calc">
                  <h6 className="_no">Calculate Profit</h6>

                  <div className="search-bar">
                    <span className="_search-icon">
                      <FaCalculator width={18} height={18} />
                    </span>
                    <input
                      type="number"
                      value={totalCost}
                      placeholder="Your Cost Price"
                      onChange={(e) => calculateProfit(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="categorySelectDiv_">
              <h6 className="tint font-weight-bold mr-3">Items Category:</h6>
              <Form.Control
                as="select"
                className="mb-3 shadow-none font-weight-bold"
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
              >
                <option value={0} selected>
                  All Categories
                </option>
                {selectedSite &&
                  selectedSite?.product_categories.map((type_) => (
                    <option value={type_.id}>{type_.name}</option>
                  ))}
              </Form.Control>
            </div>
            <div className="_item-summary">
              {totalProducts.map((productItem, index) => (
                <ItemSummary
                  details={productItem}
                  orders={siteOrders}
                  key={index}
                />
              ))}
              {totalPromotions.map((productItem, index) => (
                <ItemSummary
                  details={productItem}
                  orders={siteOrders}
                  promotion
                  key={index}
                />
              ))}
            </div>
          </div>
        )}
    </div>
  );
};

export default ItemAnalytics;
