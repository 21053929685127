import {
  AUTHENTICATION_REQUEST,
  AUTHENTICATION_SUCCESS,
  AUTHENTICATION_FAIL,
  AUTHENTICATION_LOGOUT,
  UPDATE_MERCHANT_REQUEST,
  UPDATE_MERCHANT_SUCCESS,
  UPDATE_MERCHANT_FAIL,
  UPDATE_MERCHANT_RESET,
} from "../constants/constants";

export const AuthenticationReducer = (state = {}, action) => {
  switch (action.type) {
    case AUTHENTICATION_REQUEST:
      return { loading: true };

    case AUTHENTICATION_SUCCESS:
      return { loading: false, user: action.payload };

    case AUTHENTICATION_FAIL:
      return { loading: false, error: action.payload };

    case AUTHENTICATION_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const updateUserReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MERCHANT_REQUEST:
      return { loading: true };

    case UPDATE_MERCHANT_SUCCESS:
      return { loading: false, success: true };

    case UPDATE_MERCHANT_FAIL:
      return { loading: false, error: action.payload };

    case UPDATE_MERCHANT_RESET:
      return {};

    default:
      return state;
  }
};
