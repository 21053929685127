import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./myStoreDetails.scss";
import { Link, useHistory } from "react-router-dom";
import { Image, Form, Col } from "react-bootstrap";

import * as Yup from "yup";
import { UpdateUserAction } from "../../../UpdatedStore/actions/SiteActions";
import DeleteDeliveryItem from "../../inventory/sideBar/DeleteDeliveryItem";
import EditDeliveryItem from "../../inventory/sideBar/EditDeliveryItem";
import DeleteStoreInfo from "../../inventory/siteManagement/DeleteStoreInfo";

import CustomFileInput from "../../CustomFileInput";
import {
  GetProductsAction,
  GetPromotionsAction,
} from "../../../UpdatedStore/actions/ProductManagementActions";
import SpinnerLoader from "../../../UpdatedStore/components/SpinnerLoader";
import {
  createStoreInfoAction,
  editStoreInfoAction,
} from "../../../UpdatedStore/actions/ManagementActions";
import {
  CREATE_STORE_INFO_RESET,
  EDIT_STORE_INFO_RESET,
} from "../../../UpdatedStore/constants/ManagementProductConstants";
import AddDeliveryItem from "../../inventory/sideBar/AddDeliveryItem";
import "../../inventory/sideBar/addItemModal.scss";
import DeleteImage from "../../inventory/siteManagement/DeleteImage";

const MyStoreDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { user } = useSelector((state) => state.AuthenticationReducer);
  const { sites } = useSelector((state) => state.SitesReducer);

  const [siteData, setSiteData] = useState(null);

  const {
    loading: loadingCreate,
    success: successCreate,
    error: errorCreate,
  } = useSelector((state) => state.CreateStoreInfoReducer);

  const {
    loading: loadingEdit,
    success: successEdit,
    error: errorEdit,
  } = useSelector((state) => state.EditStoreInfoReducer);

  const { loading: loadingDelete } = useSelector(
    (state) => state.DeleteStoreInfoReducer
  );

  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [submissionError, setSubmissionError] = useState("");

  const handleSubmit = () => {
    if (!loadingCreate && !loadingDelete && !loadingEdit) {
      setSubmissionError("");
      if (description) {
        if (location) {
          if (siteData?.store_information.length > 0) {
            dispatch(
              editStoreInfoAction({
                site: siteData?.name,
                description: description,
                location: location,
              })
            );
          } else {
            dispatch(
              createStoreInfoAction({
                site: siteData?.name,
                description: description,
                location: location,
              })
            );
          }
        } else {
          setSubmissionError("Location is Invalid");
        }
      } else {
        setSubmissionError("Description is Invalid");
      }
    }
  };

  const initialiseStates = () => {
    setDescription(
      siteData?.store_information[0]
        ? siteData.store_information[0].description
        : ""
    );
    setLocation(
      siteData?.store_information[0]
        ? siteData.store_information[0].location
        : ""
    );
  };

  useEffect(() => {
    const currentUrl = history.location.search;
    const urlParams = new URLSearchParams(currentUrl);
    const node = urlParams.get("site");

    if (!node) {
      history.push("/dashboard");
    }

    if (sites) {
      var fetched_site = sites && sites.find((site) => site.name === node);
      setSiteData(fetched_site);
      initialiseStates();
      dispatch(GetProductsAction(siteData));
      dispatch(GetPromotionsAction(siteData));
    }

    if (!sites) {
      history.push("/dashboard");
    }

    if (!user) {
      history.push("/");
    }

    if (successCreate) {
      setTimeout(() => {
        dispatch({ type: CREATE_STORE_INFO_RESET });
      }, 2000);
    }
    if (successEdit) {
      setTimeout(() => {
        dispatch({ type: EDIT_STORE_INFO_RESET });
      }, 2000);
    }
  }, [sites, siteData, history, successCreate, successEdit]);

  return (
    <div className="_my-store-details">
      <header>
        <div className="_hold-go-back-name-of-page">
          <Link to="/dashboard/my-store">
            <button className="_back-btn">
              <svg
                width="42"
                height="42"
                viewBox="0 0 42 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="42" height="42" rx="10" fill="#E8ECF0" />
                <path
                  d="M13.3161 20.9959C13.3161 20.5659 13.4803 20.136 13.808 19.8081L24.1241 9.49217C24.7803 8.83594 25.8442 8.83594 26.5002 9.49217C27.1562 10.1481 27.1562 11.2119 26.5002 11.8682L17.372 20.9959L26.4999 30.1236C27.1559 30.7799 27.1559 31.8435 26.4999 32.4994C25.8439 33.156 24.78 33.156 24.1237 32.4994L13.8077 22.1836C13.4799 21.8556 13.3161 21.4257 13.3161 20.9959Z"
                  fill="#9AA1AF"
                />
              </svg>
            </button>
          </Link>

          <h2>My store</h2>
          <span>Store details</span>
        </div>

        <button
          className="_save"
          onClick={() =>
            history.push(`/inventory/management${history.location.search}`)
          }
        >
          Manage
        </button>
      </header>

      <div className="_hold-info-delivery-option">
        <div className="_hold-my-store-details">
          <div className="_profile-picture">
            <div className="_flex-end">
              {siteData?.store_images.map((img, index) => (
                <DeleteImage site={siteData} img={img} key={index} />
              ))}
            </div>
          </div>

          <div className="_user-information">
            <Col>
              <Form.Group>
                <Form.Label className="text-muted font-weight-bold">
                  Store's Description*
                </Form.Label>
                <Form.Control
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  type="text"
                  placeholder="Store's Description"
                  as="textarea"
                  rows="3"
                  style={{ resize: "none" }}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label className="text-muted font-weight-bold">
                  Store's Location*
                </Form.Label>
                <Form.Control
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  as="textarea"
                  rows="3"
                  style={{ resize: "none" }}
                  placeholder="Store's Location"
                />
              </Form.Group>
            </Col>
            {submissionError && (
              <h5 className="text-danger text-center font-weight-bold">
                {submissionError}
              </h5>
            )}
            {errorCreate && (
              <h5 className="text-danger text-center font-weight-bold">
                {errorCreate}
              </h5>
            )}
            {errorEdit && (
              <h5 className="text-danger text-center font-weight-bold">
                {errorEdit}
              </h5>
            )}
            {successCreate && (
              <h5 className="text-success text-center font-weight-bold">
                Information was added successfully!
              </h5>
            )}

            {successEdit && (
              <h5 className="text-success text-center font-weight-bold">
                Information was updated successfully!
              </h5>
            )}

            <div className="btn-holder2">
              {loadingCreate || loadingEdit ? (
                <SpinnerLoader />
              ) : (
                <>
                  <button
                    className="_add-new-item cursor transcale mr-4"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                  {siteData?.store_information.length > 0 && (
                    <DeleteStoreInfo site={siteData} />
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="_delivery-payment-option">
          <div className="_delivery-options">
            <h3>Delivery options</h3>

            <div className="_courier-service">
              {siteData?.delivery_methods.map((type_) => (
                <div className="_name">
                  <div className="_title">
                    <h5>{type_.title}</h5>
                  </div>

                  <EditDeliveryItem type={type_} site={siteData} />
                  <DeleteDeliveryItem type={type_} site={siteData} />
                </div>
              ))}
              <AddDeliveryItem site={siteData} />
            </div>
          </div>

          {/* Payment */}

          <div className="_delivery-options _payment-option">
            <h3>Payment options</h3>

            <div className="_courier-service">
              <div className="_name">
                <div className="_title">
                  <h5>Pay Pal</h5>
                </div>

                {/* <button className="_remove">Remove</button> */}
              </div>
              <div className="_name">
                <div className="_title">
                  <h5>Card</h5>
                </div>

                {/* <button className="_remove">Remove</button> */}
                {/* <Remove /> */}
              </div>

              <div className="_add-another-option">
                {/* <button>Add another options</button> */}
                {/* <SelectPaymentOption /> */}
                {/* <SelectPaymentOption /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyStoreDetails;
