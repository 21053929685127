import { Box, Container, Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { BsArrowsMove } from "react-icons/bs";
import { FaTrash, FaPencilAlt } from "react-icons/fa";
import { Draggable } from "react-beautiful-dnd";
import { Image } from "react-bootstrap";
import { deleteNodeAction } from "../../actions/ApiAction";
import { SHOW_MODAL_STATE } from "../../constants/ApiConstants";
import { useDispatch } from "react-redux";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

const _style = makeStyles(function (theme) {
  return {
    grow: {
      flexGrow: 1,
    },
    root: {
      background: "#ffffff",
      color: "#000000",
      padding: "20px 0",
    },

    title: {
      marginTop: "10px",
      marginBottom: "40px",
      "& h3": {
        fontSize: theme.typography.pxToRem("18"),
        fontWeight: "bold",
        color: "#000000",
        fontFamily: `'Montserrat', 'sans-serif'`,
      },
    },
    ParentSwipper: {
      "& .swiper-slide": {
        marginRight: "0 !important",
        width: "auto !important",
      },
    },

    Wrapper: {
      paddingRight: " 0 !important",
    },
    back: function (props) {
      return {
        backgroundColor: "#ffffff",
        padding: "20px",
      };
    },
    categoryDIV: function (props) {
      return {
        backgroundColor: "inherit",
        margin: "0 10px",
      };
    },
    ItemName: function (props) {
      return {
        color: "#16284D",
        fontSize: "20px",
        fontWeight: "400",
        padding: "10px",
      };
    },
    priceName: function (props) {
      return {
        color: "#16284D",
        fontSize: "15px",
        fontWeight: "bold",
        padding: "10px",
      };
    },
  };
});

export default function NewItems({ nodeData }) {
  let classes = _style(nodeData.styles);

  const [data, setData] = useState([...nodeData.data.products]);

  useEffect(() => {
    // getData();
  }, [nodeData]);

  async function getData() {
    var response = await axios.get(nodeData.data.api.url);
    // nodeData.data = response.data;
    setData(response.data);
  }

  const dispatch = useDispatch();

  const deleteNodeHandler = (node) => {
    dispatch(deleteNodeAction(node));
  };

  const EditFunctionality = () => {
    dispatch({ type: SHOW_MODAL_STATE, payload: nodeData });
  };

  return (
    <Draggable key={nodeData.id} draggableId="promotions" index={nodeData.id}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={(classes.grow, "componentBuildBox")}
        >
          <div {...provided.dragHandleProps} className="cmpMoveIcon">
            <BsArrowsMove className="cmp" />
          </div>
          <div className="deleteFlex">
            <div className="editIDiv" onClick={EditFunctionality}>
              <FaPencilAlt className="editIcon" />
            </div>
            <div
              className="deleteIDiv"
              onClick={() => deleteNodeHandler(nodeData)}
            >
              <FaTrash className="deleteIcon" />
            </div>
          </div>

          <div className={classes.root}>
            <Container maxWidth="xl" className={classes.Wrapper}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} align="center" className={classes.title}>
                  <Typography component="h3">Newly Added Items</Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  align="center"
                  className={classes.CollectBox}
                >
                  <Swiper
                    slidesPerView={3}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                    className={classes.ParentSwipper}
                  >
                    {data.map((item, index) => {
                      return (
                        <SwiperSlide
                          key={index}
                          lg={2}
                          style={{
                            marginRight: 0,
                          }}
                        >
                          <div key={index} className={classes.categoryDIV}>
                            <Image src={item.image} width="100%" fluid />
                            <div className={classes.ItemName}>{item.name}</div>
                            <div
                              className={`${classes.priceName} text-center `}
                            >
                              {item.price}
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      )}
    </Draggable>
  );
}
