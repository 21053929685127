import {
  InputGroup,
  FormControl,
  Row,
  Col,
  Modal,
  Button,
} from "react-bootstrap";
import { UpdateNodeAction } from "../../actions/ApiAction";
import { SHOW_MODAL_STATE } from "../../constants/ApiConstants";
import { useDispatch } from "react-redux";
import { useState } from "react";

const EditCategoryList = ({ nodee, props }) => {
  const dispatch = useDispatch();
  const [node, setNode] = useState(nodee);

  const saveChanges = () => {
    dispatch(UpdateNodeAction(node));
    dispatch({ type: SHOW_MODAL_STATE });
  };

  const editCategoryname = (name, ObjIndex) => {
    let updatedNode = node.data.category_list.map((item, index) => {
      if (index === ObjIndex) {
        return { ...item, name: name };
      } else {
        return item;
      }
    });
    setNode((oldNode) => {
      let newNode = { ...oldNode };
      newNode.data.category_list = updatedNode;
      return newNode;
    });
  };

  const editCategoryImage = (image, ObjIndex) => {
    let updatedNode = node.data.category_list.map((item, index) => {
      if (index === ObjIndex) {
        return { ...item, image: image };
      } else {
        return item;
      }
    });
    setNode((oldNode) => {
      let newNode = { ...oldNode };
      newNode.data.category_list = updatedNode;
      return newNode;
    });
  };

  return (
    <>
      <Modal.Body>
        <h4 className="tint opacity font-weight-bold mb-4">PROPERTIES</h4>

        <h6 className="tint font-weight-bold mb-1">*CATEGORIES</h6>

        {node.data.category_list.map((Ilink, index) => (
          <Row>
            <Col>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text
                    id="inputGroup-sizing-default"
                    className="tint font-weight-bold"
                  >
                    NAME
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  aria-label="Default"
                  value={Ilink.name}
                  onChange={(e) => editCategoryname(e.target.value, index)}
                  aria-describedby="inputGroup-sizing-default"
                />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text
                    id="inputGroup-sizing-default"
                    className="tint font-weight-bold"
                  >
                    IMAGE
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  aria-label="Default"
                  value={Ilink.image}
                  onChange={(e) => editCategoryImage(e.target.value, index)}
                  aria-describedby="inputGroup-sizing-default"
                />
              </InputGroup>
            </Col>
          </Row>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <div className="modalRow">
          <Button
            variant="dark"
            className="btn-dark font-weight-bold rounded"
            onClick={props.onHide}
          >
            Close
          </Button>
          <Button
            className="bg-tint text-white font-weight-bold"
            onClick={saveChanges}
          >
            Save
          </Button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default EditCategoryList;
