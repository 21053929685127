import { useState } from 'react'
import { BsThreeDots } from 'react-icons/bs';
import store from '../../assets/images/store.svg';
import { Button, Dropdown } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton';

import { PlatformDeactivateModal, PlatformRemoveModal, PlatformRenameModal } from '../modals/PlatformItem';
import { CustomMenu, CustomToggle } from '../CustomDropDown';
import './styles.scss';

const Platform = (props: any) => {
    const [showRemove, setShowRemove] = useState(false)
    const [showRename, setShowRename] = useState(false)
    //const [showDetails, setShowDetails] = useState(false)
    //const [showActivate, setShowActivate] = useState(false)
    const [showDeactivate, setShowDeactivate] = useState(false)

    return (
        <div className="row">
            <div className="col-5 me-3">
                {<img src={store} alt="" /> ||  <Skeleton duration={3}/>}
            </div>

            <div className="col-6 p-0 description-container">
                <div className="dots" >
                    <Dropdown >
                        <Dropdown.Toggle as={CustomToggle} aria-expanded={true} id="dropdown-toggle">
                            <BsThreeDots className="three-dots text-center" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu as={CustomMenu} className="dropdown-container">
                            <div id="options-menu">
                                <div className="options-item primary-color " >See Details</div>
                                <div className="options-item primary-color " onClick={() => setShowDeactivate(true)}>Deactivate now</div>
                                <div className="options-item primary-color " onClick={() => setShowRename(true)}>Rename</div>
                                <div className="options-item danger-color" onClick={() => setShowRemove(true)}>Remove Platform</div>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <PlatformRenameModal show={showRename} onHide={() => setShowRename(false)} />
                <PlatformRemoveModal show={showRemove} onHide={() => setShowRemove(false)} />
                <PlatformDeactivateModal show={showDeactivate} onHide={() => setShowDeactivate(false)} />

                <div className="platform-description">
                    <span className="card-type">Website</span>
                    <p className="card-category">
                        {props.website ||  <Skeleton />}
                    </p>
                    
                        <button className='card-button-paltforms' >{props.status ||  <Skeleton />}</button>
                    
                </div>
            </div>

        </div>
    )
}

export default Platform;