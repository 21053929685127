import React, { useEffect, useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Blog from "./Blog";
import Faq from "./Faq";
import Information from "./Information";
import NewBlog from './NewBlog';
import FaqTips from "./FaqTips";

import "./main.scss";

const MainScreen = ({ site }) => {
  const history = useHistory();
  const { sites } = useSelector((state) => state.SitesReducer);
  const [siteData, setSiteData] = useState(site);

  useEffect(() => {
    const currentUrl = history.location.search;
    const urlParams = new URLSearchParams(currentUrl);
    const node = urlParams.get("site");

    if (!node) {
      history.push("/dashboard/management");
    }

    var fetched_site = sites && sites.find((site) => site.name === node);
    setSiteData(fetched_site);
  }, [history, siteData, sites]);
  return (
    <div className="storeBox___">
      <div className="__hold-management-tabs">
        <Tab.Container defaultActiveKey="information">
          <div className="wrap-tabs">
            <Nav variant="pills" className="_tabs">
              <Nav.Item>
                <Nav.Link eventKey="information"> Store Information </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="blog">Blog</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="faq">FAQ</Nav.Link>
              </Nav.Item>
            </Nav>

            <div>
              {/* {evertKey==="blog"? <NewBlog /> : <FaqTips /> } */}
              <Tab.Content>
                <Tab.Pane eventKey="blog">
                  <NewBlog />
                </Tab.Pane>
                
                <Tab.Pane eventKey="faq">
                  <FaqTips />
                </Tab.Pane>
              </Tab.Content>

            </div>
          </div>

          <div>
            <Tab.Content>
              <Tab.Pane eventKey="information">
                <Information site={siteData} />
              </Tab.Pane>
              <Tab.Pane eventKey="blog">
                <Blog site={siteData} />
              </Tab.Pane>
              <Tab.Pane eventKey="faq">
                <Faq site={siteData} />
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
    </div>
  );
};

export default MainScreen;
