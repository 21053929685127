// PRODUCTS FILTER
export const FILTER_PRODUCTS = "FILTER_PRODUCTS";
export const FILTER_ORDERS = "FILTER_ORDERS";
export const FILTER_STAFFS = "FILTER_STAFFS";
export const REVERSE_PRODUCTS_LIST = "REVERSE_PRODUCTS_LIST";
export const FILTER_WITH_OPTIONS = "FILTER_WITH_OPTIONS";
export const FILTER_ANALYTICS = "FILTER_ANALYTICS";

// APK GENERATION
export const GENERATE_APK_REQUEST = "GENERATE_APK_REQUEST";
export const GENERATE_APK_SUCCESS = "GENERATE_APK_SUCCESS";
export const GENERATE_APK_FAIL = "GENERATE_APK_FAIL";
export const GENERATE_APK_RESET = "GENERATE_APK_RESET";

// TYPES
export const CREATE_PRODUCT_TYPE_REQUEST = "CREATE_PRODUCT_TYPE_REQUEST";
export const CREATE_PRODUCT_TYPE_SUCCESS = "CREATE_PRODUCT_TYPE_SUCCESS";
export const CREATE_PRODUCT_TYPE_FAIL = "CREATE_PRODUCT_TYPE_FAIL";
export const CREATE_PRODUCT_TYPE_RESET = "CREATE_PRODUCT_TYPE_RESET";

export const EDIT_PRODUCT_TYPE_REQUEST = "EDIT_PRODUCT_TYPE_REQUEST";
export const EDIT_PRODUCT_TYPE_SUCCESS = "EDIT_PRODUCT_TYPE_SUCCESS";
export const EDIT_PRODUCT_TYPE_FAIL = "EDIT_PRODUCT_TYPE_FAIL";
export const EDIT_PRODUCT_TYPE_RESET = "EDIT_PRODUCT_TYPE_RESET";

export const DELETE_PRODUCT_TYPE_REQUEST = "DELETE_PRODUCT_TYPE_REQUEST";
export const DELETE_PRODUCT_TYPE_SUCCESS = "DELETE_PRODUCT_TYPE_SUCCESS";
export const DELETE_PRODUCT_TYPE_FAIL = "DELETE_PRODUCT_TYPE_FAIL";
export const DELETE_PRODUCT_TYPE_RESET = "DELETE_PRODUCT_TYPE_RESET";

// CATEGORIES
export const CREATE_PRODUCT_CATEGORY_REQUEST =
  "CREATE_PRODUCT_CATEGORY_REQUEST";
export const CREATE_PRODUCT_CATEGORY_SUCCESS =
  "CREATE_PRODUCT_CATEGORY_SUCCESS";
export const CREATE_PRODUCT_CATEGORY_FAIL = "CREATE_PRODUCT_CATEGORY_FAIL";
export const CREATE_PRODUCT_CATEGORY_RESET = "CREATE_PRODUCT_CATEGORY_RESET";

export const EDIT_PRODUCT_CATEGORY_REQUEST = "EDIT_PRODUCT_CATEGORY_REQUEST";
export const EDIT_PRODUCT_CATEGORY_SUCCESS = "EDIT_PRODUCT_CATEGORY_SUCCESS";
export const EDIT_PRODUCT_CATEGORY_FAIL = "EDIT_PRODUCT_CATEGORY_FAIL";
export const EDIT_PRODUCT_CATEGORY_RESET = "EDIT_PRODUCT_CATEGORY_RESET";

export const DELETE_PRODUCT_CATEGORY_REQUEST =
  "DELETE_PRODUCT_CATEGORY_REQUEST";
export const DELETE_PRODUCT_CATEGORY_SUCCESS =
  "DELETE_PRODUCT_CATEGORY_SUCCESS";
export const DELETE_PRODUCT_CATEGORY_FAIL = "DELETE_PRODUCT_CATEGORY_FAIL";
export const DELETE_PRODUCT_CATEGORY_RESET = "DELETE_PRODUCT_CATEGORY_RESET";
