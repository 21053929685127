import {
  GET_SITES_REQUEST,
  GET_SITES_SUCCESS,
  GET_SITES_FAIL,
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAIL,
  GET_STAFF_REQUEST,
  GET_STAFF_SUCCESS,
  GET_STAFF_FAIL,
  GET_All_USER_REQUEST,
  GET_All_USER_SUCCESS,
  GET_All_USER_FAIL,
  GET_USER_STATICS_REQUEST,
  GET_USER_STATICS_SUCCESS,
  GET_USER_STATICS_FAIL,
  GET_PLATFROM_STATICS_REQUEST,
  GET_PLATFROM_STATICS_SUCCESS,
  GET_PLATFROM_STATICS_FAIL,
  FILTER_ALLUSER,
  DELETE_STORE_REQUEST,
  DELETE_STORE_SUCCESS,
  DELETE_STORE_FAIL,
  DELETE_STORE_RESET,
  DELETE_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION_RESET,
} from "../constants/constants";

export const SitesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SITES_REQUEST:
      return { loading: true };

    case GET_SITES_SUCCESS:
      return { loading: false, sites: action.payload };

    case GET_SITES_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const OrdersReducer = (state = { orders: null }, action) => {
  switch (action.type) {
    case GET_ORDERS_REQUEST:
      return { loading: true };

    case GET_ORDERS_SUCCESS:
      return { loading: false, orders: action.payload };

    case GET_ORDERS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const StaffReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_STAFF_REQUEST:
      return { loading: true };

    case GET_STAFF_SUCCESS:
      return { loading: false, staffs: action.payload };

    case GET_STAFF_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const AllUserReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_All_USER_REQUEST:
      return { loading: true };

    case GET_All_USER_SUCCESS:
      return { loading: false, alluser: action.payload };

    case GET_All_USER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const UserStaticsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_STATICS_REQUEST:
      return { loading: true };

    case GET_USER_STATICS_SUCCESS:
      return { loading: false, statics: action.payload };

    case GET_USER_STATICS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const PlatfromStaticsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PLATFROM_STATICS_REQUEST:
      return { loading: true };

    case GET_PLATFROM_STATICS_SUCCESS:
      return { loading: false, statics: action.payload };

    case GET_PLATFROM_STATICS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};


export const FilterAllUserReducer = (state = { text: "" }, action) => {
  switch (action.type) {
    case FILTER_ALLUSER:
      return { text: action.payload };

    default:
      return state;
  }
};
export const DeleteStoreReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_STORE_REQUEST:
      return { loading: true };

    case DELETE_STORE_SUCCESS:
      return { loading: false, success: true };

    case DELETE_STORE_FAIL:
      return { loading: false, error: action.payload };

    case DELETE_STORE_RESET:
      return {};
    default:
      return state;
  }
};


export const RemoveNotificationReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_NOTIFICATION_REQUEST:
      return { loadingnotf: true };

    case DELETE_NOTIFICATION_SUCCESS:
      return { loadingnotf: false, successnotf: true };

    case DELETE_NOTIFICATION_FAIL:
      return { loadingnotf: false, errornotf: action.payload };

    case DELETE_NOTIFICATION_RESET:
      return {};
    default:
      return state;
  }
};
