import React from "react";
import { Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import PIC from "../../../../assets/icons/store-pic.png";
import "./holdCards.scss";
import {
  Vanilla,
  Nairobi,
  Fukushima,
  Quebec,
} from "../../../../pages/management/images";
import { useSelector } from "react-redux";
export const HoldCards: React.FC = ({ site }) => {
  const auth = useSelector((state) => state.AuthenticationReducer);
  const history = useHistory();

  const redirectHandler = () => {
    var randomstring = require("randomstring");
    var config = randomstring.generate(100);
    var hash = randomstring.generate(35);
    const url = `/dashboard/MyStoreDetails?site=${site.name}&wIM=${auth.user?.access_token}&hash=${hash}`;
    history.push(url);
  };
  return (
    <div
      className="_hold-store-management-info transcale cursor"
      onClick={redirectHandler}
    >
      <div className="_pic">
        {site.theme === "vanilla" && <Image src={Vanilla} />}
        {site.theme === "second" && <Image src={Nairobi} />}
        {site.theme === "third" && <Image src={Fukushima} />}
        {site.theme === "fourth" && <Image src={Quebec} />}
      </div>

      <div className="_info">
        <h3 className={"text-uppercase"}>{site.name}</h3>
        <div className="_hold-buttons">
          <button className="_website">Website</button>
          <button className="_on-building">
            {site.active ? "Active" : "On Building"}
          </button>
        </div>
      </div>
    </div>
  );
};
