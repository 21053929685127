import Header_backimage from '../../assests/images/Header_backimage.svg';
import homeHeaderimage from '../../assests/images/homeHeaderimage.png';
import ItemImage from '../../assests/images/ItemImage.svg';
import leftButton from '../../assests/images/leftButton.svg';
import pricing from "../../assests/images/pricing.svg";
import rightButton from '../../assests/images/rightButton.svg';
import OneStopShop_header from '../../assests/images/OneStopShop_header.svg';
import OneStopShop from '../../assests/images/OneStopShop.svg';
// import SecondTypeHeader from '../../assests/images/SecondTypeHeader.svg';
import SecondTypeHeader from '../../assests/images/secondTypeHeader.svg'
import fb from '../../assests/images/fb.svg';
import twitter from '../../assests/images/twitter.svg';
import insta from '../../assests/images/insta.svg';
import servicesImages from '../../assests/images/servicesImages.svg';
import searchNotFound from '../../assests/images/notfound.svg';
import platform from '../../assests/images/platform.svg';
import account from '../../assests/images/account.svg';
import Rectangle from "../../assests/images/Rectangle.svg";
import Golive from "../../assests/images/Golive.svg";
import manageorders from '../../assests/images/manageorders.svg';
import about_top from '../../assests/images/about_top.png';
import rock from '../../assests/images/rock.svg';
import img_start from '../../assests/images/img_start.svg';
import img_middle from '../../assests/images/img_middle.svg';
import img_last from '../../assests/images/img_last.svg';
import img_end from '../../assests/images/img_end.svg'
import Shape from '../../assests/images/Shape.svg'
import cardImage1 from '../../assests/images/cardImage1.png'
// import cardImage11 from '../../assests/images/cardImage11.png'
import cardImage2 from '../../assests/images/cardImage2.svg'
import cardImage3 from '../../assests/images/cardImage3.svg'
import cardImage4 from '../../assests/images/cardimage4.svg'
import caoursel_img from '../../assests/images/caoursel_img.png'
import oval_left from '../../assests/images/oval_left.png'
import oval_right from '../../assests/images/oval_right.png'
import cancel from '../../assests/images/cancel.png'
import card1 from '../../assests/images/card_image1.png'
import card2 from '../../assests/images/card_image2.png'
import card3 from '../../assests/images/card_image3.png'
import card4 from '../../assests/images/card_image4.png'
import not_found_image from "../../assests/images/notfound.svg"

const images = {
    not_found_image,
    SecondTypeHeader,
    Shape,
    img_end,
    img_last,
    img_middle,
    img_start,
    rock,
    about_top,
    searchNotFound,
    Header_backimage,
    homeHeaderimage,
    ItemImage,
    leftButton,
    rightButton,
    OneStopShop_header,
    OneStopShop,
    fb,
    twitter,
    insta,
    pricing,
    servicesImages,
    platform,
    account,
    Rectangle,
    Golive,
    manageorders,
    cardImage1,
    cardImage2,
    cardImage3,
    cardImage4,
    caoursel_img,
    oval_left,
    oval_right,
    cancel,
    card1,
    card2,
    card3,
    card4,
}

export default images;