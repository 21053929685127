import { Modal } from 'react-bootstrap';
import '../styles.scss'
import { PlatformItem }from "../../Platform";


const PlatformModal = (props: any) => {

  return (
    <Modal
    {...props}
    onHide={props.onHide}
    size="sm"
    aria-labelledby="notification-modal-title"
    id="platform"
    centered
  >
    <Modal.Header className="pb-3" closeButton>
      <Modal.Title className="p-0" id="platforms-modal-title">
        {props.title}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="p-0">
        <div className="platforms-list">
          <div className="">
            <PlatformItem website="Antidote Store" image={''} status="On Building" />
          </div>
          <div className="mt-5">
            <PlatformItem website="Antidote Store" image={''} status="On Building" />
          </div>
          <div className="mt-5">
            <PlatformItem website="Antidote Store" image={''} status="On Building" />
          </div>
        </div>
    </Modal.Body>
  </Modal>  
  )
}
export default PlatformModal;