import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
const firebaseConfig = {
    apiKey: "AIzaSyCUApfY-YB-YGdcXRpsv6loE07xovUUGpQ",
    authDomain: "onestopshop-fcb6a.firebaseapp.com",
    projectId: "onestopshop-fcb6a",
    storageBucket: "onestopshop-fcb6a.appspot.com",
    messagingSenderId: "169281005424",
    appId: "1:169281005424:web:567effb48bb19d885691b5",
    measurementId: "G-1GZXW45SB4"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export default auth;


