import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  SelectBox: {
    width: "75px !important",
    height: "40px !important",
    backgroundColor: "#090909",
    border: "1px solid #272727",
    "& .MuiInput-underline:before": {
      display: "none",
    },

    "& .MuiInput-underline:after": {
      display: "none",
    },

    "& .MuiSelect-select.MuiSelect-select": {
      color: "#fff",
      fontSize: theme.typography.pxToRem(16),
      fontWeight: "normal",
      fontFamily: `'Montserrat', 'sans-serif'`,
      paddingRight: "0",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: " 0 8px",
      height: "40px",
    },
    "& svg": {
      color: "#fff",
      "& path": {
        fill: "#fff",
      },
    },
  },
}));

export default function SelectLabels() {
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const classes = useStyles();

  return (
    <FormControl className={classes.SelectBox}>
      <Select
        value={age}
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem value="">
          <em>+58</em>
        </MenuItem>
        <MenuItem value={10}>+10</MenuItem>
        <MenuItem value={20}>+20</MenuItem>
        <MenuItem value={30}>+30</MenuItem>
        <MenuItem value={40}>+40</MenuItem>
        <MenuItem value={50}>+50</MenuItem>
        <MenuItem value={60}>+60</MenuItem>
        <MenuItem value={70}>+70</MenuItem>
        <MenuItem value={80}>+80</MenuItem>
      </Select>
    </FormControl>
  );
}
