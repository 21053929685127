import React from "react";
import "./accountInfo.scss";
import { Image } from "react-bootstrap";
import UserPic from "../../../assets/icons/user.png";
import EditAccount from "../editAccount/EditAccount";
import { useSelector } from "react-redux";

const AccountInfo: React.FC = () => {
  const { user } = useSelector((state) => state.AuthenticationReducer);

  return (
    <div className="_account-info">
      <div className="_hold-name-avatar">
        <div className="_hold-pic">
          <Image src={user?.Details.ProfilePicture} />
        </div>
        <div className="_hold-name">
          <h2>{user?.name}</h2>
          <div className="_hold-btns">
            <button className="_admin">{user.isSuperUser ?"Super ":""}Admin</button>
            <EditAccount />
          </div>
        </div>
      </div>

      <div className="_phone-email">
        <div className="_phone">
          <span>Phone no.</span>
          <p>{user?.Details.PhoneNumber}</p>
        </div>
        <div className="_email">
          <span>Email</span>
          <p>{user?.email}</p>
        </div>
      </div>
    </div>
  );
};

export default AccountInfo;
