import { useState } from "react";
import { Modal, Image } from "react-bootstrap";
import "./staffDetails.scss";

const StaffDetails = ({ partner, site }) => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <button
        className="_add-staff-btn font-weight-bold transcale"
        onClick={() => setModalShow(true)}
      >
        See Details
      </button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="_delete-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <h1>Staff Details</h1>

          <button onClick={() => setModalShow(false)} className="_close-modal">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="28" height="28" rx="8" fill="#E8ECF0" />
              <path
                d="M20.698 8.23344L19.7383 7.28517C19.4786 7.02549 19.0496 7.02549 18.7786 7.28517L13.9915 12.0721L9.1142 7.19484C8.85441 6.93505 8.42537 6.93505 8.15452 7.19484L7.19484 8.15452C6.93505 8.4142 6.93505 8.84323 7.19484 9.1142L12.061 13.9803L7.28517 18.7787C7.02549 19.0384 7.02549 19.4674 7.28517 19.7384L8.24484 20.6981C8.50452 20.9577 8.93355 20.9577 9.20452 20.6981L13.9915 15.911L18.7786 20.6981C19.0383 20.9577 19.4673 20.9577 19.7383 20.6981L20.698 19.7384C20.9576 19.4787 20.9576 19.0497 20.698 18.7787L15.8996 13.9916L20.6867 9.20463C20.9576 8.93344 20.9576 8.50441 20.698 8.23344Z"
                fill="#777C85"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row _hold-contentProductX">
            <div className="col-md-4">
              <Image
                src={partner.agent.details.ProfilePicture}
                width="100%"
                rounded
              />
            </div>
            <div className="col-md-8 detailsTopFlex">
              <h2>
                <span className="text-success font-weight-bold">Name:</span>
                <p>{partner.agent.name}</p>
              </h2>
              <h2>
                <span className="text-success font-weight-bold">Email:</span>
                <p>{partner.agent.email}</p>
              </h2>
            </div>
            
            <div className="col-12 mt-5">
              <h5 className="mt-0 row">
                <span className="text-success font-weight-bold mr-2">
                  Allocated Store:
                </span>
                <p>{site.name}</p>
              </h5>
              <h5 className="mt-0 row">
                <span className="text-success font-weight-bold mr-2">
                  Default Password:
                </span>
                <p>{partner.defaultPassword}</p>
              </h5>
              <h5 className="mt-0 row">
                <span className="text-success font-weight-bold mr-2">Created On:</span>
                <p>{partner.created_on.substring(0, 10)} @
                  {partner.created_on.substring(11, 19)}</p>
              </h5>
            </div>
          </div>


        </Modal.Body>
      </Modal>
    </>
  );
};

export default StaffDetails;
