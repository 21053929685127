import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import './i18n';

import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";

import App from "./App";
import { App as Builder } from "./builder/App";
import Inventory from "./components/inventory/Inventory";
import CreateProductItem from "./components/inventory/CreateProductItem";
import AppSignUP from "./features/auth/AppSignUP";
import AppLogin from "./features/auth/AppLogin";

import store from "./UpdatedStore/store/index";
import ProtectedRoute from "./UpdatedStore/routes/ProtectedRoute";
import ScrollToTop from "./UpdatedStore/components/ScrollToTop";

import Home from "./landingPage/Pages/Home";
import Faq from "./landingPage/Pages/Help";
import AboutUs from "./landingPage/Pages/AboutUs";
import Pricing from "./landingPage/Pages/Pricing";
import LearnMore from "./landingPage/Pages/LearnMore";
import Footer from "./landingPage/component/Footer";
import MyFreeAccount from "./pages/myFreeAccount/MyFreeAccount";

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ScrollToTop />
      <Route exact path="/">
        <Home />
        <Footer />
      </Route>
      <Route exact path="/faq">
        <Faq />
        <Footer />
      </Route>
      <Route exact path="/about">
        <AboutUs />
        <Footer />
      </Route>
      <Route exact path="/pricing">
        <Pricing />
        <Footer />
      </Route>
      <Route exact path="/learnmore">
        <LearnMore />
        <Footer />
      </Route>

      <Route exact path="/sign-up" component={AppSignUP} />

      <Switch>
        <ProtectedRoute path="/dashboard">
          <App />
        </ProtectedRoute>

        <Route path="/login">
          <AppLogin />
        </Route>

        <ProtectedRoute path="/inventory">
          <Inventory />
        </ProtectedRoute>

        <ProtectedRoute path="/merchant/product/create">
          <CreateProductItem />
        </ProtectedRoute>

        <ProtectedRoute path="/store/builder/">
          <Builder />
        </ProtectedRoute>

        <ProtectedRoute path="/user/account/free">
          <MyFreeAccount />
        </ProtectedRoute>
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your store to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
