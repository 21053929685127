import React, { useState, useEffect } from "react";
import { Tab, Nav } from "react-bootstrap";
import Member from "./member/Member";
import NoStaffInThisRole from "../noStaffAddOne/NoStaffInThisRole";
import "./staffMembers.scss";
import { useSelector, useDispatch } from "react-redux";
import { GetStaffsAction } from "../../../UpdatedStore/actions/SiteActions";
import Loader from "../../../UpdatedStore/components/Loader";
import Message from "../../../UpdatedStore/components/Message";
import SpinnerLoader from "../../../UpdatedStore/components/SpinnerLoader";

const StaffMembers: React.FC = () => {
  const { loading, staffs, success, error } = useSelector(
    (state) => state.StaffReducer
  );
  const { user } = useSelector((state) => state.AuthenticationReducer);
  const { text: searchText } = useSelector(
    (state) => state.FilterStaffsReducer
  );

  const [staffsLength, setStaffsLength] = useState(true);
  const [filteredStaffs, setFilteredStaffs] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!staffs) {
      dispatch(GetStaffsAction(user.access_token));
    }

    if (staffs && !filteredStaffs) {
      staffs.map((staff) => {
        if (staff.partners.length > 0) {
          setStaffsLength(false);
        }
      });

      setFilteredStaffs(staffs);
    }

    if (staffs && filteredStaffs) {
      if (searchText) {
        const filtext = searchText.toLowerCase();
        setFilteredStaffs((currentStaffs) => {
          var shallowStaffs = [...staffs];
          var newStaffsListUpdated = shallowStaffs.map((obj) => {
            var newStaffsList = obj.partners.filter((staff_item) => {
              const filterConstant = staff_item.agent.name.toLowerCase();
              if (filterConstant.indexOf(filtext) != -1) {
                return staff_item;
              }
            });
            return { ...obj, partners: newStaffsList };
          });

          return newStaffsListUpdated;
        });
      } else {
        setFilteredStaffs(staffs);
      }
    }
  }, [staffs, searchText, success]);

  return (
    <div className="_staffs">
      <Tab.Container defaultActiveKey="All">
        <div className="wrap-tabs">
          <Nav variant="pills" className="_tabs">
            <Nav.Item>
              <Nav.Link eventKey="All"> All </Nav.Link>
            </Nav.Item>
            {/* <Nav.Item>
              <Nav.Link eventKey="Owner">Store</Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="Admin">Staff</Nav.Link>
            </Nav.Item> */}
          </Nav>
        </div>

        <div>
          <Tab.Content>
            <Tab.Pane eventKey="All">
              {loading && <SpinnerLoader />}
              {error && <Message variant="danger">{error}</Message>}
              {!loading && (
                <>{staffs && staffsLength && <NoStaffInThisRole />}</>
              )}

              {filteredStaffs &&
                staffs &&
                !staffsLength &&
                filteredStaffs.reduce(
                  (acc, item) => acc + item.partners.length,
                  0
                ) <= 0 && (
                  <Message variant="primary">Nothing to see here!</Message>
                )}

              <div className="_wrap-all-members">
                {filteredStaffs &&
                  filteredStaffs.map((item) =>
                    item.partners.map((staffUser) => (
                      <Member partner={staffUser} store={item.site} />
                    ))
                  )}
              </div>
            </Tab.Pane>
            {/* <Tab.Pane eventKey="Owner">Owner</Tab.Pane>
            <Tab.Pane eventKey="Admin">
              <NoStaffInThisRole />
            </Tab.Pane> */}
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
  );
};

export default StaffMembers;
