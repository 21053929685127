import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ItemPhotos from "./ItemPhotos";
import ItemDetails from "./ItemDetails";
import MultipleProducts from "./MultipleProducts";

const CreateProductItem = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { sites } = useSelector((state) => state.SitesReducer);
  const { user } = useSelector((state) => state.AuthenticationReducer);

  const [siteData, setSiteData] = useState(null);

  const [files, setFiles] = useState([]);

  // formStates
  const [caption1, setCaption1] = useState("");
  const [caption2, setCaption2] = useState("");
  const [caption3, setCaption3] = useState("");
  const [caption4, setCaption4] = useState("");
  const [caption5, setCaption5] = useState("");

  const fetchCaptions = () => {
    return [caption1, caption2, caption3, caption4, caption5];
  };

  const onImageChange = (e) => {
    if (files.length >= 5) return;
    setFiles((previousFiles) => [...previousFiles, e.target.files[0]]);
  };

  const deleteImage = (id) => {
    setFiles((previousFiles) =>
      previousFiles.filter((i, index) => index !== id)
    );
  };

  useEffect(() => {
    const currentUrl = history.location.search;
    const urlParams = new URLSearchParams(currentUrl);
    const node = urlParams.get("site");

    if (!node) {
      history.push("/dashboard/management");
    }

    if (sites) {
      var fetched_site = sites && sites.find((site) => site.name === node);
      setSiteData(fetched_site);
    }

    if (!sites) {
      history.push("/dashboard/management");
    }

    if (!user) {
      history.push("/");
    }
  }, [history, sites]);

  return (
    <div className="createProductItem">
      <header>
        <div className="actioBoxMerchant">
          <Link to="/inventory">
            <div className="linkBaclDiv">
              <svg
                width="42"
                height="42"
                viewBox="0 0 42 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="42" height="42" rx="10" fill="#E8ECF0" />
                <path
                  d="M13.3161 20.9959C13.3161 20.5659 13.4803 20.136 13.808 19.8081L24.1241 9.49217C24.7803 8.83594 25.8442 8.83594 26.5002 9.49217C27.1562 10.1481 27.1562 11.2119 26.5002 11.8682L17.372 20.9959L26.4999 30.1236C27.1559 30.7799 27.1559 31.8435 26.4999 32.4994C25.8439 33.156 24.78 33.156 24.1237 32.4994L13.8077 22.1836C13.4799 21.8556 13.3161 21.4257 13.3161 20.9959Z"
                  fill="#9AA1AF"
                />
              </svg>
            </div>
          </Link>
          <div className="_title">
            <h2>Inventory</h2>
            <span>Add new item</span>
          </div>
        </div>
        <MultipleProducts site={siteData} />
      </header>

      <section>
        <ItemPhotos
          images={files}
          onChange={onImageChange}
          deleteImage={deleteImage}
          caption1={caption1}
          caption2={caption2}
          caption3={caption3}
          caption4={caption4}
          caption5={caption5}
          setCaption1={setCaption1}
          setCaption2={setCaption2}
          setCaption3={setCaption3}
          setCaption4={setCaption4}
          setCaption5={setCaption5}
        />
        <ItemDetails
          site={siteData}
          fetchImages={() => {
            return files;
          }}
          fetchCaptions={fetchCaptions}
        />
      </section>
    </div>
  );
};

export default CreateProductItem;
