import { Card, CardMedia, Typography, Container } from "@mui/material";
import Images from "../Images/Images";
import { useTranslation } from "react-i18next";


const Services = () => {
  const { t, i18n } = useTranslation();
  return (
    <Container>
      <div className="service-type-one">
        <Card sx={{ maxWidth: 500 }} className="service-home-card">
          <CardMedia
            component="img"
            image={Images.servicesImages}
            alt="green iguana"
          />
        </Card>
        <div className="service-div-content">
          <Typography
            className="service-text-one"
            gutterBottom
            variant="h5"
            component="div"
          >
            {t('home.heading1BoxFirst')}
          </Typography>
          <Typography className="service-desc-one" variant="body2">
            {t('home.heading2BoxFirst')}
          </Typography>
        </div>
      </div>
      <br />
      <br />

      <div className="service-type-two">
        <Card sx={{ maxWidth: 500 }} className="service-home-card" I>
          <CardMedia
            component="img"
            image={Images.servicesImages}
            alt="green iguana"
          />
        </Card>
        <div className="service-div-content-two">
          <Typography
            className="service-text-two"
            gutterBottom
            variant="h5"
            component="div"
          >
            {t('home.heading1BoxSecond')}
          </Typography>
          <Typography
            className="service-desc-two"
            variant="body2"
            color="text.secondary"
          >
            {t('home.heading2BoxSecond')}
          </Typography>
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="service-type-one">
        <Card sx={{ maxWidth: 500 }} className="service-home-card">
          <CardMedia
            component="img"
            image={Images.servicesImages}
            alt="green iguana"
          />
        </Card>
        <div className="service-div-content">
          <Typography
            className="service-text-one"
            gutterBottom
            variant="h5"
            component="div"
          >
            {t('home.sectionFirstTitle')}
          </Typography>
          <Typography className="service-desc-one" variant="body2">
            {t('home.sectionBottomTitle')}
          </Typography>
        </div>
      </div>
      <br />
      <br />
    </Container>
  );
};

export default Services;
