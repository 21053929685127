import { useState } from "react";
import Notification from "../../components/Notification/Notification";
import PlatformHeader from "../../components/PageHeaders/Platform";
import { AllPlatforms } from "../../components/management/allPlatforms/AllPlatforms";
import { IPlatform } from "../../types";
import { useSelector } from "react-redux";

import "./Platform.scss";

const Platform = () => {
  const { loading, sites, error } = useSelector((state) => state.SitesReducer);
  const [filteredData, setFilteredData] = useState(sites);
  const [searchValue, setSearchValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const handleShowDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
    const result = sites?.filter((item: IPlatform) => {
      const titleSearch = item.name;
      console.log(searchValue);
      return titleSearch?.search(e.target.value.toLocaleLowerCase()) !== -1;
    });
    setFilteredData(result);
    if (e.target.value === "") {
      setFilteredData(sites);
    }
  };

  const clearSearch = () => {
    setSearchValue("");
    setFilteredData(sites);
  };

  const handleSortBy = (name: string) => {
    console.log(name);
    const sort_result = sites?.sort((a, b) => {
      if (name === "azplatefrom") {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      } else if (name === "zaplatefrom") {
        return -1 * a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      } else if (name === "recent") {
        return Date.parse(b.created_on) - Date.parse(a.created_on);
      } else if (name === "earlier") {
        return Date.parse(a.created_on) - Date.parse(b.created_on);
      }
    });
    setFilteredData(sort_result);
  };
  return (
    <div className="_management">
      <header>
        <h2>My platforms</h2>
        <Notification />
      </header>
      <PlatformHeader
        handleChange={handleSearch}
        handleShowDropdown={handleShowDropdown}
        show={showDropdown}
        searchValue={searchValue}
        clearSearch={clearSearch}
        sortBy={handleSortBy}
      />

      <AllPlatforms filteredData={filteredData} />
    </div>
  );
};
export default Platform;
