import { FaBars, FaAngleDown, FaPlus } from "react-icons/fa";
import { useState } from "react";

const EmptyLayoutBox = () => {
  const layoutList = [1, 2, 3, 4, 5];
  const [list, setList] = useState(layoutList);

  return (
    <div className="layoutBox">
      {list &&
        list.map((item, index) => (
          <span key={index}>
            <div className="LayoutGrid2Box mb-2">
              <div className="dragHandle2">
                <FaPlus className="text-dark" />
              </div>

              <b className="opacity">Component</b>
            </div>
          </span>
        ))}
    </div>
  );
};

export default EmptyLayoutBox;
