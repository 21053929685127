import { useState, useEffect } from "react";
import { FormControl } from "react-bootstrap";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { EditNewLayoutAction } from "../actions/ApiAction";
import { SHOW_MODAL_STATE } from "../constants/ApiConstants";
import EditModal from "./EditModal";

import MarkupRenderer from "../VanillaThemeTemplate/MarkupRenderer";
import UndoRedoButtons from "./UndoRedoButtons";
import CanvasTopBox from "./CanvasTopBox";

const NewMainScreen = () => {
  const dispatch = useDispatch();
  const { layout } = useSelector((state) => state.MarkupLayoutReducer.present);

  const layoutLength = layout ? layout.length : null;

  const saveHandler = () => {
    window.alert("This Functionality is under development");
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(layout);
    const [reOrderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reOrderedItem);

    dispatch(EditNewLayoutAction(items));
  };

  const { show: showFromStore } = useSelector(
    (state) => state.EditModalReducer
  );

  useEffect(() => {
    if (showFromStore) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  }, [layout, layoutLength, showFromStore]);

  return (
    <div className="mainScreen">
      <CanvasTopBox />

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="Navbar">
          {(provided) => (
            <div
              className="markupDiv"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {layout && layoutLength > 0 ? (
                <>
                  <MarkupRenderer></MarkupRenderer>
                  {provided.placeholder}
                </>
              ) : (
                <div className="text-center previewTXT">
                  <h1 className="font-weight-bold text-dark opacity">
                    Vannilla Theme
                    <br />
                    <small>
                      Click the "
                      <b className="font-weight-bold text-primary">+</b>" button
                      to add components to the canvas
                    </small>
                  </h1>
                </div>
              )}

              <EditModal
                show={showFromStore}
                onHide={() => dispatch({ type: SHOW_MODAL_STATE })}
              />
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default NewMainScreen;
