//import components
import React from "react";
import { connect } from "react-redux";
import { EditNewLayoutAction } from "../actions/ApiAction";
// import axios from "axios";

import NavBar from "./components/Navbar";
import Banner from "./components/Banner";
import CategoryList from "./components/CategoryList";
import NewItems from "./components/NewItems";
import Collections from "./components/Collections";
import Promotions from "./components/Promotions";
import Footer from "./components/Footer";

let renderLibrary = {
  core: {
    renderLayout: (layout) => {
      let renderedNode = [];
      for (let itemKey in layout) {
        let item = layout[itemKey];
        renderedNode.push(renderLibrary.components[item.type](item));
      }
      return <React.Fragment>{renderedNode}</React.Fragment>;
    },
  },
  components: {
    Root: (nodeData) => {
      return <div>{renderLibrary.core.renderLayout(nodeData.layout)}</div>;
    },
    Navbar: (nodeData) => {
      return <NavBar nodeData={nodeData} key={nodeData.type}></NavBar>;
    },
    Banner: (nodeData) => {
      return <Banner nodeData={nodeData} key={nodeData.type}></Banner>;
    },
    Category: (nodeData) => {
      return (
        <CategoryList nodeData={nodeData} key={nodeData.type}></CategoryList>
      );
    },
    NewItems: (nodeData) => {
      return <NewItems nodeData={nodeData} key={nodeData.type}></NewItems>;
    },
    Collection: (nodeData) => {
      return (
        <Collections nodeData={nodeData} key={nodeData.type}></Collections>
      );
    },
    Promotions: (nodeData) => {
      return <Promotions nodeData={nodeData} key={nodeData.type}></Promotions>;
    },
    Footer: (nodeData) => {
      return <Footer nodeData={nodeData} key={nodeData.type}></Footer>;
    },
  },
};
class MarkupRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      config: [
        {
          type: "Constants",
          api_endpoint: "http://127.0.0.1:8000/",
          api_collection: "swagger.json",
        },
      ],
      layout: [],
    };
  }
  async componentDidMount() {
    // var response = await axios
    //   .get("http://sunerpsolutions.in/dev/rander/layout_2.json");

    // this.setState({
    //   layout: [response.data],
    // });
    this.setState({
      layout: [this.props.newLayout],
    });
  }

  render() {
    return <div>{renderLibrary.core.renderLayout([this.props.newLayout])}</div>;
  }
}

const mapStateToProps = (state) => {
  return { newLayout: state.MarkupLayoutReducer.present };
};

const mapDispatchToProps = () => {
  return { EditNewLayoutAction };
};

export default connect(mapStateToProps, mapDispatchToProps())(MarkupRenderer);

const s3 = "https://oss-backend-bucket.s3.me-south-1.amazonaws.com";

export const markupJson = {
  type: "Root",
  layout: [
    {
      type: "Navbar",
      data: {
        title: "Fukushima",
        navlinks: [
          {
            name: "Our Products",
            url: "/products",
          },
          {
            name: "Store Information",
            url: "/information",
          },
          {
            name: "Blogs",
            url: "/blogs",
          },
          {
            name: "Help",
            url: "/helps",
          },
        ],
        api: {
          search: "/search",
        },
      },
      styles: {
        titleColor: "#FF7969",
        bgColor: "#ffffff",
      },
      methods: [],
      layout: [],
    },
    {
      type: "Banner",
      data: {
        images: [s3 + "/template_assets/bannerGlass.png"],
      },
    },
    {
      type: "Category",
      data: {
        category_list: [
          {
            name: "Optical Glasses",
          },
          {
            name: "Sun Glasses",
          },
          {
            name: "Sport Glasses",
          },
          {
            name: "Fashion Glasses",
          },
        ],
      },
    },
    {
      type: "NewItems",
      data: {
        products: [
          {
            name: "Rounded Brooklyn",
            image: s3 + "/template_assets/brooklyn.png",
            price: "$ 45.50",
          },
          {
            name: "Rounded Brooklyn",
            image: s3 + "/template_assets/brooklyn.png",
            price: "$ 45.50",
          },
          {
            name: "Rounded Brooklyn",
            image: s3 + "/template_assets/brooklyn.png",
            price: "$ 45.50",
          },
          {
            name: "Rounded Brooklyn",
            image: s3 + "/template_assets/brooklyn.png",
            price: "$ 45.50",
          },
          {
            name: "Rounded Brooklyn",
            image: s3 + "/template_assets/brooklyn.png",
            price: "$ 45.50",
          },
          {
            name: "Rounded Brooklyn",
            image: s3 + "/template_assets/brooklyn.png",
            price: "$ 45.50",
          },
          {
            name: "Rounded Brooklyn",
            image: s3 + "/template_assets/brooklyn.png",
            price: "$ 45.50",
          },
          {
            name: "Rounded Brooklyn",
            image: s3 + "/template_assets/brooklyn.png",
            price: "$ 45.50",
          },
        ],
      },
    },
    {
      type: "Collection",
      data: {
        products: [
          {
            image: s3 + "/template_assets/roundedGlass.png",
            text: "Rounded SunGlasses",
          },
          {
            image: s3 + "/template_assets/metalGlass.png",
            text: "Metal Frames",
          },
          {
            image: s3 + "/template_assets/metalGlass.png",
            text: "Metal Frames",
          },
          {
            image: s3 + "/template_assets/roundedGlass.png",
            text: "Rounded SunGlasses",
          },
        ],
      },
    },
    {
      type: "Promotions",
      data: {
        title: "Our Promotions",
        promotion_list: [
          {
            name: "Rounded Brooklyn",
            image: s3 + "/template_assets/swiperGlass.png",
            price: "$ 50.00",
            discountPrice: "$ 45.50",
          },
          {
            name: "Rounded Brooklyn",
            image: s3 + "/template_assets/swiperGlass.png",
            price: "$ 50.00",
            discountPrice: "$ 45.50",
          },
          {
            name: "Rounded Brooklyn",
            image: s3 + "/template_assets/swiperGlass.png",
            price: "$ 50.00",
            discountPrice: "$ 45.50",
          },
          {
            name: "Rounded Brooklyn",
            image: s3 + "/template_assets/swiperGlass.png",
            price: "$ 50.00",
            discountPrice: "$ 45.50",
          },
          {
            name: "Rounded Brooklyn",
            image: s3 + "/template_assets/swiperGlass.png",
            price: "$ 50.00",
            discountPrice: "$ 45.50",
          },
          {
            name: "Rounded Brooklyn",
            image: s3 + "/template_assets/swiperGlass.png",
            price: "$ 50.00",
            discountPrice: "$ 45.50",
          },
          {
            name: "Rounded Brooklyn",
            image: s3 + "/template_assets/swiperGlass.png",
            price: "$ 50.00",
            discountPrice: "$ 45.50",
          },
          {
            name: "Rounded Brooklyn",
            image: s3 + "/template_assets/swiperGlass.png",
            price: "$ 50.00",
            discountPrice: "$ 45.50",
          },
          {
            name: "Rounded Brooklyn",
            image: s3 + "/template_assets/swiperGlass.png",
            price: "$ 50.00",
            discountPrice: "$ 45.50",
          },
          {
            name: "Rounded Brooklyn",
            image: s3 + "/template_assets/swiperGlass.png",
            price: "$ 50.00",
            discountPrice: "$ 45.50",
          },
        ],
      },
    },
    {
      type: "Footer",
      data: {
        our_products: [
          {
            title: "Our products",
          },
          {
            name: "Tops & t-shirt",
            url: "/tops&t-shirt",
          },
          {
            name: "Jersey",
            url: "/jersey",
          },
          {
            name: "Jackets",
            url: "/jackets",
          },
          {
            name: "Bottoms & shorts",
            url: "/bottoms-shorts",
          },
          {
            name: "Shoes",
            url: "/shoes",
          },
          {
            name: "Accessories",
            url: "/accessories",
          },
        ],

        column_three: [
          {
            name: "Store information",
            url: "/store-information",
          },
          {
            name: "Our blogs",
            url: "/our-blogs",
          },
          {
            name: "Help",
            url: "/help",
          },
        ],

        column_four: [
          {
            logo: s3 + "/template_assets/Fukushima.png",
            address:
              "Old Airport Raod, Kodihalli, Bangalore 560008, Karnataka, India,",
            instagram: "./instagram.svg",
            twitter: "./twitter.svg",
            facebook: "./facebook.svg",
          },
        ],
      },
    },
  ],
  data: [],
  methods: [],
};
