import "./loader.css";

const Loader = () => {
  return (
    <div className="loaderDivi">
      <h1 className="font-weight-bold  text-uppercase text-white">
        Your site is building, please wait!
      </h1>
      <div class="lds-facebooki">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
