import React from "react";
import Images from "../component/Images/Images";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, Box, TextField, Grid, Container } from "@mui/material";
import Header from "../component/Header";
import { InputBase } from "@mui/material";
import styled from "@mui/system/styled"; 
import { useTranslation } from "react-i18next";



const BootstrapInput = styled(InputBase)(({ theme }) => ({
  marginTop: "20px",
  "label + &": {
    // marginTop: theme.spacing(3),
    color: "#474848",
    fontSize: 14,
  },
  "& .MuiInputBase-input": {
    fontFamily: "Montserrat",
    borderRadius: 8,
    border: "1px solid #DCDEE3",
    // position: "relative",
    backgroundColor: "#FFFFFF",
    fontSize: 14,
    // width: "38rem",
    width:"315px",
    // height:'40px',
    padding: "10px 20px",
    color: "#9AA1AF",
  },
}));

export default function AboutUs() {
  const { t, i18n } = useTranslation();


  function signUpMail2(){
    var form = document.getElementById('sheetdb-form');
    form.addEventListener("submit", e => {
      e.preventDefault();
      fetch(form.action, {
          method : "POST",
          body: new FormData(document.getElementById("sheetdb-form")),
      }).then(
          response => response.json()
      ).then((html) => {
        // you can put any JS code here
        alert('Thanks for subscribing us ')
      });
    });
}

  const blockData = [
    {
      img:Images.rock,
      // {t('about.headingAbout')}
      heading:'Lorem ipsum dolor sit amet consecte.',
      description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    },
    {
      img:Images.rock,
      heading:'Lorem ipsum dolor sit amet consectetur.',
      description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    },
    {
      img:Images.rock,
      heading:'Lorem ipsum dolor sit amet consectetur.',
      description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    },
    {
      img:Images.rock,
      heading:'Lorem ipsum dolor sit amet consectetur.',
      description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    },
  ]

  return (
    <div>
      <div className="about-top ">
        <Header />
        <div className="bg-img-about">
            <p className="about-top-heading">
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod. */}
              {t('about.headingAbout')}
            </p>

              <span className="about-top-heading-para">
              {t('about.headingSecondAbout')}
              </span>
        </div>
      </div>

      <div className="aboutus-section-2" >
        <div className="about-heading-div" >
          <p className="about-heading-text" >
            {t('about.aboutThreeHeading')}
          </p>
        </div>
      </div>
        <Container maxWidth="lg" className='content-size'>
        
          {blockData.map((item,i) =>(

            <Grid container key={i}
              className="content-resizing1">

              <Grid item xs={5}>
                <img
                  src={item.img}
                  alt="rock"
                  
                  className="Imagesize"
                />
                <p className="card-heading" >
                  {/* {item.heading} */}
                  {t('about.boxOnetitle')}
                </p>
                <p className="card-desc" >
                 {/* {item.description} */}
                 {t('about.boxOneSubtitle')}
                </p>
              </Grid>
              <Grid item xs={7} >

              </Grid>
            </Grid>
          ))}
        </Container>
      <div className="theme-box">
        <div className="about-heading-div">
          <div className="about_heading-card heading-mobile-responsive">
            {t('about.businessText')}
          </div>
          <div className="about-top-heading-para" >
           {t('about.item')}
          </div>
        </div>
        <div className="theme-card">
          <div>
            <Card sx={{ maxWidth: 270, borderRadius: '10px' }}>
                <CardMedia
                  component="img"
                  image={Images.img_start}
                  alt="green iguana"
                />
            </Card>
            <div className="centering">
              <Typography gutterBottom component="div" className="lizard">
        
                {t('about.fukushimaTheme')}
              </Typography>
              <Typography variant="body2" className="lizard-desc">
                {t('about.fucushimaVarient')}
              </Typography>
            </div>
          </div>

          <div>
            <Card sx={{ maxWidth: 270, borderRadius: '10px' }}>
                <CardMedia
                  component="img"
                  // height="140"
                  image={Images.img_middle}
                  alt="green iguana"
                />
            </Card>
            <div className="centering about-card-content">
              <Typography gutterBottom component="div" className="lizard">
                {t('about.quebecTheme')}
              </Typography>
              <Typography className="lizard-desc">
               {t('about.quebecthemeVarient')}
              </Typography>
            </div>
          </div>

          <div>
            <Card sx={{ maxWidth: 270, borderRadius: '10px' }}>
                <CardMedia
                  component="img"
                  // height="140"
                  image={Images.img_last}
                  alt="green iguana"
                />
            </Card>
            <div className="centering about-card-content">
              <Typography gutterBottom component="div" className="lizard">
              {t('about.nairobeTheme')}
              </Typography>
              <Typography className="lizard-desc">
               {t('about.nairobethemeVarient')}
              </Typography>
            </div>
          </div>

          <div>
            <Card sx={{ maxWidth: 270, borderRadius: '10px' }}>
                <CardMedia
                  component="img"
                  // height="140"
                  image={Images.img_end}
                  alt="green iguana"
                />
            </Card>
            <div className="centering about-card-content">
              <Typography gutterBottom component="div" className="lizard">
               {t('about.helsinskyTheme')}
              </Typography>
              <Typography className="lizard-desc">
               {t('about.helsinskythemeVarient')}
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div className="faqEnquiry" >
        <Box component="form" autoComplete="off" >
          <Typography variant="h1" className="form-text mobile-responsive-form-text" style={{}}>
           {t('about.growyourBusiness')}
          </Typography>
          <Container maxWidth="xl" >

          <Grid container columnGap={2} mt={5} >

          <form method="post" id="sheetdb-form"
                        action="https://sheetdb.io/api/v1/uozq2k8n279s1">
              <BootstrapInput
                type="input"
                
                name="data[Email]"
                size="small"
                className="text-field input-box-mobile-responsive"
                placeholder={t('about.placeholder')}
              />
              
               <Button type="submit" onClick={signUpMail2} className="button-business">{t('about.signupNow')}</Button>
              </form>
          </Grid>
          </Container>

           <div></div>
        </Box>
      </div> 
    </div>
  );
}
