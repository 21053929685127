import React, { useEffect } from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import "./ChooseStore.scss";
import { useSelector, useDispatch } from "react-redux";
import { FILTER_ORDERS } from "../../../../UpdatedStore/constants/ManagementsConstants";
import { GetSitesAction } from "../../../../UpdatedStore/actions/SiteActions";

const MyComponent: React.FC = () => {
  const dispatch = useDispatch();
  const { sites } = useSelector((state) => state.SitesReducer);

  const { store } = useSelector((state) => state.FilterOrdersReducer);
  const { user } = useSelector((state) => state.AuthenticationReducer);

  const fetchSitesInventory = (value) => {
    dispatch({ type: FILTER_ORDERS, store: value });
  };

  useEffect(() => {
    if (!sites) {
      dispatch(GetSitesAction(user.access_token));
    }
  }, [sites]);

  return (
    <div className="_select-store">
      <Form.Control
        as="select"
        className="mb-3 shadow-none font-weight-bold"
        value={store}
        onChange={(e) => fetchSitesInventory(e.target.value)}
      >
        <option value="store" selected>
          ALL STORES
        </option>
        {sites &&
          sites.map((site, index) => (
            <option value={site.name} key={index}>
              {site.name}
            </option>
          ))}
      </Form.Control>
    </div>
  );
};

export default MyComponent;
