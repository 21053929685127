import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "./addStaff.scss";
import { useDispatch, useSelector } from "react-redux";
import { CREATE_STAFF_RESET } from "../../../UpdatedStore/constants/ManagementProductConstants";
import { createStaffAction } from "../../../UpdatedStore/actions/ProductManagementActions";
import {
  GetSitesAction,
  GetStaffsAction,
} from "../../../UpdatedStore/actions/SiteActions";

const AddStaff = () => {
  const dispatch = useDispatch();

  const { sites } = useSelector((state) => state.SitesReducer);
  const { user } = useSelector((state) => state.AuthenticationReducer);

  //   form states
  const [store, setStore] = useState("store");
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [modalShow, setModalShow] = React.useState(false);

  //   form states
  const [message, setMessage] = React.useState("");

  const { loading, error, success } = useSelector(
    (state) => state.CreateStaffReducer
  );

  const createStaffHandler = () => {
    if (store === "store") {
      setMessage("Please select a valid store");
    } else {
      if (fullname) {
        if (email) {
          dispatch(
            createStaffAction({
              name: fullname,
              email: email,
              password: password,
              site: store,
            })
          );
        } else {
          setMessage("Email is invalid!");
        }
      } else {
        setMessage("fullname is invalid!");
      }
    }
  };

  useEffect(() => {
    if (success) {
      setMessageTimeOut();
    }

    if (!sites) {
      dispatch(GetSitesAction(user.access_token));
    }

    var randomstring = require("randomstring");
    var config = randomstring.generate(10);

    setFullName("");
    setStore("store");
    setEmail("");
    setPassword(config);
  }, [success, modalShow, sites]);

  const setMessageTimeOut = () => {
    setTimeout(() => {
      dispatch({ type: CREATE_STAFF_RESET });
      dispatch(GetStaffsAction(user.access_token));

      setModalShow(false);
    }, 2000);
  };

  return (
    <>
      <Button className="_add-staff-btn" onClick={() => setModalShow(true)}>
        <span>
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="15" r="15" fill="#23AD83" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.75 8.75C14.1977 8.75 13.75 9.19772 13.75 9.75V13.75H9.75C9.19772 13.75 8.75 14.1977 8.75 14.75V15.25C8.75 15.8023 9.19772 16.25 9.75 16.25H13.75V20.25C13.75 20.8023 14.1977 21.25 14.75 21.25H15.25C15.8023 21.25 16.25 20.8023 16.25 20.25V16.25H20.25C20.8023 16.25 21.25 15.8023 21.25 15.25V14.75C21.25 14.1977 20.8023 13.75 20.25 13.75H16.25V9.75C16.25 9.19772 15.8023 8.75 15.25 8.75H14.75Z"
              fill="white"
            />
          </svg>
        </span>
        Add new staff
      </Button>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="_delete-modal"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <h1>Add New Staff</h1>

          <button onClick={() => setModalShow(false)} className="_close-modal">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="28" height="28" rx="8" fill="#E8ECF0" />
              <path
                d="M20.698 8.23344L19.7383 7.28517C19.4786 7.02549 19.0496 7.02549 18.7786 7.28517L13.9915 12.0721L9.1142 7.19484C8.85441 6.93505 8.42537 6.93505 8.15452 7.19484L7.19484 8.15452C6.93505 8.4142 6.93505 8.84323 7.19484 9.1142L12.061 13.9803L7.28517 18.7787C7.02549 19.0384 7.02549 19.4674 7.28517 19.7384L8.24484 20.6981C8.50452 20.9577 8.93355 20.9577 9.20452 20.6981L13.9915 15.911L18.7786 20.6981C19.0383 20.9577 19.4673 20.9577 19.7383 20.6981L20.698 19.7384C20.9576 19.4787 20.9576 19.0497 20.698 18.7787L15.8996 13.9916L20.6867 9.20463C20.9576 8.93344 20.9576 8.50441 20.698 8.23344Z"
                fill="#777C85"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="_hold-contentProduct">
            <Form.Control
              as="select"
              className="mb-3 shadow-none font-weight-bold"
              value={store}
              onChange={(e) => setStore(e.target.value)}
            >
              <option value="store" selected disabled>
                SELECT STORE
              </option>
              {sites &&
                sites.map((site, index) => (
                  <option value={site.name} key={index}>
                    {site.name}
                  </option>
                ))}
            </Form.Control>

            <Form.Group>
              <Form.Label className="text-muted font-weight-bold">
                Full Name*
              </Form.Label>
              <Form.Control
                value={fullname}
                onChange={(e) => {
                  const arr = e.target.value.split(" ");
                  if (arr.length > 0) {
                    for (var i = 0; i < arr.length; i++) {
                      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                    }
                    const str2 = arr.join(" ");
                    setFullName(str2);
                  } else {
                    setFullName(
                      e.target.value.charAt(0).toUpperCase() +
                        e.target.value.slice(1)
                    );
                  }
                }}
                type="text"
                placeholder="Full Name"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="text-muted font-weight-bold">
                Email*
              </Form.Label>
              <Form.Control
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Email"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="text-muted font-weight-bold">
                Generated Password
              </Form.Label>
              <Form.Control
                value={password}
                disabled
                type="text"
                placeholder="Generated Password"
              />
            </Form.Group>

            {error && <h6 className="text-danger my-1">{error}</h6>}
            {message && <h6 className="text-danger my-1">{message}</h6>}
            {success && <h6 className="text-success my-1">Successful...</h6>}
            <button
              className="_yes transcale"
              onClick={createStaffHandler}
              disabled={loading}
            >
              {loading ? "Processing..." : "Create Staff"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddStaff;
