import { useEffect } from "react";
import NewMainScreen from "../components/NewMainScreen";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../components/SideBar";
import DefaultCanvas from "./DefaultCanvas";
import MainScreen from "../secondTemplate/components/MainScreen";
import { MainScreen as ThirdMainScreen } from "../thirdTemplate/components/MainScreen";
import { MainScreen as FourthMainScreen } from "../fourthTemplate/components/MainScreen";
import { checkUser } from "../actions/ApiAction";
import Loader from "../components/pageLoader/Loader";

const NewBuilderScreen = ({ history }) => {
  const { theme } = useSelector((state) => state.EditThemeReducer);
  const { confirm } = useSelector((state) => state.BuilderConfirmReducer);
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.CreateSiteReducer);

  const auth = useSelector((state) => state.AuthenticationReducer);

  const token = auth.user?.access_token;
  useEffect(() => {
    if (token) {
      dispatch(checkUser(token));
    }
  }, [history, token, confirm]);

  return (
    <>
      {loading && <Loader />}
      <div className="screenRow">
        <SideBar />
        {theme && theme === "template" && <DefaultCanvas />}
        {theme && theme === "vanilla" && <NewMainScreen />}
        {theme && theme === "second" && <MainScreen />}
        {theme && theme === "third" && <ThirdMainScreen />}
        {theme && theme === "fourth" && <FourthMainScreen />}
      </div>
    </>
  );
};

export default NewBuilderScreen;
