import React, { useEffect, useState } from "react";
import { Button, Image, Form } from "react-bootstrap";
import DHL from "../../assets/icons/dhl.png";
import CreditLogo from "../../assets/icons/credit-logo.png";
import "./orderDetails.scss";
import { Link, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import OrderPdf from "./OrderPdf";
import { GetOrdersAction } from "../../UpdatedStore/actions/SiteActions";
import NoStaffInThisRole from "../../components/order/noStaffAddOne/NoStaffInThisRole";
import SpinnerLoader from "../../UpdatedStore/components/SpinnerLoader";
import Message from "../../UpdatedStore/components/Message";
import { updateOrderStatusAction } from "../../UpdatedStore/actions/ProductManagementActions";
import { UPDATE_ORDER_STATUS_RESET } from "../../UpdatedStore/constants/ManagementProductConstants";

const OrderDetails: React.FC = () => {
  const match = useRouteMatch();
  let site = match.params.site;
  let orderId = match.params.id;
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.AuthenticationReducer);
  const [orderStatus, setOrderStatus] = useState("-1");
  const [orderStatusCheck, setOrderStatusCheck] = useState("-1");

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);

  const { loading, orders, error } = useSelector(
    (state) => state.OrdersReducer
  );

  const {
    loading: loadingStatus,
    error: errorStatus,
    success: successStatus,
  } = useSelector((state) => state.OrderStatusReducer);

  const loadOrderDetails = () => {
    orders.map((orderSite) => {
      if (orderSite.site.name === site) {
        const exists = orderSite.orders.find(
          (item__) => item__._id === Number(orderId)
        );
        setSelectedOrder(exists);
        setSelectedSite(
          JSON.parse(orderSite.site.layout).layout.find(
            (node) => node.type === "Navbar"
          )
        );

        if (
          exists?.isPaid &&
          !exists?.isShipped &&
          !exists?.isDelivered &&
          !exists?.isCancelled
        ) {
          setOrderStatus("0");
          setOrderStatusCheck("0");
        }

        if (
          exists?.isPaid &&
          exists?.isShipped &&
          !exists?.isDelivered &&
          !exists?.isCancelled
        ) {
          setOrderStatus("1");
          setOrderStatusCheck("1");
        }
        if (
          exists?.isPaid &&
          exists?.isShipped &&
          exists?.isDelivered &&
          !exists?.isCancelled
        ) {
          setOrderStatus("2");
          setOrderStatusCheck("2");
        }
        if (exists?.isCancelled) {
          setOrderStatus("3");
          setOrderStatusCheck("3");
        }
      }
      return;
    });
  };

  const updateStatusHandler = () => {
    if (Number(orderStatus) >= 0) {
      dispatch(
        updateOrderStatusAction({
          site: site,
          id: Number(selectedOrder?._id),
          status: Number(orderStatus),
        })
      );
    }
  };

  const cancelOrderHandler = () => {
    if (Number(orderStatus) >= 0) {
      dispatch(
        updateOrderStatusAction({
          site: site,
          id: Number(selectedOrder?._id),
          status: Number("3"),
        })
      );
    }
  };

  useEffect(() => {
    if (!orders) {
      dispatch(GetOrdersAction(user.access_token));
    }

    if (orders) loadOrderDetails();

    if (successStatus) {
      setMessageTimeOut();
    }
  }, [orders, successStatus]);

  const setMessageTimeOut = () => {
    setTimeout(() => {
      dispatch({ type: UPDATE_ORDER_STATUS_RESET });
      dispatch(GetOrdersAction(user.access_token));
    }, 2000);
  };

  return (
    <div className="order-detail">
      <header>
        <Link to="/dashboard/orders">
          <div className="linkBaclDiv">
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="42" height="42" rx="10" fill="#E8ECF0" />
              <path
                d="M13.3161 20.9959C13.3161 20.5659 13.4803 20.136 13.808 19.8081L24.1241 9.49217C24.7803 8.83594 25.8442 8.83594 26.5002 9.49217C27.1562 10.1481 27.1562 11.2119 26.5002 11.8682L17.372 20.9959L26.4999 30.1236C27.1559 30.7799 27.1559 31.8435 26.4999 32.4994C25.8439 33.156 24.78 33.156 24.1237 32.4994L13.8077 22.1836C13.4799 21.8556 13.3161 21.4257 13.3161 20.9959Z"
                fill="#9AA1AF"
              />
            </svg>
          </div>
        </Link>
        <div className="_title">
          <h2>My orders</h2>
          <span>Order details</span>
        </div>
      </header>
      {error && <Message variant="danger">{error}</Message>}
      {loading ? (
        <SpinnerLoader />
      ) : (
        <>
          {!selectedOrder ? (
            <NoStaffInThisRole text="Could not find specified order" />
          ) : (
            <div className="_hold-boxes">
              <div className="_box-one">
                <h4>Order details</h4>
                <div className="_details">
                  <div className="_hold-info-action">
                    <div className="_info">
                      <h2>ID: {selectedOrder?._id}</h2>
                      <div className="_purchase-status">
                        <p>{site && site}</p>
                        <span>
                          <svg
                            width="3"
                            height="3"
                            viewBox="0 0 3 3"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="1.5" cy="1.5" r="1.5" fill="#16284D" />
                          </svg>
                        </span>

                        <p>
                          {selectedOrder?.isPaid &&
                            !selectedOrder?.isShipped &&
                            !selectedOrder?.isDelivered &&
                            !selectedOrder?.isCancelled &&
                            "Purchased"}
                          {selectedOrder?.isPaid &&
                            selectedOrder?.isShipped &&
                            !selectedOrder?.isDelivered &&
                            !selectedOrder?.isCancelled &&
                            "Out For Delivery"}
                          {selectedOrder?.isPaid &&
                            selectedOrder?.isShipped &&
                            selectedOrder?.isDelivered &&
                            !selectedOrder?.isCancelled &&
                            "Delivered"}
                          {selectedOrder?.isCancelled && "Cancelled"}
                        </p>
                      </div>
                      <h6
                        className="text-danger my-3 cursor"
                        onClick={cancelOrderHandler}
                      >
                        Cancel this order
                      </h6>
                    </div>

                    <div className="_action">
                      {loadingStatus ? (
                        <SpinnerLoader />
                      ) : (
                        <>
                          <Form.Group className="mt-3">
                            <Form.Control
                              as="select"
                              className="shadow-none font-weight-bold"
                              value={orderStatus}
                              onChange={(e) => setOrderStatus(e.target.value)}
                            >
                              <option value="-1" disabled>
                                --- STATUS ---
                              </option>
                              {orderStatusCheck === "0" && (
                                <>
                                  {" "}
                                  <option value="0">PAID</option>
                                  <option value="1">DISPATCHED</option>
                                  <option value="3">CANCEL</option>
                                </>
                              )}
                              {orderStatusCheck >= "1" && (
                                <>
                                  {" "}
                                  <option value="0">PAID</option>
                                  <option value="1">DISPATCHED</option>{" "}
                                  <option value="2">DELIVERED</option>
                                  <option value="3">CANCEL</option>
                                </>
                              )}
                            </Form.Control>
                          </Form.Group>
                          {errorStatus && (
                            <h6 className="text-danger my-1">{errorStatus}</h6>
                          )}
                          {successStatus && (
                            <h6 className="text-success my-1">Successful...</h6>
                          )}

                          <Button
                            className="_proceed transcale"
                            onClick={updateStatusHandler}
                            disabled={loadingStatus}
                          >
                            {loadingStatus ? "Processing..." : "Update"}
                          </Button>
                        </>
                      )}

                      {/* <Button className="_proceed">Proceed</Button> */}
                      {/* <Proceed /> */}
                    </div>
                  </div>

                  <div className="_email-info-phone">
                    <div className="_line">
                      <div>Transaction date</div>
                      <div>
                        {" "}
                        {selectedOrder?.paidAt.substring(0, 10)} {"  "}
                        {selectedOrder?.paidAt.substring(11, 19)}
                      </div>
                    </div>

                    <div className="_line">
                      <div>Buyer name</div>
                      <div>{selectedOrder?.shipping.fullname}</div>
                    </div>

                    <div className="_line">
                      <div>Phone no</div>
                      <div>{selectedOrder?.shipping.phone_number}</div>
                    </div>
                    <div className="_line">
                      <div>Download Pdf</div>
                      <div>
                        {selectedOrder && (
                          <OrderPdf
                            selectedOrder={selectedOrder}
                            storeName={selectedSite?.data.title}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <h4>Ordered items</h4>
                {selectedOrder?.orders.map((orderItem_) => (
                  <div className="_ordered-items">
                    <div className="_item">
                      <div className="_photo">
                        <Image src={orderItem_.image} />
                      </div>
                      <div className="_detail">
                        <h5>{orderItem_.name}</h5>
                        <span>
                          Color:{" "}
                          {orderItem_.color ? orderItem_.color : "Invalid"},
                          Size: {orderItem_.size ? orderItem_.size : "Invalid"}
                        </span>
                        <div className="_size-price">
                          <span className="_size">x{orderItem_.qty}</span>
                          <span className="_price">$ {orderItem_.price}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="_box-two">
                <h3>Delivery details</h3>
                <div className="_card">
                  <div className="_head">
                    <div className="_logo-name">
                      <Image src={DHL} />
                      <h4>{selectedOrder?.deliveryMethod.title}</h4>
                    </div>
                    <span>$ {selectedOrder?.deliveryMethod.price}</span>
                  </div>

                  <div className="_place-info">
                    <span>
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="15" cy="15" r="15" fill="white" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.5 13.0189C8.5 9.42437 11.4159 6.5 15 6.5C18.5841 6.5 21.4999 9.42437 21.5 13.0189C21.5 17.4798 15.6831 24.0287 15.4355 24.3053C15.2033 24.5647 14.7971 24.5651 14.5645 24.3053C14.3169 24.0287 8.5 17.4798 8.5 13.0189ZM11.7297 13.0189C11.7297 14.8274 13.1967 16.2987 15 16.2987C16.8032 16.2987 18.2702 14.8274 18.2702 13.0189C18.2702 11.2104 16.8032 9.73909 15 9.73909C13.1968 9.73909 11.7297 11.2104 11.7297 13.0189Z"
                          fill="#FF870C"
                        />
                      </svg>
                    </span>
                    <p>
                      {`${selectedOrder?.shipping.postal_code}, ${selectedOrder?.shipping.district}, ${selectedOrder?.shipping.province}, ${selectedOrder?.shipping.address}`}
                    </p>
                  </div>
                </div>

                <div className="_payment-details">
                  <div className="_hold-credit">
                    <div className="_credit-card">
                      <Image src={CreditLogo} />
                    </div>
                    <div className="_info">
                      <h5>
                        {selectedOrder.paymentMethod
                          ? selectedOrder.paymentMethod
                          : "Not specified"}
                      </h5>
                    </div>
                  </div>

                  <div className="_taxes">
                    <div>
                      <li>Products/services price</li>
                      <li className="bold">
                        $
                        {selectedOrder &&
                          Number(selectedOrder?.totalPrice).toFixed(2) -
                            Number(selectedOrder?.shippingPrice).toFixed(2)}
                      </li>
                    </div>

                    <div>
                      <li>Delivery price</li>
                      <li className="bold">
                        $
                        {selectedOrder &&
                          Number(selectedOrder?.shippingPrice).toFixed(2)}
                      </li>
                    </div>

                    <div>
                      <li>Taxes</li>
                      <li className="bold">$0.00</li>
                    </div>
                    <div className="_add-border-dashed" />
                    <div>
                      <li>Total price</li>
                      <li className="bold">
                        $
                        {selectedOrder &&
                          Number(selectedOrder?.totalPrice).toFixed(2)}
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default OrderDetails;
