import React, { useEffect } from "react";
import { Button, Form, Modal, Dropdown } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { DeleteSiteAction } from "../../../../UpdatedStore/actions/ManagementActions";

import { useDispatch, useSelector } from "react-redux";
import "./logOut.scss";
import SpinnerLoader from "../../../../UpdatedStore/components/SpinnerLoader";
import { DELETE_STORE_RESET } from "../../../../UpdatedStore/constants/constants";

const DeleteSiteModal: React.FC = ({ site }) => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = React.useState(false);

  const { loading, error, success } = useSelector(
    (state) => state.DeleteStoreReducer
  );

  const handleLogout = () => {
    dispatch(DeleteSiteAction({ site: site }));
  };

  useEffect(() => {
    if (success) {
      setMessageTimeOut();
    }
  }, [success]);

  const setMessageTimeOut = () => {
    setTimeout(() => {
      dispatch({ type: DELETE_STORE_RESET });
      setModalShow(false);
    }, 2000);
  };

  return (
    <>
      <Dropdown.Item eventKey="4" onClick={() => setModalShow(true)}>
        Delete Site <FaTrash className="ml-auto text-danger" />
      </Dropdown.Item>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="_log-out-modal"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <button onClick={() => setModalShow(false)} className="_close-modal">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="28" height="28" rx="8" fill="#E8ECF0" />
              <path
                d="M20.698 8.23344L19.7383 7.28517C19.4786 7.02549 19.0496 7.02549 18.7786 7.28517L13.9915 12.0721L9.1142 7.19484C8.85441 6.93505 8.42537 6.93505 8.15452 7.19484L7.19484 8.15452C6.93505 8.4142 6.93505 8.84323 7.19484 9.1142L12.061 13.9803L7.28517 18.7787C7.02549 19.0384 7.02549 19.4674 7.28517 19.7384L8.24484 20.6981C8.50452 20.9577 8.93355 20.9577 9.20452 20.6981L13.9915 15.911L18.7786 20.6981C19.0383 20.9577 19.4673 20.9577 19.7383 20.6981L20.698 19.7384C20.9576 19.4787 20.9576 19.0497 20.698 18.7787L15.8996 13.9916L20.6867 9.20463C20.9576 8.93344 20.9576 8.50441 20.698 8.23344Z"
                fill="#777C85"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="_content">
            <h3 className="text-danger font-weight-bold">Delete Site</h3>
            <p className="text-info">
              This action is irreversible, Are you sure you want to delete this
              platform?
            </p>
          </div>
          {error && <h6 className="text-danger my-3">{error}</h6>}
          {success && <h6 className="text-success my-3">Successful...</h6>}

          <div className="_act">
            {loading ? (
              <SpinnerLoader />
            ) : (
              <>
                <Button className="_yes" onClick={handleLogout}>
                  yes
                </Button>
                <Button className="_no" onClick={() => setModalShow(false)}>
                  no
                </Button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteSiteModal;
