import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BsChevronDown } from "react-icons/bs";
import PlatformModal from "../modals/LivePlatforms";
import { GetPlatfromStaticsAction } from "../../UpdatedStore/actions/SiteActions";
import "./styles.scss";
import Message from "../../UpdatedStore/components/Message";
import SpinnerLoader from "../../UpdatedStore/components/SpinnerLoader";

const LatestPlatform = () => {
  const { loading, statics, success, error } = useSelector(
    (state) => state.PlatfromStaticsReducer
  );
  const { user } = useSelector((state) => state.AuthenticationReducer);
  const [platfromstatics, setPlatfromStatics] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    if (!statics) {
      dispatch(GetPlatfromStaticsAction(user.access_token));
    }
    if (statics) {
      setPlatfromStatics(statics);
    }
  }, [statics]);

  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const showModal = (title: string) => {
    setModalTitle(title);
    setShow(true);
  };

  return (
    <div>
      {loading && <SpinnerLoader />}
      {error && <Message variant={"danger"}>{error}</Message>}
      {!error && !loading && (
        <>
          <h4>General Statistics</h4>
          <div className="statsFlexBox">
            <div className="latets-platform-box">
              <div>
                <h5 className="fs-4 fw-bold">
                  {" "}
                  {platfromstatics?.total_platfrom}{" "}
                </h5>
                <p className="m-0 gray-text gray-color">All platforms</p>
              </div>
              {/* <div onClick={() => showModal('All platforms')}>
                    <BsChevronDown className="gray-color fs-3 fw-bold" />
                </div> */}
            </div>
            <div className="latets-platform-box d-flex justify-content-between align-items-center">
              <div>
                <h5 className="fs-4 fw-bold">
                  {" "}
                  {platfromstatics?.inactive_platfrom}{" "}
                </h5>
                <p className="m-0 gray-color">On Building</p>
              </div>
              {/* <div onClick={() => showModal('On building platforms')}>
                    <BsChevronDown className="gray-color fs-3 fw-bold" />
                </div> */}
            </div>
            <div className="latets-platform-box d-flex justify-content-between align-items-center">
              <div>
                <h5 className="fs-4 fw-bold">
                  {" "}
                  {platfromstatics?.active_platfrom}{" "}
                </h5>
                <p className="m-0 gray-color">Currently Live</p>
              </div>
              {/* <div onClick={() => showModal('Currently live platforms')}>
                    <BsChevronDown className="gray-color fs-3 fw-bold" />
                </div> */}
            </div>
          </div>

          <PlatformModal
            show={show}
            onHide={() => setShow(false)}
            title={modalTitle}
          />
        </>
      )}
    </div>
  );
};

export default LatestPlatform;
